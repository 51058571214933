import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Form, Button } from 'react-bootstrap';
import config from "../config";

import Dropzone from 'react-dropzone'
import axios from 'axios';
import swal from "sweetalert";
import { strings } from '../components/strings';
import Modal from 'react-bootstrap-modal';

class SupplierSamples extends Component {

    constructor (props) {
        super(props)

        this.state = {
            open: false,
            supplier: props.supplier
        }

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
    }

    open() {
        this.setState( { open:true } );
    }

    close() {
        this.setState( { open:false } )
    }

    render() {
        return (
            <>
                <button className="link-btn" onClick={this.open}><i className="fa fa-list-alt" /></button>
                <SamplesModal supplier={this.props.supplier} open={this.state.open} close={this.close} authuser={this.props.authuser} />
            </>
        )
    }
}

class SamplesModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            supplier: this.props.supplier,
            samples: this.props.supplier.samples,
            open: false,
            projects: []
        }

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.save = this.save.bind(this);
        this.buttons = this.buttons.bind(this);
        this.delete = this.delete.bind(this);
        this.openAttachment = this.openAttachment.bind(this);
        this.mailSample = this.mailSample.bind(this);
        this.update = this.update.bind(this);

        this.getSamples = this.getSamples.bind(this);
    }

    getSamples(){
      var authuser = this.props.authuser;
      console.log('authuser', authuser)
      fetch(config.BASE_URL + '/api/getsamples/'+this.state.supplier.supplierId, {
          method: 'GET',
          headers: {
              "Content-Type": "application/json",
              'Accept': 'application/json',
              "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
          }
      })
      .then(data => data.json())
      .then(data => {
          this.setState({
              samples : data.samples
          }, () => {

          });
      })
      .catch(error => console.log(error));
    }
    update(row) {

        var authuser = this.props.authuser

        fetch(config.BASE_URL + '/api/updatesample', {
          method: "POST",
          body: JSON.stringify(row),
          headers: {
              "Content-Type": "application/json",
              'Accept': 'application/json',
                  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
          })
          .then(data => data.json())
          .then(data => {
            this.setState(  {data: data.invoices} );
          })
          .catch(error => console.log(error));
      }

    mailSample(cell, row) {
        const authuser = this.props.authuser;

        const data = {supplierId : row.supplierId, sampleId: row.sampleId, path: `${config.UPLOAD_PATH}`};

        fetch(config.BASE_URL + '/api/mailsample', {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {
            swal({
                title: `Sent!`,
                text: `The sample request sent to custmer`,
                icon: "success",
            })
        })
        .catch(error => console.log(error));
    }

    openAttachment(cell, row) {
      if (row.sampleAttachments){
        window.open(`${config.UPLOAD_URL}uploads/samples/${row.sampleAttachments}`);
      }
    }

    delete(cell, row) {

        swal({
            title: `${strings.Areyousure}`,
            text: `${strings.Thisactioncannotbeundone}`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {

                const authuser = this.props.authuser;

                const data = {supplierId : row.supplierId, sampleId: row.sampleId}

                fetch(config.BASE_URL + '/api/deletesample', {
                    body: JSON.stringify(data),
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        'Accept': 'application/json',
                        "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
                    }
                })
                .then(data => data.json())
                .then(data => {
                    this.getSamples()
                })
                .catch(error => console.log(error));
            }
          });
    }

    buttons(cell, row) {
        return (
            <>
                <button data-tip="Delete Sample Request" onClick={ () => this.delete(cell, row) } className='link-btn'><i className="fa fa-trash" /></button>
                <button data-tip="Show Attachment" onClick={ () => this.openAttachment(cell, row) } className="link-btn"><i className="fa fa-file-invoice" /></button>
                <button data-tip="Email Sample Request" onClick={ () => this.mailSample(cell, row) } className="link-btn"><i className="fa fa-envelope" /></button>
            </>
        )
    }

    componentDidMount() {
        //const authuser = this.props.authuser;
    }

    save(data) {

        const authuser = this.props.authuser;

        fetch(config.BASE_URL + '/api/addsample', {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {
            this.getSamples();
            this.setState( { open: false } );
        })
        .catch(error => console.log(error));
    }

    open() {
        this.setState( { open: true } )
    }

    close() {
        this.setState( { open: false } )
    }

    render() {

        const data = this.state.samples;
        console.log('data', data)
        const options = {
            prePage: "Previous", // Previous page button text
            nextPage: "Next", // Next page button text
            noDataText: "No records found",
            that: this, // <- needed this "this" in the search event handler
            sizePerPage: 25,
            sizePerPageList: [
                {
                    text: "5",
                    value: 5
                },
                {
                    text: "10",
                    value: 10
                },
                {
                    text: "25",
                    value: 25
                },
                {
                    text: "50",
                    value: 50
                },
                {
                    text: "All",
                    value:
                        data.length > 0
                            ? data.length
                            : 5
                }
            ]
        };

        const statusOptions = ['Pending', 'Sent', 'Receipt'];
        const typeOptions = ['Colours', 'Materials'];

        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            afterSaveCell: this.update
        }

        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={this.props.close}
                    className="InvoiceModal">
                    <Modal.Header closeButton>
                        Sample Requests - {this.props.supplier.supplierName}
                    </Modal.Header>
                    <Modal.Body>

                        <BootstrapTable
                            ref='table'
                            data={data}
                            pagination={true}
                            options={options}
                            cellEdit={cellEditProp}
                            search={true}
                            hover={true}
                            >
                            <TableHeaderColumn
                                hidden={true}
                                dataField='sampleId'
                                dataSort={true}
                                isKey={true}>
                                ID
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField='samplecreatedDate'
                                dataSort={true}
                                editable={false}
                                width={'15%'}
                                >Created Date
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField='sampleRefNo'
                                dataSort={true}
                                editable={ {type: 'text'} }
                                width={'10%'}
                                >Ref No
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='sampleStatus'
                                dataSort={true}
                                editable={ {type: 'select', options: { values: statusOptions } } }
                                width={'10%'}
                                >Status
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='sampleType'
                                dataSort={true}
                                editable={ {type: 'select', options: { values: typeOptions } } }
                                width={'10%'}
                                >Type
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField='sampleNotes'
                                dataSort={true}
                                editable={ {type: 'textarea'} }
                                >Notes
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataAlign='center'
                                headerAlign='center'
                                editable={false}
                                dataFormat={this.buttons}
                                width={'15%'}
                                >
                                <button onClick={this.open} className="btn add-btn purchasebtn"><i className="fa fa-plus" /></button>
                            </TableHeaderColumn>

                        </BootstrapTable>

                        <AddSamplesModal
                            close={this.close}
                            open={this.state.open}
                            save={this.save}
                            projects={this.state.projects}
                            supplier={this.props.supplier}
                            authuser={this.props.authuser} />

                    </Modal.Body>
                </Modal>

            </>
        )
    }
}


class AddSamplesModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            supplierId: props.supplier.supplierId,
            sampleAttachments: '',
            sampleNotes: '',
            sampleRefNo: '',
            uploaded: '',
            sampleType: 'Colours',
            sampleStatus: 'Pending',
            Projects: 'No project selected',
            ProjectSelected: [],
            InvoiceNoClass : 'form-control',
            AmountClass: 'form-control',
            projectClass: '',
            File: ''
        }

        this.drop = this.drop.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.showProjectList = this.showProjectList.bind(this);
        this.selectProject = this.selectProject.bind(this);
    }

    selectProject(id) {

        let projectSelected = this.state.ProjectSelected;

        let Amount = 0;

        if (projectSelected.indexOf(id) >= 0) {
            projectSelected = this.state.ProjectSelected.filter(projectId => {
                return projectId !== id;
            })
        } else {
            projectSelected.push(id);
        }

        this.props.projects.map(project => {
            if (projectSelected.indexOf(project.JobId) >= 0) {
                Amount += parseFloat(project.TotalCost);
            }

            return project;
        })

        this.setState( {ProjectSelected: projectSelected, Amount: Amount} );
    }

    showProjectList() {
        const projectClass = this.state.projectClass;

        if (projectClass === 'show') {
            this.setState( { projectClass: ''} );
        } else {
            this.setState( {projectClass: 'show'} );
        }
    }

    handleSubmit(e) {

        //this.props.save(this.state);
        e.preventDefault();
        this.props.save(this.state);
    }



    handleChange(e) {
        this.setState( { [e.target.name] : e.target.value, InvoiceNoClass: 'form-control', AmountClass: 'form-control'} );
    }

    drop(files) {

        const data = new FormData();

        var authuser = this.props.authuser;

        files.forEach((file) => {
            data.append(`file[]`, file, file.name);
        });

        data.append('path', `${config.UPLOAD_PATH}/uploads/samples/`);

        axios.post(
            config.BASE_URL + "/api/uploadsample",
            data ,
            {
                headers: {
                    "Content-Type": "application/json",
		            'Accept': 'application/json',
		            "Authorization" : 'Bearer ' + authuser.token
                 }
            }
        )
        .then(res => {

            const data = res.data;

            this.setState( { File: data.file, uploaded: data.uploaded} );
        });
    }

    render() {

        /* const projectSelected = this.state.ProjectSelected;

         const projects = this.props.projects.map((project) => {
            return (
                <li onClick={ () => this.selectProject(project.JobId, project.ProjectName) }>
                    <span className={projectSelected.indexOf(project.JobId) >= 0 ? 'selected' : ''}></span>
                    {`${project.JobId} - ${project.ProjectName}`}
                </li>
            )
        }); */
/*
        const projectNames = this.props.projects.filter( (project) => {
            return (projectSelected.indexOf(project.JobId) >= 0);
        });

         const pnames = projectNames.map( project => {
            return [`${project.JobId} - ${project.ProjectName}`];
        }); */



        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={this.props.close}
                    className='AddInvoiceModal LoginModal AddProduct SupplierModal'
                    >
                    <Modal.Header closeButton>
                        Add Request Sample
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={this.handleSubmit}>

                            <Form.Group>
                                <Form.Label>Ref No.</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="sampleRefNo"
                                    onChange={this.handleChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Type</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="sampleType"
                                    onChange={this.handleChange}
                                >
                                    <option value="Colours">Colours</option>
                                    <option value="Materials">Materials</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Notes</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="sampleNotes"
                                    onChange={this.handleChange}
                                    rows="2"
                                    style={{minHeight: '85px'}}
                                />
                            </Form.Group>

                            <Form.Group>

                                <Form.Label>Attachment</Form.Label>

                                <Dropzone
                                    onDrop={this.drop}
                                >
                                    {({getRootProps, getInputProps}) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <div className="drag-n-drop text-center" style={{padding: 'padding: 25px 15px'}}>
                                                Drag 'n' drop the attachment file here, or click to select the attachment file
                                                <div className="uploaded-invoice">{this.state.Attachments}</div>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>

                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Status</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="sampleStatus"
                                    onChange={this.handleChange}
                                >
                                    <option value="Pending">Pending</option>
                                    <option value="Sent">Sent</option>
                                    <option value="Receipt">Receipt</option>
                                </Form.Control>
                            </Form.Group>
                            <Button className="btn purchasebtn" type="submit">Save</Button>
                        </Form>
                    </Modal.Body>

                </Modal>
            </>
        )
    }
}

export default SupplierSamples
