import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import config from "../config";
import HeaderAdmin from '../components/HeaderAdmin';
import FooterAdmin from '../components/FooterAdmin';

import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';


class Activity extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      columnWidth: [20, 20, 20, 20, 20, 20]
    }

  }

  componentDidMount() {
    fetch(config.BASE_URL + '/api/getactivity', {
      method: "GET",
     
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json'
        }
      })
      .then(data => data.json())
      .then(data => {
        this.setState({data: data}, () => {
          for (let index = 0; index < 15; index += 1) {
            if ($(`.react-bs-container-header thead th:eq(${index})`).length === 0) break; // break if cant find any more columns
            
            $(`.react-bs-container-header thead th:eq(${index})`).resizable({
              minWidth: 50,
              handles: 'e',
              resize: (__event, ui) => {						
              const newColumnWidths = [...this.state.columnWidth];
              const container = $('.react-bs-container-header').width();
              newColumnWidths[index] = ui.size.width / container * 100;						
              this.setState({ columnWidth: newColumnWidths });
              },
            });
    
          }
        });
      })
      .catch(error => console.log(error));


  }
	
	dateFormatter = (cell, row) => {
		console.log('new date', new Date(row.Date).toLocaleString("en-AU", {timeZone: "Australia/Perth"}))
		return new Date(row.Date).toLocaleString("en-AU", {timeZone: "Australia/Perth"});
	}
	
  render() {

    const customer = []; 
    
    this.state.data.map( data => {
      customer[data.CustomerName] = data.CustomerName;
      return data;
    })

    const options = {
      prePage: "Previous", // Previous page button text
      nextPage: "Next", // Next page button text
      onRowClick: this.onRowClick,
      noDataText: "No records found",
      that: this, // <- needed this "this" in the search event handler
      sizePerPage: 25,
      sizePerPageList: [
          {
              text: "5",
              value: 5
          },
          {
              text: "10",
              value: 10
          },
          {
              text: "25",
              value: 25
          },
          {
              text: "50",
              value: 50
          },
          {
              text: "All",
              value:
                  this.state.data.length > 0
                      ? this.state.data.length
                      : 5
          }
      ]
  };

    return (
      <div className="admin-wrapper">
        <HeaderAdmin />
        <div className="dashboard-wrapper partscontent">
          <div className="dashboardhome">
            <div className="container-fluid dashboard-content jobspagecnt">
              <div className="row">
                <div className="col-md-12">
                  <ol className="breadcrumb breadm">
                    <li><a href="/admin">Admin</a></li>
                    <li><i className="fa fa-angle-right midarrow" /><a href="/admin/activity">Activity Log</a></li>
                  </ol>
                </div>
              </div>  
                    
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="inner-pagehead">
                    <h1>Activity Log</h1>
                  </div>       


                  <BootstrapTable
                      ref='table'
                      data={this.state.data}
                      pagination={true}
                      options={options}
                      search={true} 
                      hover={true}
                      trClassName={this.trClassFormat}>
                      <TableHeaderColumn 
                        hidden={true}
                        dataField='ActivityId'
                        dataSort={true}
                        isKey={true}>
                          Activity ID
                      </TableHeaderColumn>
                      <TableHeaderColumn 
                        dataField='Date'
                        dataSort={true}
                        width={this.state.columnWidth[1] + '%'}
						            dataFormat={ this.dateFormatter }
                      >Date</TableHeaderColumn>
                      <TableHeaderColumn dataField='IpAddress' width={this.state.columnWidth[2] + '%'} >IP Address</TableHeaderColumn>
                      <TableHeaderColumn 
                        dataField='CustomerName'
                        width={this.state.columnWidth[3] + '%'}
                        filter={ { type: 'SelectFilter', options: customer,  delay: 1000 } }
                         >Customer Name</TableHeaderColumn>
                      <TableHeaderColumn 
                        width={this.state.columnWidth[4] + '%'}
                        dataField='Activity'>
                        Activity
                      </TableHeaderColumn>                      
                      <TableHeaderColumn 
                        width={this.state.columnWidth[5] + '%'}
                        dataField='ProjectName'>
                        Project / Product
                      </TableHeaderColumn>                      
                  </BootstrapTable>

                </div>
              </div>
            </div>
          </div>

          <FooterAdmin />

        </div>
        
      </div>
    )
  }

}

export default Activity