import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { Carousel } from 'react-responsive-carousel';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Select, { components } from 'react-select';

import config from "../config";
import UploadImage from '../components/UploadImage';
import ColorSwatches from './ColorSwatches';

import { strings } from './strings';

/*const productImage = ["/images/slide_img1.jpg", "/images/slide_img2.jpg", "/images/slide_img1.jpg", "/images/slide_img2.jpg"]*/

class ProductHome extends Component {
  constructor(props) {
      super(props);
      this.state = {
        stockCodes: [],
        sku: '',
        materialType: [],
        doorStyle: [],
        brand: [],
        finish: [],
        colour: [],
        substrate: [],
        material: [],
        layouts: [],
        updateKey: ' ',
        products: props.products,
        product: props.product,
        doorHinge: [],
        shelves: [],
        materialArray: [],
        productsArray: localStorage.getItem("postData") ? JSON.parse(localStorage.getItem("postData")) : [],
        colorBoxUrl: "https://www.polytec.com.au/docs/technical/polytec-technical-availability-guide.pdf",
        hardware: [],
        rooms: [],

        DoorHardwareId: 1,

        maxShelve: 0,
        minShelve: 0,

        maxDrawer: 0,
        minDrawer: 0,

        minFixedShelve: 0,
        maxFixedShelve: 0,

        minAdjustableShelve: 0,
        maxAdjustableShelve: 0,

        minDrawerEqual: 0,
        maxDrawerEqual: 0,

        minDrawerInner: 0,
        maxDrawerInner: 0,

        minDrawerSmall: 0,
        maxDrawerSmall: 0,

        photoIndex: 0,
        isPopupOpen: false,

        WineRack: 0,
        MicrowaveOven: 0,

        cHeight: props.product && props.product.Height ? props.product.Height : "",
        cWidth: props.product && props.product.Width ? props.product.Width : "",
        cDepth: props.product && props.product.Depth ? props.product.Depth : "",
        cToeRecess: props.product && props.product.ToeRecess ? props.product.ToeRecess : "",
        cElevation: props.product && props.product.Elevation ? props.product.Elevation : "",
        cSoffitHeight: props.product && props.product.SoffitHeight ? props.product.SoffitHeight : "",
        cToeHeight: props.product && props.product.ToeHeight ? props.product.ToeHeight : "",

        HardwareLayout: props.product && props.product.HardwareLayout ? props.product.HardwareLayout : "",

        cWidthError: "",
        cHeightError: "",
        cDepthError: "",
        cToeHeightError: "",
        cToeRecessError: "",
        getRooms: false,
        productSave: props.productSave,
        editHinge: "",
        isLoaded: true,
        postData: {},
        jobProduct: [], //localStorage.getItem("jobProduct") ? JSON.parse(localStorage.getItem("jobProduct")) : [],
        dynProName: "",
        colourfilter: [],
        mcolour: "1",
        mshelves: "1",
        productprice: "",
        carcasecost: "",
        project: {},

        details: "",

        MaterialType1 : 0,
        MaterialType2 : 74,
        MaterialType3 : 0,
        MaterialType4 : 0,
        MaterialType5 : 0,
        MaterialType6 : 0,

        MaterialBrand1 : 0,
        MaterialBrand2 : 2,
        MaterialBrand3 : 0,
        MaterialBrand4 : 0,
        MaterialBrand5 : 0,
        MaterialBrand6 : 0,

        MaterialFinish1 : 0,
        MaterialFinish2 : 2,
        MaterialFinish3 : 0,
        MaterialFinish4 : 0,
        MaterialFinish5 : 0,
        MaterialFinish6 : 0,

        MaterialSubstrate1 : 2,
        MaterialSubstrate2 : 1,
        MaterialSubstrate3 : 0,
        MaterialSubstrate4 : 0,
        MaterialSubstrate5 : 0,
        MaterialSubstrate6 : 0,

        MaterialColour1 : 61,
        MaterialColour2 : 173,
        MaterialColour3 : 0,
        MaterialColour4 : 0,
        MaterialColour5 : 0,


        isLogin: false,

        Width: 600,
        Height: 860,
        Depth: 560,
        Elevation: null,
        RightScribe: null,
        ToeHeight: 110,
        ToeRecess: 30,
        SoffitHeight: null,
        PicturePath: null,
        DoorId: 2,
        WidthMin: 200,
        WidthMax: 600,
        HeightMin: 900,
        HeightMax: 900,
        DepthMin: 200,
        DepthMax: 800,
        ToeHeightMin: 90,
        ToeHeightMax: 170,
        ToeRecessMin: 30,
        ToeRecessMax: 30,
        SoffitHeightMin: null,
        SoffitHeightMax: null,
        ElevationMin: null,
        ElevationMax: null,
        TotalHardwareCost: 0,
        NoOfDoors: 1,

        fixedQty: props.product.fixedQty ? props.product.fixedQty : 3,
        equalQty: props.product.equalQty ? props.product.equalQty : 3,
        adjustableQty: props.product.adjustableQty ? props.product.adjustableQty : 3,

        GapTop:  3,
        GapBottom:  0,
        GapLeft:  1.5,
        GapRight:  1.5,

        HardwareId: 1,
        drawerHardwareId: 1,
        DoorHardwareId: 1,
        noOfShelve: 0,
        HingeId: 1,

        FixedShelve: 0,
        AdjustableShelve: 0,

        DrawerEqual : 1,
        DrawerInner : 0,
        DrawerSmall : 0,

        noOfDrawer: 0,
        min_order: 100,
        Gst: 0.1,
        allow : ["Pending"],
        productImage : [],

		detailImage: [],
		drawerGaps: props.product.drawerGaps,
		show: true,
		imageTips: {},
        hasError: false,
        StickerName: props.product && props.product.StickerName ? props.product.StickerName : '',
        stockCode: '',
        ProductType: '',
        ProductStockCode: '',
        //stockCode : '',
        ProductDescription: '',
        ProductPrice: 0,
        ProductQty: 1,
        ProductLineTotal: 0,

        MaxEqualDrawer: 0,
        MaxInnerDrawer: 0,
        MaxSmallDrawer: 0,
        hasInnerDrawer: 1,
        //relatedProducts: [],

        isCustomized: 0,
        NoOfMitre: '0',

        RoomId: 1,
        roomData : 'Room 1',
        slug: '',
        panelindex: 0,
        tooltipindex: 0,
        showtooltip: true,

        product_name: ''

      }

      this.button = this.button.bind(this);
      this.dimensionContent = this.dimensionContent.bind(this);
      this.materialsContent = this.materialsContent.bind(this);
      this.doorOptionsContent = this.doorOptionsContent.bind(this);
      this.shelvesContent = this.shelvesContent.bind(this);
      this.doorGapsContent = this.doorGapsContent.bind(this);
      this.layoutContent = this.layoutContent.bind(this);

      this.productChangeValues = this.productChangeValues.bind(this);
      this.handleColourChange = this.handleColourChange.bind(this);
	    this.handleHardwareChange = this.handleHardwareChange.bind(this);

      //this.getMaterial = this.getMaterial.bind(this);

      //this.getMaterialType = this.getMaterialType.bind(this);

      //this.getBrands = this.getBrands.bind(this);
      //this.getSubstrate = this.getSubstrate.bind(this);
      //this.getDoor = this.getDoor.bind(this);
      //this.getHardware = this.getHardware.bind(this);
      //this.getDoorHinge = this.getDoorHinge.bind(this);
      //this.getShelves = this.getShelves.bind(this);
      //this.getProductStockCodes = this.getProductStockCodes.bind(this);

      //this.getGst = this.getGst.bind(this);

      //this.getFinish = this.getFinish.bind(this);
      //this.getColour = this.getColour.bind(this);
      //this.getBrands = this.getBrands.bind(this);

      this.recalculate = this.recalculate.bind(this);

      this.updateCart = this.updateCart.bind(this);

      this.addToCart = this.addToCart.bind(this);

      this.updateImage = this.updateImage.bind(this);

      this.input = React.createRef();

      this.handleMaterialType = this.handleMaterialType.bind(this);
      this.handleMaterialBrand = this.handleMaterialBrand.bind(this);
      this.handleMaterialFinish = this.handleMaterialFinish.bind(this);
      this.handleMaterialSubstrate = this.handleMaterialSubstrate.bind(this);
      this.handleMaterialColour = this.handleMaterialColour.bind(this);

      this.handleColourSelect = this.handleColourSelect.bind(this);
      this.handleDetails = this.handleDetails.bind(this);
      this.stockCodeLayout = this.stockCodeLayout.bind(this);
      this.handleImageUpdate = this.handleImageUpdate.bind(this);

      this.removeImage = this.removeImage.bind(this);
      this.setData = this.setData.bind(this);

      this.showTooltip = this.showTooltip.bind(this);
      this.handleTabSelect = this.handleTabSelect.bind(this);
  }


  showTooltip () {
    const {showtooltip, tooltipindex, panelindex} = this.state
    if (!showtooltip) return;
    let panel = document.querySelectorAll('.react-tabs__tab-panel')[panelindex]
    let tooltip = panel.querySelectorAll(':not(.d-none) > .tooltip-button')[tooltipindex]
    //console.log('tooltip', tooltip)
    //console.log('tooltipindex', tooltipindex)
    if (typeof tooltip === 'undefined') {
      clearInterval(this.interval);
      return;
    }

    ReactTooltip.show(tooltip)
  }

  storeTabhandle(tab){
    let visitedtab = sessionStorage.getItem('visitedtab') || []

    if (!Array.isArray(visitedtab)) {
      visitedtab = JSON.parse(visitedtab)
    }

    if (visitedtab.indexOf(tab) >= 0){
      return false
    }
    //console.log('visitedtab', visitedtab)
    visitedtab.push(tab)
    sessionStorage.setItem('visitedtab', JSON.stringify(visitedtab));
    return true
  }

  handleTabSelect(index, lastindex, event){

    const tab = event.target.getAttribute('href')
    const showtooltip = this.storeTabhandle(tab)

    if (!showtooltip) return;

    const that = this
    clearInterval(this.interval);
    this.setState({
      showtooltip: true,
      panelindex: index,
      tooltipindex: 0
    }, () => {
      setTimeout(function(){ that.showTooltip(); }, 600);
    })

    this.interval = setInterval(() =>
      this.setState(prevState => {
          return {
            tooltipindex : prevState.tooltipindex + 1
          };
    }, () => {
          that.showTooltip();
    }), 4000);
  }

  handleImageUpdate(s) {

    if (s.Image) {

        const imageArr = s.Image.split(',');

        const images = imageArr.map(image => {
            image = `${config.BASE_URL + image}`;
            return image;
        });

        //console.log('sku', s.StockCode);

        const shelveId = s.ShelveId;
        const noOfShelve = s.Shelves;
        const hingeId = s.HingeId;

        this.setState( {
            productImage: images,
            sku: s.StockCode,
            noOfShelve: noOfShelve,
            shelveId: shelveId,
            HingeId: hingeId
        } );

    } else {
        this.setState({ productImage: ["/images/slide_img1.jpg", "/images/slide_img2.jpg", "/images/slide_img1.jpg", "/images/slide_img2.jpg"] } );
    }

  }

  stockCodeLayout() {

    const product = this.state.product;

    let productId = 0;

    if (product) {
        productId = product.ProductId;
    }

    if (productId > 0) {
        const stocks = this.state.stockCodes.filter( s => {
            return s.ProductId === productId;
        });

        return (
            <ul className="stock-codes">
               {
                   stocks.map( (s, key) => {

                       let images = [];
                       let hasImage = false;

                       if (s.Image) {
                           hasImage = true;
                           images = s.Image.split(',');
                       }

                       images = images.map( (img) => {
                            return `${config.BASE_URL}${img}`
                       });

                       return (
                            <li key={key} className={s.StockCode === this.state.sku ? 'selected' : ''}>
                                {hasImage ? <PopupImage selected={this.handleImageUpdate} stockCode={s} images={images} /> : ''}
                                {s.Details}
                            </li>
                       )
                   })
               }
            </ul>
        );
    }

  }


   handleDetails(e) {
       this.setState( {details: e.target.value} );
   }

    /* componentWillMount() {

    } */

    getImageTips() {
        fetch(config.BASE_URL + '/api/getimagetips', {
            method: "GET",
			headers: {
			  'Content-Type': 'application/json',
			  'Accept': 'application/json'
			}
        })
		.then(data => data.json())
		.then(data => {
			this.setState({
				imageTips: data.imageTips
      }, () => {
        const showtooltip = this.storeTabhandle('#dimension')
        if (showtooltip) {
          this.showTooltip()
          this.interval = setInterval(() =>
            this.setState(prevState => {
                return {
                  tooltipindex : prevState.tooltipindex + 1
                };
          }, () => {
                this.showTooltip();
          }), 4000);
        }
  		})
		})
		.catch(error => console.log(error));
	}

  updateImage() {

    const hingeId = parseInt(this.state.HingeId);
    const FixedShelve = parseInt(this.state.FixedShelve);
    const AdjustableShelve = parseInt(this.state.AdjustableShelve);
    const DrawerSmall = parseInt(this.state.DrawerSmall);
    const DrawerInner = parseInt(this.state.DrawerInner);
    const DrawerEqual = parseInt(this.state.DrawerEqual);


    const product = this.state.product;
    const DrawerHardware = parseInt(this.state.HardwareId);

    let product_name = product.ProductName;

    const wineRack = parseInt(this.state.WineRack);
    const microwaveOven = parseInt(this.state.MicrowaveOven);

    let stockCode = '';

    let isCustomized = 1;

    let images = [];


    let pStockCodes = this.state.stockCodes.filter( s => {
        return product.ProductId === parseInt(s.ProductId)
    });

    //console.log('pstock', pStockCodes);


    if (pStockCodes.length > 0) {

        if (product.hasDoor) {

            //console.log('h', hingeId);

            if (hingeId > 0) {
                pStockCodes = pStockCodes.filter( s => {
                    return s.HingeId === hingeId;
                })
            }

        }

        //console.log(pStockCodes);

        if (product.hasShelve) {

            if (FixedShelve > 0 && AdjustableShelve > 0) {
                pStockCodes = pStockCodes.filter( s => {
                    return s.FixedShelve === FixedShelve && s.AdjustableShelve === AdjustableShelve
                })
            } else if (FixedShelve > 0) {
                pStockCodes = pStockCodes.filter( s => {
                    return s.FixedShelve === FixedShelve
                })
            } else if (AdjustableShelve > 0) {
                pStockCodes = pStockCodes.filter( s => {
                    return s.AdjustableShelve === AdjustableShelve
                })
            } else {
                pStockCodes = pStockCodes.filter( s => {
                    return s.AdjustableShelve === 0 && s.FixedShelve === 0
                })
            }

            if (wineRack === 1) {

                pStockCodes = pStockCodes.filter( s => {

                    return s.WineRack === 1;

                });

            }

            if (microwaveOven === 1) {

                pStockCodes = pStockCodes.filter( s => {

                    return s.MicrowaveOven === 1

                })
            }

        }

        //console.log('pstockcode', pStockCodes);
        
        if (product.has_inner) {
          pStockCodes = pStockCodes.filter(s => {
              return s.DrawerInner === DrawerInner
          })        
        }
        
        if (product.hasDrawer) {

            if (DrawerSmall > 0) {
                pStockCodes = pStockCodes.filter(s => {
                    return s.DrawerSmall === DrawerSmall
                })
            }

            //console.log('drawer hardware', DrawerHardware);

            if (DrawerHardware > 0) {
                pStockCodes = pStockCodes.filter( s => {
                    return s.DrawerStyle === DrawerHardware
                })
            }

            pStockCodes = pStockCodes.filter(s => {
                    return s.DrawerEqual === DrawerEqual
            })

        }

        if (pStockCodes.length > 0) {



            stockCode = pStockCodes[0].StockCode;

            if (pStockCodes[0].displayname) {
                product_name = pStockCodes[0].displayname;
            }

            isCustomized = 0;

            pStockCodes = pStockCodes.filter( s => {
                return s.Image !== null
            });

            if (pStockCodes.length > 0) {

                const Image = pStockCodes[0].Image;

                if (Image) {

                    const imageArr = Image.split(',');

                    images = imageArr.map(image => {

                        image = `${config.BASE_URL + image}`;

                        return image;
                    });

                }
            }
        }

    }

    this.setState(
        {
            productImage: images,
            stockCode: stockCode,
            isCustomized: isCustomized,
            product_name: product_name
        }
    );

}


  updateCart() {
	//console.log('home updateCart', this.state);
    this.props.updateCart(this.state);
    this.setState({
			detailImage: [],
            updateKey: ''
		}, () => {
      //console.log('product', product)
		})
  }

  addToCart() {

    //console.log('add to cart', this.state);

    if (this.state.hasError) {
        return;
    }


    this.props.addToCart(this.state);

		this.setState({
			detailImage: []
		}, () => {
      //console.log('product', product)
		})

  }

  handleShelvesChange = (e) => {

    console.log(e.target.name, e.target.value)
    //const product = this.state.product;

    const name = e.target.name;

    let state = { [e.target.name] : e.target.value };

    if (name === 'WineRack') {

        if (this.state.WineRack === 0) {

            state = { WineRack: 1 }

        } else {

            state = { WineRack: 0 }
        }

    }

    if (name === 'MicrowaveOven') {

        if (this.state.MicrowaveOven === 0) {

            state = { MicrowaveOven: 1 }

        } else {

            state = { MicrowaveOven: 0 }
        }

    }



    this.setState(state, () => {
      //console.log('this.state', this.state)
      this.recalculate();
      this.updateImage();
    });

  }

  handleMaterialType(e) {

    const materials = this.state.material;

    let MaterialType1 = 0;
    let MaterialType2 = 0;
    let material = [];
    let brandId = 0;
    let finishes = [];
    let finishId = 0;
    let brands = [];
    let substrates = [];
    let substrateId = 0;
    let colours = [];
    let colourId = 0;

    switch (e.target.name) {

        case 'MaterialType1':

            MaterialType1 = parseInt(e.target.value);

            material = materials[0];

            brands = material.brand.filter( b => {
                return b.MaterialTypeId === MaterialType1
            });

            brandId = brands.length > 0 ? brands[0].BrandId : 0;

            finishes = material.finish.filter( f => {
                return f.BrandId === brandId;
            });

            finishId = finishes.length > 0 ? finishes[0].FinishId : 0;

            substrates = material.substrate.filter( s => {
                return s.BrandId === brandId;
            });

            substrateId = substrates.length > 0 ? substrates[0].SubstrateId : 0;

            colours = material.colour.filter( c => {
                return c.BrandId === brandId && c.FinishId === finishId && c.SubstrateId === substrateId;
            });

            colourId = colours.length > 0 ? colours[0].ColourId : 0;

            this.setState( {
                MaterialType1: parseInt(e.target.value),
                MaterialBrand1: brandId,
                MaterialFinish1: finishId,
                MaterialSubstrate1: substrateId,
                MaterialColour1: colourId
            });

        break;

        case 'MaterialBrand1':

            MaterialType1 = this.state.MaterialType1;

            material = materials[0];

            brandId = parseInt(e.target.value);

            finishes = material.finish.filter( f => {
                return f.BrandId === brandId;
            });

            finishId = finishes.length > 0 ? finishes[0].FinishId : 0;

            substrates = material.substrate.filter( s => {
                return s.BrandId === brandId;
            });

            substrateId = substrates.length > 0 ? substrates[0].SubstrateId : 0;

            colours = material.colour.filter( c => {
                return c.BrandId === brandId && c.FinishId === finishId && c.SubstrateId === substrateId;
            });

            colourId = colours.length > 0 ? colours[0].ColourId : 0;

            this.setState( {
                MaterialBrand1: parseInt(e.target.value),
                MaterialFinish1: finishId,
                MaterialSubstrate1: substrateId,
                MaterialColour1: colourId
            });

        break;

        case 'MaterialFinish1':

            material = materials[0];

            brandId = this.state.MaterialBrand1;

            finishId = parseInt(e.target.value)

            substrates = material.substrate.filter( s => {
                return s.BrandId === brandId;
            });

            substrateId = substrates.length > 0 ? substrates[0].SubstrateId : 0;

            colours = material.colour.filter( c => {
                return c.BrandId === brandId && c.FinishId === finishId && c.SubstrateId === substrateId;
            });

            colourId = colours.length > 0 ? colours[0].ColourId : 0;

            this.setState( {
                MaterialFinish1: parseInt(e.target.value),
                MaterialSubstrate1: substrateId,
                MaterialColour1: colourId
            });


        break;

        case 'MaterialSubstrate1':

            material = materials[0];

            brandId = this.state.MaterialBrand1;
            finishId = this.state.MaterialFinish1

            substrateId = parseInt(e.target.value);

            colours = material.colour.filter( c => {
                return c.BrandId === brandId && c.FinishId === finishId && c.SubstrateId === substrateId;
            });

            colourId = colours.length > 0 ? colours[0].ColourId : 0;

            this.setState( {
                MaterialSubstrate1: parseInt(e.target.value),
                MaterialColour1: colourId
            });

        break;

        case 'MaterialType2':

            MaterialType2 = parseInt(e.target.value);

            material = materials[1];

            brands = material.brand.filter( b => {
                return b.MaterialTypeId === MaterialType2
            });

            brandId = brands.length > 0 ? brands[0].BrandId : 0;

            finishes = material.finish.filter( f => {
                return f.BrandId === brandId;
            });

            finishId = finishes.length > 0 ? finishes[0].FinishId : 0;

            substrates = material.substrate.filter( s => {
                return s.BrandId === brandId;
            });

            substrateId = substrates.length > 0 ? substrates[0].SubstrateId : 0;

            colours = material.colour.filter( c => {
                return c.BrandId === brandId && c.FinishId === finishId && c.SubstrateId === substrateId;
            });

            colourId = colours.length > 0 ? colours[0].ColourId : 0;

            this.setState( {
                MaterialType2: parseInt(e.target.value),
                MaterialBrand2: brandId,
                MaterialFinish2: finishId,
                MaterialSubstrate2: substrateId,
                MaterialColour2: colourId,

                MaterialType3: parseInt(e.target.value),
                MaterialType4: parseInt(e.target.value),
                MaterialType5: parseInt(e.target.value)
            });

        break;

        case 'MaterialBrand2':

            material = materials[1];

            brandId = parseInt(e.target.value)

            finishes = material.finish.filter( f => {
                return f.BrandId === brandId;
            });

            finishId = finishes.length > 0 ? finishes[0].FinishId : 0;

            substrates = material.substrate.filter( s => {
                return s.BrandId === brandId;
            });

            substrateId = substrates.length > 0 ? substrates[0].SubstrateId : 0;

            colours = material.colour.filter( c => {
                return c.BrandId === brandId && c.FinishId === finishId && c.SubstrateId === substrateId;
            });

            colourId = colours.length > 0 ? colours[0].ColourId : 0;

            this.setState( {
                MaterialBrand2: parseInt(e.target.value),
                MaterialFinish2: finishId,
                MaterialSubstrate2: substrateId,
                MaterialColour2: colourId,

                MaterialBrand3: parseInt(e.target.value),
                MaterialBrand4: parseInt(e.target.value),
                MaterialBrand5: parseInt(e.target.value)
            });

        break;

        case 'MaterialFinish2':

            material = materials[1];

            brandId = this.state.MaterialBrand2;

            finishId = parseInt(e.target.value);

            substrates = material.substrate.filter( s => {
                return s.BrandId === brandId;
            });

            substrateId = substrates.length > 0 ? substrates[0].SubstrateId : 0;

            colours = material.colour.filter( c => {
                return c.BrandId === brandId && c.FinishId === finishId && c.SubstrateId === substrateId;
            });

            colourId = colours.length > 0 ? colours[0].ColourId : 0;

            this.setState( {
                MaterialFinish2: parseInt(e.target.value),
                MaterialSubstrate2: substrateId,
                MaterialColour2: colourId,

                MaterialFinish3: parseInt(e.target.value),
                MaterialFinish4: parseInt(e.target.value),
                MaterialFinish5: parseInt(e.target.value)
            });

        break;

        case 'MaterialSubstrate2':

            material = materials[1];

            brandId = this.state.MaterialBrand2;
            finishId = this.state.MaterialFinish2
            substrateId = parseInt(e.target.value);

            colours = material.colour.filter( c => {
                return c.BrandId === brandId && c.FinishId === finishId && c.SubstrateId === substrateId;
            });

            colourId = colours.length > 0 ? colours[0].ColourId : 0;

            this.setState( {
                MaterialSubstrate2: parseInt(e.target.value),
                MaterialColour2: colourId,

                MaterialSubstrate3: parseInt(e.target.value),
                MaterialSubstrate4: parseInt(e.target.value),
                MaterialSubstrate5: parseInt(e.target.value)
            });

        break;

        default:

    }

    /* if (e.target.name === 'MaterialType1') {

        this.setState( {
            MaterialType1: value
        }, () => {
            this.recalculate();
            this.updateImage();
        });

    } else {
        this.setState( {
            MaterialType2: value,
            MaterialType3: value,
            MaterialType4: value,
            MaterialType5: value
        }, () => {
            this.recalculate();
            this.updateImage();
        })
    } */

}

handleMaterialBrand(e) {
    const value = e.target.value;

    //console.log('handle', e.target.name)

    if (e.target.name === 'MaterialBrand1') {

        this.setState( {
            MaterialBrand1: value
        }, () => {
            this.recalculate();
            this.updateImage();
        });

    } else {
        this.setState( {
            MaterialBrand2: value,
            MaterialBrand3: value,
            MaterialBrand4: value,
            MaterialBrand5: value
        }, () => {
            this.recalculate();
            this.updateImage();
        })
    }
}

handleMaterialFinish(e) {
    const value = e.target.value;

    if (e.target.name === 'MaterialFinish1') {

        this.setState( {
            MaterialFinish1: value
        }, () => {
            this.recalculate();
            this.updateImage();
        });

    } else {
        this.setState( {
            MaterialFinish2: value,
            MaterialFinish3: value,
            MaterialFinish4: value,
            MaterialFinish5: value
        }, () => {
            this.recalculate();
            this.updateImage();
        })
    }
}

handleMaterialSubstrate(e) {
    const value = e.target.value;

    if (e.target.name === 'MaterialSubstrate1') {

        this.setState( {
            MaterialSubstrate1: value
        }, () => {
            this.recalculate();
            this.updateImage();
        });

    } else {
        this.setState( {
            MaterialSubstrate2: value,
            MaterialSubstrate3: value,
            MaterialSubstrate4: value,
            MaterialSubstrate5: value
        }, () => {
            this.recalculate();
            this.updateImage();
        })
    }
}

handleMaterialColour(e) {
    const value = e.target.value;

    if (e.target.name === 'MaterialColour1') {

        this.setState( {
            MaterialColour1: value
        }, () => {
            this.recalculate();
            this.updateImage();
        });

    } else {
        this.setState( {
            MaterialColour2: value,
            MaterialColour3: value,
            MaterialColour4: value,
            MaterialColour5: value
        }, () => {
            this.recalculate();
            this.updateImage();
        })
    }
}


  handleHardwareChange = (e) => {
	//var sectionId = e.target.name.slice(-1)
	var targetName = e.target.name
	//var trimName = targetName.replace(/[0-9]/g, '')
	var targetValue = e.target.value

	var drawerHardwareId = 'drawerHardwareId'

	this.setState((prevState, props) => {
		return {
			[targetName]: targetValue,
			[drawerHardwareId]: targetValue
		};
	}, () => {
        this.recalculate();
        this.updateImage();
    });
  }


  handleColourChange = (e) => {

    var sectionId = e.target.name.slice(-1)
	var targetName = e.target.name
	var trimName = targetName.replace(/[0-9]/g, '')
	var targetValue = e.target.value

	//console.log('e', e, targetName, targetValue);

	//console.log('trimName', trimName, targetValue);

	this.setState((prevState, props) => {
		return {

			[targetName]: targetValue,

			[trimName+'3']: (sectionId === '1' ) ? targetValue : prevState[`${trimName}3`],
			[trimName+'4']: (sectionId === '1' ) ? targetValue : prevState[`${trimName}4`],
			[trimName+'5']: (sectionId === '1' ) ? targetValue : prevState[`${trimName}5`],
		};
	}, () => {
		//console.log(this.state);
        this.recalculate();
        this.updateImage();
    });
  }

  handleBrand(e, productsectionId, key, msection) {

    var brandId = e.target.value

    var colorUrlBox = "color" + msection.replace(/ /g, '')
    var colorBoxUrl = brandId === 1 ? "https://www.polytec.com.au/docs/technical/polytec-technical-availability-guide.pdf" : brandId === 2 ? "https://gocabinets.com.au/wp-content/uploads/2014/02/Formica-Colours.pdf" : "https://selector.com/media/files/laminex-colour-palette-brochure-1.pdf"


	var sectionId = e.target.name.slice(-1)
	var targetName = e.target.name
	var trimName = targetName.replace(/[0-9]/g, '')
	var targetValue = e.target.value

	this.setState((prevState, props) => {
		return {
			[colorUrlBox]: colorBoxUrl,
			[`MaterialBrand${productsectionId}`] : targetValue,

			[trimName+'3']: (sectionId === '1' ) ? targetValue : prevState[`${trimName}3`],
			[trimName+'3']: (sectionId === '1' ) ? targetValue : prevState[`${trimName}3`],
			[trimName+'4']: (sectionId === '1' ) ? targetValue : prevState[`${trimName}4`],
			[trimName+'5']: (sectionId === '1' ) ? targetValue : prevState[`${trimName}5`],
		};
	}, () => {
		//console.log(this.state);
        //this.recalculate();
        //this.updateImage();
    });

	//console.log('material')
    //console.log(this.state.material)

    this.state.material.map((Material, key) => {

            fetch(config.BASE_URL + '/api/getfinish/' + brandId, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(data => data.json())
            .then(data => {

                let material = this.state.material;

                material[key].finish = data

                this.setState({ material: material })

            })
            .catch(error => console.log(error));

            return Material;

    })

    fetch(config.BASE_URL + '/api/getcolour/' + brandId, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(data => data.json())
        .then(data => {

            let material = this.state.material;

            material[key].colour = data

            this.setState({ material: material, colourfilter: data })

        })
        .catch(error => console.log(error));
}

  getGst() {


  }

  
  productChangeValues(e) {

    if (e.target.name === "GapTop" && e.target.value != '') {
      if (isNaN(e.target.value)) {
        return;
      }
    }

    if (e.target.name === "GapBottom" && e.target.value != '') {
      if (isNaN(e.target.value)) {
        return;
      }
    }
    if (e.target.name === "GapLeft" && e.target.value != '') {
      if (isNaN(e.target.value)) {
        return;
      }
    }
    if (e.target.name === "GapRight" && e.target.value != '') {
      if (isNaN(e.target.value)) {
        return;
      }
    }


    this.setState(
      {
        [e.target.name] : e.target.value,
      });

        if (e.target.name === "Width") {

          if ((e.target.value >= this.state.product.WidthMin) && (e.target.value <= this.state.product.WidthMax)) {
                this.setState({
                    WidthError: "",
                    hasError: false
                })
            } else {
                var cWidthError = `Please enter min ${this.state.product.WidthMin} and max  ${this.state.product.WidthMax}`
                this.setState({
                    WidthError: cWidthError,
                    hasError: true
                })
            }
        }

        if (e.target.name === "Height") {

            if ((e.target.value >= this.state.product.HeightMin) && (e.target.value <= this.state.product.HeightMax)) {

                this.setState({
                    HeightError: "",
                    hasError: false
                })
            } else {
                var cHeightError = `Please enter min ${this.state.product.HeightMin} and max  ${this.state.product.HeightMax}`
                this.setState({
                    HeightError: cHeightError,
                    hasError: true
                })
            }
        }

        if (e.target.name === "Depth") {
            if ((e.target.value >= this.state.product.DepthMin) && (e.target.value <= this.state.product.DepthMax)) {
                this.setState({
                    DepthError: "",
                    hasError: false
                })
            } else {
                var cDepthError = `Please enter min ${this.state.product.DepthMin} and max  ${this.state.product.DepthMax}`
                this.setState({
                    DepthError: cDepthError,
                    hasError: true
                })
            }
        }

        if (e.target.name === "ToeRecess") {

          if (this.state.product.ProductName === 'Overhead 1 Door' || this.state.product.ProductName === 'Overhead Open') {
                this.setState({
                    elevation: e.target.value
                }, () => {
                    if ((e.target.value >= this.state.product.ElevationMin) && (e.target.value <= this.state.product.ElevationMax)) {
                        this.setState({
                            ToeRecessError: "",
                            hasError: false
                        })
                    } else {
                        const cToeRecessError1 = `Please enter min ${this.state.product.ElevationMin} and max  ${this.state.product.ElevationMax}`
                        this.setState({
                            ToeRecessError: cToeRecessError1,
                            hasError: true
                        })
                    }
                })

            } else {
                if ((e.target.value >= this.state.product.ToeRecessMin) && (e.target.value <= this.state.product.ToeRecessMax)) {
                    this.setState({
                        ToeRecessError: "",
                        hasError: false
                    })
                } else {
                    const cToeRecessError2 = `Please enter min ${this.state.product.ToeRecessMin} and max  ${this.state.product.ToeRecessMax}`
                    this.setState({
                        ToeRecessError: cToeRecessError2,
                        hasError: true
                    })
                }
            }

        }

        if (e.target.name === "ToeHeight") {
            if (this.state.product.ProductName === 'Overhead 1 Door' || this.state.product.ProductName === 'Overhead Open') {
                this.setState({
                    SoffitHeight: e.target.value
                }, () => {
                    if ((e.target.value >= this.state.product.SoffitHeightMin) && (e.target.value <= this.state.product.SoffitHeightMax)) {
                        this.setState({
                            ToeHeightError: "",
                            hasError: false
                        })
                    } else {
                        var cToeHeightError = `Please enter min ${this.state.product.SoffitHeightMin} and max  ${this.state.product.SoffitHeightMax}`
                        this.setState({
                            ToeHeightError: cToeHeightError,
                            hasError: true
                        })
                    }
                })
                if ((e.target.value >= this.state.product.SoffitHeightMin) && (e.target.value <= this.state.product.SoffitHeightMax)) {
                    this.setState({
                        ToeHeightError: "",
                        hasError: false
                    })
                } else {
                    const cToeHeightError1 = `Please enter min ${this.state.product.SoffitHeightMin} and max  ${this.state.product.SoffitHeightMax}`
                    this.setState({
                        ToeHeightError: cToeHeightError1,
                        hasError: true
                    })
                }
            } else {
                if ((e.target.value >= this.state.product.ToeHeightMin) && (e.target.value <= this.state.product.ToeHeightMax)) {
                    this.setState({
                        ToeHeightError: "",
                        hasError: false
                    })
                } else {
                    const cToeHeightError2 = `Please enter min ${this.state.product.ToeHeightMin} and max  ${this.state.product.ToeHeightMax}`
                    this.setState({
                        ToeHeightError: cToeHeightError2,
                        hasError: true
                    })
                }
            }
        }

      this.recalculate();
  }

  dimensionContent() {
    const {CabinetHeight, CabinetWidth, CabinetDepth, KickerHeight} = this.state.imageTips;

    const product = this.state.product;

    return (
      <>
        <h3>Select Stock Size</h3>
        <div className="selectsizes">
          <ul>
              <li className="selected"><span>{this.state.Height} X {this.state.Width} X {this.state.Depth} </span></li>
          </ul>
        </div>
        <div className="col-md-12 createcustomsize">
          <h3>Create Custom Size</h3>
          <div className="cabinetsizes">

              <div className="col-md-7 leftlable">
              <button className="tooltip-button"
                data-tip={
                    CabinetHeight && CabinetHeight.video ?
                    ('<iframe className="sproutvideo-player" src="'+CabinetHeight.video+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : CabinetHeight && CabinetHeight.text ? CabinetHeight.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.CabinetHeight}
              </div>

              <div className="col-md-5 rightqty">
                <div className="qty mt-5">
                    <input
                        type="number"
                        id="cHeight"
                        className="count"
                        name="Height"
                        value={this.state.Height}
                        onChange={this.productChangeValues} />
                </div>
              </div>

          </div>

          <div style={{ fontSize: 14, color: "red", marginBottom: 20 }}> {this.state.HeightError} </div>

          <div className="cabinetsizes">

              <div className="col-md-7 leftlable">

                <button className="tooltip-button" data-tip={CabinetWidth && CabinetWidth.video ? ('<iframe className="sproutvideo-player" src="'+CabinetWidth.video+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : CabinetWidth && CabinetWidth.text ? CabinetWidth.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.CabinetWidth}

              </div>

              <div className="col-md-5 rightqty">
                <div className="qty mt-5">
                    <input type="number"
                        id="cHeight"
                        className="count"
                        name="Width"
                        value={this.state.Width}
                        onChange={this.productChangeValues} />
                </div>
              </div>

          </div>

          <div style={{ fontSize: 14, color: "red", marginBottom: 20 }}> {this.state.WidthError} </div>

          <div className="cabinetsizes">

              <div className="col-md-7 leftlable">

                <button className="tooltip-button" data-tip={CabinetDepth && CabinetDepth.video ? ('<iframe className="sproutvideo-player" src="'+CabinetDepth.video+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : CabinetDepth && CabinetDepth.text ? CabinetDepth.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.CabinetDepth}
              </div>

              <div className="col-md-5 rightqty">
                <div className="qty mt-5">
                    <input type="number"
                        id="cHeight"
                        className="count"
                        name="Depth"
                        value={this.state.Depth}
                        onChange={this.productChangeValues} />
                </div>
              </div>
          </div>

          <div style={{ fontSize: 14, color: "red", marginBottom: 20 }}> {this.state.DepthError} </div>

          {product.has_kicker === 1 ?

          <>

            <div className="cabinetsizes">

                <div className="col-md-7 leftlable">

                    <button className="tooltip-button" data-tip={KickerHeight && KickerHeight.video ? ('<iframe className="sproutvideo-player" src="'+KickerHeight.video+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') :  KickerHeight && KickerHeight.text ? KickerHeight.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.KickerHeight}

                </div>

                <div className="col-md-5 rightqty">
                    <div className="qty mt-5">
                        <input type="number"
                            id="cHeight"
                            className="count"
                            name="ToeHeight"
                            value={this.state.ToeHeight}
                            onChange={this.productChangeValues} />
                    </div>
                </div>
            </div>

            <div style={{ fontSize: 14, color: "red", marginBottom: 20 }}> {this.state.ToeHeightError} </div>
            <ReactTooltip place="top" html={true} globalEventOff='click' />
        </>

          : '' }

        </div>

        </>
    )

  }

  doorOptionsContent() {

    const doorHinge = this.state.doorHinge;

	const hardware = this.state.hardware.filter(h => {
        return h.HardwareName.indexOf('Hinge') >= 0;
    });

    const { DoorHinging,  HarwareType } = this.state.imageTips

    const hingeOptions = doorHinge.filter(hinge => {
        return this.state.NoOfDoors === hinge.NoOfDoor
    });
    //const defaultDoorHardwareId = (typeof this.state.doorHardwareId === 'undefined' && hardware.length > 0) ? hardware[0].HardwareId : 1
    //console.log('defaultDoorHardwareId', this.state)
    return (
      <>
        <div id="dooroption">
            <div className="dooroption doorgaps">

                <div className="door-select">

                    <button className="tooltip-button" data-tip={HarwareType && HarwareType.video ? ('<iframe className="sproutvideo-player" src="'+HarwareType.video+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') :  HarwareType && HarwareType.text ? HarwareType.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.HingeHardware}

                    <select id="DoorHarwareId"
                            name="DoorHardwareId"
                                   value={this.state.DoorHardwareId}
                            onChange={this.handleShelvesChange}>
                        {
                            hardware && hardware.length > 0 ? hardware.map((Hardware, key) => (

                                <option key={key}
                                        value={Hardware.HardwareId}>{Hardware.HardwareName}</option>

                            )) : ""
                        }
                    </select>

                </div>




                <div className="door-select">
                    <button className="tooltip-button" data-tip={DoorHinging && DoorHinging.video ? ('<iframe className="sproutvideo-player" src="'+DoorHinging.video+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') :  DoorHinging && DoorHinging.text ? DoorHinging.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.DoorOptionsTabDoorHinging}

                    <select
                            id="hinge"
                            name="HingeId"
                            value={this.state.HingeId}
                            onChange={this.handleShelvesChange}>
                        {
                            hingeOptions && hingeOptions.length > 0 ? hingeOptions.map((hinge, key) => (

                                    <option
                                        key={`hinge-${key}`}
                                        value={hinge.HingeId}
                                        >{hinge.HingeType}</option>



                            )) : ""
                        }

                    </select>
                </div>

					<ReactTooltip place="top" html={true} globalEventOff='click' />
				</div>


			</div>
		  </>
		)
    }

  layoutContent() {

    const layouts = this.state.layouts;

    const {Hardware, DrawerQuantity, EqualDrawer, SmallDrawer, InnerDrawer} = this.state.imageTips;

    const hardware = this.state.hardware.filter(h => {
        return h.HardwareName.indexOf('Drawer') >= 0
    });

    const product = this.state.product;

    const drawers = [];
    const drawerEquals = [];
    const drawerSmalls = [];
    const drawerInners = [];

    for(let i = parseInt(this.state.minDrawer); i <= parseInt(this.state.maxDrawer); i += 1) drawers.push(i);

    //console.log('state', this.state);

    for(let i = 0; i <= parseInt(product.max_equal_drawer); i += 1) drawerEquals.push(i);
    for(let i = 0; i <= parseInt(product.max_small_drawer); i += 1) drawerSmalls.push(i);
    for(let i = 0; i <= parseInt(product.max_inner_drawer); i += 1) drawerInners.push(i);

    //console.log('equal', this.state.DrawerEqual);
    //console.log('small', this.state.DrawerSmall);
    ///console.log('inner', this.state.DrawerInner);

    return (
        <>

          { product.hasDrawer || product.has_inner ?

            <div className="door-select">
                <button className="tooltip-button" data-tip={Hardware && Hardware.video ? ('<iframe className="sproutvideo-player" src="'+Hardware.video+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : Hardware && Hardware.text ? Hardware.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.DrawerHardware}
                <select id="hardwareid"
                        name="HardwareId"
                        value={this.state.HardwareId}
                        onChange={this.handleHardwareChange}>
                    {
                        hardware && hardware.length > 0 ? hardware.map((Hardware, key) => (

                            <option key={key}
                                    value={Hardware.HardwareId}>{Hardware.HardwareName}</option>

                        )) : ""
                    }
                </select>
            </div>

            : '' }

            <div className="d-none door-select">
                <button className="tooltip-button" data-tip={strings.HardwareLayout} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.DrawerLayout}
                <select name="HardwareLayout"
                          id="HardwareLayout"
                          value={this.state.HardwareLayout}
                        onChange={this.handleShelvesChange}>
                    <option value="">-- Please Select --</option>
                    {
                        layouts.map( (l, key) => {
                            return <option key={key} value={l.layout} selected={l.layout === this.state.HardwareLayout} >{l.layout}</option>
                        })
                    }
                </select>

            </div>

            <div className="d-none door-select">
                <button className="tooltip-button" data-tip={DrawerQuantity && DrawerQuantity.video ? ('<iframe className="sproutvideo-player" src="'+DrawerQuantity.video+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : DrawerQuantity && DrawerQuantity.text ? DrawerQuantity.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.DrawerQuantity}
                <select name="noOfDrawer"
                        id="noOfDrawer"
                        value={this.state.noOfDrawer}
                        onChange={this.handleShelvesChange}>
                    <option value disabled>-- Please Select --</option>
                    {
                        drawers.map( (d, key) => {
                            return <option key={key} value={d}>{d}</option>
                        })
                    }

                </select>

            </div>

            { product.hasDrawer  ?

            <div className="door-select">
              <button className="tooltip-button" data-tip={EqualDrawer && EqualDrawer.video ? ('<iframe className="sproutvideo-player" src="'+EqualDrawer.video+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : EqualDrawer && EqualDrawer.text ? EqualDrawer.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.DrawerEqual}
                <select name="DrawerEqual"
                        onChange={this.handleShelvesChange}
                        value={this.state.DrawerEqual}
                        defaultValue={this.state.DrawerEqual}
                        >
                    <option value disabled>-- Please Select --</option>
                    {
                        drawerEquals.map( (d, key) => {
                            return <option key={key} value={d}>{d}</option>
                        })
                    }
                </select>
            </div>

            : '' }

            { product.hasDrawer ?

            <div className="door-select">
                <button className="tooltip-button" data-tip={SmallDrawer && SmallDrawer.video ? ('<iframe className="sproutvideo-player" src="'+SmallDrawer.video+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : SmallDrawer && SmallDrawer.text ? SmallDrawer.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.DrawerSmall}

                <select name="DrawerSmall"
                        onChange={this.handleShelvesChange}
                        value={this.state.DrawerSmall}
                        defaultValue={this.state.DrawerSmall}
                        >
                    <option value disabled>-- Please Select --</option>
                    {
                        drawerSmalls.map( (d, key) => {
                            return <option key={key} value={d}>{d}</option>
                        })
                    }
                </select>
            </div>

            : '' }


            { product.has_inner ?

            <div className="door-select">
                <button className="tooltip-button" data-tip={InnerDrawer && InnerDrawer.video ? ('<iframe className="sproutvideo-player" src="'+InnerDrawer.video+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : InnerDrawer && InnerDrawer.text ? InnerDrawer.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.DrawerInner}

                <select name="DrawerInner"
                        onChange={this.handleShelvesChange}
                        value={this.state.DrawerInner}
                        defaultValue={this.state.DrawerInner}
                        >
                    <option value disabled>-- Please Select --</option>
                    {
                        drawerInners.map( (d, key) => {
                            return <option key={key} value={d}>{d}</option>
                        })
                    }
                </select>
            </div>

            : '' }

            <ReactTooltip place="top" html={true} globalEventOff='click' />

        </>
    )

  }



  shelvesContent() {

    const shelves = this.state.shelves;

    const product = this.state.product;

    const {DrawerType, DrawerQuantity, FixedShelves, AdjustableShelves} = this.state.imageTips

    const shelvesDefault =  (this.state.mshelves !== '') ? this.state.mshelves : 2

    const options = [];

    for(let i = parseInt(this.state.minShelve); i <= parseInt(this.state.maxShelve); i += 1) options.push(i);

    const fixedOptions = [];
    const adjustableOptions = [];

    for(let i = parseInt(this.state.minFixedShelve); i <= parseInt(this.state.maxFixedShelve); i += 1) fixedOptions.push(i);
    for(let i = parseInt(this.state.minAdjustableShelve); i <= parseInt(this.state.maxAdjustableShelve); i += 1) adjustableOptions.push(i);

    return (
      <>
        <div id="Shelfoptions">


            <div className="d-none door-select">
                <button className="tooltip-button" data-tip={DrawerType && DrawerType.video ? ('<iframe className="sproutvideo-player" src="'+DrawerType.video+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : DrawerType && DrawerType.text ? DrawerType.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.CabinetOptionsTabShelves}
                <select id="shelveId"
                        name="mshelves"
                        onChange={this.handleShelvesChange}
						defaultValue={shelvesDefault}>
                    {
                        shelves && shelves.length > 0 ? shelves.map((shelve, key) => {

                            return <option key={key}
                                value={shelve.ShelveId}>{shelve.ShelveName}</option>

                        }) : ""
                    }

                </select>
            </div>

            <div className="d-none door-select">
                <button className="tooltip-button" data-tip={DrawerQuantity && DrawerQuantity.video ? ('<iframe className="sproutvideo-player" src="'+DrawerQuantity.video+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : DrawerQuantity && DrawerQuantity.text ? DrawerQuantity.text  : '' } data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.ShelveQuantity}
                <select name="noOfShelve"
                        id="noOfShelve"
                        onChange={this.handleShelvesChange}>
                    <option value disabled>-- Please Select --</option>
                    {
                        options.map( (option, key) => {
                            return <option key={key} selected={this.state.noOfShevel === option} value={option}>{option}</option>
                        })
                    }
                </select>

            </div>

            <div className="door-select">
                <button className="tooltip-button" data-tip={FixedShelves && FixedShelves.video ? ('<iframe className="sproutvideo-player" src="'+FixedShelves.video+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : FixedShelves && FixedShelves.text ? FixedShelves.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.FixedShelve}
                <select name="FixedShelve"
                        onChange={this.handleShelvesChange}
                        value={this.state.FixedShelve}
                        >
                    <option value disabled>-- Please Select --</option>
                    {
                        fixedOptions.map( (option, key) => {
                            return <option key={key} value={option}>{option}</option>
                        })
                    }

                </select>
            </div>

            <div className="door-select">
                <button className="tooltip-button" data-tip={AdjustableShelves && AdjustableShelves.video ? ('<iframe className="sproutvideo-player" src="'+AdjustableShelves.video+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : AdjustableShelves && AdjustableShelves.text ? AdjustableShelves.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.AdustableShelve}
                <select name="AdjustableShelve"
                        onChange={this.handleShelvesChange}
						value={this.state.AdjustableShelve}>
                    <option value disabled>-- Please Select --</option>
                    {
                        adjustableOptions.map( (option, key) => {
                            return <option key={key} value={option}>{option}</option>
                        })
                    }

                </select>
            </div>

            { false &&  product.ProductId === 5 ?

                <>

                    <div className="door-select">

                        <input type="checkbox" checked={this.state.WineRack} onClick={this.handleShelvesChange} name="WineRack" /> {strings.WineRack}

                    </div>

                    <div className="door-select">

                        <input type="checkbox" checked={this.state.MicrowaveOven} onClick={this.handleShelvesChange} name="MicrowaveOven" /> {strings.MicrowaveOven}

                    </div>

                </>

                : '' }

            <ReactTooltip place="top" html={true} globalEventOff='click' />

        </div>
      </>
    )

  }

  removeImage(key, ev){

    var product = this.state.product
    var detailImage = Array.isArray(product.detailImage) ? product.detailImage : []

    if (detailImage.length > 0) {
      delete detailImage[key];
    }

		product.detailImage = detailImage
		this.setState({
			product: product,
			detailImage: detailImage
		}, () => {
      //console.log('product.detailImage', product.detailImage)
		})
  }

	onDropImage	= (file) =>{
    var {detailImage} = this.state
		detailImage.push(file)
		this.setState({
			detailImage: detailImage
		}, () => {

		})
	}

  doorGapsContent() {

    const {product, detailImage} = this.state

    const {DoorGapTop, DoorGapBottom, DoorGapLeft, DoorGapRight, DrawerGaps} = this.state.imageTips

    const gapTop = this.state.GapTop;
    const gapBottom = this.state.GapBottom;
    const gapLeft = this.state.GapLeft;
    const gapRight = this.state.GapRight;

    return (
      <>
        <div id="doorgaps" className="doorgaps">


            <div className="door-select">
			    <ul className="detail-images" style={{margin: "0px"}}>
			        {
                  detailImage.map((img, key) => {
                  let ext = img.split('.').pop()
                  if (ext != 'bmp' && ext != 'png' && ext != 'jpg' && ext != 'jpeg' && ext != 'BMP' && ext != 'PNG' && ext != 'JPG' && ext != 'JPEG') {
                    img = '/uploads/' + ext + '-prev.png'
                  }
                  return (
  				        <li><i style={{float: 'right'}} className="fa fa-times"  onClick={this.removeImage.bind(null, key)} />
                  <img alt="" src={config.UPLOAD_URL+img} /></li>
                  )
                }
			        )}
			    </ul>
            </div>

            <div className="door-select">
			    <UploadImage
				    onDropImage={this.onDropImage}
			    />
            </div>
            <div className="form-group" style={{paddingTop: "0px"}}>

                <label>{strings.VariationsTabDetails}</label>

                <textarea className="form-control" style={{width: '100%', height: '70px', minHeight: '70px'}} onChange={this.handleDetails} value={this.state.details} name="details" />

            </div>

            { product.hasDoor === 1 ?

                <>

                    <div style={{float: 'none', clear: 'both'}}></div>
                    <div className="door-select form-group col-md-6" style={{width:"48%", marginRight: "10px", paddingLeft: '0px', marginTop: '0px', paddingTop: "0px"}}>
                            <button className="tooltip-button"
                                data-tip={DoorGapTop && DoorGapTop.video ? ('<img src='+config.UPLOAD_URL+DoorGapTop.video+' />') : DoorGapTop && DoorGapTop.text ? DoorGapTop.text : ''}
                                data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.VariationsTabDoorGapTop}
                            <span className="variationunit"><input type="text" id="gapTop" name="GapTop" value={gapTop} onChange={this.productChangeValues} placeholder="0mm" /></span>
                        </div>

                    <div className="door-select form-group col-md-6"  style={{width:"48%", paddingRight: '0px',  marginTop: '0px', paddingTop: "0px"}}>
                            <button className="tooltip-button" data-tip={DoorGapBottom && DoorGapBottom.video ? ('<img src='+config.UPLOAD_URL+DoorGapBottom.video+' />') : DoorGapBottom && DoorGapBottom.text ? DoorGapBottom.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.VariationsTabDoorGapBottom}
                            <span className="variationunit"><input type="text" id="gapBottom" name="GapBottom" value={gapBottom} onChange={this.productChangeValues} placeholder="0mm" /></span>
                        </div>
                    <div style={{float: 'none', clear: 'both'}}></div>
                    <div className="door-select form-group col-md-6" style={{width:"48%", marginRight: "10px", paddingLeft: '0px', marginTop: '0px', paddingTop: "0px"}}>
                            <button className="tooltip-button" data-tip={DoorGapLeft && DoorGapLeft.video ? ('<img src='+config.UPLOAD_URL+DoorGapLeft.video+' />') : DoorGapLeft && DoorGapLeft.text ? DoorGapLeft.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.VariationsTabDoorGapLeft}
                            <span className="variationunit"><input type="text" id="gapLeft" name="GapLeft" value={gapLeft} onChange={this.productChangeValues} placeholder="1.5mm" /></span>
                        </div>
                    <div className="door-select form-group col-md-6"  style={{width:"48%", paddingRight: '0px',  marginTop: '0px', paddingTop: "0px"}}>
                            <button className="tooltip-button" data-tip={DoorGapRight && DoorGapRight.video ? ('<img src='+config.UPLOAD_URL+DoorGapRight.video+' />') : DoorGapRight && DoorGapRight.text ? DoorGapRight.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.VariationsTabDoorGapRight}
                            <span className="variationunit"><input type="text" id="gapRight" name="GapRight" value={gapRight} onChange={this.productChangeValues} placeholder="1.5mm" /></span>
                        </div>

                </>

                : '' }

                { product.hasDrawer === 1  ?

                    <>

                        <div style={{float: 'none', clear: 'both'}}></div>
                        <div className="door-select" style={{paddingTop: "0px"}}>
                              <button className="tooltip-button" data-tip={DrawerGaps && DrawerGaps.video ? ('<iframe className="sproutvideo-player" src="'+DrawerGaps.video+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : DrawerGaps && DrawerGaps.text ? DrawerGaps.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.CabinetOptionsTabDrawerGaps}
                            <textarea onChange={(event) =>this.setState({drawerGaps: event.target.value})} value={this.state.drawerGaps} style={{width: '100%', height: '70px', minHeight: '70px'}}></textarea>

                        </div>

                    </>

                    : '' }


        </div>
        <ReactTooltip place="top" html={true} globalEventOff='click' />
      </>
    )

  }

	onSelectImage = (colour, SectionId) => {
		this.setState({
			[`MaterialColour${SectionId}`] : colour.value,
			[`MaterialColourSelect${SectionId}`] : SectionId
		})

	}

	handleColourSelect = (SectionId, colour, selControl) => {
		this.setState({
			[`${selControl.name}`] : colour.value,
			[`MaterialColourSelect${SectionId}`] : SectionId
		})
	}

  materialsContent() {

    //console.log('state', this.state);

    const product = this.state.product;

    const material  = this.state.material.filter(m => {
            if (m.Section === 'Interior Materials' && product.InteriorMaterial === 1) { return m }
            if (m.Section === 'Exterior Materials' && product.ExteriorMaterial === 1) { return m }
        });

    let materialType = this.state.materialType;

    const brand = this.state.brand;

    const { InteriorMaterial, ExteriorMaterial, ToeMaterial, CaseEdgeMaterial, FaceEdgeMaterial} = this.state.imageTips

  	const Option = props => {
		return (
		<components.Option {...props}>
			<img alt="" className="SelectColourImage" src={config.UPLOAD_URL+props.data.image} /> &nbsp;{props.data.label}
		</components.Option>
		);
    };

    const relatedProducts = this.props.relatedProducts || [];

    const InteriorLabel = relatedProducts.subcategory && strings[relatedProducts.subcategory.id] && strings[relatedProducts.subcategory.id].InteriorMaterial ? strings[relatedProducts.subcategory.id].InteriorMaterial : strings.InteriorMaterial

    const ExteriorLabel = relatedProducts.subcategory && strings[relatedProducts.subcategory.id] && strings[relatedProducts.subcategory.id].ExteriorMaterial ? strings[relatedProducts.subcategory.id].ExteriorMaterial : strings.ExteriorMaterial;

    //console.log('material', material);

    return (
      <>
        <div id="materailoption">
            <div className="col-md-12 createcustomsize">
				<ReactTooltip place="top" html={true} globalEventOff='click' />
                {


                    material && material.length > 0 ? material.map((Material, key) => {

                        let SectionId = Material.SectionId;

                        let value = parseInt(this.state[`MaterialType${SectionId}`]);
                        let brandValue = parseInt(this.state[`MaterialBrand${SectionId}`]);
                        let finishValue = parseInt(this.state[`MaterialFinish${SectionId}`]);
                        let substrateValue = parseInt(this.state[`MaterialSubstrate${SectionId}`]);
                        let colourValue = parseInt(this.state[`MaterialColour${SectionId}`]);

                        let doorStyle = this.state.doorStyle;

                        if (Material.SectionId === 2) {

                            const dStyle = doorStyle.filter( d => d.DoorId === parseInt(this.state.DoorId) );

                            if (dStyle.length > 0) {

                                const dstyle = dStyle[0].DoorName;


                                materialType = materialType.filter(mt => {

                                    const t = mt.MaterialType;

                                    return t.substr(0, 4) === dstyle.substr(0, 4)

                                });

                                if (materialType.length === 1) {
                                    value = materialType[0].MaterialTypeId;
                                }

                            }
                        }

                        var tooltipLink = <></>
						switch(Material.SectionId) {
							case 1:
								tooltipLink = <button className="tooltip-button" data-tip={InteriorMaterial && InteriorMaterial.video ? ('<iframe className="sproutvideo-player" src="'+InteriorMaterial.video+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : InteriorMaterial && InteriorMaterial.text ? InteriorMaterial.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button>
								break;
							case 2:
								tooltipLink = <button className="tooltip-button" data-tip={ExteriorMaterial && ExteriorMaterial.video ? ('<iframe className="sproutvideo-player" src="'+ExteriorMaterial.video+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : ExteriorMaterial && ExteriorMaterial.text ? ExteriorMaterial.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button>
								break;
							case 3:
								tooltipLink = <button className="tooltip-button" data-tip={ToeMaterial && ToeMaterial.video ? ('<iframe className="sproutvideo-player" src="'+ToeMaterial.video+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : ToeMaterial && ToeMaterial.text ? ToeMaterial.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button>
								break;
							case 4:
								tooltipLink = <button className="tooltip-button" data-tip={CaseEdgeMaterial && CaseEdgeMaterial.video ? ('<iframe className="sproutvideo-player" src="'+CaseEdgeMaterial.video+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : CaseEdgeMaterial && CaseEdgeMaterial.text ? CaseEdgeMaterial.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button>
								break;
							case 5:
								tooltipLink = <button className="tooltip-button" data-tip={FaceEdgeMaterial && FaceEdgeMaterial.video ? ('<iframe className="sproutvideo-player" src="'+FaceEdgeMaterial.video+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : FaceEdgeMaterial && FaceEdgeMaterial.text ? FaceEdgeMaterial.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button>

								break;
							default:
								tooltipLink = ''
                        }


                        const brandOptions = brand.filter((brand) => {
                            return brand.MaterialTypeId === value
                        });


						const colours = Material.colour.filter((Colour) => {

                            return brandValue === Colour.BrandId &&
                                   finishValue === Colour.FinishId
                        });

                        const colourOptions = colours.map((Colour) => {
							return {
								value   : Colour.ColourId,
								label   : Colour.ColourName,
								image   : Colour.ColourImage,
								brandid : Colour.BrandId
							}
                        });

						const finishOptions = Material.finish.filter((finish) => {
                            return finish.BrandId === brandValue
                        });

                        const substrates = Material.substrate.map( substrate => {

                            if (substrate.SubstrateName === 'MR MDF 1' || substrate.SubstrateName === 'MR MDF 2' || substrate.SubstrateName === 'MR MDF 3') {

                                substrate.SubstrateName = 'MR MDF';

                            }

                            return substrate;
                        })

                        const substrateOptions = substrates.filter((substrate) => {
                            return substrate.BrandId === brandValue
                        });

                        let defaultOption = [];

                        defaultOption = colourOptions.filter((colour) =>{
							return colour.value === colourValue
                        });




                        return (

                            <React.Fragment key={key}>
                                <h3>
									{tooltipLink}  {Material.SectionId === 1  ? InteriorLabel : ExteriorLabel}
								</h3>

                                {false && Material.SectionId === 2 ? (
                                <div className="cabinetsizes">
                                    <div className="col-md-4 leftlable">{strings.materialTabSelectyourDoorStyle}</div>
                                    <div className="col-md-8 rightqty">
                                        <div className="mt-5 materials_type">
										<select id="doorid"
												name="DoorId"
												onChange={this.handleColourChange}
												value={parseInt(this.state.DoorId)}
                        >
											{
												doorStyle && doorStyle.length > 0 ? doorStyle.map((door, key) => (

													<option key={key}
															value={door.DoorId} >{door.DoorName}</option>

												)) : ""
											}
										</select>
                                        </div>
                                    </div>
                                </div>
								) : ('')}

                                <div className="cabinetsizes">
                                    <div className="col-md-4 leftlable">{strings.materialTabMaterialType}</div>
                                    <div className="col-md-8 rightqty">
                                        <div className="mt-5 materials_type">
                                            <select
                                                name={"MaterialType" + Material.SectionId}
                                                id={"materaltype" + Material.SectionId}
                                                value={value}
                                                onChange={this.handleMaterialType}>
                                                {
                                                    materialType && materialType.length > 0 ? materialType.map((material, key) => (

                                                        <option key={key} value={material.MaterialTypeId}  >{material.MaterialType}</option>

                                                    )) : ""
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="cabinetsizes">
                                    <div className="col-md-4 leftlable">{strings.materialTabBrand}</div>
                                    <div className="col-md-8 rightqty">
                                        <div className="mt-5 materials_type">
                                            <select
                                                name={"MaterialBrand" + Material.SectionId}
                                                id={"materalbrand " + Material.SectionId}
                                                onChange={this.handleMaterialType}
                                                value={brandValue}
                                                >
                                                {
                                                    brandOptions && brandOptions.length > 0 ? brandOptions.map((brands, key) => (
                                                        <option key={key} value={brands.BrandId} >{brands.BrandName}</option>
                                                    )) : ""
                                                }
                                            </select>


                                        </div>
                                    </div>
                                </div>
                                <div className="cabinetsizes">
                                    <div className="col-md-4 leftlable">{strings.materialTabFinish}</div>
                                    <div className="col-md-8 rightqty">
                                        <div className="mt-5 materials_type">
                                            <select
                                                name={"MaterialFinish" + Material.SectionId}
                                                id={"materalfinish " + Material.SectionId}
                                                onChange={this.handleMaterialType}
                                                value={finishValue}
                                                >
                                                {
                                                    finishOptions && finishOptions.length > 0 ? finishOptions.map((Finish, key) => (

                                                        <option key={key} value={Finish.FinishId} >{Finish.FinishName}</option>

                                                    )) : ""
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="cabinetsizes">
                                    <div className="col-md-4 leftlable">{strings.materialTabSubstrate}</div>
                                    <div className="col-md-8 rightqty">
                                        <div className="mt-5 materials_type">
                                            <select
                                                name={"MaterialSubstrate" + Material.SectionId}
                                                id={"materalsubstrate " + Material.SectionId}
                                                onChange={this.handleMaterialType}
                                                vaalue={substrateValue}
                                                >
                                                {
                                                    substrateOptions && substrateOptions.length > 0 ? substrateOptions.map((Substrate, key) => (
                                                        <option
                                                          key={key}
                                                          value={Substrate.SubstrateId} >{Substrate.SubstrateName}</option>
                                                    )) : ""
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="cabinetsizes">
                                    <div className="col-md-4 leftlable">{strings.materialTabColour}
                                    </div>
                                    <div className="col-md-8 rightqty">
                                        <div className="mt-5 materials_type">

                                                {

                                                    colourOptions.length >  0 ?


                                                <Select
                                                    options={colourOptions}
                                                    backspaceRemovesValue={true}
                                                    value = {defaultOption.length > 0 ? defaultOption[0] : colourOptions[0]}
                                                    name={"MaterialColour" + Material.SectionId}
                                                    onChange={this.handleColourSelect.bind(this, Material.SectionId)}
                                                    styles={{
                                                        control: (base, state) => ({
                                                            ...base,
                                                            '&:hover': { borderColor: 'gray' }, // border style on hover
                                                            border: 'none', // default border color
                                                            boxShadow: 'none', // no box-shadow
                                                        })
                                                    }}
                                                    components={{ Option }}



                                                />

                                                :

                                                <select name={"MaterialColour" + Material.SectionId} />


                                                }


											<ColorSwatches onSelectImage={this.onSelectImage} colours={colourOptions} SectionId={Material.SectionId} />

                                        </div>
                                    </div>
                                </div>
                                <hr style={{marginTop: '0px'}} />
								<ReactTooltip place="top" html={true} globalEventOff='click' />
                            </React.Fragment>

                        )
                    }) : ""
                }
                <div className="cabinetsizes" id="hiddenattribute" style={{ display: 'none' }}>
                    <div className="col-md-6 leftlable">Horizontal Grain
                    </div>
                    <div className="col-md-6 rightqty">
                        <div className="mt-5 checkboxdiv">
                            <label className="radiobox">
                                <input type="checkbox" defaultValue />
                                <div className="checkmark" /> </label>
                        </div>
                    </div>
                </div>


            </div>


        </div>

        </>
    )

  }

  shouldComponentUpdate(newProps) {
      const product = newProps.product;

      if (product && product.ProductName) {
          //console.log('should', product)
          return true;
      }

      return false;
  }

  componentDidUpdate(newProps, prevState) {

    if (this.props.updateKey !== prevState.updateKey) {
        this.setData();
    }

  }

  componentDidMount() {
      this.setData();
      this.getImageTips()
  }

  componentWillUnmount(){
    sessionStorage.removeItem('visitedtab');
    clearInterval(this.interval)
  }

  setData() {

    if (true) {

        //console.log('set data', this.props.roomId, this.props.roomMaterials, this.props.productSave);

        const hinges = this.props.hardware.filter( h => {

            const name = h.HardwareName;

            return name.indexOf('Hinge') !== -1;

        });

        const drawers = this.props.hardware.filter( h => {

            const name = h.HardwareName;

            return name.indexOf('Drawer') !== -1;

        });

        const product = this.props.product;

        const roomMaterials = this.props.roomMaterials.filter( m => this.props.roomId === m.RoomId );

        let MaterialData = product.MaterialData ? product.MaterialData : [];

        //console.log('room materials', roomMaterials, this.props.productSave);

        if (roomMaterials.length > 0 && this.props.productSave === 'addToCart') {

            //console.log('materials', roomMaterials);
            MaterialData = roomMaterials[0].materials;

        }

        const hardwareData = product.hardwareData ? product.hardwareData : { HardwareId : drawers.length > 0 ? drawers[0].HardwareId : 1, DoorHardwareId : hinges.length > 0 ? hinges[0].HardwareId : 1 };

        const doorData = product.doorData ? product.doorData : { DoorId: 2, GapTop: 3, GapBottom : 0, GapRight : 1.5, GapLeft : 1.5 }

        const materialTypes = this.props.materialType;

        let defaultDoorId = doorData.DoorId;

        const doorId = parseInt(product.DoorId);

        const noDoor =  7;
        const twoDoor = 3;

        let hingeId = product.HingeId ? product.HingeId : 1;

        if (product.NoOfDoors === 0) {
            hingeId = noDoor;
        }

        if (product.NoOfDoors === 2) {
            hingeId = twoDoor;
        }

        const hingeData = product.hingeData ? product.hingeData: { HingeId : hingeId };

        const MaterialType1 =  MaterialData && MaterialData.length > 0 ? MaterialData[0].MaterialId : this.props.materialDefault && this.props.materialDefault[0] ? this.props.materialDefault[0].MaterialTypeId : 1;
        const MaterialType2 =  MaterialData && MaterialData.length > 0 ? MaterialData[1].MaterialId : this.props.materialDefault && this.props.materialDefault[1] ? this.props.materialDefault[1].MaterialTypeId : 1;

        const MaterialBrand1 = MaterialData && MaterialData.length > 0 ? MaterialData[0].BrandId : this.props.materialDefault && this.props.materialDefault[0] ? this.props.materialDefault[0].BrandId : 1;
        const MaterialBrand2 = MaterialData && MaterialData.length > 1 ? MaterialData[1].BrandId : this.props.materialDefault && this.props.materialDefault[1] ? this.props.materialDefault[1].BrandId : 1;

        const MaterialSubstrate1 = MaterialData && MaterialData.length > 0 ? MaterialData[0].SubstrateId : this.props.materialDefault && this.props.materialDefault[0] ? this.props.materialDefault[0].SubstrateId : 1;
        const MaterialSubstrate2 = MaterialData && MaterialData.length > 1 ? MaterialData[1].SubstrateId : this.props.materialDefault && this.props.materialDefault[1] ? this.props.materialDefault[1].SubstrateId : 1;

        const MaterialFinish1 = MaterialData && MaterialData.length > 0 ? MaterialData[0].FinishId : this.props.materialDefault && this.props.materialDefault[0] ? this.props.materialDefault[0].FinishId : 1;
        const MaterialFinish2 = MaterialData && MaterialData.length > 1 ? MaterialData[1].FinishId : this.props.materialDefault && this.props.materialDefault[1] ? this.props.materialDefault[1].FinishId : 1;

        const MaterialColour1 = MaterialData && MaterialData.length > 0 ? MaterialData[0].ColourId : this.props.materialDefault && this.props.materialDefault[0] ? this.props.materialDefault[0].ColourId : 1;
        const MaterialColour2 = MaterialData && MaterialData.length > 1 ? MaterialData[1].ColourId : this.props.materialDefault && this.props.materialDefault[1] ? this.props.materialDefault[1].ColourId : 1;

        let productQty = product.ProductQty ? product.ProductQty : 1;

        if (this.props.productSave === 'addToCart') {
            productQty = 1;
        }


        this.setState({
            //relatedproducts   : this.props.relatedProducts,
            product           : product,
            product_name      : product.ProductName,
            Width             : product.Width,
            Height            : product.Height,
            Depth             : product.Depth,
            Elevation         : product.Elevation,
            RightScribe       : product.RightScribe,
            ToeHeight         : product.ToeHeight,
            ToeRecess         : product.ToeRecess,
            SoffitHeight      : product.SoffitHeight,
            PicturePath       : product.PicturePath,
            WidthMin          : product.WidthMin,
            WidthMax          : product.WidthMax,
            HeightMin         : product.HeightMax,
            HeightMax         : product.HeightMax,
            DepthMin          : product.DepthMin,
            DepthMax          : product.DepthMax,
            ToeHeightMin      : product.ToeHeightMin,
            ToeHeightMax      : product.ToeHeightMax,
            ToeRecessMin      : product.ToeRecessMin,
            ToeRecessMax      : product.ToeRecessMax,
            SoffitHeightMin   : product.SoffitHeightMin,
            SoffitHeightMax   : product.SoffitHeightMax,
            ElevationMin      : product.ElevationMin,
            ElevationMax      : product.ElevationMax,
            TotalHardwareCost : product.TotalHardwareCost,
            NoOfDoors         : product.NoOfDoors,
            noOfShelve        : product.noOfShelve ? product.noOfShelve : 1,
            mshelves          : product.mshelves ? product.mshelves : 2,
            HingeId           : hingeData ? hingeData.HingeId : hingeId,

            ProductQty        : productQty,

            HardwareId        : hardwareData ? hardwareData.HardwareId : 0,

            HardwareLayout    : product.HardwareLayout ? product.HardwareLayout : '',

            DrawerHardwareId  : product.HardwareId ? product.HarwareId: 1,

            DoorHardwareId    : hardwareData.DoorHardwareId ? hardwareData.DoorHardwareId: 1,

            fixedQty          : product.fixedQty ? product.fixedQty : this.props.minFixedShelve,
            equalQty          : product.equalQty ? product.equalQty : this.props.minDrawerEqual,
            adjustableQty     : product.adjustableQty ? product.adjustableQty : 0,

            AdjustableShelve  : product.adjustableQty ? product.adjustableQty : product.AdjustableShelve ? product.AdjustableShelve : this.props.minAdjustableShelve,
            FixedShelve       : product.fixedQty ? product.fixedQty : product.FixedShelve ? product.FixedShelve : this.props.minFixedShelve,

            DoorId            : doorId ? doorId : doorData ? doorData.DoorId : defaultDoorId,

            MicrowaveOven     : product.MicrowaveOven,
            WineRack          : product.WineRack,


            MaterialType1 : MaterialType1,

            MaterialType2 : MaterialType2,
            MaterialType3 : 0,
            MaterialType4 : 0,
            MaterialType5 : 0,
            MaterialType6 : 0,


            MaterialBrand1 : MaterialBrand1,
            MaterialBrand2 : MaterialBrand2,
            MaterialBrand3 : 0,
            MaterialBrand4 : 0,
            MaterialBrand5 : 0,
            MaterialBrand6 : 0,


            MaterialFinish1 : MaterialFinish1,
            MaterialFinish2 : MaterialFinish2,
            MaterialFinish3 : 0,
            MaterialFinish4 : 0,
            MaterialFinish5 : 0,
            MaterialFinish6 : 0,


            MaterialSubstrate1 : MaterialSubstrate1,
            MaterialSubstrate2 : MaterialSubstrate2,
            MaterialSubstrate3 : 1,
            MaterialSubstrate4 : 1,
            MaterialSubstrate5 : 1,
            MaterialSubstrate6 : 1,


            MaterialColour1 : MaterialColour1,
            MaterialColour2 : MaterialColour2,
            MaterialColour3 : 0,
            MaterialColour4 : 0,
            MaterialColour5 : 0,

			hasDoor     : product.hasDoor,
			hasDrawer   : product.hasDrawer,
			drawerGaps  : product.drawerGaps,
            detailImage : this.props.productSave == 'edit' && product.detailImage && !Array.isArray(product.detailImage) ? JSON.parse(product.detailImage) : Array.isArray(product.detailImage) ? product.detailImage : [],

            Gst         : this.props.gst,
            min_order   : this.props.min_order,
            material    : this.props.material,
            materialType: materialTypes,
            brand       : this.props.brand,
            substrate   : this.props.substrate,
            doorStyle   : this.props.door,
            hardware    : this.props.hardware,
            doorHinge   : this.props.hinge,
            shelves     : this.props.shelve,
            layouts     : this.props.layouts,
            stockCodes  : this.props.stockcodes,

            details     : product.details ? product.details : '',

            maxShelve   : this.props.maxShelve,
            minShelve   : this.props.minShelve,
            minDrawer   : this.props.minDrawer,
            maxDrawer   : this.props.maxDrawer,

            minFixedShelve : this.props.minFixedShelve,
            maxFixedShelve : this.props.maxFixedShelve,
            maxAdjustableShelve : this.props.maxAdjustableShelve,
            minAdjustableShelve : this.props.minAdjustableShelve,

            minDrawerEqual : this.props.minDrawerEqual,
            maxDrawerEqual : this.props.maxDrawerEqual,
            minxDrawerSmall : this.props.minDrawerSmall,
            maxDrawerSmall : this.props.maxDrawerSmall,
            minDrawerInner : this.props.minDrawerInner,
            maxDrawerInner : this.props.maxDrawerInner,

            DrawerSmall : product.DrawerSmall ? product.DrawerSmall : 0,
            DrawerEqual : product.DrawerEqual ? product.DrawerEqual : 1,
            DrawerInner : product.DrawerInner ? product.DrawerInner : 0,

            GapTop      : doorData.GapTop,
            GapBottom   : doorData.GapBottom,
            GapRight    : doorData.GapRight,
            GapLeft     : doorData.GapLeft,

            RoomId      : product.RoomId ? product.RoomId : 1,
            roomData    : product.roomData ? product.roomData : 'Room 1',

            productSave : this.props.productSave,
            updateKey   : this.props.updateKey,
            slug        : product.slug

        }, () => {
            this.updateImage();
        });

    }

  }

  recalculate() {

    let productprice = 0;
    let doorscost = 0;
    let shelvescost = 0;
    let carcasecost = 0;

    const hardwarecost = this.state.product.TotalHardwareCost;

    this.setState({
        Price: productprice,
        DoorsCost: doorscost,
        ShelvesCost: shelvescost,
        HardwareCost: hardwarecost,
        CarcaseCost: carcasecost
    });

  }

  button(label, img, id)  {

    const target = `#${id}`;

    return (
      <button className="nav-link" data-toggle="pill" href={target}>
        <span><img alt="" src={img} /></span>
        {label}
      </button>
    )
  }

  render() {

    console.log('product home sticker name', this.state.StickerName);

    const product = this.props.product;
    const status = product.Status ? product.Status : 'Pending';
    const productSave = this.props.productSave;
    const dimensionTab = this.button(strings.dimensionTab, '/images/dimension.png', 'dimension');
    const materialsTab = this.button(strings.materialsTab, '/images/materialicon.png', 'materials');

    const shelfOptionsTab = this.button(strings.cabinetOptionsTab, '/images/ahelfoption.png', 'shelves');
    const doorGapsTab = this.button(strings.variationsTab, '/images/doorgap.png', 'gaps');

    const dimensionContent = this.dimensionContent();
    const materialContent = this.materialsContent();
    const doorContent = this.doorOptionsContent();
    const shelvesContent = this.shelvesContent();
    const doorGapsContent = this.doorGapsContent();
    const layoutContent = this.layoutContent();

    const relatedProducts = this.props.relatedProducts || [];

    return (
      <>

                    <div className="col-xs-12 col-sm-6 col-md-4 leftsliderbox">
                      <h1>{this.state.product_name}</h1>

                      <ImageSlider isCustomized={this.state.isCustomized} images={this.state.productImage} slug={this.state.slug} />

                      <div className="related-products">
                        {
                            relatedProducts && relatedProducts.length === 0 ? '' :
                              relatedProducts.products.map((prod) => {
                                  return prod.ProductId === product.ProductId ? '' : (
                                      <RelatedProduct key={prod.ProductId} click={(event) => {this.props.goToProducts(prod.ProductId)}} product={prod} />
                                  )
                              })
                        }
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-5 midtabsection">

                      <Tabs defaultIndex={0} onSelect={this.handleTabSelect}>
                        <TabList>
                            <Tab>{dimensionTab}</Tab>
                            <Tab>{materialsTab}</Tab>
                            { product.hasDrawer === 1 || product.hasDoor === 1 || product.hasShelve ?

							    <Tab>{shelfOptionsTab}</Tab>

                                : '' }
                            <Tab>{doorGapsTab}</Tab>
                        </TabList>

                        <TabPanel>
                            {dimensionContent}
                        </TabPanel>

                        <TabPanel>
                            {materialContent}
                        </TabPanel>


                            { product.hasDrawer === 1 || product.hasDoor === 1 || product.hasShelve ?

                            <TabPanel>

                                { product.hasDoor === 1 ? doorContent : '' }

                                { product.hasShelve === 1 ? shelvesContent : '' }

                                { product.hasDrawer === 1 || product.has_inner ? layoutContent : ''}

							</TabPanel>

                            : '' }


                        <TabPanel>
                            {doorGapsContent}
                        </TabPanel>

                      </Tabs>
                      {

                          (productSave === "edit") ?
                              <button className="upsize" onClick={this.updateCart}>Update</button>
                              :
                              <button className="upsize" onClick={this.addToCart}>{strings.AddToCart}</button>

                      }
                    </div>

      </>
    )
  }
}

class RelatedProduct extends Component {

    render() {

        const product = this.props.product;

        return (

            <>
                <div className="single-product" onClick={this.props.click} style={ { backgroundImage: `url(${product.Image})` } }>

                    <div className="product-title">{product.ProductName}</div>

                </div>
            </>

        )

    }

}

class ImageSlider extends Component {

    render() {

       //console.log('image', this.props);

        const isCustomized = this.props.isCustomized;

        const images = this.props.images.map( (image, key) => {
            return (
                <div className={this.props.slug} key={key}>
                    <img alt="" id="carousel-img" src={image} />
                    <div className="overlay-bg" />
                </div>
            )
        });

        return (

            <>

            {
             (images.length > 0) ?

                <Carousel autoPlay>
                { images }
                </Carousel>

            :

                <div className="not-available">

                    { isCustomized === 1 ?

                        <h2>Customized Product</h2>

                        : '' }

                </div>

            }

            </>
        )
    }

}

class PopupImage extends Component {

    constructor(props) {

        super(props);

        this.state = {
            images: props.images,
            isOpen: false,
            stockCode: props.stockCode,
            photoIndex: 0
        }
    }

    render() {

        const {photoIndex, isOpen} = this.state;

        const images = this.props.images;

        return (
            <>
                <div className="single-image">
                    {images ? <img alt="" src={images[0]} /> : ''}
                    <div className="img-button">
                        <button onClick={ () => { this.props.selected(this.props.stockCode) } }><i className="fa fa-check" /></button>
                        <button onClick={ () => { this.setState({ isOpen: true }) }}><i className="fa fa-image" /></button>
                    </div>
                </div>
                {isOpen &&
                    (
                        <Lightbox
                            mainSrc={images[photoIndex]}
                            nextSrc={images[(photoIndex + 1) % images.length]}
                            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                            onCloseRequest={() => this.setState({ isOpen: false })}
                            onMovePrevRequest={() =>
                                this.setState({
                                    photoIndex: (photoIndex + images.length - 1) % images.length,
                                })
                            }
                            onMoveNextRequest={() =>
                                this.setState({
                                    photoIndex: (photoIndex + 1) % images.length,
                                })
                            }
                        />
                    )
                }
            </>
        )
    }

}

export default ProductHome
