import React, { Component } from 'react';
import Header from  '../components/Header';
import Footer from '../components/Footer';
import swal from "sweetalert";
import config from '../config';

class Contact extends Component {

  constructor(props) {
    super(props);

        this.state = {
          products: {},
          product: {},
          productSave:"save",
          materialArray: [],
          productsArray: [],
          jobProduct: [],
          project: {ProjectName: '', ProjectId: 0},
          isLogin: false,
          noProject: false,
          customerId: 0,
          jcustomer: [],
          jobId: 0,
          RoomId: 1,
          roomData: 'Room 1',
          defaultProduct: [],
          stockCodes: [],
          customer: {Name: '', Email: '', MobileNumber: '', CustomerId: 0}
        }

        this.doLogin = this.doLogin.bind(this);
        this.doLogout = this.doLogout.bind(this);
        this.addproject = this.addproject.bind(this);
        this.getActiveProject = this.getActiveProject.bind(this);
        this.goToProducts = this.goToProducts.bind(this);
        this.saveDashboard = this.saveDashboard.bind(this);

  }

  saveDashboard() {

    window.location = '/dashboard';

  }

  doLogout() {
    this.setState({isLogin:false, project: [], customer: [], customerId: 0})
    swal("", "You have been logout!", "success");
}

doLogin() {

    const customer = localStorage.getItem("customer");

    const project = localStorage.getItem("project");

    const jProject = JSON.parse(project);

    const jcustomer = JSON.parse(customer);

    let noProject = true;

    if (jcustomer.projects > 0 || (jProject && jProject.ProjectId > 0)) {
        noProject = false;
    }

    this.setState(
        {
            isLogin:true,
            customer : customer,
            jcustomer : jcustomer,
            customerId: jcustomer.CustomerId,
            project: jProject,
            noProject: noProject
        }
    );

    if (noProject) {
        this.getActiveProject(jcustomer.CustomerId);
    }

}

addproject(data) {

  const customer = this.state.jcustomer;

  const params = {name: data.name, customerId: this.state.customerId, clientName: customer.Name, contactNo: customer.MobileNumber, email:customer.Email}

  fetch(config.BASE_URL + '/api/addnewproject', {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json'
      }
    })
    .then(data => data.json())
    .then(data => {

      let customer = JSON.parse(this.state.customer);

      customer.projects = 1;

      localStorage.setItem("customer", JSON.stringify(customer));
      localStorage.setItem("project", JSON.stringify(data));

      this.setState({noProject: false, project: data})

    })
    .catch(error => console.log(error));

}

getActiveProject(customerId) {
  fetch(config.BASE_URL + '/api/get-active-project/' + customerId , {
      method: "GET",
      headers: {
          "Content-Type": "application/json"
      }
  })
  .then(data => data.json())
  .then(data => {
      if (data.length > 0) {
          this.setState({
              noProject: false,
              project: data[0]
          });
      }
  })
  .catch(error => console.log(error));
}

goToProducts(productId, productSave) {

  fetch(config.BASE_URL + '/api/getproductbyId/' + productId, {
      method: "GET",
      headers: {
          "Content-Type": "application/json"
      }
  })
  .then(data => data.json())
  .then(data => {

      //console.log('got product'); console.log(data[0]);

     this.setState({ products: data, product: data[0], productSave: productSave })
  })
  .catch(error => console.log(error));
}

  render() {
    return (
      <>
        <Header
            page="contact-us"
            saveDashboard={this.saveDashboard}
            saveProject={this.saveProject}
            sendProducts={this.goToProducts}
            doLogin={this.doLogin}
            doLogout={this.doLogout}
            project={this.state.project}
            saveLogin={this.saveLogin}
            isLogin={this.state.isLogin} />

        <Content />

        <Footer />
      </>
    )
  }
}


class Content extends Component {

  render() {

    return (

    <>

      <div className="top-background main-banner">
        <div className="container">
            <div clasName="row">
                <h1>Contact Flatpacks</h1>
            </div>
        </div>
      </div>

      <div className="main-content">

        <div className="container">

            <div className="roww">

              <div className="col-md-3">

                <h4>Flat Packs Australia is a family-owned custom cabinetry supplier located at Perth.<br />
                Phone: { config.PHONE }</h4>

                <h4><a href="mailto:sales@flatpackswa.com.au">Email: { config.EMAIL }</a></h4>

                <h4>Perth Factory & Showroom:</h4>
                <p>8 Geelong Court<br />Bibra Lake WA</p>

                <h4>Opening Hours</h4>
                <p>Monday - Thursday:<br />7.00 am to 4.30pm<br /><br />
                Friday: 7.00 am to 3.00pm<br /><br />
                Saturday: By Appointment</p>

              </div>

              <div className="col-md-9">

                  <h2>Flatpacks Equiries</h2>
                  <p>If you have any questions for Flatpacks. Fill out this form and the Flatpacks team will be in touch with you promptly. If you would like to get in touch with us by phone, please call { config.PHONE }.</p>

                  <div className="form-group">

                    <label>How can we help you?</label>
                    <select name='how' className='form-control'>
                      <option value='General Enquiry' >General Enquiry</option>
                      <option value='I&#039;m interested in becoming a Member' >I&#039;m interested in becoming a Member</option>
                      <option value='I&#039;m interested in becoming a Supplier' >I&#039;m interested in becoming a Supplier</option>
                      <option value='I&#039;d like some help with Flatpacks Software' >I&#039;d like some help with Flatpacks Software</option>
                      <option value='Job Opportunities' >Job Opportunities</option>
                      <option value='Other' >Other</option>
                    </select>
                  </div>

                  <div className="form-group">

                    <label>Name</label>

                    <input type="text" className="form-control" />

                  </div>

                  <div className="form-group">

                    <label>Email</label>

                    <input type="text" className="form-control" />

                  </div>

                  <div className="form-group">

                    <label>Your Question</label>

                    <textarea className="form-control" />

                  </div>

                  <button className="btn btn-primary">Submit</button>

              </div>

            </div>

        </div>

      </div>

    </>

    )

  }

}

export default Contact
