import React, { Component } from 'react';
import swal from "sweetalert";
import config from "../config";
import eValid from "./emailValidate";

class EditProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal:"none",
            userid: '',
			Name: '',
			Email: '',
			Phone: '',
			Password: '',
			Token: '',
			ConfirmPassword: '',
			emailIsValid: false,
			emailUnique: false,
			cpOnly: false,
			modalTitle: 'Edit Profile'
        }
        this.closeDialog= this.closeDialog.bind(this)
    }

    componentWillReceiveProps(newprops){
		
        this.setState({	
            modal:newprops.editModal,
            userid: (newprops.User) ? newprops.User.Id : '',
			Token: (newprops.User) ? newprops.User.token : '',
			Name: (newprops.User) ? (newprops.User.Name) : '',
			Email: (newprops.User) ? (newprops.User.Email) : '',
			Phone: (newprops.User) ? (newprops.User.Phone) : '',
			cpOnly: (newprops.cpOnly) ? (newprops.cpOnly) : false,
			modalTitle: (newprops.modalTitle) ? (newprops.modalTitle) : 'Edit Profile',
        },()=>{
            if(this.state.modal===true){
               this.openDialog()
            } else {
                this.closeDialog()
            }
        })
    }

    openDialog() {
        var userProfilemodal = document.getElementById('userProfilemodal');
        userProfilemodal.style.display = "block";
        userProfilemodal.style.backgroundColor = "#000000ab";

      }
      closeDialog() {
        var userProfilemodal = document.getElementById('userProfilemodal');
        userProfilemodal.style.display = "none";
        this.setState({
            modal:"none",
            userid: '',
			Name: '',
			Email: '',
			Phone: '',
			Password: '',
			Token: '',
			ConfirmPassword: '',
			emailIsValid: false,
			emailUnique: false,
			cpOnly: false,
			modalTitle: 'Edit Profile'
        })
      }
	
	
  async handleFormSubmit() {
    let self = this;
    const {userid, Name, Email, Phone, Password, ConfirmPassword, Token} = this.state
	
	if (!this.state.cpOnly && !eValid.isValidEmailAddress(this.state.Email)) {
		swal("", "Invalid Email Address", "error");
		return;
	}
	
	if (this.state.cpOnly && (this.state.Password != this.state.ConfirmPassword)) {
		swal("", "Password do not match. Please try again.", "error");
		return;
	}
	
	//check unique email
	var uniqueEmail = await eValid.isEmailUnique(Email, userid);
	this.setState({
		emailUnique: uniqueEmail
	});
		
	if (!this.state.emailUnique) {
		swal("", "Email Address already exists", "error");
		return;
	}
		
	var data = [];
	
	if (this.state.cpOnly){
        this.setState({
			emailUnique: true
        })
		
		data = {
		  Id: userid,
		  Password: Password,
		}
	}else{
		data = {
		  Id: userid,
		  Name: Name,
		  Email: Email,
		  Phone: Phone,
		  editProfile: 1
		}		
	}

	var uri = (this.state.cpOnly) ? '/api/changepassword' : '/api/updateuser';
			
      fetch(config.BASE_URL + uri, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
		  "Authorization": 'Bearer ' + Token
        }
      }).then((response) => response.json())
        .then((data) => {		
          this.setState({
            modal:"none",
            userid: '',
			Name: '',
			Email: '',
			oldEmail: '',
			Phone: '',
			Password: '',
			Token: '',
			ConfirmPassword: '',
			emailIsValid: false,
			emailUnique: false,
			cpOnly: false,
			modalTitle: 'Edit Profile'
		  })
		  swal("", "Change password successful", "success")
		  self.closeDialog();
        })
        .catch((error) => {
            console.log("Error, with message::", error)
            this.setState({ modal: false }, () => {
              self.closeDialog();
          })
        });
  }

    render() {
        return (
            <div>
                <a type="button" style={{display:"none"}} className="upsize cartbtn purchasebtn btn" data-toggle="modal" data-target="#userProfilemodal" data-whatever="@mdo" id="updateProfile">
                </a>
                <div className="modal" id="userProfilemodal" tabIndex={-1} role="dialog" aria-labelledby="userProfilemodalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content" style={{marginTop:"100px"}}>
                            <div className="modal-header">
                                <h4 className="modal-title" id="userEditmodalLabel" style={{float:"left"}}>{this.state.modalTitle}</h4>
                                <button type="button" className="close" onClick={this.closeDialog.bind(this)} aria-label="Close">
                                    <span aria-hidden="true" onClick={this.closeDialog.bind(this)}>x</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
									{this.state.cpOnly == true &&
										<div>
										<div className="form-group">
											<label htmlFor="phone-data" className="col-form-label">Password:</label>
											<div>
												<input className="form-control" name="Password" type="password"
													onChange={event => this.setState({Password: event.target.value})}>
												</input>
											</div>
										</div>	
										<div className="form-group">
											<label htmlFor="phone-data" className="col-form-label">Confirm Password:</label>
											<div>
												<input className="form-control" name="ConfirmPassword" type="password"
													onChange={event => this.setState({ConfirmPassword: event.target.value})}>
												</input>
											</div>
										</div>
										</div>	
									}
									{this.state.cpOnly == false &&
									<div>
                                    <div className="form-group">
                                        <label htmlFor="name-data" className="col-form-label">Name:</label>
                                        <div>
                                            <input className="form-control" name="Name" type="text"												value={this.state.Name}
												onChange={event => this.setState({Name: event.target.value})}>
                                            </input>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email-data" className="col-form-label">Email:</label>
                                        <div>
                                            <input className="form-control" name="Email" type="email"
												value={this.state.Email}
												onChange={event => this.setState({Email: event.target.value})}
												onBlur={() => this.setState({
													emailIsValid: eValid.isValidEmailAddress(this.state.Email)
												})}>
                                            </input>
                                        </div>
                                    </div>									
								  </div>
									}	
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={this.handleFormSubmit.bind(this)}>
                                    Save
                                </button>
                                <button type="button" onClick={this.closeDialog.bind(this)} className="btn btn-secondary">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default EditProfile;