import React, { Component } from 'react';
import { withRouter } from 'react-router';
import config from "../config";
import * as ReactBootstrap from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import swal from "sweetalert";

class Login extends Component {
    constructor(props) {
        super(props);
		var authuser = localStorage.getItem('authuser');
        this.state = {
            Email: '',
            Password: '',
			isValidLogin: (authuser !== null && authuser !== '') ? true : false,
			Error: {hasError: false, ErrorMessage: ''},
        };
    } 
	handleResponse(response) {
		if (!response.ok) {
			throw Error(response.statusText);
		}
		return response;
	}
	
	handleErrors(error) {
		this.setState({
			hasError: true
		})
	}
	
	isValidEmailAddress(input) {
		//let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;

		if ( re.test(input) ) {
			return true;
		}
		else {
			swal("", "Invalid Email Address", "error")
			return false;
		}
	}

    loginUser = () => {
		const {Email, Password} = this.state

		if (!this.isValidEmailAddress(this.state.Email)) {
			swal("", "Invalid Email Address", "error");
			return;
		}

		var data = {
			Email: Email,
			Password: Password,
		}
		fetch(config.BASE_URL + '/api/userlogin', {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
			  'Content-Type': 'application/json',
			  'Accept': 'application/json'
			}
		})
		.then(this.handleResponse)
		.then(data => data.json())
        .then(data => {
          //console.log('this is the data', data);
				if (data.length !== 0){
					localStorage.setItem('authuser', JSON.stringify(data));
					this.setState({
						isValidLogin: true
					});
				}else{
					this.setState({
						isValidLogin: false
					});
					swal("", "Email or password is wrong", "error");
				}
		})
        .catch(error => this.handleErrors(error));
    }

    render() {
		
		if (this.state.isValidLogin) {
			return <Redirect to="/admin/jobs" />;
		}else{
			return (
				<div>
					<div className="dashboard-header" style={{ position: "sticky", top: 0, zIndex: "99999" }}>
						<nav className="navbar navbar-expand-lg top-nav fixed-top" style={{ marginBottom: 0 }}>
							<a className="navbar-brand" href="/"><img src={config.TOP_LOGO} alt="Logo" style={ {maxWidth: '190px'} } /></a>
						</nav>
					</div>
					<div className="dashboard-wrapper-login partscontent">
						<div className="dashboardhome">
							<div className="container-fluid dashboard-content jobspagecnt">
								<div className="row row-login">
									<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
									<ReactBootstrap.Form className="justify-content-center">
									  <ReactBootstrap.Form.Group controlId="formGroupEmail">
										<ReactBootstrap.Form.Label>Email address</ReactBootstrap.Form.Label>
										<ReactBootstrap.Form.Control type="email" placeholder="Enter email"
											onChange={event => this.setState({Email: event.target.value})}
											onBlur={() => this.setState({
											emailIsValid: this.isValidEmailAddress(this.state.Email)})} />
									  </ReactBootstrap.Form.Group>
									  <ReactBootstrap.Form.Group controlId="formGroupPassword">
										<ReactBootstrap.Form.Label>Password</ReactBootstrap.Form.Label>
										<ReactBootstrap.Form.Control type="password" placeholder="Password" onChange={event => this.setState({Password: event.target.value})} />
									  </ReactBootstrap.Form.Group>
									  
									  <ReactBootstrap.Form.Group controlId="formHorizontalCheck">
										<ReactBootstrap.Form.Check style={{float: 'left'}} label="&nbsp; Remember me" />
										<p className="resetPassword"><a href="/resetpassword">Reset Password</a></p>
										<p style={{float: 'none', clear: 'both'}}></p>
									  </ReactBootstrap.Form.Group>								  
									  <ReactBootstrap.Form.Group>
										<ReactBootstrap.Button onClick={() => this.loginUser()} type="button">Sign In</ReactBootstrap.Button>
									  </ReactBootstrap.Form.Group>								  
									</ReactBootstrap.Form>
									</div>
								</div>
							</div>
						</div>
						{/* ============================================================== */}
						{/* footer */}
						{/* ============================================================== */}
						<div className="footer">
							<div className="container-fluid">
								<div className="row">
									<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
										COPYRIGHT © 2020 FLAT PACKS WA | <a href="/terms">TERMS &amp; CONDITIONS</a>
									</div>
								</div>
							</div>
						</div>
						{/* ============================================================== */}
						{/* end footer */}
						{/* ============================================================== */}
					</div>
				</div>	
			)
		}
		
    }
}
export default withRouter(Login);

export class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Email: '',
            Password: ''
        };
    } 
	handleResponse(response) {
		if (!response.ok) {
			throw Error(response.statusText);
		}
		return response;
	}
	
	handleErrors(error) {
		this.setState({
			hasError: true
		})
	}
	
	isValidEmailAddress(input) {
		//let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		
		const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;

		if ( re.test(input) ) {
			return true;
		}
		else {
			swal("", "Invalid Email Address", "error")
			return false;
		}
	}

    ResetPassword = () => {
		const {Email} = this.state

		if (!this.isValidEmailAddress(this.state.Email)) {
			swal("", "Invalid Email Address", "error");
			return;
		}

		var data = {
			Email: Email
		}
		fetch(config.BASE_URL + '/api/resetrequest', {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
			  'Content-Type': 'application/json',
			  'Accept': 'application/json'
			}
		})
		.then(this.handleResponse)
		.then(data => data.json())
        .then(data => {
			var statuscode = (data.code === '200') ? 'success' : 'error'
			swal("", data.message, statuscode);
		})
        .catch(error => this.handleErrors(error));
    }

    render() {
		
		if (this.state.isValidLogin) {
			return <Redirect to="/admin/jobs" />;
		}else{
			return (
				<div>
					<div className="dashboard-header" style={{ position: "sticky", top: 0, zIndex: "99999" }}>
						<nav className="navbar navbar-expand-lg top-nav fixed-top" style={{ marginBottom: 0 }}>
							<a className="navbar-brand" href="/"><img src={config.TOP_LOGO} alt="Logo" style={ {maxWidth: '190px'} } /></a>
						</nav>
					</div>
					<div className="dashboard-wrapper-login partscontent">
						<div className="dashboardhome">
							<div className="container-fluid dashboard-content jobspagecnt">
								<div className="row row-login">
									<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
									<ReactBootstrap.Form className="justify-content-center">
									  <ReactBootstrap.Form.Group controlId="formGroupEmail">
										<ReactBootstrap.Form.Label>Email address</ReactBootstrap.Form.Label>
										<ReactBootstrap.Form.Control type="email" placeholder="Enter email"
											onChange={event => this.setState({Email: event.target.value})}
											onBlur={() => this.setState({
											emailIsValid: this.isValidEmailAddress(this.state.Email)})} />
									  </ReactBootstrap.Form.Group>
									  								  
									  <ReactBootstrap.Form.Group>
										<ReactBootstrap.Button onClick={() => this.ResetPassword()} type="button">Reset Password</ReactBootstrap.Button>
									  </ReactBootstrap.Form.Group>
									  <ReactBootstrap.Form.Group controlId="formHorizontalCheck">
										<p style={{fontSize: '14px'}}><a href="/login">Click here to login</a></p>
										<p style={{float: 'none', clear: 'both'}}></p>
									  </ReactBootstrap.Form.Group>										  
									</ReactBootstrap.Form>
									</div>
								</div>
							</div>
						</div>
						{/* ============================================================== */}
						{/* footer */}
						{/* ============================================================== */}
						<div className="footer">
							<div className="container-fluid">
								<div className="row">
									<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
										COPYRIGHT © 2020 FLAT PACKS WA | <a href="/terms">TERMS &amp; CONDITIONS</a>
									</div>
								</div>
							</div>
						</div>
						{/* ============================================================== */}
						{/* end footer */}
						{/* ============================================================== */}
					</div>
				</div>	
			)
		}
		
    }
}

export class ResetValidate extends Component {
    constructor(props) {
		
        super(props);
        this.state = {
            Email: '',
            Password: '',
			ConfirmPassword: '',
			ResetKey: '',
			ResetSuccess: false,
			Error: {hasError: false, ErrorMessage: ''}
        };
    }
	
	componentDidMount() {
		var Error = {
			hasError: false,
			ErrorMessage: ''			
		}
		
		if (this.props.match.params.email === '' && !this.isValidEmailAddress(this.props.match.params.email)) {
			Error = {
				hasError: true,
				ErrorMessage: 'Invalid Email Address'
			}
		}
		if (this.props.match.params.resetkey === '') {
			Error = {
				hasError: true,
				ErrorMessage: 'Invalid reset key'
			}
		}
		
		this.setState({
			Email: this.props.match.params.email,
			ResetKey: this.props.match.params.resetkey,
			Error: Error
		}, () => {
			var data = {
				Email: this.props.match.params.email,
				ResetKey: this.props.match.params.resetkey			
			}
			
			fetch(config.BASE_URL + '/api/resetvalidate', {
				method: 'POST',
				body: JSON.stringify(data),
				headers: {
				  'Content-Type': 'application/json',
				  'Accept': 'application/json'
				}
			})
			.then(data => data.json())
			.then(data => {
				if (data.code !== '200'){
					this.setState({
						Error: {
							hasError: true,
							ErrorMessage: data.message
						}
					})					
				}					
			})
			.catch(error => console.log(error));
		})		
	}
	
	isValidEmailAddress(input) {
		//let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;

		if ( re.test(input) ) {
			return true;
		}
		else {
			//swal("", "Invalid Email Address", "error")
			return false;
		}
	}

    ResetPassword = () => {
		const {Email, Password, ConfirmPassword, ResetKey} = this.state
		
		if (Password !== ConfirmPassword) {
			swal("", 'Confirm Password Do Not Match', 'error');
			return;
		}
		
		var data = {
			Email: Email,
			ResetKey: ResetKey,
			Password: Password
		}
		fetch(config.BASE_URL + '/api/resetpassword', {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
			  'Content-Type': 'application/json',
			  'Accept': 'application/json'
			}
		})
		.then(this.handleResponse)
		.then(data => data.json())
        .then(data => {
			var statuscode = (data.code === '200') ? 'success' : 'error'
			swal("", data.message, statuscode);
			
			if (data.code === '200') {
				this.setState({
					Error: {
						hasError: false,
						ErrorMessage: ''						
					},
					ResetSuccess: true
				})				
			}else{
				this.setState({
					Error: {
						hasError: true,
						ErrorMessage: data.message
					},
					ResetSuccess: false
				})				
			}
		})
        .catch(error => console.log(error));
    }

    render() {
		
		if (this.state.ResetSuccess) {
			return <Redirect to="/login" />;
		}else{
			return (
				<div>
					<div className="dashboard-header" style={{ position: "sticky", top: 0, zIndex: "99999" }}>
						<nav className="navbar navbar-expand-lg top-nav fixed-top" style={{ marginBottom: 0 }}>
							<a className="navbar-brand" href="/"><img src={config.TOP_LOGO} alt="Logo" style={ {maxWidth: '190px'} } /></a>
						</nav>
					</div>
					<div className="dashboard-wrapper-login partscontent">
						<div className="dashboardhome">
							<div className="container-fluid dashboard-content jobspagecnt">
								<div className="row row-login">
									<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
									{!this.state.Error.hasError ? (
									<ReactBootstrap.Form className="justify-content-center">
									  <ReactBootstrap.Form.Group controlId="formGroupEmail">
										<ReactBootstrap.Form.Label>New Password</ReactBootstrap.Form.Label>
										<ReactBootstrap.Form.Control type="password" placeholder="Enter password"
											onChange={event => this.setState({Password: event.target.value})} />
									  </ReactBootstrap.Form.Group>
									  <ReactBootstrap.Form.Group controlId="formGroupEmail">
										<ReactBootstrap.Form.Label>Confirm New Password</ReactBootstrap.Form.Label>
										<ReactBootstrap.Form.Control type="password" placeholder="confirm password"
											onChange={event => this.setState({ConfirmPassword: event.target.value})} />
									  </ReactBootstrap.Form.Group>									  								  
									  <ReactBootstrap.Form.Group>
										<ReactBootstrap.Button onClick={() => this.ResetPassword()} type="button">Reset Password</ReactBootstrap.Button>
									  </ReactBootstrap.Form.Group>
									  <ReactBootstrap.Form.Group controlId="formHorizontalCheck">
										<p style={{fontSize: '14px'}}><a href="/login">Click here to login</a></p>
										<p style={{float: 'none', clear: 'both'}}></p>
									  </ReactBootstrap.Form.Group>										  
									</ReactBootstrap.Form>
									) : (
										<>
										<h3>{this.state.Error.ErrorMessage}</h3>
										<p><a href="/resetpassword">Click here to request again</a></p>
										</>
									)}
									</div>
								</div>
							</div>
						</div>
						{/* ============================================================== */}
						{/* footer */}
						{/* ============================================================== */}
						<div className="footer">
							<div className="container-fluid">
								<div className="row">
									<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
										COPYRIGHT © 2020 FLAT PACKS WA | <a href="/terms">TERMS &amp; CONDITIONS</a>
									</div>
								</div>
							</div>
						</div>
						{/* ============================================================== */}
						{/* end footer */}
						{/* ============================================================== */}
					</div>
				</div>	
			)
		}
		
    }
}
