import React from 'react';
import {injectStripe} from 'react-stripe-elements';
import config from '../config';
import CardSection from './CardSection';
import { Redirect } from 'react-router';
import { strings } from './strings';

class CheckoutForm extends React.Component {

    constructor(props) {

		super(props);

		this.state = {
    			message: '',
    			payType: 'Stripe',
    			formProcess: false,
    			subtotal: (props.subtotal) ? props.subtotal : 0,
    			errorMessage: '',
    			intentSecret: '',
    			goto: props.redirect,
    			JobId: (props.JobId) ? props.JobId : 0,
    			projectId: (props.projectId) ? props.projectId : 0,
    			redirect: false,
    			hasError: false,
				paymentComplete: false,
				project: {ProjectName: '', ProjectId: 0},
    			Customer: (props.Customer) ? props.Customer : [],
    			addressData: (props.addressData) ? props.addressData : [],
    			productsData: (props.productsData) ? props.productsData : [],
          		paybyaccount: false,
          pickupOption:  (props.pickupOption) ? props.pickupOption : '',
          OverallTotalCost: 0,
          shippingCost: 0
		}

        this.formatCurrency = this.formatCurrency.bind(this)
        this.handleChangeCheck = this.handleChangeCheck.bind(this)
        this.paybyaccountPayment = this.paybyaccountPayment.bind(this)
    }

    handleChangeCheck(ev){
      this.setState(prevState => ({
        paybyaccount: !prevState.paybyaccount
      }));
    }

  componentDidUpdate(newProps, prevState) {
    //console.log('prevState.addressData', prevState.addressData)
    //console.log('newProps.addressData', newProps.addressData)
    if (prevState.addressData !== newProps.addressData || prevState.pickupOption !== newProps.pickupOption || newProps.shippingCost != prevState.shippingCost) {
      this.setState({
  			addressData: (newProps.addressData) ? newProps.addressData : [],
        pickupOption: (newProps.pickupOption) ? newProps.pickupOption : '',
        shippingCost: newProps.shippingCost
  		});
      this.getPaymentIntent(newProps);
    }

  }

	componentDidMount() {
		this.setState({
			message: '',
			payType: 'Stripe',
			formProcess: false,
			goto: this.props.redirect,
			subtotal: (this.props.subtotal) ? this.props.subtotal : 0,
			JobId: (this.props.JobId) ? this.props.JobId : 0,
			projectId: (this.props.projectId) ? this.props.projectId : 0,
			project: this.props.project,
			redirect: false,
			errorMessage: '',
			hasError: false,
			paymentComplete: false,
			Customer: (this.props.Customer) ? this.props.Customer : [],
			addressData: (this.props.addressData) ? this.props.addressData : [],
			productsData: (this.props.productsData) ? this.props.productsData : [],
  			pickupOption: (this.props.pickupOption) ? this.props.pickupOption : ''
		});

  	}

  	async getPaymentIntent(data) {

    const TotalCost = parseFloat(data.shippingCost) + parseFloat(data.result) + parseFloat(data.assemblyCost)
    const MinOrder = data.min_order ? data.min_order : 0
		var data = {
			amount: (TotalCost >= MinOrder) ? TotalCost.toFixed(2)  : MinOrder.toFixed(2),
      JobId: data.JobId
		}

		var Token = this.state.Customer.token

		await fetch(config.BASE_URL + '/api/paymentintent', {
				method: "POST",
				body: JSON.stringify(data),
				headers: {
			  			'Content-Type': 'application/json',
			  			'Accept': 'application/json',
			  			"Authorization": 'Bearer ' + Token
					}
				})
				.then(data => data.json())
				.then(data => {
					this.setState({intentSecret: data.paymentIntent.client_secret, OverallTotalCost: (TotalCost > MinOrder) ? TotalCost  : MinOrder});
				})
				.catch(error => console.log(error));
  	}

  	handleSubmit = (ev) => {
      //console.log('this.state.addressData', this.state.addressData)
		ev.preventDefault();
    //return;
		if (this.props.addressData.length === 0) {

			this.setState({
				formProcess: false,
				hasError: true,
				errorMessage: `${strings.Invoiceaddressisrequired}`
			});

			return;

		} else {

			if (this.props.addressData.invoiceAddLabel === '') {
				this.setState({
					formProcess: false,
					hasError: true,
					errorMessage: `${strings.Invoiceaddressisrequired}`
				});
				return;
			}

      if (this.props.addressData.shippingAddLabel === '' && !this.props.isPickUp) {
				this.setState({
					formProcess: false,
					hasError: true,
					errorMessage: `${strings.Shippingaddressisrequired}`
				});
				return;
			}

		}

  		this.setState({formProcess: true});

		if (this.state.paybyaccount) {

			this.paybyaccountPayment();

		} else {

			const cardElement = this.props.elements.getElement('cardNumber');

			if (this.state.intentSecret !== '') {
				this.confirmPayment(cardElement);
			}
		}
  	}

	paybyaccountPayment(){

  		const {addressData, Customer, project, pickupOption} = this.props;

  		const Token = Customer.token;

  		const actualTotal = (parseFloat(this.props.result) > this.props.min_order) ? parseFloat(this.props.result)+parseFloat(this.props.assemblyCost) : this.props.min_order

		var data = {

			JobId: this.state.JobId,
			PaymentMethod: 'Pay By Account',
			PaidDateTime: new Date().getTime() / 1000,
			PaidAmount: parseFloat(actualTotal) * 100,
			PaymentRef: '',
			project: project,
			Status: 'succeeded',
			CustomerId: Customer.CustomerId,
			ShippingAddressLine1: (addressData.shippingAddress) ? addressData.shippingAddress.street_num + ' ' + addressData.shippingAddress.route : '',
			State: (addressData.shippingAddress) ? addressData.shippingAddress.aState : '',
			ZipCode:  (addressData.shippingAddress) ? addressData.shippingAddress.postcode : '',
			City:  (addressData.shippingAddress) ? addressData.shippingAddress.city : '',
			Country: (addressData.shippingAddress) ? addressData.shippingAddress.country : '',
			invoiceAdd: addressData.invoiceAddLabel,
			shippingAdd:addressData.shippingAddLabel,
      		pickupOption: pickupOption,
          customDelivery: this.props.customDelivery,
          shippingCost: this.props.shippingCost

		}



		fetch(config.BASE_URL + '/api/completepayment', {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				"Authorization": 'Bearer ' + Token
			}
		})
		.then(data => data.json())
		.then(data => {
			this.setState({
			hasError: false,
			savingProgress: false,
			intentSecret: '',
			paymentComplete: true
			}, () => {
				this.props.completePayment();
			})
		})
		.catch(error => console.log(error));
	}

 async confirmPayment(cardElement) {
	 //console.log('this.state.addressData');
	 //console.log(this.state.addressData);
    const shipping = {
      name: this.props.Customer.Name,
      address: {
        line1: (this.props.addressData.shippingAddress) ? this.props.addressData.shippingAddress.street_num + ' ' + this.props.addressData.shippingAddress.route : '',
        city: (this.props.addressData.shippingAddress) ? this.props.addressData.shippingAddress.city : '',
        postal_code: (this.props.addressData.shippingAddress) ? this.props.addressData.shippingAddress.postcode : '',
        state: (this.props.addressData.shippingAddress) ? this.props.addressData.shippingAddress.aState : '',
        country: (this.props.addressData.shippingAddress) ? this.props.addressData.shippingAddress.country : ''
      }
    };

    const billing_details = {
      name: this.props.Customer.Name,
	    email: this.props.Customer.Email,
      address: {
        line1: (this.props.addressData.invoiceAddress) ? this.props.addressData.invoiceAddress.street_num + ' ' + this.props.addressData.invoiceAddress.route : '',
        city: (this.props.addressData.invoiceAddress) ? this.props.addressData.invoiceAddress.city : '',
        postal_code: (this.props.addressData.invoiceAddress) ? this.props.addressData.invoiceAddress.postcode : '',
        state: (this.props.addressData.invoiceAddress) ? this.props.addressData.invoiceAddress.aState : '',
        country: (this.props.addressData.invoiceAddress) ? this.props.addressData.invoiceAddress.country : ''
      }
    };

	const response = await this.props.stripe.confirmCardPayment(this.state.intentSecret, {
		payment_method: {
			card: cardElement,
            billing_details
		},
		shipping
	});

	this.handlePayment(response)

 }

	handlePayment = paymentResponse => { //save response to database
		const {paymentIntent, error} = paymentResponse;
		const {addressData} = this.state;

		//console.log(addressData);

		if (error) {
			//swal(error.message);
			this.setState({
				formProcess: false,
				hasError: true,
				errorMessage: error.message
			});

		} else if (paymentIntent.status === 'succeeded') {
			//console.log(paymentIntent);
			const Token = this.props.Customer.token;
			const project = this.props.project;
			var data = {
				JobId: this.props.JobId,
				project: project,
				PaymentMethod: 'Credit Card',
				PaidDateTime: paymentIntent.created,
				PaidAmount: paymentIntent.amount	,
				PaymentRef: paymentIntent.id,
				Status: paymentIntent.status,
				CustomerId: this.props.Customer.CustomerId,
				ShippingAddressLine1: (paymentIntent.shipping.address.line1) ? paymentIntent.shipping.address.line1 : '',
				State: (paymentIntent.shipping.address.state) ? paymentIntent.shipping.address.state : '',
				ZipCode:  (paymentIntent.shipping.address.postal_code) ? paymentIntent.shipping.address.postal_code : '',
				City:  (paymentIntent.shipping.address.city) ? paymentIntent.shipping.address.city : '',
				Country: (paymentIntent.shipping.address.country) ? paymentIntent.shipping.address.country : '',
				invoiceAdd: addressData.invoiceAddLabel,
				shippingAdd:addressData.shippingAddLabel,
        		pickupOption: this.props.pickupOption,
            customDelivery: this.props.customDelivery,
            shippingCost: this.props.shippingCost

			}
			fetch(config.BASE_URL + '/api/completepayment', {
				method: "POST",
				body: JSON.stringify(data),
				headers: {
					  'Content-Type': 'application/json',
					  'Accept': 'application/json',
					  "Authorization": 'Bearer ' + Token
				}
			})
			.then(data => data.json())
			.then(data => {
				this.setState({
					hasError: false,
					savingProgress: false,
					intentSecret: '',
					paymentComplete: true
				}, () => {

					this.props.completePayment();

					/*localStorage.removeItem('JobId');
					localStorage.removeItem('postData');
					localStorage.removeItem('jobProducts');

					setTimeout(() => this.setState({ redirect: true }), 1000)*/
				})
			})
			.catch(error => console.log(error));
		}

	}

  formatCurrency(money){
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(money);
  }

  render() {
	if (this.state.redirect) {
		return (
			<Redirect to="/dashboard" />
		)
	} else {

		//console.log('checkout form props', this.props);

		  const {productsData} = this.props

    //console.log('this.props', this.props)

    	const MinOrder = this.props.min_order ? parseFloat(this.props.min_order) : 0

    	var TotalWeight = 0

      //console.log('this.props.subtotal', this.props.result)
      const TotalShippingCost = this.props.shippingCost ? parseFloat(this.props.shippingCost) : 0

      //console.log('this.props.assemblyCost', this.props.assemblyCost)
      //console.log('TotalShippingCost', TotalShippingCost)
      //console.log('this.props.result', this.props.result)

      var OverallTotalCost = (parseFloat(this.props.result) + TotalShippingCost + parseFloat(this.props.assemblyCost))

      const isMinOrder = (OverallTotalCost < MinOrder)

      OverallTotalCost = OverallTotalCost >= MinOrder  ? OverallTotalCost : MinOrder

      //console.log('this.props.addressData', this.props.addressData)

		return (
			<div className="row">
      {this.state.Customer.pay_by_account !== 1 ? '' :
      <div className="col-md-12">
        <div className="form-group greenCheckBox" style={{marginBottom: "10px"}}>
            <label className="form-check-input"><input checked={this.state.paybyaccount ? true: false} onChange={this.handleChangeCheck} type="checkbox" name="paybyaccount" value="1" /><span className="checkmark1"></span> {strings.paybyAccount}</label>
          </div>
      </div>
      }
				<form className="payment-form-stripe" onSubmit={this.handleSubmit}>
        {this.state.paybyaccount ? '' :
					<CardSection
						hasError={this.state.hasError}
						errorMessage={this.state.errorMessage}
						paymentComplete={this.state.paymentComplete}
						/>
        }


						<div className="col-md-12">

							<div className="order-details">

								<h2>{strings.OrderDetails}</h2>
									<div className="product-cart-summary-wrapper">
									<table className="product-cart-summary">
										<tbody>
											<tr>
												<th></th>
												<th>Name</th>
												<th>Dimension</th>
                      <th>Price</th>
                      <th>QTY</th>
												<th className="alignright">Total</th>
										</tr>
										{
                      productsData && productsData.length > 0 ? productsData.map((product, key) => {
                        //console.log('productsData', product)

						const dimension = (product.jobProductData.ProductType === 'accessories') ? '' : product.jobProductData.Height+' x '+product.jobProductData.Width+' x '+product.jobProductData.Depth
                        const Qty = (product.jobProductData.ProductType === 'accessories') ? product.jobProductData.fixedQty : product.jobProductData.ProductQty
                        const UnitPrice = (product.jobProductData.ProductType === 'accessories') ? product.jobProductData.HardwareCost : product.jobProductData.Price
                        const TotalPrice = product.jobProductData.ProductType === 'accessories' ? product.jobProductData.Price : product.jobProductData.Price * product.jobProductData.ProductQty
                        const tWeight = product.jobProductData.Weight ? parseFloat(product.jobProductData.Weight) : 0

                        TotalWeight += tWeight

						return (
											<tr key={key}>
												<td><img alt={product.jobProductData.ProductName} src={product.jobProductData.image} style={{height: "50px"}} /></td>
												<td>{product.jobProductData.ProductName}</td>
												<td>{dimension}</td>
                        						<td>{this.formatCurrency(UnitPrice)}</td>
                        						<td>{Qty}</td>
												<td className="alignright">{this.formatCurrency(TotalPrice)}</td>
											</tr>)
										}) : ""
										}

										</tbody>

									</table>
									</div>
                  { (parseFloat(this.props.assemblyCost) > 0) ? (
                  <table className="product-cart-summary">
                      <tr>
                        <td>Subtotal:</td>
                        <td className="alignright"><b>{this.formatCurrency(this.props.result)}</b></td>
                      </tr>
                      <tr>
                        <td>Assembly Cost:</td>
                        <td className="alignright"><b>{ this.formatCurrency(this.props.assemblyCost)}</b></td>
                      </tr>
                  </table>
                ) : ('')}

                <table className="product-cart-summary-shipping">
                    <tr>
                      <td>Total Estimated Weight</td>
                      <td className="alignright"><b>{TotalWeight.toFixed(2)} kg</b></td>
                    </tr>
                    { this.props.isPickUp ?
                      '' :
                    <tr>
                      <td>Shipping Cost</td>
                      <td className="alignright"><b>{ this.formatCurrency(parseFloat(TotalShippingCost)) } </b></td>
                    </tr>
                    }
                </table>
                  <div className="col-md-12" style={{padding: '0px'}}>
									<dl className="modal-order-summary">
										<dd ><label  style={{ fontSize: "18px", fontWeight: "900" }}>{this.formatCurrency(parseFloat(OverallTotalCost)) }</label>
                      						<span style={{fontSize: '14px'}}>GST Included</span>
                    					</dd>
									</dl>
                  {isMinOrder == true ?
                  <div className="alert alert-danger" style={{padding: '8px 15px', marginTop: '10px', marginBottom: '10px'}}>
                    <p style={{fontSize: '14px'}}>{strings.minOrderMessage.replace('[minorder]', this.formatCurrency(parseFloat(MinOrder)))}</p>
                  </div>
                  : ''
                  }
                  </div>
                  <div style={{float: 'none', clear: 'both', paddingBottom: '10px', borderBottom: 'solid 3px #1aab37'}}></div>
								</div>
							</div>


						<div className="col-md-12">
              {this.state.hasError ?
              <div class="alert alert-danger" role="alert">{this.state.errorMessage}</div>
              : ''
              }
								<div>
									{ (this.state.formProcess)? (
										  <button className="btn cartbtn purchasebtn d-block upsize" disabled>Processing please wait...</button>
										) : (
										  <button className="btn cartbtn purchasebtn d-block upsize">{strings.PlaceOrder}</button>
										)}
								</div>
						</div>
						<div style={{float: "none", clear: "both"}}></div>
					</form>

			</div>
		);
	}
  }
}

export default injectStripe(CheckoutForm);
