import React, { Component } from 'react';
//import Konva from 'konva';
import { Stage, Layer, Line } from 'react-konva';

class PanelOption extends Component {
  constructor() {
      super();
      this.onClick = this.onClick.bind(this)
  }

  onClick = e => {
    this.props.onClickEdges(e.target.attrs.name)
    if (this.props[e.target.attrs.name]) {
      e.target.zIndex(3)
    }else{
     e.target.zIndex(0)
    }
  }


  onMouseEnter = e => {
    e.target.getStage().container().style.cursor = 'pointer'
  }

  onMouseLeave = e => {
    e.target.getStage().container().style.cursor = 'default'
  }

  render() {
    //console.log('this.props', this.props.product)
    const {lineTop, lineLeft, lineBottom, lineRight, product} = this.props
    console.log('this product', product)
    return (
      <>
      { product.ProductName && product.ProductName.indexOf("Kicker") === -1 ?
      <Stage width={290} height={375}>
        <Layer>

        <Line
          x={70}
          y={50}
          points={[0, 0, 150, 0]}
          stroke={!lineTop ? 'grey' : '#8dc63f' }
          strokeWidth={8}
          name="lineTop"
          lineCap="square"
          onClick={this.onClick}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          zIndex={!lineTop ? 3 : 0 }
        />
        <Line
          x={70}
          y={50}
          points={[0, 300, 0, 0]}
          stroke={!lineLeft ? 'grey' : '#8dc63f' }
          strokeWidth={8}
          name="lineLeft"
          lineCap="square"
          onClick={this.onClick}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          zIndex={!lineLeft ? 3 : 0 }
        />
        <Line
          x={70}
          y={350}
          points={[0, 0, 150, 0]}
          stroke={!lineBottom ? 'grey' : '#8dc63f' }
          strokeWidth={8}
          name="lineBottom"
          lineCap="square"
          onClick={this.onClick}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          zIndex={!lineBottom ? 3 : 0 }
        />
        <Line
          x={220}
          y={50}
          points={[0, 300, 0, 0]}
          stroke={!lineRight ? 'grey' : '#8dc63f' }
          strokeWidth={8}
          name="lineRight"
          lineCap="square"
          onClick={this.onClick}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          zIndex={!lineRight ? 3 : 0 }
        />

        </Layer>
      </Stage>

      :

      <Stage width={275} height={200}>
        <Layer>
        <Line
          x={5}
          y={50}
          points={[0, 0, 275, 0]}
          stroke={!lineLeft ? 'grey' : '#8dc63f' }
          strokeWidth={8}
          name="lineLeft"
          lineCap="square"
          onClick={this.onClick}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          zIndex={!lineLeft ? 3 : 0 }
        />
        <Line
          x={5}
          y={50}
          points={[0, 100, 0, 0]}
          stroke={!lineTop ? 'grey' : '#8dc63f' }
          strokeWidth={8}
          name="lineTop"
          lineCap="square"
          onClick={this.onClick}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          zIndex={!lineTop ? 3 : 0 }
        />
        <Line
          x={5}
          y={150}
          points={[0, 0, 275, 0]}
          stroke={!lineRight ? 'grey' : '#8dc63f' }
          strokeWidth={8}
          name="lineRight"
          lineCap="square"
          onClick={this.onClick}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          zIndex={!lineRight ? 3 : 0 }
        />
        <Line
          x={270}
          y={50}
          points={[0, 100, 0, 0]}
          stroke={!lineBottom ? 'grey' : '#8dc63f' }
          strokeWidth={8}
          name="lineBottom"
          lineCap="square"
          onClick={this.onClick}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          zIndex={!lineBottom ? 3 : 0 }
        />

        </Layer>
      </Stage>
    }
    </>
    );
  }
}
export default PanelOption
