import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import config from "../config";
import HeaderAdmin from '../components/HeaderAdmin';
import FooterAdmin from '../components/FooterAdmin';
import swal from 'sweetalert';
import Modal from 'react-bootstrap-modal';
import { strings } from '../components/strings';
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';

const noCost = 'Cost Items does not include GST'

class Master extends Component {

    constructor(props) {
        super(props);

        var authuser = JSON.parse(localStorage.getItem('authuser'));

        this.state = {
            AuthUser: (authuser) ? authuser[0] : [],
            doorstyle: [],
            hardware: [],
            defaults: [],
            pickupoptions: []

        }

        this.getMaterials = this.getMaterials.bind(this);

        this.saveDoorStyle = this.saveDoorStyle.bind(this);
        this.addDoorStyle = this.addDoorStyle.bind(this);
        this.deleteDoorStyle = this.deleteDoorStyle.bind(this);

        this.saveHardware = this.saveHardware.bind(this);
        this.addHardware = this.addHardware.bind(this);
        this.deleteHardware = this.deleteHardware.bind(this);
        this.handleChangeMinOrder = this.handleChangeMinOrder.bind(this);
        this.handleBlurMinOrder = this.handleBlurMinOrder.bind(this);
        this.onChecked = this.onChecked.bind(this);
        this.saveDefaults = this.saveDefaults.bind(this);

        this.getPickupOptions = this.getPickupOptions.bind(this);
        this.savePickupOption = this.savePickupOption.bind(this);
        this.addPickupOption = this.addPickupOption.bind(this);
        this.deletePickupOption = this.deletePickupOption.bind(this);

        this.saveShippingRates = this.saveShippingRates.bind(this);
    }
    handleChangeMinOrder(e){
      const {defaults} = this.state
      defaults[0][e.target.name] = e.target.value

      this.setState({
        defaults: defaults
      }, ()=> {
        //console.log(this.state.defaults)
      })

    }

    onChecked(e){
      //console.log('e', e);
      const {defaults} = this.state
      defaults[0][e.target.name] = defaults[0][e.target.name] === 0 ? 1 : 0;

      this.setState({
        defaults: defaults
      }, ()=> {
        this.saveDefaults();
      })
    }

    saveDefaults(){
      const {defaults} = this.state
      var authuser = this.state.AuthUser;
      fetch(config.BASE_URL + '/api/savedefault', {
        method: "POST",
        body: JSON.stringify(defaults),
        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json',
            "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
          }
        })
        .then(data => data.json())
        .then(data => {
          this.getMaterials();
        })
        .catch(error => console.log(error));
    }

    handleBlurMinOrder(){
      this.saveDefaults();
    }

    saveHardware(data) {

        var authuser = this.state.AuthUser;
        fetch(config.BASE_URL + '/api/savehardware', {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
              "Content-Type": "application/json",
              'Accept': 'application/json',
              "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
          })
          .then(data => data.json())
          .then(data => {
            this.getMaterials();
          })
          .catch(error => console.log(error));

    }

    addHardware(data) {
        var authuser = this.state.AuthUser;
        fetch(config.BASE_URL + '/api/addhardware', {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
              "Content-Type": "application/json",
              'Accept': 'application/json',
              "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
          })
          .then(data => data.json())
          .then(data => {
            this.getMaterials();
          })
          .catch(error => console.log(error));
    }

    deleteHardware(data) {

        var authuser = this.state.AuthUser;
        fetch(config.BASE_URL + '/api/deletehardware', {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
              "Content-Type": "application/json",
              'Accept': 'application/json',
              "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
          })
          .then(data => data.json())
          .then(data => {
            this.getMaterials();
          })
          .catch(error => console.log(error));

    }

    saveDoorStyle(data) {
        var authuser = this.state.AuthUser;
        fetch(config.BASE_URL + '/api/savedoorstyle', {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
              "Content-Type": "application/json",
              'Accept': 'application/json',
              "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
          })
          .then(data => data.json())
          .then(data => {
            this.getMaterials();
          })
          .catch(error => console.log(error));
    }

    saveShippingRates(data) {
        var authuser = this.state.AuthUser;
        fetch(config.BASE_URL + '/api/saveshippingrates', {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
              "Content-Type": "application/json",
              'Accept': 'application/json',
              "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
          })
          .then(data => data.json())
          .then(data => {

          })
          .catch(error => console.log(error));
    }

    addDoorStyle(data) {
        var authuser = this.state.AuthUser;
        fetch(config.BASE_URL + '/api/adddoorstyle', {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
              "Content-Type": "application/json",
              'Accept': 'application/json',
              "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
          })
          .then(data => data.json())
          .then(data => {
            this.getMaterials();
          })
          .catch(error => console.log(error));
    }

    deleteDoorStyle(data) {
        var authuser = this.state.AuthUser;
        fetch(config.BASE_URL + '/api/deletedoorstyle', {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
              "Content-Type": "application/json",
              'Accept': 'application/json',
              "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
          })
          .then(data => data.json())
          .then(data => {
            this.getMaterials();
          })
          .catch(error => console.log(error));
    }


        savePickupOption(data) {
            var authuser = this.state.AuthUser;
            fetch(config.BASE_URL + '/api/savepickupoption', {
              method: "POST",
              body: JSON.stringify(data),
              headers: {
                  "Content-Type": "application/json",
                  'Accept': 'application/json',
                  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
                }
              })
              .then(data => data.json())
              .then(data => {
                this.getMaterials();
              })
              .catch(error => console.log(error));
        }

        addPickupOption(data) {
            var authuser = this.state.AuthUser;
            fetch(config.BASE_URL + '/api/addpickupoption', {
              method: "POST",
              body: JSON.stringify(data),
              headers: {
                  "Content-Type": "application/json",
                  'Accept': 'application/json',
                  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
                }
              })
              .then(data => data.json())
              .then(data => {
                this.getMaterials();
              })
              .catch(error => console.log(error));
        }

        deletePickupOption(data) {
            var authuser = this.state.AuthUser;
            fetch(config.BASE_URL + '/api/deletepickupoption', {
              method: "POST",
              body: JSON.stringify(data),
              headers: {
                  "Content-Type": "application/json",
                  'Accept': 'application/json',
                  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
                }
              })
              .then(data => data.json())
              .then(data => {
                this.getMaterials();
              })
              .catch(error => console.log(error));
        }

        getPickupOptions() {
            fetch(config.BASE_URL + '/api/getpickupoptions', {
              method: "GET",
              headers: {
                  "Content-Type": "application/json",
                  'Accept': 'application/json'
                }
              })
              .then(data => data.json())
              .then(data => {
                this.setState(
                  data
                );
              })
              .catch(error => console.log(error));
        }

    getMaterials() {
        fetch(config.BASE_URL + '/api/getmaterials', {
          method: "GET",
          headers: {
              "Content-Type": "application/json",
              'Accept': 'application/json'
            }
          })
          .then(data => data.json())
          .then(data => {
            console.log('data.default', data.default)
            this.setState(
              data
            );
          })
          .catch(error => console.log(error));
    }

    componentDidMount() {
        this.getMaterials();
    }

    render() {
      const {defaults} = this.state
      //console.log(defaults[0])
      //const isChecked = defaults[0] && defaults[0].activation_required === 1  ? true : false
      //console.log('isChecked', isChecked)
      //console.log('defaults', defaults);
        return (
            <div className="admin-wrapper">
                <HeaderAdmin />
                <div className="dashboard-wrapper partscontent">
                    <div className="dashboardhome">
                        <div className="container-fluid dashboard-content jobspagecnt">
                            {/* ============================================================== */}
                            {/* pagecontent  */}
                            {/* ============================================================== */}
                            <div className="row">
                            <div className="col-md-12">
                                <ol className="breadcrumb breadm">
                                    <li><a href="/admin">Admin</a></li>
                                    <li><i className="fa fa-angle-right midarrow" /><a href="/admin/masters">Masters</a></li>
                                </ol>
                                <div className="form-group">
                                    <label style={{fontSize: '20px', marginRight: '25px'}}>Minimum Job Value ($)</label>
                                    <input style={{display: 'inline-block', width: '200px'}} type="text" name="min_order" className="form-control" defaultValue={defaults[0] ? defaults[0].min_order : ''} onChange={this.handleChangeMinOrder} onBlur={this.handleBlurMinOrder} />
                                    <label style={{fontSize: '20px', marginLeft: '25px'}}>(with GST)</label>
                                </div>
                            </div>

                            </div>
                            <ShippingRates data={this.state.defaults} save={this.saveShippingRates} />
                            { /* <DoorStyle data={this.state.doorstyle} save={this.saveDoorStyle} add={this.addDoorStyle} delete={this.deleteDoorStyle} /> */ }
                            <HardwareData  data={this.state.hardware} save={this.saveHardware} add={this.addHardware} delete={this.deleteHardware}  />
                            <PickupOptions  data={this.state.pickupoptions} save={this.savePickupOption} add={this.addPickupOption} delete={this.deletePickupOption}  />
                            {/* ============================================================== */}
                            {/* end pagecontent  */}
                            {/* ============================================================== */}

                        </div>
                    </div>
                    <FooterAdmin />
                </div>
            </div>
        )
    }

}

class HardwareData extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            nameClass: 'form-control',
            columnWidth: [0, 50, 25, 25]
        }

        this.editFormatter = this.editFormatter.bind(this)
        this.save = this.save.bind(this)
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.submit = this.submit.bind(this);
        this.delete = this.delete.bind(this)

		this.onSortChange = this.onSortChange.bind(this);
    this.percentCostFormatter = this.percentCostFormatter.bind(this)

    }

    delete(row) {
        swal({
          title: `${strings.Areyousure}`,
          text: "Once deleted, you will not be able to recover this record",
          icon: "warning",
          buttons: true,
          dangerMode: true
        }).then(willDelete => {
          if (willDelete) {
             this.props.delete(row)
          } else {
              return false;
          }
      });
    }

    editFormatter(cell,row) {
            return  <button className="link-btn" onClick={() => this.delete(row)}>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                    </button>;
    }

    open() {
        this.setState({open:true});
    }

    close() {
        this.setState({open:false});
    }

    submit(data) {
        this.close();
        this.props.add(data)
    }

    save(row, cellName, cellValue) {
        this.props.save(row);
    }

    componentDidMount() {
      for (let index = 0; index < 10; index += 1) {
        if ($(`.hardware-section .react-bs-container-header thead th:eq(${index})`).length === 0) break; // break if cant find any more columns

        $(`.hardware-section .react-bs-container-header thead th:eq(${index})`).resizable({
          minWidth: 50,
          handles: 'e',
          resize: (__event, ui) => {
          const newColumnWidths = [...this.state.columnWidth];
          newColumnWidths[index] = ui.size.width;
          this.setState({ columnWidth: newColumnWidths });
          },
        });

      }

  }

	onSortChange(sortName, sortOrder) {
		console.log(sortName, sortOrder)
		localStorage.setItem("HardwareDataSort", JSON.stringify({
		sortName: sortName,
		sortOrder: sortOrder
		}));
	}

  percentCostFormatter(cell, row){
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(row.percentage_cost);
  }

    render() {

		var HardwareDataSort = localStorage.getItem('HardwareDataSort');

		if (HardwareDataSort) {
			HardwareDataSort = JSON.parse(HardwareDataSort)
		}

        const options = {
            prePage: "Previous", // Previous page button text
            nextPage: "Next", // Next page button text
            onRowClick: this.onRowClick,
            noDataText: "No records found",
            that: this, // <- needed this "this" in the search event handler
            sizePerPage: 5,
			defaultSortName: (HardwareDataSort) ? HardwareDataSort.sortName : null,
			defaultSortOrder: (HardwareDataSort) ? HardwareDataSort.sortOrder : null,
			onSortChange: this.onSortChange,
            sizePerPageList: [
                {
                    text: "5",
                    value: 5
                },
                {
                    text: "10",
                    value: 10
                },
                {
                    text: "25",
                    value: 25
                },
                {
                    text: "50",
                    value: 50
                },
                {
                    text: "All",
                    value:
                        this.props.data.length > 0
                            ? this.props.data.length
                            : 5
                }
            ]
        };

        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            afterSaveCell: this.save
        }

        const createNumberEditor = (onUpdate, props) => (<NumberEditor onUpdate={ onUpdate } {...props}/>);

        return(
            <div className="row hardware-section">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                    <div className="inner-pagehead">
                        <h1>Hardware Data<span class="text-dark mb-4">{noCost}</span></h1>
                    </div>

                    <BootstrapTable
                        ref='table'
                        data={this.props.data}
                        pagination={true}
                        options={options}
                        search={false}
                        hover={true}
                        cellEdit={cellEditProp}
                        trClassName={this.trClassFormat}>
                        <TableHeaderColumn hidden={true} dataField='HardwareId' isKey={true}>Door Id</TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} width={this.state.columnWidth[1] + '%'} dataField='HardwareName' >Hardware Name</TableHeaderColumn>
                        <TableHeaderColumn
                          dataSort={true} width={this.state.columnWidth[2] + '%'}
                          customEditor={ { getElement : createNumberEditor, customEditorParameters: { step: 0.1 }  } }
                          dataField='peritem_costkg' >Per Item Weight (Kg)</TableHeaderColumn>
                        <TableHeaderColumn
                          dataSort={true} width={this.state.columnWidth[2] + '%'}
                          customEditor={ { getElement : createNumberEditor, customEditorParameters: { step: 0.1 }  } }
                          dataFormat={this.percentCostFormatter}
                          dataField='percentage_cost' >Per Item Cost</TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={{ cursor: "pointer", textAlign:"center"}}
                            thStyle={{ textAlign:"center" }}
                            editable={false}
                            dataFormat={ this.editFormatter.bind(this) }>
                            <button onClick={this.open} className="btn purchasebtn">Add</button>
                        </TableHeaderColumn>
                    </BootstrapTable>
                    <HardwareModal open={this.state.open} close={this.close} submit={this.submit} />
                </div>
            </div>
        )

    }
}

class HardwareModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            nameClass: 'form-control',
            errorClass: 'hidden',
            HardwareName: '',
            percentage_cost: 0,
            peritem_costkg: 0
        }

        this.submit = this.submit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps() {
      this.setState( {
        HardwareName: '',
        percentage_cost: 0
      } )
    }

    handleChange(e) {
      if (e.target.name === 'peritem_costkg' && e.target.value != '') {
        if ( isNaN(e.target.value)) {
          console.log('this is not a number', e.target.value)
          return;
        }
      }
      if (e.target.name === 'percentage_cost' && e.target.value != '') {
        if ( isNaN(e.target.value)) {
          return;
        }
      }
      
      this.setState({ [e.target.name] : e.target.value, nameClass: 'form-control', errorClass: 'hidden'} )
    }

    submit() {
        const HardwareName = this.state.HardwareName;

        let valid = true;

        if (HardwareName === '') {
          valid = false;
          this.setState({nameClass: 'form-control border-danger', errorClass: 'text-red'})
        }

        if (valid) {
          this.props.submit(this.state);
        }
      }

    render() {

        return (

            <Modal
                show={this.props.open}
                onHide={this.props.close}
                className="LoginModal"
            >

                <Modal.Header closeButton>
                    Add Hardware
                </Modal.Header>

                <Modal.Body>
                    <div className="form-group">
                        <label>Hardware Name</label>
                        <input type="text" name="HardwareName" className={this.state.nameClass} onChange={this.handleChange} />
                        <span className={this.state.errorClass}>Enter hardware name</span>
                    </div>

                    <div className="form-group">
                        <label>Per Item Cost (Kg)</label>
                        <input value={this.state.peritem_costkg} type="text" name="peritem_costkg" className={this.state.nameClass} onChange={this.handleChange} />
                    </div>

                    <div className="form-group">
                        <label>Per Item Cost</label>
                        <input value={this.state.percentage_cost} type="text" name="percentage_cost" className={this.state.nameClass} onChange={this.handleChange} />
                    </div>
                    
                    <button onClick={this.submit} type="button" className="btn cartbtn purchasebtn">Submit</button>
                </Modal.Body>

            </Modal>

        )

    }

}

class PickupOptions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            nameClass: 'form-control',
            columnWidth: [0, 80]
        }

        this.editFormatter = this.editFormatter.bind(this)
        this.save = this.save.bind(this)
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.submit = this.submit.bind(this);
        this.delete = this.delete.bind(this)

		this.onSortChange = this.onSortChange.bind(this);

    }

    delete(row) {
        swal({
          title: `${strings.Areyousure}`,
          text: "Once deleted, you will not be able to recover this record",
          icon: "warning",
          buttons: true,
          dangerMode: true
        }).then(willDelete => {
          if (willDelete) {
             this.props.delete(row)
          } else {
              return false;
          }
      });
    }

    editFormatter(cell,row) {
            return  <button className="link-btn" onClick={() => this.delete(row)}>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                    </button>;
    }

    open() {
        this.setState({open:true});
    }

    close() {
        this.setState({open:false});
    }

    submit(data) {
        this.close();
        this.props.add(data)
    }

    save(row, cellName, cellValue) {
        this.props.save(row);
    }

    componentDidMount() {
      for (let index = 0; index < 10; index += 1) {
        if ($(`.hardware-section .react-bs-container-header thead th:eq(${index})`).length === 0) break; // break if cant find any more columns

        $(`.hardware-section .react-bs-container-header thead th:eq(${index})`).resizable({
          minWidth: 50,
          handles: 'e',
          resize: (__event, ui) => {
          const newColumnWidths = [...this.state.columnWidth];
          newColumnWidths[index] = ui.size.width;
          this.setState({ columnWidth: newColumnWidths });
          },
        });

      }

  }

	onSortChange(sortName, sortOrder) {
		//console.log(sortName, sortOrder)
		localStorage.setItem("PickupOptionsSort", JSON.stringify({
		sortName: sortName,
		sortOrder: sortOrder
		}));
	}


    render() {

		var PickupOptionsSort = localStorage.getItem('PickupOptionsSort');

		if (PickupOptionsSort) {
			PickupOptionsSort = JSON.parse(PickupOptionsSort)
		}

        const options = {
            prePage: "Previous", // Previous page button text
            nextPage: "Next", // Next page button text
            onRowClick: this.onRowClick,
            noDataText: "No records found",
            that: this, // <- needed this "this" in the search event handler
            sizePerPage: 5,
			defaultSortName: (PickupOptionsSort) ? PickupOptionsSort.sortName : null,
			defaultSortOrder: (PickupOptionsSort) ? PickupOptionsSort.sortOrder : null,
			onSortChange: this.onSortChange,
            sizePerPageList: [
                {
                    text: "5",
                    value: 5
                },
                {
                    text: "10",
                    value: 10
                },
                {
                    text: "25",
                    value: 25
                },
                {
                    text: "50",
                    value: 50
                },
                {
                    text: "All",
                    value:
                        this.props.data.length > 0
                            ? this.props.data.length
                            : 5
                }
            ]
        };

        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            afterSaveCell: this.save
        }

        /* const createNumberEditor = (onUpdate, props) => (<NumberEditor onUpdate={ onUpdate } {...props}/>); */

        return(
            <div className="row hardware-section">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                    <div className="inner-pagehead">
                        <h1>Pickup Options<span class="text-dark mb-4">{noCost}</span></h1>
                    </div>

                    <BootstrapTable
                        ref='table'
                        data={this.props.data}
                        pagination={true}
                        options={options}
                        search={false}
                        hover={true}
                        cellEdit={cellEditProp}
                        trClassName={this.trClassFormat}>
                        <TableHeaderColumn hidden={true} dataField='Id' isKey={true}>Id</TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} width={this.state.columnWidth[1] + '%'} dataField='optionname' >Option Name</TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={{ cursor: "pointer", textAlign:"center"}}
                            thStyle={{ textAlign:"center" }}
                            editable={false}
                            dataFormat={ this.editFormatter.bind(this) }>
                            <button onClick={this.open} className="btn purchasebtn">Add</button>
                        </TableHeaderColumn>
                    </BootstrapTable>
                    <PickupOptionsModal open={this.state.open} close={this.close} submit={this.submit} />
                </div>
            </div>
        )

    }
}

class PickupOptionsModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            nameClass: 'form-control',
            errorClass: 'hidden',
            optionname: ''
        }

        this.submit = this.submit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps() {
      this.setState( {
        optionname: ''
      })
    }

    handleChange(e) {
        this.setState({ [e.target.name] : e.target.value, nameClass: 'form-control', errorClass: 'hidden'} )
    }

    submit() {

        const optionname = this.state.optionname;
        let valid = true;

        if (optionname === '') {
          valid = false;
          this.setState({nameClass: 'form-control border-danger', errorClass: 'text-red'})
        }

        if (valid) {
          this.props.submit(this.state);
        }
      }

    render() {

        return (

            <Modal
                show={this.props.open}
                onHide={this.props.close}
                className="LoginModal"
            >

                <Modal.Header closeButton>
                    Add Pickup Option
                </Modal.Header>

                <Modal.Body>
                    <div className="form-group">
                        <label>Pickup Option Name</label>
                        <input type="text" name="optionname" className={this.state.nameClass} onChange={this.handleChange} />
                        <span className={this.state.errorClass}>Enter pickup option name</span>
                    </div>


                    <button onClick={this.submit} type="button" className="btn cartbtn purchasebtn">Submit</button>
                </Modal.Body>

            </Modal>

        )

    }

}

class ShippingRates extends Component {
    constructor(props) {
      super (props);
      //console.log('props', props)
      this.state = {
        defaults: props.data,
        columnWidth: [250, 250, 250, 250, 250 ],
      }
      this.save = this.save.bind(this)
      this.formatCurrency = this.formatCurrency.bind(this)
      this.formatBoolean = this.formatBoolean.bind(this)
    }

    formatBoolean(bool){
      return bool == 1 ? 'Yes' : 'No'
    }

    formatCurrency(money){
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(money);
    }

    save(row, cellName, cellValue) {
      this.props.save(row);
    }

    componentDidMount() {
      for (let index = 0; index < 10; index += 1) {
        if ($(`.door-section .react-bs-container-header thead th:eq(${index})`).length === 0) break; // break if cant find any more columns

        $(`.door-section .react-bs-container-header thead th:eq(${index})`).resizable({
          minWidth: 50,
          handles: 'e',
          resize: (__event, ui) => {
          const newColumnWidths = [...this.state.columnWidth];
          newColumnWidths[index] = ui.size.width;
          this.setState({ columnWidth: newColumnWidths });
          },
        });

      }
    }

    render() {

      const options = {
        prePage: "Previous", // Previous page button text
        nextPage: "Next", // Next page button text
        onRowClick: this.onRowClick,
        noDataText: "No records found",
        that: this, // <- needed this "this" in the search event handler
        sizePerPage: 5,
        sizePerPageList: [
            {
                text: "5",
                value: 5
            },
            {
                text: "10",
                value: 10
            },
            {
                text: "25",
                value: 25
            },
            {
                text: "50",
                value: 50
            },
            {
                text: "All",
                value:
                    this.props.data.length > 0
                        ? this.props.data.length
                        : 5
            }
        ]
    };

    const cellEditProp = {
      mode: 'click',
      blurToSave: true,
      afterSaveCell: this.save
    }

    return (
        <div className="row door-section">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

              <div className="inner-pagehead">
                  <h1>Shipping Rates<span class="text-dark mb-4">{noCost}</span></h1>
              </div>

              <BootstrapTable
                  ref='table'
                  data={this.props.data}
                  pagination={true}
                  options={options}
                  search={false}
                  hover={true}
                  cellEdit={cellEditProp}
                  className="ShippingRatesTable">
                  <TableHeaderColumn hidden={true} dataField='Id' isKey={true}>Id</TableHeaderColumn>
                  <TableHeaderColumn
                  dataFormat={ this.formatCurrency }
                  width={this.state.columnWidth[1] + 'px'}
                  dataField='within_perth_fixed'>Fixed Rate Within Perth
                  </TableHeaderColumn>

                  <TableHeaderColumn
                  dataFormat={ this.formatCurrency }
                  width={this.state.columnWidth[2] + 'px'} dataField='winthin_perth_per' >Rate Per 1kg Within Perth</TableHeaderColumn>
                  <TableHeaderColumn
                  dataFormat={ this.formatCurrency }
                  width={this.state.columnWidth[3] + 'px'} dataField='outside_perth_fixed'>Fixed Rate Outside Perth</TableHeaderColumn>
                  <TableHeaderColumn
                  dataFormat={ this.formatCurrency }
                  width={this.state.columnWidth[4] + 'px'} dataField='outside_perth_per'>Rate Per 1kg Outside Perth</TableHeaderColumn>
                  <TableHeaderColumn
                  dataFormat={ this.formatBoolean }
                  editable={ { type: 'checkbox', options: { values: '1:0' }, style: {float: 'left !important'} } }
                  tdStyle={{ textAlign:"left"}}
                  width={this.state.columnWidth[5] + 'px'} dataField='outside_perth_auto'>Auto Calculate Outside Perth</TableHeaderColumn>
              </BootstrapTable>
          </div>
      </div>
      )
    }
  }

class DoorStyle extends Component {
    constructor(props) {
      super (props);
      console.log('props', props)
      this.state = {
        doorstyle: props.data,
        columnWidth: [100, 300, 100, 100, 100, 100, 100, 100, 100, 100 ],
        materialtype: []
      }

      this.editFormatter = this.editFormatter.bind(this)
      this.save = this.save.bind(this)
      this.open = this.open.bind(this);
      this.close = this.close.bind(this);
      this.submit = this.submit.bind(this);
      this.delete = this.delete.bind(this)
      this.onSortChange = this.onSortChange.bind(this);
      this.getMaterialType = this.getMaterialType.bind(this);
      this.materialFormatter = this.materialFormatter.bind(this);
    }

    delete(row) {
      swal({
        title: `${strings.Areyousure}`,
        text: "Once deleted, you will not be able to recover this record",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(willDelete => {
        if (willDelete) {
           this.props.delete(row)
        } else {
            return false;
        }
    });
    }

    editFormatter(cell,row) {
          return  <button className="link-btn" onClick={() => this.delete(row)}>
                      <i className="fa fa-trash" aria-hidden="true"></i>
                  </button>;
    }

    open() {
      this.setState({open:true});
    }

    close() {
      this.setState({open:false});
    }

    submit(data) {
      this.close();
      this.props.add(data)
    }

    save(row, cellName, cellValue) {
      if (cellName === 'Material') {

        this.state.materialtype.map(material => {
          if (material.MaterialType === cellValue) {
            row.Material = material.MaterialTypeId
          }
          return material;
        });

      }
      //console.log('row', row)
      this.props.save(row);
    }

    componentDidMount() {
      for (let index = 0; index < 10; index += 1) {
        if ($(`.door-section .react-bs-container-header thead th:eq(${index})`).length === 0) break; // break if cant find any more columns

        $(`.door-section .react-bs-container-header thead th:eq(${index})`).resizable({
          minWidth: 50,
          handles: 'e',
          resize: (__event, ui) => {
          const newColumnWidths = [...this.state.columnWidth];
          newColumnWidths[index] = ui.size.width;
          this.setState({ columnWidth: newColumnWidths });
          },
        });

      }

      this.getMaterialType()

    }

	onSortChange(sortName, sortOrder) {
		localStorage.setItem("DoorStyleSort", JSON.stringify({
		sortName: sortName,
		sortOrder: sortOrder
		}));
	}

  getMaterialType() {
    fetch(config.BASE_URL + '/api/getmaterialtype', {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then(data => data.json())
    .then(data => {
        this.setState({
          materialtype: data
        })
    })
    .catch(error => console.log(error));

  }

  materialFormatter(cell, row){

    console.log('materialtype', this.state.materialtype);

    const mat = this.state.materialtype.filter(material => {
      return material.MaterialTypeId === parseInt(cell)
    });
    return (mat.length > 0) ? mat[0].MaterialType : ''
  }

    render() {

		var DoorStyleSort = localStorage.getItem('DoorStyleSort');

		if (DoorStyleSort) {
			DoorStyleSort = JSON.parse(DoorStyleSort)
		}

      const options = {
        prePage: "Previous", // Previous page button text
        nextPage: "Next", // Next page button text
        onRowClick: this.onRowClick,
        noDataText: "No records found",
        that: this, // <- needed this "this" in the search event handler
        sizePerPage: 5,
        defaultSortName: (DoorStyleSort) ? DoorStyleSort.sortName : null,
        defaultSortOrder: (DoorStyleSort) ? DoorStyleSort.sortOrder : null,
        onSortChange: this.onSortChange,
        sizePerPageList: [
            {
                text: "5",
                value: 5
            },
            {
                text: "10",
                value: 10
            },
            {
                text: "25",
                value: 25
            },
            {
                text: "50",
                value: 50
            },
            {
                text: "All",
                value:
                    this.props.data.length > 0
                        ? this.props.data.length
                        : 5
            }
        ]
    };

    const cellEditProp = {
      mode: 'click',
      blurToSave: true,
      afterSaveCell: this.save
    }

    const materials = this.state.materialtype.map(material => {
      return {
  		value: material.MaterialTypeId,
  		text: material.MaterialType
  	 }
    })


    return (
        <div className="row door-section">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

              <div className="inner-pagehead">
                  <h1>Door Styles<span class="text-dark mb-4">{noCost}</span></h1>
                  
              </div>

              <BootstrapTable
                  ref='table'
                  data={this.props.data}
                  pagination={true}
                  options={options}
                  search={false}
                  hover={true}
                  cellEdit={cellEditProp}
                  trClassName={this.trClassFormat}>
                  <TableHeaderColumn hidden={true}dataField='DoorId' isKey={true}>Door Id</TableHeaderColumn>
                  <TableHeaderColumn dataSort={true} width={this.state.columnWidth[1] + 'px'} dataField='DoorName' >Door Name</TableHeaderColumn>
                  <TableHeaderColumn
                      dataSort={true}
                      width={this.state.columnWidth[2] + 'px'}
                      dataField='percentage_cost'
                      editable={ { type: 'number'} }
                  >
                      Cost Percentage
                  </TableHeaderColumn>
                  <TableHeaderColumn dataSort={true} width={this.state.columnWidth[2] + 'px'} dataField='DoorCategory'>DoorCategory</TableHeaderColumn>
                  <TableHeaderColumn dataSort={true} width={this.state.columnWidth[3] + 'px'} hidden={true}  dataField='DoorCategory'>DoorCategory</TableHeaderColumn>
                  <TableHeaderColumn
                  dataSort={true}
                  width={this.state.columnWidth[4] + 'px'}
                  dataField='Material'
                  editable={ { type: 'select', options: { values: materials } } }
                  dataFormat={ this.materialFormatter }

                  >Material</TableHeaderColumn>
                  <TableHeaderColumn
                    tdStyle={{ cursor: "pointer", width: "8%", textAlign:"center"}}
                    thStyle={{ width: "8%", textAlign:"center" }}
                    editable={false}
                    dataFormat={ this.editFormatter.bind(this) }>
                    <button onClick={this.open} className="btn purchasebtn">Add</button>
                  </TableHeaderColumn>
              </BootstrapTable>
              <DoorStyleModal materialtype={this.state.materialtype} open={this.state.open} close={this.close} submit={this.submit} />
          </div>
      </div>
      )
    }
  }

  class DoorStyleModal extends Component {

    constructor(props) {
      super(props);

      this.state = {
        DoorName  : '',
        DoorCategory : '',
        Material : 1,
        GapTop : 0,
        GapBottom : 0,
        GapLeft : 0,
        GapRight : 0,
        percentage_cost: 0,
        nameClass : 'form-control',
        errorClass: 'hidden'
      }

      this.submit = this.submit.bind(this);
      this.handleChange = this.handleChange.bind(this);

    }

    componentWillReceiveProps() {

      this.setState( {
        DoorName: '',
        DoorCategory: '',
        Material: 1,
        percentage_cost : 0,

      });

    }

    componentDidMount() {
      const materialtype = this.props.materialtype
        this.setState({
          Material: (materialtype.length > 0) ? materialtype[0]['MaterialTypeId'] : 1
        })
    }

    handleChange(e) {
      this.setState({ [e.target.name] : e.target.value, nameClass: 'form-control', errorClass: 'hidden'} )
    }

    submit() {
      const DoorName = this.state.DoorName;

      let valid = true;

      if (DoorName === '') {
        valid = false;
        this.setState({nameClass: 'form-control border-danger', errorClass: 'text-red'})
      }

      if (valid) {
        this.props.submit(this.state);
      }
    }

    render() {

      const {materialtype} = this.props
      const options = materialtype && materialtype.length > 0 ? materialtype.map(material => {
        return <option value={material.MaterialTypeId}>{material.MaterialType}</option>
      }) : ''
      return (
        <Modal
            show={this.props.open}
            onHide={this.props.close}
            className="LoginModal"
          >

         <Modal.Header closeButton>
           Add Door Style
         </Modal.Header>

          <Modal.Body>
            <div className="form-group">
              <label>Door Name</label>
              <input type="text" name="DoorName" className={this.state.nameClass} onChange={this.handleChange} />
              <span className={this.state.errorClass}>Enter door name</span>
            </div>
            <div className="form-group">
              <label>Cost Percentage</label>
              <input type="number" name="percentage_cost" className='form-control' onChange={this.handleChange} />
            </div>
            <div className="form-group">
              <label>DoorCategory</label>
              <input type="text" name="DoorCategory" className="form-control" onChange={this.handleChange} />
            </div>
            <div className="form-group">
              <label>Material</label>
              {/*<input type="text" name="Material" className="form-control" onChange={this.handleChange} />*/}
              <select name="Material" onChange={this.handleChange} className="form-control">
                  {options}
              </select>
            </div>



            <button onClick={this.submit} type="button" className="btn cartbtn purchasebtn">Submit</button>
          </Modal.Body>

        </Modal>

      )

    }

  }

class NumberEditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value : props.defaultValue,
            step : props.step
        }

        this.handleChange = this.handleChange.bind(this);
    }

    getValue() {
      return this.state.value;
    }

    handleChange(e) {
        this.setState( {
            value: e.target.value
        })
    }

    render() {
      const { value, onUpdate, ...rest } = this.props;
      return [
        <input
          { ...rest }
          type="number"
          className="form-control numberEditor"
          step={this.state.step}
          onChange={this.handleChange}

        />
      ];
    }
  }

export default Master;
