import React, { Component } from 'react';
// import { render } from 'react-dom';
import { withRouter } from "react-router-dom";
import { Button, Accordion, Card } from 'react-bootstrap';
import Nestable from 'react-nestable';
import config from "../config";
import HeaderAdmin from '../components/HeaderAdmin';
import FooterAdmin from '../components/FooterAdmin';
import Dropzone from 'react-dropzone'
import axios from 'axios';
import swal from "sweetalert";
import BootstrapTable from 'react-bootstrap-table-next';
import ContentEditable from 'react-contenteditable'
// import EditableLabel from 'react-inline-editing';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import AdminMenuProducts from './AdminMenuProducts'


const styles = {
	position: "relative",
	padding: "4px 15px",
	fontSize: "16px",
	border: "1px solid rgb(249, 250, 250)",
	background: "#EEE",
	cursor: "pointer",
	borderRadius: "5px"
};

class UploadImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
			user: (props.user) ? props.user: [],
			files: [],
			fileData: (props.Item.img) ? (props.Item.img) : '',
			fileName: (props.Item.img) ? (props.Item.img) : '',
        };
    }

	onDrop = (acceptedFiles) =>{
		const headers = {
			"Authorization": 'Bearer ' + this.state.user.token
		}

		const data = new FormData();
		acceptedFiles.forEach((file) => {
			const reader = new FileReader()

			reader.onabort = () => console.log('file reading was aborted')
			reader.onerror = () => console.log('file reading has failed')
			reader.onload = (e) => {

				const binaryStr = e.target.result
				this.setState({
					fileData: binaryStr
				});
			}
			reader.readAsDataURL(file);

			//upload image
			data.append('itemid', this.props.Item.id);
			data.append('folder', config.UPLOAD_PATH);
			data.append(`myimages[]`, file, file.name);
			axios.post(config.BASE_URL + "/api/uploadmenuimage", data, { headers: headers } )
			.then(res => {
				//console.log(res);
				this.setState({
                        fileName: res.data.filename || '',
				}, () => {
					//console.log('res.filename: ' + res.filename);
					this.props.onDropImage(res.data.filename || '', this.props.Item.id)
				})
			})
		})
	}

  render() {
    const previewStyle = {
		display: 'inline',
		width: 100,
		height: 100,
    }

    return (
		<>
		<label>Menu Image</label>
          <Dropzone
            onDrop={this.onDrop}
			multiple={false}
          >
            {({getRootProps, getInputProps}) => (
            <div className="menus-dropdown-dropzone" {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="drag-n-drop text-center">
			  {this.state.fileName !=  '' ? (
				<img src={config.UPLOAD_URL + this.state.fileName} />
			  ) : (
				<i className="fa fa-image" /> )
			  }
			  Drag 'n' drop some files here, or click to select files</div>
            </div>
            )}
          </Dropzone>

		</>
	)
  }

}

class AdminMenus extends Component {
    constructor(props) {
        super(props);
		var user = JSON.parse(localStorage.getItem('authuser'));
        this.state = {
			addItems: [],
			items: [],
			newItem: '',
			stockcodes: [],
			products: [],
			user: (user) ? user[0] : [],

        };

		this.insertMenuLine = this.insertMenuLine.bind(this);
		this.saveMenu = this.saveMenu.bind(this);
		this.onChange=this.onChange.bind(this);
		this.timer = null;
		this.getImage = this.getImage.bind(this);
		this.handleBlur = this.handleBlur.bind(this)
		this.onChangeProductOrder = this.onChangeProductOrder.bind(this)
    }

    componentDidMount() {
        this.getMenu();
		//this.getProductsstockCodeList();
		this.getAllProducts()
		this.menuUpdates = {}
		this.menuItemsUpdate = []
    }

	getMenu = async() => {
		await fetch(config.BASE_URL + '/api/getmenu', {
			headers: {
				"Content-Type": "application/json",
				'Accept': 'application/json'
			}
		})
		.then(data => data.json())
		.then(data => {

			this.setState({
				items: data.menuItem,
			}, () => {
				this.menuItemsUpdate = data.menuItem
			})
		})
		.catch(error => console.log(error));
	}
    getProductsstockCodeList() {
        fetch(config.BASE_URL + '/api/getproductstockcodelist', {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
		.then(data => data.json())
		.then(data => {
			this.setState({
				stockcodes: data.codes,
			}, () => {
				//console.log(this.state.stockcodes);
			})
		})
		.catch(error => console.log(error));
    }

    getAllProducts() {
        fetch(config.BASE_URL + '/api/getproductswithimage', {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(data => data.json())
        .then(data => {

			var products = data.map((product) =>{
				return {ProductId: product.ProductId, ProductName: product.ProductName, Image: product.Image}
			});

			this.setState({
				products: products
			}, () => {
				console.log(this.state.products);
			})
        })
        .catch(error => console.log(error));
    }

    getproductImage= async(productid) => {
        await fetch(config.BASE_URL + '/api/getstockcodeimage/'+productid, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(data => data.json())
        .then(data => {
			return data.image
        })
        .catch(error => console.log(error));
    }

	saveMenu = async() => {
		var user = this.state.user;
		await fetch(config.BASE_URL + '/api/savemenu', {
			body: JSON.stringify({menuItem: this.menuItemsUpdate}),
			method: 'POST',
			headers: {
				"Content-Type": "application/json",
				'Accept': 'application/json',
				"Authorization": 'Bearer ' + user.token
			}
		})
		.then(data => data.json())
		.then(data => {
			console.log(data);
			swal("", "Menu Successfully  Save", "success")
		})
		.catch(error => console.log(error));
	}

	deleteMenu = (e, item) => {
		e.preventDefault();
		var selected = this.findByIdDelete(this.menuItemsUpdate, item.id);

		this.menuItemsUpdate = this.sanitize(selected)

		this.setState({
			products: this.sanitize(selected)
		}, () => {
			//console.log(this.state.products);
		})

	}

	sanitize = (object) => {
		Object
			.entries(object)
			.forEach(([k, v]) => {
				if (v && typeof v === 'object') {
					this.sanitize(v);
				}
				if (v && typeof v === 'object' && !Object.keys(v).length || v === null || v === undefined) {
					if (Array.isArray(object)) {
						object.splice(k, 1);
					} else {
						delete object[k];
					}
				}
			});
		return object;
	}

	findByIdDelete = (data, id) => {
		for(var i = 0; i < data.length; i++) {
			if (data[i].id == id) {
				data[i] = [];
			} else if (data[i].children && data[i].children.length && typeof data[i].children === "object") {
				this.findByIdDelete(data[i].children, id);
			}
		}
		return data;
	}

	findByIdUpdate = (data, id, img) => {
		for(var i = 0; i < data.length; i++) {
			if (data[i].id == id) {
				data[i].img = img;
			} else if (data[i].children && data[i].children.length && typeof data[i].children === "object") {
				this.findByIdUpdate(data[i].children, id, img);
			}
		}
		return data;
	}

	onDropImage = (img, itemid) => {
		var items = this.findByIdUpdate(this.menuItemsUpdate, itemid, img);
		this.menuItemsUpdate = items
	}

	onChange = (data, item) =>{
		this.menuItemsUpdate = data
	}

	changeRename = (data, itemid, label) => {
		for(var i = 0; i < data.length; i++) {
			if (data[i].id == itemid) {
				data[i].text = label;
				//console.log(data[i]);
			} else if (data[i].children && data[i].children.length && typeof data[i].children === "object") {
				this.changeRename(data[i].children, itemid, label);
			}
		}
		this.setState({
			items: data
		}, () => {
			//console.log(data);
		})
	}


	insertMenuLine = () => {
		var items = this.menuItemsUpdate;
		var id = (Math.floor(Math.random() * (999 - 100 + 1)) + 100)
		items.unshift({
			id: id,
			text: 'Menu 1',
			img: '',
			sku: [],
			active: 1,
			children: [],
			products: []
		});
		this.menuItemsUpdate = items
		this.setState({
			products: items
		}, () => {
			//console.log(this.state.products);
		})
	}

	handleChangeText = (val, id) => {
		this.menuUpdates = {id: id, text: val}
	};

	handleKeyDown = (ev) => {
		if (ev.keyCode == 13) {
			ev.preventDefault()
			if (this.menuUpdates.hasOwnProperty('id')){
				console.log(this.menuUpdates)
				this.changeRename(this.menuItemsUpdate, this.menuUpdates.id, this.menuUpdates.text);
				this.menuUpdates = {}
			}
		}
	}

	handleBlur = () => {
		if (this.menuUpdates.hasOwnProperty('id')){
			console.log(this.menuUpdates)
			this.changeRename(this.menuItemsUpdate, this.menuUpdates.id, this.menuUpdates.text);
			this.menuUpdates = {}
		}
	}

	insertSKU = (data, itemid, sku) => {
		for(var i = 0; i < data.length; i++) {
			if (data[i].id == itemid) {
				var skus = Array.isArray(data[i].sku) ? data[i].sku : [];
				skus.push(sku);
				data[i].sku = skus;
				console.log(data[i]);
			} else if (data[i].children && data[i].children.length && typeof data[i].children === "object") {
				this.insertSKU(data[i].children, itemid, sku);
			}
		}
		this.menuItemsUpdate = data
	}

	insertProductId = (data, itemid, productid, img) => {

		for(var i = 0; i < data.length; i++) {
			if (data[i].id == itemid) {
				var products = Array.isArray(data[i].products) ? data[i].products : [];
				products.push(productid);
				data[i].products = products;
				console.log(products);
			} else if (data[i].children && data[i].children.length && typeof data[i].children === "object") {
				this.insertProductId(data[i].children, itemid, productid);
			}
		}
		
		this.menuItemsUpdate = data
		this.setState({
			items: this.menuItemsUpdate,
		}, () => {

		})
	}

	removeSKU = (data, itemid, sku) => {
		for(var i = 0; i < data.length; i++) {
			if (data[i].id == itemid) {
				var skus = Array.isArray(data[i].sku) ? data[i].sku : []
				skus.splice( skus.indexOf(sku), 1);
				data[i].sku = skus;
				//console.log(data[i]);
			} else if (data[i].children && data[i].children.length && typeof data[i].children === "object") {
				this.removeSKU(data[i].children, itemid, sku);
			}
		}
		this.menuItemsUpdate = data
	}

	removeProductId = (data, itemid, productid) => {
		for(var i = 0; i < data.length; i++) {
			if (data[i].id == itemid) {
				var products = Array.isArray(data[i].products) ? data[i].products : [];
				var index = products.findIndex(function(product){
					return product.ProductId == productid.ProductId;
				});
				products.splice( index, 1);
				console.log(products);
				data[i].products = products;
			} else if (data[i].children && data[i].children.length && typeof data[i].children === "object") {
				this.removeProductId(data[i].children, itemid, productid);
			}
		}
		this.menuItemsUpdate = data
		this.setState({
			items: this.menuItemsUpdate,
		}, () => {

		})
	}

	getImage = (productid) =>{
        fetch(config.BASE_URL + '/api/get', {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(data => data.json())
        .then(data => {
			return data.img
        })
        .catch(error => console.log(error));
	}

	updateMenuProducts = (data, itemid, products) => {

		for(var i = 0; i < data.length; i++) {
			if (data[i].id == itemid) {
				data[i].products = products;
			} else if (data[i].children && data[i].children.length && typeof data[i].children === "object") {
				this.updateMenuProducts(data[i].children, itemid, products);
			}
		}
		this.menuItemsUpdate = data
	}

	onChangeProductOrder = (data, item, itemid) =>{
		this.updateMenuProducts(this.menuItemsUpdate, itemid, data)
	}

	updateMenuItemStatus = (data, itemid, status) => {
		for(var i = 0; i < data.length; i++) {
			if (data[i].id == itemid) {
				data[i].isActive = status;
			} else if (data[i].children && data[i].children.length && typeof data[i].children === "object") {
				this.updateMenuItemStatus(data[i].children, itemid, status);
			}
		}
		this.setState({
			items: data
		}, () => {
			this.menuItemsUpdate = data
		})
	}

	handleClick = (itemid, ev) => {
		var isActive = (ev.target.checked) ? 1 : 0
		this.updateMenuItemStatus(this.menuItemsUpdate, itemid, isActive);
	}

  renderItem = ({ item, collapseIcon, handler }) => {

	const { SearchBar } = Search;

    const { stockcodes  } = this.state;
	const columns =
	[
		{
			dataField: 'ProductName',
			text: 'Product Name',
			sort: true
		}
	];

	if (item.products === undefined) item.products = [];

	var selectedproducts = item.products.map((product) =>{
		return product.ProductId
	})

	const selectRow = {
		mode: 'checkbox',
		selected: selectedproducts,
		selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
			<></>
		),
		selectColumnStyle: { width: '50px' },
		selectionRenderer: ({ mode, checked, disabled }) => (
			<>
			<p  className="greenCheckBox" style={{paddingLeft: "12px", marginBottom: "0px", marginTop: "0px"}}>
				<label>
					<input type="checkbox" defaultChecked={checked} ref={input => this.fullName = input} />
					<span className="checkmark1"></span>
				</label>
			</p>
			</>
		),
		clickToSelect: false,
		onSelect: (row, isSelect, rowIndex, e) => {
			//console.log(React.Children.toArray(e.target).find('input'));
			console.log(isSelect)
			if (isSelect){

				var prod_itemid = (Math.floor(Math.random() * (999 - 100 + 1)) + 100)
				this.insertProductId(this.menuItemsUpdate, item.id, {id: prod_itemid, ProductId: row.ProductId, ProductName: row.ProductName, Image: row.Image});
			}else{
				this.removeProductId(this.menuItemsUpdate, item.id, {ProductId: row.ProductId, ProductName: row.ProductName, Image: row.Image});
			}
		}
	};

	const defaultSorted = [{
	  dataField: 'ProductName',
	  order: 'asc'
	}];

    return (
      <div style={styles}>
		<Accordion key={item.id}>
		  <Card><a className="menu-move-btn" href="#"><i className="fa fa-arrows-alt"></i></a>
			<Card.Header style={{fontWeight: "500", height: "45px"}}>
				{handler}
				<a href="#" className="menu-delete-btn"><i style={{float:"right"}} onClick={(event) => this.deleteMenu(event, item)} className="fa fa-times" style={{cursor: 'pointer !important'}}></i></a>
				<p className="greenCheckBox" style={{paddingLeft: '0px', marginBottom: '0px', marginTop: '0px', float: 'left'}}><label><input onClick={(event) => {this.handleClick(item.id, event)}} type="checkbox" defaultChecked={item.isActive} /><span className="checkmark1"></span></label></p>
				  <ContentEditable
					key={item.id}
					html={item.text}
					className="ContentEditable"
					disabled={false}
					onChange={(event) => this.handleChangeText(event.target.value, item.id)}
					onKeyDown = {(event) => this.handleKeyDown(event)}
					onBlur = {this.handleBlur}
				  />
				<Accordion.Toggle className="menus-dropdown-toggle" style={{width:"90%"}} as={Button} variant="link" eventKey="1">
			  </Accordion.Toggle>
			</Card.Header>
			<Accordion.Collapse eventKey="1">
			  <Card.Body>
				<div className="menus-dropdown-body row">
					{item.children.length == 0 ? (
					<>
					<div className="col-md-6 menus-table">
						<AdminMenuProducts itemid={item.id} onChangeProductOrder={this.onChangeProductOrder} items={item.products} />
					</div>
					<div className="col-md-3 menus-table">

					<ToolkitProvider
					  keyField="ProductId"
					  data={ this.state.products }
					  columns={ columns }
					  search
					  remote
					>
					  {
						props => (
						  <div>
							<SearchBar { ...props.searchProps } />
							<BootstrapTable
								{ ...props.baseProps }
								selectRow={selectRow}
								defaultSorted={defaultSorted}
							/>
						  </div>
						)
					  }
					</ToolkitProvider>
					</div>
					<div className="col-md-3" style={{float:'right'}}>
					<UploadImage
					Item = {item}
					user = {this.state.user}
					onDropImage={this.onDropImage}
					/>
					</div>
					</>
					) : (
					<div className="col-md-12" style={{float:'right'}}>
					<UploadImage
					Item = {item}
					user = {this.state.user}
					onDropImage={this.onDropImage}
					/>
					</div>
					)}

				</div>
			  </Card.Body>
			</Accordion.Collapse>
		  </Card>
		</Accordion>
      </div>
    );
  };


  render() {

    return (
            <div className="admin-wrapper">
				<HeaderAdmin />
                <div className="dashboard-wrapper partscontent">
                    <div className="dashboardhome">
                        <div className="container-fluid dashboard-content jobspagecnt">
							<div className="row">
                            <div className="col-md-12">
                                <ol className="breadcrumb breadm">
                                    <li><a href="/admin">Admin</a></li>
                                    <li ><i className="fa fa-angle-right midarrow" /><a style={{ cursor: "pointer" }}>Menu Management</a></li>
                                </ol>
                            </div>
							</div>
                            <div className="row">
								<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
								<div style={{float: "left", marginBottom: "10px"}}><button onClick={() => this.insertMenuLine()} className="btn purchasebtn cartbtn">Add New Menu Line</button></div>
								<div style={{float: "right", marginBottom: "10px"}}><button onClick={() => this.saveMenu()} className="btn purchasebtn cartbtn">Save Menu</button></div>
								<div className="clearfix"></div>
								<Nestable
									items={[...this.state.items]}
									collapsed={false}
									renderItem={this.renderItem}
									ref={el => this.refNestable = el}
									onChange={this.onChange}
								/>
								</div>
                            </div>
                        </div>
                    </div>
                    <FooterAdmin />
                </div>
            </div>
    );
  }
}

export default withRouter(AdminMenus);
