import config from "../config";


export default {
	 isValidEmailAddress(input) {
		let re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;

		if ( re.test(input) ) {
			return true;
		}
		else {
			return false;
		}
	},
	async isEmailUnique(email, UserId) {
		var emailUnique = false;
		await fetch(config.BASE_URL + '/api/getuserbyemail/'+encodeURIComponent(email).replace(/\./g, '_period_'), {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(data => data.json())
            .then(data => {
               emailUnique = (data.length === 0) ? true : (data.length === 1 && data[0].Id === UserId) ? true : false;
            })
            .catch(error => console.log(error));
			
		return emailUnique;	
    }	
}
