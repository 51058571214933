import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import config from "../../config";
import Modal from 'react-bootstrap-modal';
import Comments from "./Comments";
import swal from 'sweetalert';
import { strings } from '../../components/strings';
import CreateProjectModal from './CreateProjectModal';

class Orders extends Component {

    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            hoverIdx: null,
            expanded: [],
            customer: props.customer,
            showStatus: 'all',
            allClass: 'active',
            pendingClass: '',
            inProgressClass: '',
            completedClass: '',
            payByAccount: false,
            payByAccountClass: 'pay-by-account-button',
			defaultJobId: (props.defaultJobId) ? props.defaultJobId : null
        }

        this.options = this.options.bind(this);
        this.editProject = this.editProject.bind(this);
        this.saveProject = this.saveProject.bind(this);

        this.showAll = this.showAll.bind(this);
        this.showPending = this.showPending.bind(this);
        this.showInProgress = this.showInProgress.bind(this);
        this.showCompleted = this.showCompleted.bind(this);
        this.showPayByAccount = this.showPayByAccount.bind(this);

    }

    showPayByAccount() {

        const status = this.state.payByAccount;

        if (status) {

            this.setState( { payByAccount: false, payByAccountClass: 'pay-by-account-button'} );

        } else {

            this.setState( { payByAccount: true, payByAccountClass: 'pay-by-account-button active' } );

        }

    }

    showAll() {
        this.setState( { showStatus: 'all', allClass: 'active', pendingClass: '', inProgressClass: '', completedClass: '' } );
    }

    showPending() {
        this.setState( { showStatus: 'pending', allClass: '', pendingClass: 'active', inProgressClass: '', completedClass: '' } );
    }

    showInProgress() {
        this.setState( { showStatus: 'in-progress', allClass: '', pendingClass: '', inProgressClass: 'active', completedClass: '' } );
    }

    showCompleted() {
        this.setState( { showStatus: 'completed', allClass: '', pendingClass: '', inProgressClass: '', completedClass: 'active' } );
    }

    saveProject(old, newValue, row, column) {
        const data = {id: row.ProjectId, name: newValue, customerId: row.CustomerId}
        this.props.updateProject(data);
    }

    editProject(data) {

        return (
            <>
                <EditProject save={this.props.updateProject} project={data} />
            </>
        )
    }


    gotoEdit(JobId) {
        window.location = `/dashboard/edit-order/${JobId}`;
    }

    options(data) {

        let editText = 'Edit Order';

        if (data.Status === 'In Progress' || data.Status === 'Completed') {
            editText = 'View Order';
        }

        const edit = <button className="btn-options" onClick={ () => this.gotoEdit(data.JobId) }>Edit Order</button>
        const view = <View id={data.JobId} customer={this.state.customer} />
        const reorder = <Reorder id={data.JobId} customer={this.state.customer} reorder={this.props.reorder} />
        const delBtn = <Delete id={data.JobId} customer={this.state.customer} status={data.Status} delete={this.props.delete} />

        return (
            <div className="row">
                <div className="col-md-4">{ (data.Status === 'In Progress' || data.Status === 'Completed') ? view :  edit }</div>
                <div className="col-md-4">{reorder}</div>
                <div className="col-md-4">{delBtn}</div>
            </div>
        )
    }

    componentWillReceiveProps(newProps) {

        const ids = newProps.projects.map( val => {
            return val.JobId
        });

        this.setState({jobs : newProps.projects, expanded: ids});
    }



    handleOnExpand = (row, isExpand, rowIndex, e) => {

        /* const JobId = row.JobId; */

    }

    render() {

        const columns =
            [
                {
                    dataField: 'JobId',
                    text: 'Job Id'
                },
                {
                    dataField: 'ProjectName',
                    text: 'Project Name',
                    blurToSave: true
                },
                {
                    dataField: 'TotalCost',
                    text: 'Total Price $',
                    editable: false
                },
                {
                    dataField: 'Status',
                    text: 'Status',
                    editable: false
                },
                {
                    dataField: 'options',
                    align: 'center',
                    text: 'Options',
                    headerAlign: 'center',
                    editable: false,
                    classes: (cell, row, rowIndex, colIndex) => {
                        return row.Status
                    }
                },
            ];



        let data = this.state.jobs.filter(job =>  {

                   job.options = this.options(job);

                    return ( (this.state.showStatus === 'all') ||
                         (this.state.showStatus === 'pending' && job.Status === 'Pending') ||
                         (this.state.showStatus === 'in-progress' && job.Status === 'In Progress') ||
                         (this.state.showStatus === 'completed' && job.Status === 'Completed'))

                }
            )

        if ( this.state.payByAccount ) {

            data = data.filter( job => {

                return job.PaymentMethod === 'Pay By Account'

            });

        }

        const expandRow = {
                parentClassName: 'parent-expand',
                renderer: row => (
                  <div style={ { width: '100%' } } className={"project-indicator " + row.CustomerStatus}>
					<Comments Job={row} />
                  </div>
                ),
                expanded: this.state.expanded,
                onExpand: this.handleOnExpand,
                showExpandColumn: true,
                expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                    if (isAnyExpands) {
                      return <i className="fa fa-minus"></i>;
                    }
                    return <i className="fa fa-plus"></i>;
                  },

                expandColumnRenderer: ({ expanded }) => {
                    if (expanded) {
                      return (
                        <i className="fa fa-minus"></i>
                      );
                    }
                    return (
                      <i className="fa fa-plus"></i>
                    );
                 }
              };

		const { SearchBar } = Search;

		const { defaultJobId } = this.state;

		const searchOptions = {
			defaultSearch: defaultJobId
		}

        return (
            <div className="partscontent dashboard-wrapper">

                <div className="dashboardhome">

                    <div className="container-fluid dashboard-content">

                        <div className="row">

                            <div className="col-xl-12">

                                <div className="inner-pagehead">

                                    <h1>Projects</h1>

                                 </div>

								<ToolkitProvider
									keyField='JobId'
									data={ data }
									columns={ columns }
									noDataIndication="Table is Empty"
									search = {searchOptions}
								>
								  {
									props => (
									  <div>
                                        <div className="row">
                                            <div className="col-md-4">
										        <SearchBar { ...props.searchProps } />
                                                <button onClick={this.showPayByAccount}
                                                        data-tip="Due payment projects"
                                                        className={this.state.payByAccountClass}>
                                                            <i className="fas fa-user-circle" />
                                                </button>
                                            </div>
                                            <div className="col-md-8 text-right buttons">
                                                <button className={this.state.allClass} onClick={this.showAll}>All</button>
                                                <CreateNewProject addproject={this.props.addproject} />
                                                <button className={this.state.pendingClass} onClick={this.showPending}>Quotes</button>
                                                <button className={this.state.inProgressClass} onClick={this.showInProgress}>In Progress</button>
                                                <button className={this.state.completedClass} onClick={this.showCompleted}>Completed</button>
                                            </div>
										</div>
                                        <BootstrapTable
											{ ...props.baseProps }
											expandRow = { expandRow }
											onExpand = { this.handleOnExpand }
											cellEdit={
												cellEditFactory({
													mode: 'click',
													blurToSave: true,
													afterSaveCell: this.saveProject
												})
											}
										/>
									  </div>
									)
								  }
								</ToolkitProvider>

                            </div>

                        </div>

                    </div>

                </div>

            </div>
        )
    }
}

class CreateNewProject extends Component {

    constructor(props) {
      super(props);
  
      this.state = {
        open: false,
      }
  
      this.openModal =   this.openModal.bind(this);
      this.closeModal = this.closeModal.bind(this);
      this.submit = this.submit.bind(this);
  
    }
  
    openModal() {
      this.setState(
        {
          open: true
        }
      )
    }
  
    closeModal() {
      this.setState(
        {
          open: false
        }
      )
    }
  
    submit(data) {
  
     this.closeModal();
     this.props.addproject(data);
    }
  
    render() {
  
      return(
        <>
          <button onClick={this.openModal}>New Project</button>
          <CreateProjectModal open={this.state.open} close={this.closeModal} submit={this.submit} />          
        </>
      )
    }
  }

class View extends Component {

    constructor(props) {

        super(props);

        this.state = {
            JobId: props.id,
            customer: props.customer
        }

        this.open = this.open.bind(this);

    }

    open() {
        window.open(`/dashboard/view-order/${this.state.JobId}`, 'view-order', 'width=900 height=900 scrolls resizable');
    }

    render() {

        return (

            <>
                <button onClick={this.open} className="link-button">View Order</button>
            </>

        )

    }

}

class Reorder extends Component {

    constructor(props) {

        super(props)

        this.do = this.do.bind(this);
    }

    do() {

        const id = this.props.id;
        const customer = this.props.customer;

        const data = {id: id, customer:customer}

        this.props.reorder(data)

    }

    render() {
        return (
            <>
                <button className="btn-reorder" onClick={this.do}>Duplicate</button>
            </>
        )
    }
}

class Delete extends Component {
    constructor(props) {
        super(props);

        this.confirm = this.confirm.bind(this);

        this.state = {
            allow: ["Pending"],
        }
    }


    confirm() {
        const status = this.props.status;

        if (this.state.allow.indexOf(status) >= 0) {
            swal({
                title: `${strings.Areyousure}`,
                text: `${strings.Thisactioncannotbeundone}`,
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
              .then((willDelete) => {
                if (willDelete) {
                  this.props.delete(this.props.id)
                }
              });
        } else {
            swal("", `This order is in ${status}. Delete is not allow`, 'error');
        }
    }

	downloadOrderClient = () => {
		const customer = JSON.parse(this.props.customer);
		//console.log('customer', customer);
		const JobId = this.props.id
		swal({
			title: "",
			text: strings.GeneratingReportMessage,
			icon: "",
			button: {
				text: "test",
				value: true,
				visible: false,
				closeModal: true
			}
		});

		var data = {
        type: 'customer',
        folder: config.UPLOAD_PATH,
				api_url: config.BASE_URL,
        frontend_url: config.UPLOAD_URL,
				logo: config.TOP_LOGO
		}

		fetch(config.BASE_URL + '/api/generateexcel/'+JobId, {
				body: JSON.stringify(data),
				method: 'POST',
				headers: {
					"Content-Type": "application/json",
					'Accept': 'application/json',
					"Authorization": 'Bearer ' + customer.token
				}
		})
		.then(data => data.json())
		.then(data => {
			const link = document.createElement('a');
			//link.target = "_blank";
			link.href = data.data.fileurl;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			swal.close()
		})
		.catch(error => console.log(error));
    }

    render() {



        return (
            <>
                <button title="Download Excel File" onClick={this.downloadOrderClient} className="btn-download">
                    <i className="fas fa-file-excel" />
                </button>

                <button title="Delete This Project" onClick={this.confirm} className="btn-delete">
                    <i className="fa fa-trash" />
                </button>
            </>
        )
    }
}

class EditProject extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: props.project.ProjectName,
            id: props.project.ProjectId,
            customerId: props.project.CustomerId,
            open: false,
        }

        this.openModal = this.openModal.bind(this);
        this.close = this.close.bind(this);
        this.handleChange = this.handleChange.bind(this)
        this.save = this.save.bind(this);
    }

    save() {
        this.setState({ open: false})
        this.props.save(this.state);
    }

    handleChange(e) {
        this.setState({
            name : e.target.value
        })
    }

    close() {
        this.setState(
            {
                open: false
            }
        )
    }

    openModal() {
        this.setState(
            {
                open: true
            }
        )
    }



    render() {

        return (
            <>
                <button className="project-name-button" onClick={this.openModal}>
                    {this.props.project.ProjectName}
                </button>
                <Modal
                     show={this.state.open}
                     onHide={this.close}
                     >
                    <Modal.Header closeButton>
                        Edit Project
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label>Project Name</label>
                            <input type="text" name="name" className="form-control" value={this.state.name} onChange={this.handleChange} />
                        </div>
                        <div className="form-group">
                            <button onClick={this.save} className="btn cartbtn purchasebtn">Save</button>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default Orders;
