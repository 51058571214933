import React, { Component } from 'react';
import swal from "sweetalert";
import config from "../config";
import eValid from "./emailValidate";

class EditUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal:"none",
            userid: '',
			Name: '',
			Email: '',
			Phone: '',
			Password: '',
			UserRole: '1',
			Status: '1',
			emailIsValid: false,
			emailUnique: false,
			Token: ''
        }
        this.closeDialog= this.closeDialog.bind(this)
    }

    componentWillReceiveProps(newprops){
		//console.log(newprops.User);
        this.setState({	
            modal:newprops.showModal,
            userid: (newprops.UserId) ? newprops.UserId : '',
			Name: (newprops.User) ? (newprops.User.Name) : '',
			Email: (newprops.User) ? (newprops.User.Email) : '',
			Phone: (newprops.User) ? (newprops.User.Phone) : '',
			Password: (newprops.User) ? (newprops.User.Password) : '',
			UserRole: (newprops.User) ? (newprops.User.UserRole) : '1',
			Status: (newprops.User) ? (newprops.User.Status) : '1',
			Token: (newprops.Token) ? newprops.Token : '',
        },()=>{
            if(this.state.modal===true){
               this.openDialog()
            } else {
                this.closeDialog()
            }
        })
    }

	_handleSuccess = () => {
		console.log('saved');
		this.props.onSuccess();
	}
  
    openDialog() {
        var userEditModal = document.getElementById('userEditmodal');
        userEditModal.style.display = "block";
        userEditModal.style.backgroundColor = "#000000ab";

      }
      closeDialog() {
        var userEditModal = document.getElementById('userEditmodal');
        userEditModal.style.display = "none";
        this.setState({
            userid: '',
			Name: '',
			Email: '',
			Phone: '',
			Password: '',
			UserRole: '1',
			Status: '1'
        });
      }

	
  async handleFormSubmit() {
    let self = this
    const {userid, Name, Email, Phone, Password, UserRole, Status, Token } = this.state
	
	if (!eValid.isValidEmailAddress(this.state.Email)) {
		swal("", "Invalid Email Address", "error");
		return;
	}
	//console.log('userid:' + userid);
	//check unique email
	var uniqueEmail = await eValid.isEmailUnique(Email, userid);
	
	this.setState({
		emailUnique: uniqueEmail
	});
		
	if (!this.state.emailUnique) {
		swal("", "Email Address already exists", "error");
		return;
	}
	
	
    var data = {
      Id: userid,
	  Name: Name,
	  Email: Email,
	  Phone: Phone,
	  Password: Password,
	  UserRole: (UserRole) ? UserRole : 1,
	  Status: (Status) ? Status : 1
    }
	//console.log(data);
	var uri = (userid) ? '/api/updateuser' : '/api/adduser';
	
      fetch(config.BASE_URL + uri, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
		  "Authorization": 'Bearer ' + Token
        }
      }).then((response) => response.json())
        .then((data) => {
          this.setState({
			userid: '',
			Name: '',
			Email: '',
			Phone: '',
			Password: '',
			UserRole: '1',
			Status: '1',
			modal: false
		}, () => {
            swal("", "Record Save Successfully!", "success")
            .then((value) => {
                self.closeDialog();
            });
            this.setState({
				userid: '',
				Name: '',
				Email: '',
				Phone: '',
				Password: '',
				UserRole: '1',
				Status: '1',
				modal: false
            },()=>{
              //self.props.sendJobList(this.state.getJobList)
			  this._handleSuccess();
            })
          })
        })
        .catch((error) => {
            console.log("Error, with message::", error)
            this.setState({ modal: false }, () => {
              self.closeDialog();
          })
        });
  }

    render() {
        return (
            <div>
                
                <div className="modal" id="userEditmodal" tabIndex={-1} role="dialog" aria-labelledby="userEditmodalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content" style={{marginTop:"100px"}}>
                            <div className="modal-header">
                                <h4 className="modal-title" id="userEditmodalLabel" style={{float:"left"}}>Add/Edit User</h4>
                                <button type="button" className="close" onClick={this.closeDialog.bind(this)} aria-label="Close">
                                    <span aria-hidden="true" onClick={this.closeDialog.bind(this)}>x</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="name-data" className="col-form-label">Name:</label>
                                        <div>
                                            <input className="form-control" name="Name" type="text"												value={this.state.Name}
												onChange={event => this.setState({Name: event.target.value})}>
                                            </input>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email-data" className="col-form-label">Email:</label>
                                        <div>
                                            <input className="form-control" name="Email" type="email"
												value={this.state.Email}
												onChange={event => this.setState({Email: event.target.value})}
												onBlur={() => this.setState({
													emailIsValid: eValid.isValidEmailAddress(this.state.Email)
												})}>
                                            </input>
                                        </div>
                                    </div>
                                    {/*<div className="form-group">
                                        <label htmlFor="phone-data" className="col-form-label">Phone:</label>
                                        <div>
                                            <input className="form-control" name="Phone" type="text"
												value={this.state.Phone}
												onChange={event => this.setState({Phone: event.target.value})}>
                                            </input>
                                        </div>
                                    </div>*/}
                                    <div className="form-group">
                                        <label htmlFor="phone-data" className="col-form-label">Password:</label>
                                        <div>
                                            <input className="form-control" name="Password" type="password"
												value={this.state.Password}
												onChange={event => this.setState({Password: event.target.value})}>
                                            </input>
                                        </div>
                                    </div>									
								  <div className="form-group">
									<label htmlFor="status-data" className="col-form-label">Status:</label>
									<div>
									  <select className="form-control" name="Status" onChange={event => this.setState({Status: event.target.value})} >
										<option value="1">Active</option>
										<option value="0">Inactive</option>
									  </select>
									</div>
								  </div>
								  <div className="form-group">
									<label htmlFor="role-data" className="col-form-label">Role:</label>
									<div>
									  <select className="form-control" name="UserRole" onChange={event => this.setState({UserRole: event.target.value})}>
										<option value="1">Admin</option>
										<option value="2">Manufacturing</option>
										<option value="3">Financial</option>
									  </select>
									</div>
								  </div>								  
                                    {/*<div className="form-group">
                                        <label htmlFor="message-text" className="col-form-label">Select the Quotation File:</label>
                                        <input type="file" className="custom-file-input" id="document" name="document" aria-describedby="inputGroupFileAddon01" accept="application/pdf,application/vnd.ms-excel"
                                            onChange={this.handleFilePath.bind(this)}/>
                                    </div>*/}
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={this.handleFormSubmit.bind(this)}>
                                    Save
                                </button>
                                <button type="button" onClick={this.closeDialog.bind(this)} className="btn btn-secondary">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default EditUser;