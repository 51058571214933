import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import config from "../config";
import HeaderAdmin from '../components/HeaderAdmin';
import FooterAdmin from '../components/FooterAdmin';
import swal from 'sweetalert';
import Modal from 'react-bootstrap-modal';
import Dropzone from 'react-dropzone'
import axios from 'axios';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { strings } from '../components/strings';
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import ReactTooltip from 'react-tooltip'

class StockCodeList extends Component {

  constructor(props) {
    super(props);
	  var authuser = JSON.parse(localStorage.getItem('authuser'));
    this.state = {
		  AuthUser: (authuser) ? authuser[0] : [],
		  data: [],
		  products: [],
		hinges: [],
		shelves: [],
      open: false,
      columnWidth: [0, 0, 12, 12, 12, 12, 12, 12, 12, 12],
      hasDoor: true,
      hasShelve: true,
      hasDrawer: true,
      isKicker: false,
      hardware: []
    }

    this.delete = this.delete.bind(this);
    this.getList = this.getList.bind(this);
    this.save = this.save.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.submit = this.submit.bind(this);
    this.shelveFormatter = this.shelveFormatter.bind(this);
    //this.csvFormatter = this.csvFormatter.bind(this);

    this.onSortChange = this.onSortChange.bind(this);

    this.getHinges = this.getHinges.bind(this);
    this.getShelves = this.getShelves.bind(this);

    this.hingeFormatter = this.hingeFormatter.bind(this);
    this.wineRackFormatter = this.wineRackFormatter.bind(this);
    this.microWaveOvenFormatter = this.microWaveOvenFormatter.bind(this);

    this.expandComponent = this.expandComponent.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  wineRackFormatter(cell, row) {
    return cell === 1 ? 'Yes' : 'No';
  }

  microWaveOvenFormatter(cell, row) {
    return cell === 1 ? 'Yes' : 'No';
  }

  open() {
    this.setState({open:true})
  }

  close() {
    this.setState({open:false})
  }

  submit(data) {

    var authuser = this.state.AuthUser;

    fetch(config.BASE_URL + '/api/addstockcode', {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
		  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
        }
      })
      .then(data => data.json())
      .then(data => {
        this.getList();
      })
      .catch(error => console.log(error));
  }

  delete(row) {
	var authuser = this.state.AuthUser;
    swal({
        title: `${strings.Areyousure}`,
        text: "Once deleted, you will not be able to recover this record",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(willDelete => {
        if (willDelete) {
          fetch(config.BASE_URL + '/api/deletestockcode', {
            method: "POST",
            body: JSON.stringify(row),
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
				        "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
              }
            })
            .then(data => data.json())
            .then(data => {
              this.getList();
            })
            .catch(error => console.log(error));
        } else {
            return false;
        }
      });
  }

  save(row, cellName, cellValue) {

    var authuser = this.state.AuthUser;

    if (cellName === 'ProductName') {
      this.state.products.map(product => {
        if (product.ProductName === cellValue) {
          row.ProductId = product.ProductId
        }
        return product;
      })
    }

    if (cellName === 'ShelveId') {
      if (cellValue === 'Adjustable') row.ShelveId = 2;
      if (cellValue === 'Fix') row.ShelveId = 1;
    }

    if (cellName === 'HingeId') {
      const {hinges} = this.state
      const selected = hinges.filter(hinge => hinge.HingeId === row.HingeId)
          row.DoorHinge = selected[0].HingeType
    }

    //console.log('cellName', cellName);
    //console.log('cellValue', cellValue);


    if (cellName === 'DrawerStyle') {

      this.state.hardware.map(d => {

        if (d.text === cellValue) {

          row.DrawerStyle = d.value

        }

        return d;

      });

    }

    //console.log('row', row);

    fetch(config.BASE_URL + '/api/savestockcode', {
      method: "POST",
      body: JSON.stringify(row),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
		      "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
        }
      })
      .then(data => data.json())
      .then(data => {
        // this.getList();
      })
      .catch(error => console.log(error));
  }

  getList() {

	  var authuser = this.state.AuthUser;
    fetch(config.BASE_URL + '/api/getproductstockcodelist', {
        method: "GET",

        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json',
			"Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
          }
        })
        .then(data => data.json())
        .then(data => {

          this.setState(
              {
                  data: data.codes,
                  products: data.products,
                  hardware: data.hardware,
                  shelves: data.shelves,
                  hinges: data.hinges
              }, () => {
            for (let index = 0; index < 15; index += 1) {
              if ($(`.react-bs-container-header thead th:eq(${index})`).length === 0) break; // break if cant find any more columns

              $(`.react-bs-container-header thead th:eq(${index})`).resizable({
                minWidth: 50,
                handles: 'e',
                resize: (__event, ui) => {
                  const newColumnWidths = [...this.state.columnWidth];
                  const container = $('.react-bs-container-header').width();
                  newColumnWidths[index] = ui.size.width / container * 100;

                  //console.log(newColumnWidths);

                  this.setState({ columnWidth: newColumnWidths });
                },
              });

            }
          });
        })
        .catch(error => console.log(error));
  }

  getHinges() {
    fetch(config.BASE_URL + '/api/gethinges', {
        method: "GET",

        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json'
          }
        })
        .then(data => data.json())
        .then(data => {
			this.setState({
				hinges: data.hinges
			}, () => {
			})
        })
        .catch(error => console.log(error));
  }

  getShelves() {
    fetch(config.BASE_URL + '/api/getshelves', {
        method: "GET",

        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json'
          }
        })
        .then(data => data.json())
        .then(data => {
			this.setState({
				shelves: data
			}, () => {
			})
        })
        .catch(error => console.log(error));
  }

    editFormatter(cell, row) {

      const prod = this.state.products.filter(product => {
          return product.ProductId === row.ProductId
      });
      console.log('row.has_inner', prod)
      const hasDrawer = prod.length > 0 ? prod[0].hasDrawer : 0;
      const hasInner = prod.length > 0 ? prod[0].has_inner : 0;
      //console.log('hasDrawer', hasDrawer)
      return  <>
                  {hasDrawer && hasDrawer === 1 || hasInner && hasInner === 1 ? <DrawerSizesButton save={this.save} authuser={this.state.AuthUser} data={row} hasDrawer={hasDrawer} hasInner={hasInner}  /> : ''}
                  <ImageButton authuser={this.state.AuthUser} data={row} />
                  <button className="link-btn" onClick={ () => this.delete(row) }><i className="fa fa-trash" aria-hidden="true" /></button>
                </>;
    }

    shelveFormatter(cell, row) {

      if (cell === 1 || cell === 2)

        return `${row.ShelveId === 1 ? 'FIX' : 'ADJ'}`;

      else if (cell === 3)

        return 'N/A';

      else

        return cell;
    }

    hingeFormatter(cell, row) {

		    const {hinges} = this.state
		    const selected = hinges.filter(hinge => hinge.HingeId === row.HingeId)

        return (selected.length > 0) ? selected[0].HingeType: cell;
    }

    csvFormatter(cell, row) {

      return `${row.CsvFile === 1 ? 'Yes' : 'No'}`;

    }

    componentDidMount() {
		  this.getList();
		  //this.getHinges();
		  //this.getShelves();
    }

  isExpandableRow(row) {
      return true
  }

  expandComponent(row) {

    const prod = this.state.products.filter(product => {
        return product.ProductId === row.ProductId
    });

      return (
          <>
              <BSTable product={prod[0] ? prod[0] : []} data={ row } save={this.save} hardware={this.state.hardware} />
          </>
      );
  }

onSortChange(sortName, sortOrder) {
	localStorage.setItem("stockCodeSort", JSON.stringify({
		sortName: sortName,
		sortOrder: sortOrder
	}));
}

  onFilterChange(filterConds) {
    //console.log('onFilterChange', filterConds)
    if (Object.keys(filterConds).length > 0){
        const {ProductName} = filterConds
        const prod = this.state.products.filter(product => {
            return product.ProductName === ProductName.value
        });

        this.setState( {
            hasDoor: (prod && prod.length > 0 && prod[0].hasDoor === 1) ? true : false,
            hasDrawer: (prod && prod.length > 0 && prod[0].hasDrawer === 1) ? true : false,
            hasShelve: (prod && prod.length > 0 && prod[0].hasShelve === 1) ? true : false,
            isKicker: (prod && prod.length > 0 && prod[0].ProductId === 46) ? true : false
        }, () => {
          //console.log('this.state', this.state)
        });

    }else{
      this.setState( {
          hasDoor: true,
          hasDrawer: true,
          hasShelve: true,
          isKicker: false
      }, () => {
        //console.log('this.state', this.state)
      });
    }
  }

  render() {

    var stockCodeSort = localStorage.getItem('stockCodeSort');

    if (stockCodeSort) {
      stockCodeSort = JSON.parse(stockCodeSort)
    }

    const cellEditProp = {
        mode: 'click',
        blurToSave: true,
        afterSaveCell: this.save
    }

    const products = this.state.products.map(product => {
      return product.ProductName
    });

    const productOptions = [];

    for(let i = 0; i < this.state.products.length; i++) {
      productOptions[this.state.products[i].ProductName] = this.state.products[i].ProductName
    }

    const { hinges } = this.state;

	  const hingesOptions = hinges.map(hinge => {
		  return {
			  value: hinge.HingeId,
			  text: hinge.HingeType
		  }
	  })

  /* const shelvesOptions = shelves.map(shelve => {
		return {
			value: shelve.ShelveId,
			text: shelve.ShelveName
		}
	})

	const hardwareOptions = []; */

    const options = {
        prePage: "Previous", // Previous page button text
        nextPage: "Next", // Next page button text
        onRowClick: this.onRowClick,
        noDataText: "No records found",
        that: this, // <- needed this "this" in the search event handler
        sizePerPage: 25,
        defaultSortName: (stockCodeSort) ? stockCodeSort.sortName : null,
        defaultSortOrder: (stockCodeSort) ? stockCodeSort.sortOrder : null,
		    onSortChange: this.onSortChange,
        onFilterChange: this.onFilterChange,
        sizePerPageList: [
            {
                text: "5",
                value: 5
            },
            {
                text: "10",
                value: 10
            },
            {
                text: "25",
                value: 25
            },
            {
                text: "50",
                value: 50
            },
            {
                text: "All",
                value:
                    this.state.data.length > 0
                        ? this.state.data.length
                        : 5
            }
        ]
    };


      return (
        <div className="admin-wrapper">
            <HeaderAdmin />
            <div className="dashboard-wrapper partscontent">
              <div className="dashboardhome">
                  <div className="container-fluid dashboard-content jobspagecnt">
                    {/* ============================================================== */}
                    {/* pagecontent  */}
                    {/* ============================================================== */}
                    <div className="row">
                      <div className="col-md-12">
                        <ol className="breadcrumb breadm">
                            <li><a href="/admin">Admin</a></li>
                            <li><i className="fa fa-angle-right midarrow" /><a href="/admin/stockcode">Product Stock Code List</a></li>
                        </ol>
                      </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                            <div className="inner-pagehead">
                                <h1>Product Stock Code List</h1>
                                <span class="small-text text-dark">Cost Items does not include GST</span>
                            </div>

                            <div className="stockcode-table">


                            <BootstrapTable
                                ref='table'
                                data={this.state.data}
                                pagination={true}
                                options={options}
                                search={true}
                                hover={true}
                                cellEdit={cellEditProp}
                                trClassName={this.trClassFormat}
                                expandableRow={ this.isExpandableRow }
                                expandComponent={ this.expandComponent }
                                expandColumnOptions={ { expandColumnVisible: true } }
                                >
                                <TableHeaderColumn
                                  hidden={true}
                                  dataField='ProductStockCodeId'
                                  dataSort={true}
                                  isKey={true}>
                                    Product Stock Code ID
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  dataField='ProductName'
                                  dataSort={true}
                                  width={this.state.columnWidth[2] + '%'}
                                  filter={ { type: 'SelectFilter', options: productOptions,  delay: 1000 } }
                                  editable={ {type: 'select', options: { values : products } } } >Product Name</TableHeaderColumn>

                                <TableHeaderColumn
                                  dataField='StockCode'
                                  dataSort={true}
                                  width={this.state.columnWidth[4] + '%'}
                                   >Stock Code</TableHeaderColumn>
                                   <TableHeaderColumn
                                     dataField='displayname'
                                     dataSort={true}
                                     width={this.state.columnWidth[4] + '%'}
                                      >Display Name</TableHeaderColumn>
                                <TableHeaderColumn
                                  dataField='Details'
                                  dataSort={true}
                                  width={this.state.columnWidth[3] + '%'}
                                  editable={ {type: 'textarea'} } >Details</TableHeaderColumn>

                                {<TableHeaderColumn
                                  dataField='NoOfMitre'
                                  dataSort={true}
                                  hidden={!this.state.isKicker}
                                  width={this.state.columnWidth[4] + '%'}
                                >No of Mitre</TableHeaderColumn>}
                                <TableHeaderColumn
                                  dataField='HingeId'
                                  dataSort={true}
                                  hidden={!this.state.hasDoor}
								                  dataFormat={ this.hingeFormatter }
                                  width={this.state.columnWidth[5] + '%'}
                                  editable={ { type: 'select', options: { values: hingesOptions} } }>
                                  Door Hinge
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                  dataField='DoorHinge'
                                  hidden={true}
									              >
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  dataField='AdjustableShelve'
                                  hidden={!this.state.hasShelve}
                                  dataSort={true}
                                  width={this.state.columnWidth[6] + '%'}
                                  editable={ { type: 'number' } }
                                >
                                    Adjustable Shelves
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                  dataField='FixedShelve'
                                  dataSort={true}
                                  hidden={!this.state.hasShelve}
                                  width={this.state.columnWidth[7] + '%'}
                                  editable={ { type: 'number'} }
                                >
                                    Fixed Shelves
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                  dataField='WineRack'
                                  dataSort={true}
                                  hidden={true}
                                  dataFormat={ this.wineRackFormatter }
                                  width={this.state.columnWidth[7] + '%'}
                                  editable={ { type: 'checkbox', options: { values: '1:0' } } }
                                >
                                    Wine Rack
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                  dataField='MicrowaveOven'
                                  dataSort={true}
                                  hidden={true}
                                  dataFormat={ this.microWaveOvenFormatter }
                                  width={this.state.columnWidth[7] + '%'}
                                  editable={ { type: 'checkbox', options: { values: '1:0' } } }
                                >
                                    Microwave Oven
                                </TableHeaderColumn>



                                <TableHeaderColumn
                                  tdStyle={{ cursor: "pointer", textAlign:"center"}}
                                  thStyle={{ textAlign:"center" }}
                                  editable={false}
                                  width={this.state.columnWidth[8] + '%'}
                                  dataFormat={ this.editFormatter.bind(this) }>
                                <button onClick={this.open} className="btn add-btn purchasebtn"><i className="fa fa-plus" /></button>
                                </TableHeaderColumn>


                            </BootstrapTable>

                            </div>
                            <AddModal hinges={this.state.hinges} shelves={this.state.shelves} products={this.state.products} open={this.state.open} close={this.close} submit={this.submit} />


                        </div>
                    </div>

                    {/* ============================================================== */}
                    {/* end pagecontent  */}
                    {/* ============================================================== */}
                    </div>
                </div>

            <FooterAdmin />
            </div>
        </div>
      )
  }

}

class DrawerSizesButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false
    }

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  close() {
    this.setState( {open:false} )
  }

  open() {
    this.setState( {open:true} );
  }



  render() {
    
    // var DrawerSizeArr = []

    const {DrawerSize} = this.props.data
    //console.log('DrawerSize', DrawerSize)
    const hasDrawerSize = DrawerSize && DrawerSize.equal ? null : {color: '#333'}
    return (
      <>
        <button style={hasDrawerSize} onClick={this.open} className="link-btn" data-tip="Drawer Size"><i class="fa fa-server"></i></button>
        <DrawerSizesModal hasDrawer={this.props.hasDrawer} hasInner={this.props.hasInner} save={this.props.save} data={this.props.data} open={this.state.open} close={this.close} />
        <ReactTooltip place="top" html={false} />
      </>
    )
  }
}

class ImageButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false
    }

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  close() {
    this.setState( {open:false} )
  }

  open() {
    this.setState( {open:true} );
  }

  render() {
    //console.log('this.props.data', this.props.data)
    const {images} = this.props.data
    const hasImages = images && images.length > 0 ? null : {color: '#333'}
    return (
      <>
        <button style={hasImages} onClick={this.open} className="link-btn"><i className="fa fa-image" aria-hidden="true" /></button>
        <ImageModal authuser={this.props.authuser} data={this.props.data} open={this.state.open} close={this.close} />
      </>
    )
  }
}

/*
function Accept(props) {
  const {acceptedFiles, rejectedFiles, getRootProps, getInputProps} = useDropzone({
    accept: 'image/jpeg, image/png'
  });

  const acceptedFilesItems = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const rejectedFilesItems = rejectedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <section>
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <div className="drag-n-drop text-center">
          <i className="fa fa-image" />Drag 'n' drop some files here, or click to select files
        </div>
        <em>(Only *.jpeg and *.png images will be accepted)</em>
      </div>
    </section>
  );
}*/

class DrawerSizesModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      DrawerSize: Array.isArray(props.data.DrawerSize) ? props.data.DrawerSize :
      {
        equal: {
          runnerheight: 0,
          faceheight: 0
        },
        small: {
          runnerheight: 0,
          faceheight: 0
        },
        inner: {
          runnerheight: 0,
          faceheight: 0
        }
      }
    }

    this.onSave = this.onSave.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }
  
  componentDidMount(){
    this.setState( {
      DrawerSize: this.props.data.DrawerSize || {
              equal: {
                runnerheight: 0,
                faceheight: 0
              },
              small: {
                runnerheight: 0,
                faceheight: 0
              },
              inner: {
                runnerheight: 0,
                faceheight: 0
              }
            }
    }, () => {
      console.log('this.state', this.state);
    })
    
  }
  onSave() {

    const {DrawerSize} = this.state;
    const data = this.props.data;
    data.DrawerSize = DrawerSize;

    this.props.save(data);
    this.props.close();
  }

  changeHandler(i, event){
    console.log('i', i)
    const {DrawerSize} = this.state || []
    //console.log('this.state before', DrawerSize);
    DrawerSize[i][event.target.name] =  event.target.value
    this.setState( {
      DrawerSize: DrawerSize
    }, () => {
      //console.log('this.state after', this.state);
    })
  }

  render() {
    const {DrawerSize, displayname} = this.props.data
    return (
      <>
        <Modal
          show={this.props.open}
          onHide={this.props.close}
          className="DrawerSizedModal">
          <Modal.Header closeButton>
            Drawer Sizes - {displayname}
          </Modal.Header>
          <Modal.Body>
          <div className="row">
            <div className="col-md-3">
                <div className="form-group">
                </div>
            </div>
                <div className="col-md-4">
                    <label><b>Runner Height</b></label>
                </div>
                <div className="col-md-4">
                    <label><b>Face Height</b></label>
                </div>
            </div>
          { !this.props.hasDrawer ?  '' : 
          <>
          <div className="row">
            <div className="col-md-3">
                <div className="form-group">
                    <label style={{paddingTop: '15px'}}>Equal Drawer :</label>
                </div>
            </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <input onChange={this.changeHandler.bind(null, 'equal')} type="number" name="faceheight" defaultValue={DrawerSize && DrawerSize.equal ? DrawerSize.equal.faceheight : 0} className="form-control" />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <input onChange={this.changeHandler.bind(null, 'equal')} type="number" name="runnerheight" defaultValue={DrawerSize && DrawerSize.equal ? DrawerSize.equal.runnerheight : 0}  className="form-control" />
                    </div>
                </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                  <div className="form-group">
                      <label style={{paddingTop: '15px'}}>Small Drawer :</label>
                  </div>
              </div>
                  <div className="col-md-4">
                      <div className="form-group">
                          <input onChange={this.changeHandler.bind(null, 'small')} type="number" name="faceheight" defaultValue={DrawerSize && DrawerSize.small ? DrawerSize.small.faceheight : 0} className="form-control" />
                      </div>
                  </div>
                  <div className="col-md-4">
                      <div className="form-group">
                          <input onChange={this.changeHandler.bind(null, 'small')} type="number" name="runnerheight" defaultValue={DrawerSize && DrawerSize.small ? DrawerSize.small.runnerheight : 0} className="form-control" />
                      </div>
                  </div>
              </div>
              </>
            }
            { !this.props.hasInner ?  '' : 
              <div className="row">
                <div className="col-md-3">
                    <div className="form-group">
                        <label style={{paddingTop: '15px'}}>Inner Drawer :</label>
                    </div>
                </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <input onChange={this.changeHandler.bind(null, 'inner')} type="number" name="faceheight" defaultValue={DrawerSize && DrawerSize.inner ? DrawerSize.inner.faceheight : 0} className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <input onChange={this.changeHandler.bind(null, 'inner')} type="number" name="runnerheight" defaultValue={DrawerSize && DrawerSize.inner ? DrawerSize.inner.runnerheight : 0} className="form-control" />
                        </div>
                    </div>
                </div>
              }
          </Modal.Body>
          <Modal.Footer>
          <button className="btn cartbtn purchasebtn" onClick={this.onSave}> Save Changes </button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

class ImageModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      image1: '',
      image2: '',
      image3: '',
      images: [],
      stockId: props.data.ProductStockCodeId
    }

    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.drop = this.drop.bind(this);
    this.getStockImages = this.getStockImages.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.imageFormat = this.imageFormat.bind(this);
    this.deleteButton = this.deleteButton.bind(this);
    this.save = this.save.bind(this);
    this.customMultiSelect = this.customMultiSelect.bind(this)
  }

  save(row) {

    const data = row;

    var authuser = this.props.authuser;

    fetch(config.BASE_URL + '/api/updatestockimage', {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
		      "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
        }
      })
      .then(data => data.json())
      .then(data => {
        //do nothing
      })
      .catch(error => console.log(error));

  }

  deleteButton(cell, row) {

    return (
      <button className="link-btn" onClick={ () => this.deleteImage(row.ProductStockCodeImageId) }><i className="fa fa-trash" /></button>
    )

  }

  imageFormat(cell, row) {

    return (
      <StockImage data={row} />
    )

  }

  deleteImage(id) {

    const data = {stockId: this.props.data.ProductStockCodeId, imageId: id};

    var authuser = this.props.authuser;

    fetch(config.BASE_URL + '/api/deletestockimage', {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
		  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
        }
      })
      .then(data => data.json())
      .then(data => {

        const images = this.state.images.filter(image => {
          if (image.ProductStockCodeImageId != id) {
            return image
          }
        });

        this.setState({images:images});
      })
      .catch(error => console.log(error));
  }

  getStockImages(id) {

    var authuser = this.props.authuser;

    fetch(config.BASE_URL + '/api/getstockimages/' + id, {
      method: "GET",
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
		  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
        }
      })
      .then(data => data.json())
      .then(data => {
        this.setState({images: data})
      })
      .catch(error => console.log(error));
  }

  drop(files) {
    const data = new FormData();
    var authuser = this.props.authuser;

    data.append('id', this.props.data.ProductStockCodeId);

    files.forEach((file, index) => {
        data.append(`myimages[]`, file, file.name);
    });

    axios.post(config.BASE_URL + "/api/uploadimage", data , { headers: { "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )}} )
    .then(res => {
      this.setState( { images: res.data} );
    })

  }

  handleChange(e) {
    this.setState( {[e.target.name] : e.target.value});
  }

  submit() {

    const data = this.state;

    this.setState({image1: '', image2: '', image3: ''});

    var authuser = this.state.AuthUser;

    fetch(config.BASE_URL + '/api/stockcodesubmitimage', {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
		  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
        }
      })
      .then(data => data.json())
      .then(data => {
        this.setState( {images: data} )
      })
      .catch(error => console.log(error));
  }
    
  onRowSelect = (row, isSelected, e) => {
    //console.log(e, e)
    /*
    this.state.images.map(image => {
      image.isMenuImage = 0
    })
    */
    this.state.images[e.target.value].isMenuImage = isSelected ? 1 : 0
    row.isMenuImage = isSelected ? 1 : 0
    this.save(row)
  }

  componentDidMount() {
    this.getStockImages(this.props.data.ProductStockCodeId);
  }
  /*
  customRadioSelect(props) {
      const { type, disabled, checked, onChange, rowIndex } = props;
      if (rowIndex === 'Header') {
        return ('Menu Image?');
      } else {
        const selected = this.state.images[rowIndex]
        const isChecked = selected && selected.isMenuImage == 1 ? true : false

        return (
          <div className='ismenu-personalized'>
            <input
              type={ type }
              name={ 'ismenu' + selected.ProductStockCodeId }
              id={ 'ismenu' + selected.ProductStockCodeId }
              value={rowIndex}
              checked={ isChecked }
              disabled={ disabled }
              className="form-control editor edit-radio"
              style={{minHeight: '25px', height:'25px', outline: 'currentcolor none medium', border:'0px none', boxShadow:'none'}}
              onChange={ e=> onChange(e, rowIndex) }
              />
          </div>);
      }
    }
    */
    
    
  customMultiSelect(props) {
    const { type, checked, disabled, onChange, rowIndex } = props;
    if (rowIndex === 'Header') {
      return (
        <div className='checkbox-personalized'>
          {/*}
          <Checkbox {...props}/>
          <label htmlFor={ 'checkbox' + rowIndex }>
            <div className='check'></div>
          </label> */}
          Menu Image?
        </div>);
    } else {
      
      const selected = this.state.images[rowIndex]
      const isChecked = selected && selected.isMenuImage == 1 ? true : false
      
      return (
        <div className='checkbox-personalized'>
          <input
            type={ type }
            name={ 'checkbox' + rowIndex }
            id={ 'checkbox' + rowIndex }
            value={rowIndex}
            checked={ isChecked }
            disabled={ disabled }
            onChange={ e=> onChange(e, rowIndex) }
            ref={ input => {
              if (input) {
                input.indeterminate = props.indeterminate;
              }
            } }/>
          <label htmlFor={ 'checkbox' + rowIndex }>
            <div className='check'></div>
          </label>
        </div>);
    }
  }
  
  render() {
    const images = this.state.images;

    const cellEditProp = {
      mode: 'click',
      blurToSave: true,
      afterSaveCell: this.save
    }
    
    const selectRowProps = {
      clickToSelect: true,
      clickToSelectAndEditCell: false,
      mode: "checkbox",
      onSelect: this.onRowSelect,
      columnWidth: '145px',
      customComponent: this.customMultiSelect
    }
    
    return (
      <>
        <Modal
          show={this.props.open}
          onHide={this.props.close}
          className="StockImageModal">

          <Modal.Header closeButton>
            Stock Code Image
          </Modal.Header>

          <Modal.Body>

              <BootstrapTable
                data={images}
                cellEdit={cellEditProp}
                hover={true}
                selectRow={selectRowProps}
              >
                <TableHeaderColumn
                  dataField="ProductStockCodeImageId"
                  hidden={true}
                  isKey={true}
                >
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="ImagePath"
                  dataFormat={this.imageFormat}
                  editable={false}
                >
                  Image
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="SortOrder"
                  headerAlign="center"
                  dataAlign="center"
                  editable={ { type: "number" } }
                >
                  Sort Order
                </TableHeaderColumn>
                {/*
                <TableHeaderColumn
                  dataField="isMenuImage"
                  headerAlign="center"
                  dataAlign="center"
                  dataFormat={ this.radioFormatter }
                  editable={ { type: 'radio', style: {}, className: "is-menu-image", options: {checked: true} } }
                >                
                  Is Menu Image?
                </TableHeaderColumn> */}
                <TableHeaderColumn
                  editable={false}
                  headerAlign="center"
                  dataAlign="center"
                  dataFormat={this.deleteButton}
                >
                  Action
                </TableHeaderColumn>

              </BootstrapTable>

          </Modal.Body>
          <Modal.Footer>
          <Dropzone
            onDrop={this.drop}
          >
            {({getRootProps, getInputProps}) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="drag-n-drop text-center"><i className="fa fa-image" />Drag 'n' drop some files here, or click to select files</div>
            </div>
            )}
          </Dropzone>



          </Modal.Footer>


        </Modal>
      </>
    )
  }
}


class Checkbox extends React.Component {
  componentDidMount() { this.update(this.props.checked); }
  componentDidUpdate(prevprops) {
    this.update(this.props.checked); 
  }
  update(checked) {
    ReactDOM.findDOMNode(this).indeterminate = checked === 'indeterminate';
  }
  render() {
    return (
      <input className='react-bs-select-all'
        type='checkbox'
        name={ 'checkbox' + this.props.rowIndex }
        id={ 'checkbox' + this.props.rowIndex }
        checked={ this.props.checked }
        onChange={ this.props.onChange } />
    );
  }
}
/*
class Checkbox extends React.Component {
  componentDidMount() {
    this.update(this.props.checked);
  }
  componentDidUpdate(prevprops) {
    //if (prevprops.rowIndex != this.props.rowIndex) {
      this.update(this.props.checked); 
    //}
  }
  update(checked) {
    ReactDOM.findDOMNode(this).indeterminate = checked === 'indeterminate';
  }
  render() {
    return (
      <input className='react-bs-select-all'
        type='radio'
        name={ 'radio' + this.props.rowIndex }
        id={ 'radio' + this.props.rowIndex }
        checked={ this.props.checked }
        onChange={ this.props.onChange } />
    );
  }
}
*/
class StockImage extends Component {

  constructor(props) {
    super(props);

    this.delete = this.delete.bind(this);

    this.state = {
      id : props.data.ProductStockCodeImageId,
      isOpen: false
    }

  }

  delete() {
    this.props.delete(this.props.data.ProductStockCodeImageId);
  }


  render() {

    const isOpen = this.state.isOpen;
    const imgSrc = config.BASE_URL + this.props.data.ImagePath;

    return (
      <>

          {/* <button className="btn-delete" onClick={this.delete}><i className="fa fa-minus" /></button> */}
          <img onClick={() => {this.setState({ isOpen: true})} } src={imgSrc} />
          { isOpen &&
            <Lightbox
              mainSrc={imgSrc}
              onCloseRequest={() => this.setState({ isOpen: false })}
            />
          }
      </>
    )
  }


}

class AddModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ProductId   : 1,
      StockCode   : '',
      Details : '',
      DoorHinge  : '',
      Shelves : '',
      ShelveId: 1,
      DoorRevals: '',
      DrawerSize: '',
      Weight: '',
      CsvFile: 1,
      HarwareCost: 0,
      PerDrawCost: 0,
      ExtraCost: 0,
      AssemblyCost: 0,
      HingeId: 1,
      errorCodeClass: 'form-control',
      errorClass: 'hidden',
    }

    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }

  handleChange(e) {

    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    this.setState({ [e.target.name] : value, errorCodeClass: 'form-control', errorClass: 'hidden'} )
  }

  submit() {

    const stockCode = this.state.StockCode;

    let valid = true;

    if (stockCode == '') {
      valid = false;
      this.setState({errorCodeClass: 'form-control border-danger', errorClass: 'text-red text-danger'})
    }

    if (valid) {
      this.props.submit(this.state);
      this.props.close();
    }
  }

  getHinges() {
    fetch(config.BASE_URL + '/api/gethinges', {
        method: "GET",

        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json'
          }
        })
        .then(data => data.json())
        .then(data => {
			this.setState({
				hinges: data.hinges
			}, () => {
			})
        })
        .catch(error => console.log(error));
  }

  getShelves() {
    fetch(config.BASE_URL + '/api/getshelves', {
        method: "GET",

        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json'
          }
        })
        .then(data => data.json())
        .then(data => {
			this.setState({
				shelves: data
			}, () => {
			})
        })
        .catch(error => console.log(error));
  }

  render() {

    const options = this.props.products.map(product => {
      return <option value={product.ProductId}>{product.ProductName}</option>
    })

    const hingeOptions = this.props.hinges.map(hinge => {
      return <option value={hinge.HingeId}>{hinge.HingeType}</option>
    })

    const shelveOptions = this.props.shelves.map(shelve => {
      return <option value={shelve.ShelveId}>{shelve.ShelveName}</option>
    })

    return (
      <Modal
          show={this.props.open}
          onHide={this.props.close}
          className="LoginModal"
        >

       <Modal.Header closeButton>
         Add Product Stock Code
       </Modal.Header>

        <Modal.Body>
          <div className="form-group">
            <label>Product</label>
            <select name="ProductId" className="form-control" onChange={this.handleChange}>
              {options}
            </select>
          </div>
          <div className="form-group">
            <label>Stock Code</label>
            <input type="text" name="StockCode" className={this.state.errorCodeClass} onChange={this.handleChange} />
            <span className={this.state.errorClass}>Enter the product stock code</span>
          </div>
          <div className="form-group">
            <label>Display Name</label>
            <input type="text" name="displayname" className={this.state.errorCodeClass} onChange={this.handleChange} />
            <span className={this.state.errorClass}>Enter the product display name</span>
          </div>
          <div className="form-group">
            <label>Details</label>
            <textarea name="Details" className="form-control" onChange={this.handleChange} />
          </div>

          <div className="row">

              <div className="col-md-4">
                <div className="form-group">
                  <label>Door Hinge</label>
                  <select name="HingeId" className="form-control" onChange={this.handleChange}>
                      {hingeOptions}
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Adj Shelves</label>
                  <input type="number" name="AdjustableShelve" className="form-control" onChange={this.handleChange} />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Fixed Shelves</label>
                  <input type="number" name="FixedShelve" className="form-control" onChange={this.handleChange} />
                </div>
              </div>
          </div>

          <div className="row">

              <div className="col-md-4">
                <div className="form-group">
                  <label>Door Reveals</label>
                  <input type="text" name="DoorReveals" className="form-control" onChange={this.handleChange} />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Drawer Size</label>
                  <input type="text" name="DrawerSize" className="form-control" onChange={this.handleChange} />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Base Weight(kg)</label>
                  <input type="number" name="Weight" className="form-control" onChange={this.handleChange} />
                </div>
              </div>
          </div>

          {/* <div className="form-check">

              <label><input type="checkbox" name="CsvFile" onChange={this.handleChange} checked={this.state.CsvFile} />  CSV File Created Online</label>


          </div> */}

          <div className="row">

              <div className="col-md-6">
                <div className="form-group">
                  <label>Hardware Cost</label>
                  <input type="number" name="HardwareCost" className="form-control" onChange={this.handleChange} />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>No. Of Drawer</label>
                  <input type="number" name="PerDrawCost" className="form-control" onChange={this.handleChange} />
                </div>
              </div>
          </div>

          <div className="row">

              <div className="col-md-6">
                <div className="form-group">
                  <label>No. Of Hinge</label>
                  <input type="number" name="ExtraCost" className="form-control" onChange={this.handleChange} />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Assembly Cost (%)</label>
                  <input type="number" name="AssemblyCost" className="form-control" onChange={this.handleChange} />
                </div>
              </div>
          </div>


          <button onClick={this.submit} type="button" className="btn cartbtn purchasebtn">Submit</button>
        </Modal.Body>

      </Modal>

    )

  }
}


class ShelveEditor extends React.Component {
  constructor(props) {
    super(props);

    this.updateData = this.updateData.bind(this);
    this.state = { shelve: props.defaultValue, shelveType: props.row.ShelveId };
  }



  updateData() {
    this.props.onUpdate( {Shelves: this.state.shelve, ShelveId: this.state.shelveType} );
  }

  render() {

    const shelves = [];

    shelves[1] = 'Fixed';
    shelves[2] = 'Adjustable';

    const numbers = [0,1,2,3,4,5,6,7,8,9,10];

    return (
      <span>
        <select
          value={ this.state.shelveType }
          className="form-control"
          onChange={ (ev) => { this.setState( { shelveType: ev.currentTarget.value } ); } } >
          { shelves.map( (shelve, key) => (<option key={ key } value={ key }>{ shelve }</option>)) }
        </select>

        <select
          value={ this.state.shelve }
          className="form-control"
          onChange={ (ev) => { this.setState( { shelve: ev.currentTarget.value } ); } } >
          { numbers.map( (number, key) => (<option key={ number } value={ number }>{ number }</option>)) }
        </select>

        {<button
          className='btn btn-info btn-xs textarea-save-btn'
          onClick={ this.updateData }>
          save
        </button>}
      </span>
    );
  }
}

class BSTable extends React.Component {

  constructor(props) {
      super(props);

      this.state = {
        columnWidth: [],
      }

      this.csvFormatter = this.csvFormatter.bind(this);
      this.formatDrawerSizes = this.formatDrawerSizes.bind(this)
      this.hardwareCostFormatter = this.hardwareCostFormatter.bind(this)
      this.AssemblyCostFormatter = this.AssemblyCostFormatter.bind(this)
      this.drawerStyleFormat = this.drawerStyleFormat.bind(this)
  }

  drawerStyleFormat(cell, row)  {

    let style = "-";

    const drawer = row.DrawerStyle;

    this.props.hardware.map( d => {

      if (d.value == drawer) {
        style = d.text
      }

    });

    return style;

  }

  csvFormatter(cell, row) {
    return `${row.CsvFile == 1 ? 'Yes' : 'No'}`;
  }

formatDrawerSizes(cell, row){
  return '';
  /*
  var drawers = [];
  try{
    drawers = JSON.parse(row.DrawerSize)
  }catch{}
  //console.log(row.DrawerSize)
  //console.log('drawers', row)

  const drawerslist = (Array.isArray(drawers) && drawers.length > 0) ? drawers.map((drawer, index) => {
      return `(${drawer.noOfDrawers}) Drawer ${drawer.faceheight} ${drawer.runnerheight}<br />`
  })  : '';

  return drawerslist
  */

}

hardwareCostFormatter(cell, row){
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(row.HardwareCost);
}

AssemblyCostFormatter(cell, row){
  
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'percent'
  });
  return formatter.format(parseFloat(row.AssemblyCost) / 100);
}


  render() {

    const cellEditProp = {
        mode: 'click',
        blurToSave: true,
        afterSaveCell: this.props.save
    }
    //console.log(this.props)
      let data = [];

      data.push(this.props.data);

      const isAccessories = (this.props.data.ProductId == 30) ? true : false

      const isHidden = (this.props.data.ProductName.indexOf('Kicker') !=  -1 || this.props.data.ProductName.indexOf('Panel') !=  -1 || this.props.data.ProductName.indexOf('Filler') !=  -1) ? false : true

    if (this.props.data) {

      const prod = this.props.product

      const  hasDrawer = () => {
        return prod && prod.hasDrawer == 1 ? true : false
      }

      const  hasInnerDrawer = () => {
        return prod && prod.has_inner == 1 ? true : false
      }
      
      const  hasDoor = () => {
        return prod && prod.hasDoor == 1 ? true : false
      }

      const  hasShelves = () => {
        return prod && prod.hasShelve == 1 ? true : false
      }

      const drawerOptions = this.props.hardware.filter( d => {

        return d.text.indexOf('Drawer') >= 0;


      });
      //console.log('drawerOptions', this.props.hardware);
      /* const drawerOptions = his.props.hardware.filters( d => {

          return {
            value: d.HarwareId,
            text: d.HardwareName
          }

      }); */

      //console.log('drawer options', drawerOptions);

      const isAccessories = (this.props.data.ProductId == 30) ? true : false

      return (
        <BootstrapTable
          cellEdit={cellEditProp}
          data={ data }>
          <TableHeaderColumn
              dataField='ProductStockCodeId'
              hidden={true}
              isKey={true}></TableHeaderColumn>

          <TableHeaderColumn
            dataField="DrawerStyle"
            dataSort={true}
            hidden={!hasDrawer()}
            dataFormat={this.drawerStyleFormat}
            editable={ { type: 'select', options: {values: drawerOptions} } }
          >
          Drawer Hardware Style
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField='DoorReveals'
            hidden={!hasDoor()}
          >
              Door Reveals
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField='DrawerSize'
            hidden={true}
          >
              Drawer Size
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField='Weight'
          >
              Base Item Weight (kg)
          </TableHeaderColumn>

          {/* <TableHeaderColumn
            dataField='CsvFile'
            dataFormat={ this.csvFormatter }
            editable={ { type: 'checkbox', options: { values: '1:0' } } }
          >
              CSV File Created Online
          </TableHeaderColumn> */}


          <TableHeaderColumn
            dataField='HardwareCost'
            dataFormat={ this.hardwareCostFormatter }
              >Hardware Cost {isAccessories ? '(with Tax)' : ''}</TableHeaderColumn>

          <TableHeaderColumn
            dataField='DrawerEqual'
            hidden={!hasDrawer()}
            editable={ { type: 'number'} }
            >Drawer Equal</TableHeaderColumn>

          <TableHeaderColumn
            dataField='DrawerSmall'
            hidden={!hasDrawer()}
            editable={ { type: 'number'} }
            >Drawer Small</TableHeaderColumn>

          <TableHeaderColumn
            dataField='DrawerInner'
            hidden={!hasInnerDrawer()}
            editable={ { type: 'number'} }
            >Drawer Inner</TableHeaderColumn>

            {/*
            <TableHeaderColumn
              dataField='DrawerSize'
              editable={false}
              hidden={!hasDrawer()}
              dataFormat={ this.formatDrawerSizes }
              >Drawer Sizes </TableHeaderColumn>
              */}
          <TableHeaderColumn
            dataField='ExtraCost'
            editable={ { type: 'number'} }
            hidden={!hasDoor()}
          >No. Of Hinges</TableHeaderColumn>
          <TableHeaderColumn
            dataField='AssemblyCost'
            dataFormat={this.AssemblyCostFormatter}
            editable={ { type: 'number'} } >Assembly Cost (%)</TableHeaderColumn>

          <TableHeaderColumn
              hidden={isHidden}
              dataField='noShortEdges'
              editable={ { type: 'number'} } >No of Short Edges</TableHeaderColumn>

          <TableHeaderColumn
                hidden={isHidden}
                dataField='noLongEdges'
                editable={ { type: 'number'} } >No of Long Edges</TableHeaderColumn>

        </BootstrapTable>

      );
    } else {
      return (<p>?</p>);
    }
  }
}

class BSTable2 extends React.Component {

  constructor(props) {
      super(props);

      this.state = {
      }
  }



  render() {

      const cellEditProp = {
          mode: 'click',
          blurToSave: true,
          afterSaveCell: this.props.save
      }

      const data = [];

      data.push(this.props.data)

      //console.log('expand', data);

    if (this.props.data) {
      return (
        <BootstrapTable
          cellEdit={cellEditProp}
          data={ data }>
          <TableHeaderColumn
              dataField='ProductStockCodeId'
              hidden={true}
              isKey={true}></TableHeaderColumn>

        </BootstrapTable>);
    } else {
      return (<p>?</p>);
    }
  }
}



export default StockCodeList
