import React, { Component } from 'react';
import config from '../config';

class LastOrder extends Component {

    constructor(props) {

        super(props);

        this.state = {
            status: "last-order hidden",
            index: 0,        
            products: props.products    
        }

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
    }

    show() {

        let index = this.state.index;
        console.log('this', this.props);

        if (index < this.props.products.length) index++; else index = 0;

        this.setState( { status: "last-order", index: index }, () => {
            setTimeout( this.hide, 10000)
        } );
    }

    hide() {
        this.setState( { status: "last-order hidden" }, () => {
            setTimeout( this.show, 3000 )
        } );
    }

    componentDidMount() {

        setTimeout( this.show, 3000);
    }

    render() {   

        const product = this.props.products ? this.props.products[this.state.index] : [];

        const ago = product && product.OrderDate > 1 ? product.OrderDate + ' days ago' : '1 day ago';

        const image = product && product.ImagePath ? config.BASE_URL + product.ImagePath : '';

        const imgClass = `image ` + (product && product.slug ? product.slug : '');

        console.log('image', image, product, this.state);

        return (

            <>
                <div className={this.state.status}>

                    <div className="order-details">

                        <div className={imgClass}>

                            <img src={image} />

                        </div>

                        <div className="info">

                            <p>Some purchased a</p>

                            <div className="product-name">
                                {product ? product.ProductName : ''}                                
                            </div>

                            <div className="order-date">
                                {ago}
                            </div>

                        </div>

                    </div>

                    <button onClick={this.hide} className="close-button"><i className="fa fa-times" /></button>

                </div>
            </>

        )

    }

}

export default LastOrder