import React from 'react';
//import GooglePlacesAutocomplete, {geocodeByPlaceId} from 'react-google-places-autocomplete';
import config from '../config';
import { strings } from './strings';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
class OrderAddress extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
    			invoiceAddLabel: props.customer.InvoiceAddress,
    			invoiceAddress: [],
    			shippingAddLabel: props.customer.ShippingAddress,
    			shippingAddress: {
            street_num: '',
            route: '',
            city: '',
            postcode: '',
            aState: 'WA',
            country: 'AU',
          },
    			isPickup: false,
    			sameAsInvoice: false,
          countries: [],
          pickupoptions: [],
          pickupOption: 'By Van',
          customAddress: false
        }
    		this.onSelectInvoiceAddress = this.onSelectInvoiceAddress.bind(this);
        this.getPickupOptions = this.getPickupOptions.bind(this);
        this.changeOption = this.changeOption.bind(this);
        this.getcountries = this.getcountries.bind(this);
        this.handleChangeShipping = this.handleChangeShipping.bind(this);
        this.onBlur = this.onBlur.bind(this);
        //this.onChangeShipping = this.onChangeShipping.bind(this);
        this.onCheckPickUp = this.onCheckPickUp.bind(this);

        this.handleChangeInvoice = this.handleChangeInvoice.bind(this);
        this.handleSelectInvoice = this.handleSelectInvoice.bind(this);
        this.handleBlurInvoice = this.handleBlurInvoice.bind(this);

        this.handleChangeShipping = this.handleChangeShipping.bind(this);
        this.handleSelectShipping = this.handleSelectShipping.bind(this);
        this.handleBlurShipping = this.handleBlurShipping.bind(this);
        this.onShippingError = this.onShippingError.bind(this);
    }

    onShippingError(){
      this.setState({
        customAddress: true
      }, () => {

      })
    }

    handleBlurInvoice = e =>{
      const address = e.target.value
      geocodeByAddress(address)
        .then(results => this.onSelectInvoiceAddress(results))
        .catch(error => console.error('Error', error));
    }
    handleChangeInvoice = address => {
      this.setState({ invoiceAddLabel: address });
    };

    handleSelectInvoice = address => {
      geocodeByAddress(address)
        .then(results => this.onSelectInvoiceAddress(results))
        .catch(error => console.error('Error', error));
    };

    handleBlurShipping = e =>{
      const address = e.target.value
      geocodeByAddress(address)
        .then(results => this.onSelectShippingAddress(results))
        .catch(error => this.onShippingError())
    }
    handleChangeShipping = address => {
      this.setState({ shippingAddLabel: address });
    };

    handleSelectShipping = address => {
      geocodeByAddress(address)
        .then(results => this.onSelectShippingAddress(results))
        .catch(error => this.onShippingError())
    };

    onCheckPickUp = (ev) => {
      this.setState({
  		    [ev.target.name]: ev.target.checked ? true : false
      }, () => {
        this.props.onCheckPickUp(this.state)
      })
    }

    onBlur(){
      const {shippingAddress} = this.state
      if (this.state.shippingAddress.postcode == '') {
        this.setState({
  				customAddress: true
  			}, () => {

  			})
      }
    }
	getAddresscompoment(comType, Address){
		for (var i=0; i < Address.length; i++) {
			if (Address[i].types.indexOf(comType) > -1) {
				//console.log(Address[i]);
				return Address[i];
			}
		}
	}
  onSelectInvoiceAddress = data => {
      var street_number = this.getAddresscompoment('street_number', data[0].address_components);
      var route = this.getAddresscompoment('route', data[0].address_components);
      var aState = this.getAddresscompoment('administrative_area_level_1', data[0].address_components);
      var city = this.getAddresscompoment('locality', data[0].address_components);
      var postal_code = this.getAddresscompoment('postal_code', data[0].address_components);
      var country = this.getAddresscompoment('country', data[0].address_components);
      this.setState({
        invoiceAddLabel: data[0].formatted_address,
        invoiceAddress: {
          street_num: (street_number !== undefined) ? street_number.short_name : '',
          route: (route !== undefined) ? route.short_name : '',
          city: (city !== undefined) ? city.short_name : '',
          postcode: (postal_code !== undefined) ? postal_code.short_name : '',
          aState: (aState !== undefined) ? aState.short_name : '',
          country: (country !== undefined) ? country.short_name : ''
        }
      }, () => {
        this.props.onSelectAddress(this.state, false);
      })
  }
  onSelectShippingAddress = (data) => {
			var street_number = this.getAddresscompoment('street_number', data[0].address_components);
			var route = this.getAddresscompoment('route', data[0].address_components);
			var aState = this.getAddresscompoment('administrative_area_level_1', data[0].address_components);
			var city = this.getAddresscompoment('locality', data[0].address_components);
			var postal_code = this.getAddresscompoment('postal_code', data[0].address_components);
			var country = this.getAddresscompoment('country', data[0].address_components);
			this.setState({
        customAddress: false,
				shippingAddLabel: data[0].formatted_address,
				shippingAddress: {
					street_num: (street_number !== undefined) ? street_number.short_name : '',
					route: (route !== undefined) ? route.short_name : '',
					city: (city !== undefined) ? city.short_name : '',
					postcode: (postal_code !== undefined) ? postal_code.short_name : '',
					aState: (aState !== undefined) ? aState.short_name : '',
					country: (country !== undefined) ? country.short_name : '',
				}
			}, () => {
				this.props.onSelectAddress(this.state, true);
			})
	}
    /*
	onSelectInvoiceAddress = async(address) => {
    console.log('addrses', address)
		geocodeByPlaceId(address.place_id)
		.then(data => {
			var street_number = this.getAddresscompoment('street_number', data[0].address_components);
			var route = this.getAddresscompoment('route', data[0].address_components);
			var aState = this.getAddresscompoment('administrative_area_level_1', data[0].address_components);
			var city = this.getAddresscompoment('locality', data[0].address_components);
			var postal_code = this.getAddresscompoment('postal_code', data[0].address_components);
			var country = this.getAddresscompoment('country', data[0].address_components);
			this.setState({
				invoiceAddLabel: data[0].formatted_address,
				invoiceAddress: {
					street_num: (street_number !== undefined) ? street_number.short_name : '',
					route: (route !== undefined) ? route.short_name : '',
					city: (city !== undefined) ? city.short_name : '',
					postcode: (postal_code !== undefined) ? postal_code.short_name : '',
					aState: (aState !== undefined) ? aState.short_name : '',
					country: (country !== undefined) ? country.short_name : ''
				}
			}, () => {
				this.props.onSelectAddress(this.state, false);
			})
		})
		.catch(error => console.log(error));
	}
  */

/*
	onSelectShippingAddress = async(address) => {
    console.log('address address address address', address)
		geocodeByPlaceId(address.place_id)
		.then(data => {
			var street_number = this.getAddresscompoment('street_number', data[0].address_components);
			var route = this.getAddresscompoment('route', data[0].address_components);
			var aState = this.getAddresscompoment('administrative_area_level_1', data[0].address_components);
			var city = this.getAddresscompoment('locality', data[0].address_components);
			var postal_code = this.getAddresscompoment('postal_code', data[0].address_components);
			var country = this.getAddresscompoment('country', data[0].address_components);
			this.setState({
        customAddress: false,
				shippingAddLabel: data[0].formatted_address,
				shippingAddress: {
					street_num: (street_number !== undefined) ? street_number.short_name : '',
					route: (route !== undefined) ? route.short_name : '',
					city: (city !== undefined) ? city.short_name : '',
					postcode: (postal_code !== undefined) ? postal_code.short_name : '',
					aState: (aState !== undefined) ? aState.short_name : '',
					country: (country !== undefined) ? country.short_name : '',
				}
			}, () => {
				this.props.onSelectAddress(this.state, true);
			})
		})
		.catch(error => console.log(error));
	}
*/
  handleChangeShipping(e){
    var shippingAddress = this.state.shippingAddress
    shippingAddress[e.target.name] = e.target.value
    this.setState({
      shippingAddress: shippingAddress
    }, () => {
      //console.log(this.state)
      this.props.onSelectAddress(this.state, true);
    })
  }
  getPickupOptions(){
    fetch(config.BASE_URL + '/api/getpickupoptions', {
				method: "GET",
				headers: {
			  			'Content-Type': 'application/json',
			  			'Accept': 'application/json'
					}
				})
				.then(data => data.json())
				.then(data => {
					this.setState({pickupoptions: data.pickupoptions});
				})
				.catch(error => console.log(error));
	}

  getcountries(){
    fetch(config.BASE_URL + '/api/getcountries', {
				method: "GET",
				headers: {
			  			'Content-Type': 'application/json',
			  			'Accept': 'application/json'
					}
				})
				.then(data => data.json())
				.then(data => {
					this.setState({countries: data.items});
				})
				.catch(error => console.log(error));
	}

	componentDidMount() {
    this.getPickupOptions();
    this.getcountries();
	}

	handleChangeCheck = (ev) => {
    if (this.state.invoiceAddLabel == '') {
      return;
    }

		if (ev.target.checked){
			this.setState({
				[ev.target.name]: true
			}, () => {
				this.setState({
					shippingAddLabel: (this.state.sameAsInvoice) ? this.state.invoiceAddLabel : this.state.shippingAddLabel,
					shippingAddress: (this.state.sameAsInvoice) ? this.state.invoiceAddress : this.state.shippingAddress,
          customAddress: false
				}, () => {
					this.props.onSelectAddress(this.state, true);
				});
			});

		}else{
			this.setState({
				[ev.target.name]: false
			}, () => {
				this.props.onSelectAddress(this.state, true);
			});
		}

	}

  changeOption(ev){
    this.props.onChangeOption(ev.target.value);
  }

  handleChange = address => {
    this.setState({
        invoiceAddLabel: address
    }, () => {
      console.log('invoiceAddLabel', address)
    })
  };

  render() {

    const {pickupoptions, shippingAddress, countries, customAddress} = this.state

    const {customDelivery} = this.props

    const country = countries.filter( s => {
        return s.code == shippingAddress.country;
    });

    const states = country[0] ? country[0].states : []



		return (
			<>

      <div className="form-group" style={{marginBottom: "10px"}}>

      <PlacesAutocomplete
        value={this.state.invoiceAddLabel}
        onChange={this.handleChangeInvoice}
        onSelect={this.handleSelectInvoice}
        debounce={400}
        >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                  <input
                    {...getInputProps({
                      placeholder: 'Invoice Address',
                      className: 'location-search-input form-control'
                    })}
                    onBlur={this.handleBlurInvoice}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            {/*
				  <GooglePlacesAutocomplete
					idPrefix="invoiceAdd"
					required={true}
					onSelect={this.onSelectInvoiceAddress}
          onChange={this.onAddressChange}
					inputClassName="form-control"
					placeholder="Invoice Address"
					autocomplete="off"
					initialValue = {this.state.invoiceAddLabel}
          onComplete={this.onComplete}
					debounce={400}

				  /> */}
              </div>

               <div className="form-group greenCheckBox" style={{marginBottom: "0px"}}>
		<label className="form-check-input"><input onChange={this.onCheckPickUp} type="checkbox" name="isPickup" value="1" /><span className="checkmark1"></span>{strings.PickupfromOffice}</label>
              </div>
				{ (!this.state.isPickup)? (
				<>
				  <div className="form-group" style={{marginBottom: "10px"}}>
          <PlacesAutocomplete
            value={this.state.shippingAddLabel}
            onChange={this.handleChangeShipping}
            onSelect={this.handleSelectShipping}
            debounce={400}
            >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                      <input
                        {...getInputProps({
                          placeholder: 'Shipping Address',
                          className: 'location-search-input form-control'
                        })}
                        onBlur={this.handleBlurShipping}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
          {/*
					  <GooglePlacesAutocomplete
						idPrefix="shippingAdd"
						onSelect={this.onSelectShippingAddress}
						inputClassName="form-control"
						placeholder="Shipping Address"
						autocomplete="off"
						initialValue = {this.state.shippingAddLabel}
						debounce={400}
						autocompletionRequest={{
							componentRestrictions: {
							  country: ['au'],
							}
						  }}
            renderInput={(props) => (
                <input
                  {...props}
                  className="form-control"
                  placeholder="Shipping Address"
                  onBlur={this.onBlur}
                />
            )}
					  />
            */}
				  </div>
          {
            customAddress ?
          <div>{/*
            <div className="form-group" style={{marginBottom: "10px", padding: '0px'}}>
              <input type="text" placeholder="Street" className="form-control" name="Street" />
            </div> */}
            <div className="form-group col-md-6" style={{marginBottom: "10px", paddingLeft: '0px'}}>
              <input onChange={this.handleChangeShipping}  type="text" placeholder="City" defaultValue={shippingAddress.city} className="form-control" name="city" />
            </div>
            <div onChange={this.handleChangeShipping}  className="form-group col-md-6" style={{marginBottom: "10px", padding: '0px'}}>
              <input type="text" onChange={this.handleChangeShipping}  defaultValue={shippingAddress.postcode} placeholder="Zip Code" className="form-control" name="postcode" />
            </div>
            <div className="form-group col-md-6" style={{marginBottom: "10px", paddingLeft: '0px'}}>
              <select name="aState" onChange={this.handleChangeShipping} className="form-control">
              {
                  states && states.length > 0 ? states.map((c, key) => (
                    <option key={key}
                      value={c.code ? c.code : c.name} selected={c.code == shippingAddress.aState}>{c.name}</option>

                  )) : ""
              }
              </select>
            </div>
            <div className="form-group col-md-6" style={{marginBottom: "10px", padding: '0px'}}>
              <select onChange={this.handleChangeShipping}  className="form-control" name="country">
              {
                  countries && countries.length > 0 ? countries.map((c, key) => (
                    <option key={key}
                      value={c.code} selected={c.code == shippingAddress.country}>{c.name}</option>

                  )) : ""
              }
              </select>
            </div>
          </div>
          : ''
        }

        {customDelivery ?
        <div class="alert alert-danger" role="alert">{strings.CustomDelivery}</div>
        : ''
        }
				   <div className="form-group greenCheckBox" style={{marginBottom: "10px"}}>
					   <label className="form-check-input"><input onChange={this.handleChangeCheck} type="checkbox" name="sameAsInvoice" value="1" /><span className="checkmark1"></span> {strings.SameasInvoiceAddress}</label>
				  </div>
				</>
				) : (
          <div className="form-group" style={{marginBottom: "10px"}}>
          <div className="door-select" style={{paddingTop: '0px'}}>
					<select className="form-control" style={{borderColor: '#DDD'}} onChange={this.changeOption}>
          {
              pickupoptions && pickupoptions.length > 0 ? pickupoptions.map((option, key) => (
                <option key={key}
                  value={option.optionname}>{option.optionname}</option>

              )) : ""
          }
          </select>

          </div>
          </div>
				)}
			</>
		);
  }
}
export default OrderAddress;
