import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "../node_modules/react-bootstrap-table/css/react-bootstrap-table.css";
import './App.css';
import ProductPage from './containers/ProductsPage';
import Jobs from './admin/Jobs';
import Users from './admin/Users';
import Login, {ResetPassword, ResetValidate} from './admin/Login';
import {PrivateRoute, PrivateRouteCustomer} from './components/PrivateRoute'
import Dashboard from './containers/Dashboard';
import Products from './admin/Products';
import EditOrder from './containers/EditOrder';
import Customers from './containers/Customers';
import MaterialList from './admin/MaterialList';
import StockCodeList from './admin/StockCodeList';
import Activity from './admin/Activity';
import AdminMenus from './admin/AdminMenus';
import Contact from './containers/Contact';
import About from './containers/About';
import News from './containers/News';
import Partners from './containers/Partners';
//import CustomerList from './admin/CustomerList';
import CustomerListNew from './admin/CustomerListNew';
import Master from './admin/Master';
import Messages from './admin/Messages';
import ImageTips from './admin/ImageTips';
import SlangTranslation from './admin/SlangTranslation';
import Outstanding from './admin/Outstanding';
import ViewOrder from './components/Dashboard/ViewOrder';
import ViewOrderAdmin from './components/ViewOrderAdmin';
import Zipcodes from './admin/Zipcodes';
import Home from './containers/Home';

import Metatags, {RenderMetaTags} from './admin/Metatags';

import Suppliers from './admin/Suppliers';

class App extends Component {

  render() {
    return (
      <div key="App">
        <RenderMetaTags />
        <Router>
          <Switch>
            <Route path="/" exact component={ProductPage} />
            <Route path="/:prodid(\d+)/:prodsku" exact component={ProductPage} />
            <Route path="/activate/:customerid(\d+)/:activationcode" exact component={ProductPage} />
			      <Route path="/:prodid(\d+)" exact component={ProductPage} />
			      <Route path="/login" exact component={Login} />
			      <Route path="/resetpassword" exact component={ResetPassword} />
			      <Route path="/resetvalidate/:email/:resetkey" exact component={ResetValidate} />
            <Route path="/contact-us" exact component={Contact} />
            <Route path="/about-us" exact component={About} />
            <Route path="/news" match component={News} />

            <Route path="/home" match component={Home} />

            {/* <Route path="/admin/customer-list-old" exact component={CustomerList} /> */}
            <Route path="/admin/customer-list" exact component={CustomerListNew} />
            <Route path="/admin/outstanding" exact component={Outstanding} />
            <Route path="/admin/suppliers" exact component={Suppliers} />
            <Route path="/partners/:slug" exact component={Partners} />

            <PrivateRoute path="/admin" exact component={Jobs} />

            <Route path="/admin/menus" exact component={AdminMenus} />

            <PrivateRoute path="/admin/jobs" exact component={Jobs} />
            <PrivateRoute path="/admin/products" exact component={Products} />

            <PrivateRoute path="/admin/masters" exact component={Master} />
            <PrivateRoute path="/admin/zipcodes" exact component={Zipcodes} />

            <PrivateRoute path="/admin/material-list" exact component={MaterialList} />
            <PrivateRoute path="/admin/stock-code-list" exact component={StockCodeList} />
			      <PrivateRoute path="/admin/users" exact component={Users} />
            <PrivateRoute path="/admin/activity" exact component={Activity} />
			      <PrivateRoute path="/admin/messages" exact component={Messages} />
			      <PrivateRoute path="/admin/imagetips" exact component={ImageTips} />
			      <PrivateRoute path="/admin/slangtranslation" exact component={SlangTranslation} />
            <PrivateRoute path="/admin/metatags" exact component={Metatags} />
            <PrivateRoute path="/admin/view-order/:id" exact component={ViewOrderAdmin} />
            
			      <PrivateRouteCustomer path="/customer" exact component={Customers} />
			      <PrivateRouteCustomer path="/customer/profile" exact component={Customers} />
            <PrivateRouteCustomer path="/dashboard" exact component={Dashboard} />

			      <PrivateRouteCustomer path="/dashboard/:id" exact component={Dashboard} />
            <PrivateRouteCustomer path="/dashboard/edit-order/:id" exact component={EditOrder} />
            <PrivateRouteCustomer path="/dashboard/view-order/:id" exact component={ViewOrder} />

            <PrivateRouteCustomer path="/dashboard/edit-order/:id/:prodid/:prodsku" exact component={EditOrder} />
            <PrivateRouteCustomer path="/dashboard/edit-order/:id/:prodid" exact component={EditOrder} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
