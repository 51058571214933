import React from 'react';
import config from '../config';
import { strings } from './strings';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
  StripeProvider,
  Elements,
} from 'react-stripe-elements';

class CardSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			savingProgress: false,
			errorMessage: (props.errorMessage) ? props.errorMessage : '',
			hasError: (props.hasError) ? props.hasError : false,
			paymentComplete: (props.paymentComplete) ? props.paymentComplete : false,
        }

        this.savePaymentDetails = this.savePaymentDetails.bind(this);
    }

    componentWillReceiveProps(newprops){
        this.setState({
			errorMessage: (newprops.errorMessage) ? newprops.errorMessage : '',
			hasError: (newprops.hasError) ? newprops.hasError : false,
			paymentComplete: (newprops.paymentComplete) ? newprops.paymentComplete : false
        },()=>{
			//console.log(this.state);
        })
    }

 savePaymentDetails =(event) => {
	event.preventDefault();
	this.setState({savingProgress: true});

	const {cvc, payType, expYear, expMonth, cardNumber} = this.state

    var data = {
		cvc: cvc,
		payType: payType,
		expYear: expYear,
		expMonth: expMonth,
		cardNumber: cardNumber
    }

	fetch(config.BASE_URL + '/api/savepaymentdetails', {
		method: "POST",
		body: JSON.stringify(data),
		headers: {
			  'Content-Type': 'application/json',
			  'Accept': 'application/json'
		}
	})
	.then(data => data.json())
	.then(data => {
		this.setState({savingProgress: false});
	})
	.catch(error => console.log(error));
 }
  render() {
    return (
					<div className="col-md-12">

					{/* (this.state.hasError)? (
					<div className="alert alert-danger" role="alert">
					{this.state.errorMessage}
					</div>
					) : (
					''
        )*/}
					{ (this.state.paymentComplete)? (
					<div className="alert alert-success" role="alert">
					Payment is successful, redirecting to dashboard...
					</div>
					) : (
					''
					)}

						<div className="row">

							<div className="col-md-12">

									{/*<h2>{strings.PaymentDetails}</h2>*/}

									<div>

										<div className="radio-button" style={ {display:'none'} }>
											<input name="payment-type" onChange={this.handleChangeRadio} value="stripe" type="radio" defaultChecked={true} style={{display: "inline-block"}} />
											{strings.AmountDue}
										</div>


							  		<div className="mt-5">
								  		<div className="row">
									  		<div className="col-xs-12 col-md-12">
										  		<div className="form-group">
											 		{/* <p className="payment-form-subheading">Pro Plan</p> */ }
											  		<h2>Credit Card Details</h2>
											  		<div className="input-group">
														<label>
														<CardNumberElement
															placeholder="Card number"
														onChange={this.handleChange}
														/>
												  		</label>
											  		</div>
										  		</div>
									  		</div>

									  		<div className="col-xs-6 col-md-6">
										  		<div className="form-group">
													<label>
														<CardCVCElement
														  onChange={this.handleChange}
														/>
													</label>
										  		</div>
									  		</div>

									  		<div className="col-xs-6 col-md-6">
												<div className="form-group">
													<label>
														<CardExpiryElement
														  onChange={this.handleChange}
														/>
													</label>
												</div>
									  		</div>
								  		</div>
							  		</div>


							  <div className="panel-footer">
								  <div className="row">
									{/*
									  <div className="col-xs-12 col-md-12"  style={{padding: "0px"}}>
										<button type="button" onClick={event => this.savePaymentDetails(event)} className="btn btn-success btn-md btn-block">SAVE</button>
									  </div>
									  */}
									  <div className="col-xs-12 col-md-12"  style={{padding: "0px"}}>
										<img src="/images/badge.png" />
									  </div>
								  </div>
							  </div>

							</div>

						</div>


						</div>

						<div className="d-none" style={{padding: "0px"}}>
							<input onChange={this.handleChangeRadio} name="payment-type" value="custom" type="radio" />
							 <h4 className="payment-type-heading">Use my Flatpackswa Account Number</h4>

							  <div className="panel-body mt-5">
								  <div className="row">
									  <div className="col-xs-12 col-md-12">
									</div>
								</div>
								</div>

						</div>
					</div>

    );
  }
}

export default CardSection;
