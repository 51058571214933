import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';
import config from "../config";
class ColorSwatches extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openSwatches: false,
		}
		
		this.openSwatches = this.openSwatches.bind(this);
		this.closeSwatches = this.closeSwatches.bind(this);

	}
	closeSwatches() {
		this.setState({ openSwatches: false});
	}
  
	openSwatches() {
		this.setState({ openSwatches: true});
	}
	onSelectImage = (colour, SectionId) => {
		this.props.onSelectImage(colour, SectionId)
		this.setState({ openSwatches: false});
	}
	componentDidMount() {

	}

	render() {
		return (
		<>
			<button onClick={this.openSwatches} style={{display: 'block', marginTop: '10px', backgroundColor: 'transparent', borderColor: 'transparent', color: 'green'}} href="#colourswatches"><i className="fa fa-search-plus 2x" aria-hidden="true"></i>Colour Swatches</button>
			<ColorSwatchesModal key={1} colours={this.props.colours} openSwatches={this.state.openSwatches} closeSwatches={this.closeSwatches} onSelectImage={this.onSelectImage} SectionId={this.props.SectionId} />
		</>	
		)
	}
}


class ColorSwatchesModal extends Component {
	constructor(props) {
		super(props)
		this.state = {
			colours: (props.colours) ? props.colours : [],
			SectionId: props.SectionId
		}

	}
	
	onSelectImage = (colour, SectionId) =>{
		this.props.onSelectImage(colour, SectionId)
	}
	
	render() {
		
		//console.log('this.props.colours', this.props.colours)
		
		return (
			<Modal
			  show={this.props.openSwatches}
			  onHide={this.props.closeSwatches}
			  className="ColorSwatchesModalModal"
			>
			<Modal.Header closeButton> 
			</Modal.Header>
			<Modal.Body>
				<div>
				<ul className="imageColourSwatchesList">
				{
					this.props.colours && this.props.colours.length > 0 ? this.props.colours.map((colour) => (
						<li><img onClick={() => this.onSelectImage(colour, this.props.SectionId)} src={config.UPLOAD_URL+colour.image} style={{maxWidth: '100px'}} alt={colour.label} title={colour.label} /></li>

					)) : ""
				}				
	
				</ul>
				</div>
			</Modal.Body>

		  </Modal>

		)

  }

}
export default ColorSwatches;
