import React, { Component } from 'react';
import Modal from "react-bootstrap-modal";
import { strings } from '../../components/strings';

class CreateProjectModal extends Component {

    constructor(props) {
  
      super(props);
  
      this.state = {
        name: '',
        inpClass : 'form-control',
        errorClass: 'hidden'
      }
  
      this.save = this.save.bind(this);
      this.handleAddress = this.handleAddress.bind(this);
  
    }
  
    handleAddress(e) {
      this.setState(
        {
          name: e.target.value,
          inpClass: 'form-control',
          errorClass: 'hidden',
        }
      )
    }
  
    save() {
      const name = this.state.name;
      let valid = true;
  
      if (name === '') {
  
        valid = false
  
        this.setState(
          {
            inpClass: 'form-control border border-danger',
            errorClass: 'error'
          }
        )
      }
  
      if (valid) {
        this.setState({ name: ''});
        this.props.submit(this.state);
      }
    }
  
  
    render() {
  
      return (
        <Modal
            show={this.props.open}
            onHide={this.props.close}
            className="CreateProjectModal"
         >
  
           <Modal.Header closeButton>Create New Project</Modal.Header>
  
            <Modal.Body>
  
              <div className="form-group">
                <input type="text"  className={this.state.inpClass} name="projectName" placeholder="Enter Project Name" onChange={this.handleAddress} />
                <span className={this.state.errorClass}>{strings.Pleaseenterprojectname}</span>
              </div>
  
              <button className="btn cartbtn purchasebtn d-block upsize" onClick={this.save}>Create Project</button>
  
            </Modal.Body>
  
        </Modal>
  
      )
    }
  }

export default CreateProjectModal;