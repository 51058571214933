import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Form, Button } from 'react-bootstrap';
import config from "../config";
import HeaderAdmin from '../components/HeaderAdmin';
import FooterAdmin from '../components/FooterAdmin';
import Modal from 'react-bootstrap-modal';
import Dropzone from 'react-dropzone'
import axios from 'axios';
import swal from "sweetalert";

import SupplierSamples from './SupplierSamples';

import { strings } from '../components/strings';

//import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';

export default class Suppliers extends Component {

    constructor(props) {

        super(props);

        const authuser = JSON.parse(localStorage.getItem('authuser'));

        this.state = {
            suppliers: [],
            totalSize: 0,
            page: 1,
            sizePerPage: 10,
            searchtext: '',
            currentIndex: 0,
            sortField: 'supplierName',
            sortOrder: 'asc',
            addFailed: false,
            AuthUser: (authuser) ? authuser[0] : [],
            columnWidth: [0, 15, 15, 15, 15, 15, 15, 10],
        }

        this.getSuppliersData = this.getSuppliersData.bind(this);

        this.save = this.save.bind(this);
        this.expandComponent = this.expandComponent.bind(this);

        this.onSortChange = this.onSortChange.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.saveSupplier = this.saveSupplier.bind(this);
        this.samplesButton = this.samplesButton.bind(this);
        this.statusFormatter = this.statusFormatter.bind(this);
        this.actionFormatter = this.actionFormatter.bind(this);
        this.delete = this.delete.bind(this);
        this.deleteSupplier = this.deleteSupplier.bind(this);
    }



    samplesButton(cell, row) {
        return (
            <>
                <SupplierSamples supplier={row} authuser={this.state.AuthUser} />
            </>
        )
    }

    saveSupplier(data) {

        const user = this.state.AuthUser;
        data.supplierCreateBy = user.Name
        fetch(config.BASE_URL + '/api/addsupplier', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json',
        		"Authorization": 'Bearer ' + user.token
            }
        })
        .then(response => response.json())
        .then( (response) => {
          this.setState( { open: false } );
          this.getSuppliersData();
        }).catch(error => console.log(error));
    }

    open() {
        this.setState( { open: true } );
    }

    close() {
        this.setState( { open: false } );
    }

    save(rowData, cellName, cellValue){

        const user = this.state.AuthUser;

        rowData.supplierModifiedBy = user.Name
        fetch(config.BASE_URL + '/api/updatesupplier', {
            method: 'POST',
            body: JSON.stringify(rowData),
			headers: {
				"Content-Type": "application/json",
				'Accept': 'application/json',
        		"Authorization": 'Bearer ' + user.token
				}
        }).then(function(response){
            this.getSuppliersData();
        }).catch(error => console.log(error));

    }

    getSuppliersData(){

        var authuser = this.state.AuthUser;

        fetch(config.BASE_URL + '/api/getsuppliers', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {

            this.setState({
                suppliers : data.suppliers
            }, () => {

            });
        })
        .catch(error => console.log(error));
    }

    isExpandableRow(row) {
        return false
    }

    expandComponent(row) {

        return (
            <>
                <ChatBox data={row} user={this.state.AuthUser} />
            </>
        );
    }


    componentDidMount() {
        this.getSuppliersData();
    }

	onSortChange(sortName, sortOrder) {
		localStorage.setItem("SupplierSort", JSON.stringify({
		sortName: sortName,
		sortOrder: sortOrder
		}));
	}
  deleteSupplier(data) {
	var authuser = this.state.AuthUser;
    fetch(config.BASE_URL + '/api/deletesupplier', {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
		  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
        }
      })
      .then(data => data.json())
      .then(data => {
        this.getSuppliersData();
      })
      .catch(error => console.log(error));
  }
  delete(row) {
    swal({
      title: `${strings.Areyousure}`,
      text: "Once deleted, you will not be able to recover this record",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
         this.deleteSupplier(row)
      } else {
          return false;
      }
  });
  }

    statusFormatter(cell, row){
      return cell === 1 ? 'Active' : 'Inactive'
    }

    actionFormatter(cell, row) {
  		return(
        <>
        <SupplierSamples supplier={row} authuser={this.state.AuthUser} />
        <button className="link-button" onClick={() => this.delete(row)}>
  					<i className="fa fa-trash" aria-hidden="true"></i>
  				</button>
        </>
      )
    }

    render() {

    const {suppliers} = this.state
		var SupplierSort = localStorage.getItem('SupplierSort');

		if (SupplierSort) {
			SupplierSort = JSON.parse(SupplierSort)
		}

        const options = {
            prePage: "Previous", // Previous page button text
            nextPage: "Next", // Next page button text
            onRowClick: this.onRowClick,
            noDataText: "No records found",
            that: this, // <- needed this "this" in the search event handler
            sizePerPage: 25,
            defaultSortName: (SupplierSort) ? SupplierSort.sortName : null,
            defaultSortOrder: (SupplierSort) ? SupplierSort.sortOrder : null,
            onSortChange: this.onSortChange,
            sizePerPageList: [
                {
                    text: "5",
                    value: 5
                },
                {
                    text: "10",
                    value: 10
                },
                {
                    text: "25",
                    value: 25
                },
                {
                    text: "50",
                    value: 50
                }
            ]
        };

        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            afterSaveCell: this.save
        }

        const statusOptions = [
          {text: 'Active', value: 1},
          {text: 'Inactive', value: 0}
        ]
        return (

            <div className="admin-wrapper">
            <HeaderAdmin />
            <div className="dashboard-wrapper partscontent">
                <div className="dashboardhome">
                    <div className="container-fluid dashboard-content jobspagecnt">
                        {/* ============================================================== */}
                        {/* pagecontent  */}
                        {/* ============================================================== */}
                        <div className="row">
                        <div className="col-md-12">
                            <ol className="breadcrumb breadm">
                                <li><a href="/admin">Admin</a></li>
                                <li ><i className="fa fa-angle-right midarrow" /><button className="link-button" style={{ cursor: "pointer" }}>Suppliers List</button></li>
                            </ol>
                        </div>
                        </div>
                        <div className="customer-section row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                {/* top heading section*/}
                                <div className="inner-pagehead">
                                    <h1>Suppliers List</h1>
                                </div>
                                  <div className="products-table">

                                    <BootstrapTable
                                        ref='table'
                                        data={suppliers}
                                        pagination={true}
                                        options={options}
                                        search={true}
                                        hover={true}
                                        cellEdit={cellEditProp}
                                        trClassName={this.trClassFormat}>
                                        <TableHeaderColumn
                                            hidden={true}
                                            dataField='supplierId'
                                            dataSort={true}
                                            width={this.state.columnWidth[0] + '%'}
                                            isKey={true}>
                                                ID
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField='supplierName'
                                            dataSort={true}
                                            editable={true}
                                            width={this.state.columnWidth[1] + '%'}
                                            >Name
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField='supplierEmail'
                                            dataSort={true}
                                            editable={true}
                                            width={this.state.columnWidth[2] + '%'}
                                            >Email</TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField='supplierContact'
                                            dataSort={true}
                                            editable={true}
                                            width={this.state.columnWidth[3] + '%'}
                                            >Contact No</TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField='supplierTypeService'
                                            dataSort={true}
                                            width={this.state.columnWidth[4] + '%'}
                                            >Services</TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField='supplierAddress'
                                            dataSort={true}
                                            width={this.state.columnWidth[5] + '%'}
                                            >Address</TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField='supplierStatus'
                                            width={this.state.columnWidth[6] + '%'}
                                            dataFormat={ this.statusFormatter }
                                            editable={ { type: 'select', options: { values: statusOptions} } }
                                            dataSort={true}
                                            >Status</TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataSort={false}
                                            editable={false}
                                            headerAlign='center'
                                            dataAlign='center'
                                            dataFormat={this.actionFormatter}
                                            width={this.state.columnWidth[8] + '%'}
                                            >
                                            <button onClick={this.open} className="btn add-btn purchasebtn"><i className="fa fa-plus" /></button>
                                            </TableHeaderColumn>
                                    </BootstrapTable>
                                    <AddModal open={this.state.open} addFailed={this.state.addFailed} close={this.close} save={this.saveSupplier} />
                                </div>




                            </div>
                        </div>
                        {/* ============================================================== */}
                        {/* end pagecontent  */}
                        {/* ============================================================== */}
                    </div>
                </div>
                <FooterAdmin />
            </div>
        </div>

        )

    }

}

/* class SamplesList extends Component {

    constructor (props) {
        super(props)

        this.state = {
            open: false,
            customer: props.customer
        }

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
    }

    open() {
        this.setState( { open:true } );
    }

    close() {
        this.setState( { open:false } )
    }

    render() {
        return (
            <>
                <button className="link-btn" onClick={this.open}><i className="fa fa-list-alt" /></button>
                <InvoiceModal customer={this.props.customer} open={this.state.open} close={this.close} authuser={this.props.authuser} />
            </>
        )
    }
} */

/* class InvoiceModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            open: false
        }

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.save = this.save.bind(this);
        this.AmountFormat = this.AmountFormat.bind(this);
        this.buttons = this.buttons.bind(this);
        this.delete = this.delete.bind(this);
        this.openInvoice = this.openInvoice.bind(this);
        this.InvoiceSummary = this.InvoiceSummary.bind(this);
        this.mailInvoice = this.mailInvoice.bind(this);
        this.update = this.update.bind(this);
    }

    update(row) {

        var authuser = this.props.authuser

        fetch(config.BASE_URL + '/api/updateinvoice', {
          method: "POST",
          body: JSON.stringify(row),
          headers: {
              "Content-Type": "application/json",
              'Accept': 'application/json',
                  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
          })
          .then(data => data.json())
          .then(data => {
            this.setState(  {data: data.invoices} );
          })
          .catch(error => console.log(error));
      }

    InvoiceSummary() {

        let total = 0;
        let notpaid = 0;
        let paid = 0;

        this.state.data.map(invoice => {

            if (invoice.Status == 'Not Paid') {
                notpaid += parseFloat(invoice.Amount);
            }

            if (invoice.Status == 'Paid') {
                paid += parseFloat(invoice.Amount);
            }

            total += parseFloat(invoice.Amount);


        });

        return (
            <div className="row invoice-summary">
                <div className="col-md-8" />
                <div className="col-md-4 p-0 m-0 border">
                    <table className="table table-border">
                        <tbody>
                            <tr>
                                <td className="text-right font-weight-bold">Not Paid</td>
                                <td className="text-right">{notpaid.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                            </tr>
                            <tr>
                                <td className="text-right">Paid</td>
                                <td className="text-right">{paid.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                            </tr>
                            <tr>
                                <td className="text-right">Total</td>
                                <td className="text-right">{total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    mailInvoice(cell, row) {
        const authuser = this.props.authuser;

        const data = {supplierId : row.supplierId, id: row.id, path: `${config.UPLOAD_PATH}`};

        fetch(config.BASE_URL + '/api/mailinvoice', {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {
            swal({
                title: `Sent!`,
                text: `The invoice sent to custmer`,
                icon: "success",
            })
        })
        .catch(error => console.log(error));
    }

    openInvoice(cell, row) {
        const authuser = this.props.authuser;

        const data = {supplierId : row.supplierId, id: row.id, path: `${config.UPLOAD_URL}`};

        fetch(config.BASE_URL + '/api/openinvoice', {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {
            window.open(`${config.UPLOAD_URL}uploads/invoices/${data.invoice.File}`);
        })
        .catch(error => console.log(error));
    }

    delete(cell, row) {

        swal({
            title: `${strings.Areyousure}`,
            text: `${strings.Thisactioncannotbeundone}`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {

                const authuser = this.props.authuser;

                const data = {supplierId : row.supplierId, id: row.id}

                fetch(config.BASE_URL + '/api/deleteinvoice', {
                    body: JSON.stringify(data),
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        'Accept': 'application/json',
                        "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
                    }
                })
                .then(data => data.json())
                .then(data => {
                    this.setState( { data: data.invoices } );
                })
                .catch(error => console.log(error));
            }
          });
    }

    buttons(cell, row) {
        return (
            <>
                <button onClick={ () => this.delete(cell, row) } className='link-btn'><i className="fa fa-trash" /></button>
                <button onClick={ () => this.openInvoice(cell, row) } className="link-btn"><i className="fa fa-file-invoice" /></button>
                <button onClick={ () => this.mailInvoice(cell, row) }className="link-btn"><i className="fa fa-envelope" /></button>
            </>
        )
    }

    AmountFormat(cell) {

        const amount = cell;

        return (
            <>
                {amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
            </>
        )
    }

    componentDidMount() {

        const authuser = this.props.authuser;

        const data = {supplierId : this.props.customer.supplierId}

        fetch(config.BASE_URL + '/api/getinvoices', {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {
            this.setState( { data: data.invoices } );
        })
        .catch(error => console.log(error));
    }

    save(data) {

        const authuser = this.props.authuser;

        fetch(config.BASE_URL + '/api/addinvoice', {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {
            this.setState( { open: false, data: data.invoices } );
        })
        .catch(error => console.log(error));
    }

    open() {
        this.setState( { open: true } )
    }

    close() {
        this.setState( { open: false } )
    }

    render() {

        const data = this.state.data;

        const options = {
            prePage: "Previous", // Previous page button text
            nextPage: "Next", // Next page button text
            noDataText: "No records found",
            that: this, // <- needed this "this" in the search event handler
            sizePerPage: 25,
            sizePerPageList: [
                {
                    text: "5",
                    value: 5
                },
                {
                    text: "10",
                    value: 10
                },
                {
                    text: "25",
                    value: 25
                },
                {
                    text: "50",
                    value: 50
                },
                {
                    text: "All",
                    value:
                        data.length > 0
                            ? data.length
                            : 5
                }
            ]
        };

        const statusOptions = ['Not Paid', 'Paid'];

        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            afterSaveCell: this.update
        }

        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={this.props.close}
                    className="InvoiceModal">
                    <Modal.Header closeButton>
                        Invoices / Customer - {this.props.customer.Name}
                    </Modal.Header>
                    <Modal.Body>

                        <BootstrapTable
                            ref='table'
                            data={data}
                            pagination={true}
                            options={options}
                            cellEdit={cellEditProp}
                            search={true}
                            hover={true}
                            >
                            <TableHeaderColumn
                                hidden={true}
                                dataField='id'
                                dataSort={true}
                                isKey={true}>
                                Invoice Id
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField='CreatedDate'
                                dataSort={true}
                                editable={false}
                                >Created Date
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField='InvoiceNo'
                                dataSort={true}
                                editable={ {type: 'text'} }
                                >Invoice No
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField='Status'
                                dataSort={true}
                                editable={ {type: 'select', options: { values: statusOptions } } }
                                >Status
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField='Amount'
                                dataSort={true}
                                editable={ {type: 'number'} }
                                headerAlign="right"
                                dataAlign="right"
                                dataFormat={this.AmountFormat}
                                >Amount
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField='Notes'
                                dataSort={true}
                                editable={ {type: 'textarea'} }
                                >Notes
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataAlign='center'
                                headerAlign='center'
                                editable={false}
                                dataFormat={this.buttons}
                                >
                                <button onClick={this.open} className="btn add-btn purchasebtn"><i className="fa fa-plus" /></button>
                            </TableHeaderColumn>

                        </BootstrapTable>

                        {this.InvoiceSummary()}

                        <AddInvoiceModal
                            close={this.close}
                            open={this.state.open}
                            save={this.save}
                            customer={this.props.customer}
                            authuser={this.props.authuser} />

                    </Modal.Body>
                </Modal>
            </>
        )
    }
} */

class AddInvoiceModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            supplierId: props.customer.supplierId,
            InvoiceNo: '',
            File: '',
            Amount: 0,
            Notes: '',
            uploaded: '',
            Status: 'Not Paid',
            InvoiceNoClass : 'form-control',
            AmountClass: 'form-control'
        }

        this.drop = this.drop.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(e) {

        //this.props.save(this.state);
        e.preventDefault();

        this.props.save(this.state);
    }



    handleChange(e) {
        this.setState( { [e.target.name] : e.target.value, InvoiceNoClass: 'form-control', AmountClass: 'form-control'} );
    }

    drop(files) {

        const data = new FormData();

        var authuser = this.props.authuser;

        files.forEach((file) => {
            data.append(`file[]`, file, file.name);
        });

        data.append('path', `${config.UPLOAD_PATH}/uploads/invoices/`);

        axios.post(
            config.BASE_URL + "/api/uploadinvoice",
            data ,
            {
                headers: {
                    "Content-Type": "application/json",
		            'Accept': 'application/json',
		            "Authorization" : 'Bearer ' + authuser.token
                 }
            }
        )
        .then(res => {

            const data = res.data;

            this.setState( { File: data.file, uploaded: data.uploaded} );
        });
    }

    render() {
        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={this.props.close}
                    className='addInvoiceModal'
                    >
                    <Modal.Header closeButton>
                        Upload Invoice
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={this.handleSubmit}>

                            <Form.Group>
                                <Form.Label>Invoice No.</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="InvoiceNo"
                                    onChange={this.handleChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group>

                                <Form.Label>Upload Invoice</Form.Label>

                                <Dropzone
                                    onDrop={this.drop}
                                >
                                    {({getRootProps, getInputProps}) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <div className="drag-n-drop text-center">
                                                Drag 'n' drop the invoice file here, or click to select the invoice file
                                                <div className="uploaded-invoice">{this.state.uploaded}</div>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>

                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Amount</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="Amount"
                                    required
                                    onChange={this.handleChange}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Notes</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="Notes"
                                    onChange={this.handleChange}
                                    rows="2"
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Status</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="Status"
                                    onChange={this.handleChange}
                                >
                                    <option value="Not Paid">Not Paid</option>
                                    <option value="Paid">Paid</option>
                                </Form.Control>
                            </Form.Group>
                            <Button className="btn purchasebtn" type="submit">Save</Button>
                        </Form>
                    </Modal.Body>

                </Modal>
            </>
        )
    }
}

class AddModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            supplierName: '',
            supplierEmail: '',
            supplierAddress: '',
            supplierWebsite: '',
            supplierStatus: 1,
            supplierTypeService: '',
            supplierContact: '',
            errors: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
    }

    validateEmail(email)  {

        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return (true)
        }

        return (false)
    }

    save() {

        let valid = true;

        const email = this.state.supplierEmail;
        const contact = this.state.supplierContact;
        const errors = [];
        const name = this.state.supplierName;

        if (name.length == 0) {
            valid = false;
            errors.push('Name is required');
        }

        if (contact.length == 0) {
            valid = false;
            errors.push('Contact No is required');
        }

        if (email.length == 0) {
            valid = false;
            errors.push('Email Address is required')
        } else if (!this.validateEmail(email)) {
            valid = false;
            errors.push('Email is not valid email address');
        }

        this.setState( { errors: errors } );

        if (valid) {
            this.props.save(this.state);
        }
    }

    handleChange(e) {

      this.setState( {
        [e.target.name] : e.target.value,
        errors: ''
      } )

    }

    render () {
        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={this.props.close}
                    className="LoginModal AddProduct SupplierModal"

                  >
                    <Modal.Header closeButton>
                        ADD SUPPLIER
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.errors ?

                                this.state.errors.map(error => {
                                    return (
                                    <div className="bg-danger error p-2">{error}</div>
                                    )
                                })

                            : ''
                        }
                        <Form onSubmit={this.handleSubmit}>

                        <div className="row">
                            <div className="col-md-12">
                        <div className="form-group">
                            <label>Supplier Name</label>
                            <input type="text" className="form-control" name="supplierName" value={this.state.supplierName} onChange={this.handleChange} />
                        </div>
                        </div>
                        </div>
                        <div className="row">

                            <div className="col-md-6">

                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="text" className="form-control" name="supplierEmail" value={this.state.supplierEmail} onChange={this.handleChange} />
                                </div>

                            </div>

                            <div className="col-md-6">

                                <div className="form-group">
                                    <label>Contact</label>
                                    <input type="text" className="form-control" name="supplierContact" value={this.state.supplierContact} onChange={this.handleChange} />
                                </div>

                            </div>

                        </div>

                        <div className="row">
                            <div className="col-md-6">
                        <div className="form-group">
                        <label>Website</label>
                        <input type="text" className="form-control" name="supplierWebsite" value={this.state.supplierWebsite} onChange={this.handleChange} />
                        </div>
                        </div>

                        <div className="col-md-6">
                    <div className="form-group">
                        <label>Type of Service</label>
                        <input type="text" className="form-control" name="supplierTypeService" value={this.state.supplierTypeService} onChange={this.handleChange} />
                    </div>
                    </div>

                        </div>

                        <div className="row">
                            <div className="col-md-12">
                        <div className="form-group">
                        <label>Address</label>
                        <Form.Control
                            as="textarea"
                            style={{minHeight: '70px'}}
                            name="supplierAddress"
                            onChange={this.handleChange}
                            rows="2"
                        />
                        </div>
                        </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group select">
                                    <label>Active</label>
                                    <select name="supplierStatus" className="form-control" onChange={this.handleChange}>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                            <div style={{clear: 'both', float: 'none'}}></div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn cartbtn purchasebtn" onClick={this.save}> Save </button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

class NumberEditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value : props.defaultValue,
            step : props.step
        }

        this.handleChange = this.handleChange.bind(this);
    }

    getValue() {
      return this.state.value;
    }

    handleChange(e) {
        this.setState( {
            value: e.target.value
        })
    }

    render() {
      const { value, onUpdate, ...rest } = this.props;
      return [
        <input
          { ...rest }
          type="number"
          className="form-control numberEditor"
          step={this.state.step}
          onChange={this.handleChange}

        />
      ];
    }
  }

  class ChatBox extends Component {

	constructor(props) {
		super(props);

		this.state = {
			id: props.id,
			data: props.data,
			AuthUser: props.user,
			NewComment: '',
			status: props.data.Status,
			pStatus: props.data.ProjectStatus,
			comments: []
        }

        this.handleChange = this.handleChange.bind(this);
        this.getComments = this.getComments.bind(this);
        this.renderComments = this.renderComments.bind(this);
        this.submitComment = this.submitComment.bind(this);
    }

    submitComment() {

        const user = this.state.AuthUser;
        const row = this.state.data;

        const commentdata = {
			supplierId: row.supplierId,
			commentText: this.state.NewComment,
			senderId: user.Id,
			senderName: user.Name,
			receiverId: (row.supplierId) ? row.supplierId : 0,
			receiverName: row.Name
		}


        var auth_user = this.state.AuthUser;

		fetch(config.BASE_URL + '/api/addcustomercomment', {
			method: 'POST',
			body: JSON.stringify(commentdata),
			headers: {
			  'Content-Type': 'application/json',
			  'Accept': 'application/json',
              "Authorization": 'Bearer ' + auth_user.token
			}
		})
		.then(data => data.json())
		.then(data => {

            var comments = this.state.comments;

			comments.push(data[0]);

            this.setState({
                comments: comments,
                NewComment: ''
			})

		})
		.catch(error => console.log(error));

	}

    renderComments(comments) {

		let commentHTML = '';
		if (comments.length > 0){
			commentHTML = comments.map( item => {
				return (
					<dl key={item.commentId} className="job-comments-wrapper">
					  <dt><b className="comment_name">{item.senderName} &nbsp;</b> <span className="comment_date">{item.createdDate}</span> &nbsp; : &nbsp; &nbsp; </dt>
					  <dd><div className="comment_text">{item.commentText}</div></dd>
					</dl>
				)
			});
		}

		return commentHTML;

	}

    handleChange(e) {
        this.setState({ NewComment: e.target.value});
    }

    getComments(supplierId) {

        var user = this.state.AuthUser;

        fetch(config.BASE_URL + '/api/getcustomercomments/'+supplierId, {
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + user.token
            }
        })
        .then(data => data.json())
        .then(data => {

            var comments = this.state.comments;

            data.map(function(item) {
                var data = comments.filter(comment => comment.commentId == item.commentId);

                if (data.length == 0) {
                    comments.push(item);
                }
            });

            this.setState({ comments: comments });
        })
        .catch(error => console.log(error));
    }

	componentDidMount() {
        const supplierId = this.props.data.supplierId;

        this.getComments(supplierId);
	}


	render() {

        const id = this.state.id;
		const data = this.state.data;
		const chatbox = `chatbox-${id}`;

		const comments = this.renderComments(this.state.comments);

		return (
			<>
				<div key={id} id={chatbox} >

					<div className="chat-box" ref={this.MsRef} style={ { marginLeft: '50px', height: '100px', overflow: "scroll" } }>

						{comments}

					</div>

                      <div className="admin chat-box-input" style={ { marginLeft: '40px' } }>
                          <div className="d-flex">
                              <div className="form-group mr-2">
							  	<input type="text" className="form-control" value={this.state.NewComment} placeholder={strings.Enteryourcomment} onChange={this.handleChange} />
                              </div>
                              <div>
                                  <button onClick={this.submitComment} className="btn purchasebtn">Submt</button>
                              </div>
                          </div>
                      </div>
				</div>
			</>
		)
	}


}
