import config from '../config';

export default function Cart(
        material,
        lineTop,
        lineBottom,
        lineLeft,
        lineRight,
        data,
        DoorId,
        hardware,
        Width,
        Height,
        Depth,
        product,
        stockCodes,
        stockCode,
        GapTop,
        GapBottom,
        GapLeft,
        GapRight,
        Gst,
        ProductType,
        ProductLineTotal,
        ProductStockCode,
        ProductPrice,
        ProductStockImage,
        Elevation,
        SoffitHeight,
        ToeHeight,
        ToeRecess,
        drawerGaps,
        detailImage,
        details,
        fixedQty,
        equalQty,
        adjustableQty,
        HardwareLayout,
        ProductDescription,
        ProductQty,
        roomData,
        RoomId,
        FixedShelve,
        AdjustableShelve,
        NoOfMitre
      )
{

    let noShortEdges = 0;
    let noLongEdges = 0;

    if (lineTop){
        noShortEdges += 1
    }
    if (lineBottom){
        noShortEdges += 1
    }
    if (lineLeft){
        noLongEdges += 1
    }
    if (lineRight){
        noLongEdges += 1
    }


    let MaterialId = 1;
    let BrandId = 1;
    let FinishId = 1;
    let SubstrateId = 1;
    let ColourId = 1;

    let carcaseBrand = 0;
    let carcaseFinish = 0;
    let carcaseSubstrate = 0;
    let carcaseColour = 0;

    let faceBrand = 0;
    let faceFinish = 0;
    let faceSubstrate = 0;
    let faceColour = 0;
    let materialObj = [];

    let displayname = '';

    //console.log('material', material)
    material.map((Material) => {

        const sectionId  = Material.SectionId;

        // console.log('data', data);

        switch (sectionId) {
            case 1:

                MaterialId = data.MaterialType1;
                BrandId = data.MaterialBrand1;
                FinishId = data.MaterialFinish1;
                SubstrateId = data.MaterialSubstrate1;
                ColourId = data.MaterialColour1;

                Material.finish.map( (finish) => {
                    if (finish.FinishId == data.MaterialFinish1) {
                        carcaseFinish = finish.CostPercent;
                    }
                });

                Material.brand.map( (brand) => {
                    if (brand.BrandId = data.MaterialBrand1) {
                        //carcaseBrand = brand.CostPercent;
                        carcaseBrand = 0;
                    }
                });

                Material.substrate.map( (substrate) => {
                    if (substrate.SubstrateId == data.MaterialSubstrate1) {
                        carcaseSubstrate = substrate.CostPercent;
                    }
                });

                Material.colour.map( (colour) => {
                    if (colour.ColourId == data.MaterialColour1) {
                        carcaseColour = colour.RatePerSQm;
                    }
                })

            break;

            case 2:

                MaterialId = data.MaterialType2;
                BrandId = data.MaterialBrand2;
                FinishId = data.MaterialFinish2;
                SubstrateId = data.MaterialSubstrate2;
                ColourId = data.MaterialColour2;

                Material.finish.map( (finish) => {
                    if (finish.FinishId == data.MaterialFinish2) {
                        faceFinish = finish.CostPercent;
                    }
                });

                Material.brand.map( (brand) => {
                    if (brand.BrandId = data.MaterialBrand2) {
                        //faceBrand = brand.CostPercent;
                        faceBrand = 0;
                    }
                });


                Material.substrate.map( (substrate) => {
                    if (substrate.SubstrateId == data.MaterialSubstrate2) {
                        //console.log('substrate', substrate)
                        faceSubstrate = substrate.CostPercent;
                    }
                });

                Material.colour.map( (colour) => {
                    if (colour.ColourId == data.MaterialColour2) {
                        faceColour = colour.RatePerSQm;
                    }
                })

            break;


        }

        materialObj.push({
            ProductSectionId: sectionId,
            MaterialId: MaterialId,
            BrandId: BrandId,
            FinishId: FinishId,
            SubstrateId: SubstrateId,
            ColourId: ColourId,
            DoorId : DoorId
        })
    });

    let hardwarecost = 0;

    let perDrawCost = 0;
    let extraCost = 0;
    let assemblyCost = 0;

    let doorHardwarePercentageCost = 0;
    let hardwarePercentageCost = 0;
    let doorPerItemCostKg = 0;
    let drawerPerItemCostKg = 0;

    hardware.map(door => {

        if (door.HardwareId === parseFloat(data.DoorHardwareId)) {
            doorHardwarePercentageCost = door.percentage_cost;
            doorPerItemCostKg = door.peritem_costkg
        }

        if (door.HardwareId === parseFloat(data.HardwareId)) {
            hardwarePercentageCost = door.percentage_cost;
            drawerPerItemCostKg = door.peritem_costkg
        }
        //return false;
    });


    const selectedBrand = data.brand.filter(brand =>{
        return brand.BrandId.toString() == data.MaterialBrand1.toString()
    });

    const backSideArea = Width * Height;

    const topArea = Width * Depth;

    const bottomArea = Width * Depth;

    const sideArea = Height * Depth * 2;

    const doorArea = Width * Height / 1000000;

    const carcaseArea = (backSideArea + topArea + bottomArea + sideArea) / 1000000;

    const selectedMaterialtype1 = data.materialType.filter(materialType =>{
        return materialType.MaterialTypeId.toString() == data.MaterialType1.toString()
    });

    const selectedDoorMaterial = data.materialType.filter(materialType => {
        return materialType.MaterialTypeId.toString() == data.MaterialType2.toString();
    });

    const selectedDoorStyle = data.doorStyle.filter( d => {
        return d.DoorId == data.DoorId
    });

    let doorStylePercentageCost = 0;

    if (selectedDoorStyle.length > 0) {
        doorStylePercentageCost = selectedDoorStyle[0].percentage_cost;
    }

    var productCode = product.code;

    const hingeData = {
        HingeId: data.HingeId
    }

    const noOfShelves = parseInt(FixedShelve) + parseInt(AdjustableShelve)

    var shelveData = {
        shelveId: data.mshelves,
        noOfShelves: noOfShelves,
        WineRack : data.WineRack,
        MicrowaveOven : data.MicrowaveOven
    }

    const shelfTypeCode = parseInt(shelveData.shelveId) === 1 ? "FS" : "AS"


    const hardwareData = {
        HardwareId: data.HardwareId,
        DoorHardwareId: data.DoorHardwareId,
        DrawerHardwareId: data.HardwareId
    }

    //console.log('hardware data', hardwareData);

    let image = config.BASE_URL + '/storage/upload/no-image.jpg';

    //console.log('ProductType', ProductType)

    //console.log('stockCode', stockCode)
    var getstockCodefromDB = stockCodes.filter(function (products) {
        return products.StockCode === stockCode;
    });

    if (getstockCodefromDB.length > 0 && getstockCodefromDB[0].displayname.length > 0) {
        displayname = getstockCodefromDB[0].displayname;
    }
    

    if (ProductType == 'panels') {
        getstockCodefromDB = stockCodes.filter(function (products) {
          return products.noShortEdges == noShortEdges && products.noLongEdges == noLongEdges && products.ProductId == product.ProductId;
        });

        //console.log('product', product);
        if (product.ProductName.indexOf('Kicker') >= 0) {
          getstockCodefromDB = stockCodes.filter(function (products) {
            return products.NoOfMitre == NoOfMitre;
          });
        }
    }

    //console.log('selectedDoorMaterial[0]', selectedDoorMaterial[0])

    const carcaseMaterialtype = (selectedMaterialtype1.length > 0) ? (parseFloat(selectedMaterialtype1[0].RatePerSQm)) : 0;

    const doorMaterialType = (selectedDoorMaterial.length > 0) ? parseFloat(selectedDoorMaterial[0].RatePerSQm) : 0;

    const carcaseCost = carcaseArea * carcaseMaterialtype;

    const carcaseBoardCost = carcaseArea * carcaseColour;

    //const totalCarcaseCost =  carcaseCost + (carcaseCost * ((carcaseBrand + carcaseFinish + carcaseSubstrate + carcaseColour) / 100));
    let totalCarcaseCost =  carcaseCost + carcaseBoardCost;
    const exteriorColour = 0;
    let doorCost = doorArea * doorMaterialType;
    let doorBoardCost = doorArea * faceColour;
    //doorCost += (doorCost * doorStylePercentageCost / 100);

    //var totalDoorCost = doorCost + (doorCost * ((faceBrand + faceFinish + faceSubstrate + faceColour) / 100));
    var totalDoorCost = doorCost + doorBoardCost;
     totalDoorCost = totalDoorCost + (totalDoorCost * (faceSubstrate / 100));

    const sExtraCost =  (getstockCodefromDB.length > 0) ? getstockCodefromDB[0].ExtraCost : 0

    var DoorHingeCost = doorHardwarePercentageCost * sExtraCost

    if (product.hasDoor == 0){
      totalDoorCost = 0;
      DoorHingeCost = 0;
    }

    //console.log('getstockCodefromDB[0]', getstockCodefromDB[0])

    var drawersizes = [];

    if (getstockCodefromDB.length > 0) {
      try {
        drawersizes = getstockCodefromDB[0].DrawerSize && getstockCodefromDB[0].DrawerSize.equal ? getstockCodefromDB[0].DrawerSize : {}
      }catch(err) {}
    }

    var faceArea = 0;
    var runnerArea = 0;
    var backArea = 0;
    var DrawerEqual = (getstockCodefromDB[0]) ? getstockCodefromDB[0].DrawerEqual : 0;
    var DrawerSmall = (getstockCodefromDB[0]) ? getstockCodefromDB[0].DrawerSmall : 0;
    var DrawerInner = (getstockCodefromDB[0]) ? getstockCodefromDB[0].DrawerInner : 0;

    if ( parseInt(product.hasDrawer) === 1 ) {
      if (drawersizes.equal) {
        faceArea += Width * drawersizes.equal.faceheight / 1000000 * DrawerEqual;
        runnerArea += Depth * drawersizes.equal.runnerheight / 1000000 * 2 * DrawerEqual;
        backArea += Width * drawersizes.equal.runnerheight / 1000000 * DrawerEqual;
      }

      if (drawersizes.small) {
        faceArea += Width * drawersizes.small.faceheight / 1000000 * DrawerSmall;
        runnerArea += Depth * drawersizes.small.runnerheight / 1000000 * 2 * DrawerSmall;
        backArea += Width * drawersizes.small.runnerheight / 1000000 * DrawerSmall;
      }
    }
    
    if (parseInt(product.has_inner) === 1) {
      if (drawersizes.inner) {
        faceArea += Width * drawersizes.inner.faceheight / 1000000 * DrawerInner;
        runnerArea += Depth * drawersizes.inner.runnerheight / 1000000 * 2 * DrawerInner;
        backArea += Width * drawersizes.inner.runnerheight / 1000000 * DrawerInner;
      }
    }

    const drawerBottomArea = Width * Depth / 1000000;
    
    console.log('product', product)
    console.log('DrawerInner', DrawerInner)
    //const NoOfDrawers = DrawerEqual + DrawerSmall + DrawerInner;
    let NoOfDrawers = parseInt(product.hasDrawer) === 1 ?  (DrawerEqual + DrawerSmall) : 0;
    
    NoOfDrawers = parseInt(product.has_inner) === 1 ?  (NoOfDrawers + DrawerInner) : NoOfDrawers;
    
    console.log('NoOfDrawers', NoOfDrawers )
    
    const totalDrawerArea = faceArea + runnerArea + drawerBottomArea + backArea;

    let drawerCost = (totalDrawerArea * doorMaterialType) + (totalDrawerArea * faceColour);

    var sPerDrawCost =  (getstockCodefromDB.length > 0) ? getstockCodefromDB[0].PerDrawCost : 0
    
    //console.log(DrawerEqual, DrawerSmall, DrawerInner);
    if (NoOfDrawers == 0) {
        drawerCost = 0;
        hardwarePercentageCost = 0;
        sPerDrawCost = 0;
    }
    
    
    console.log('hardwarePercentageCost', hardwarePercentageCost)
    
    drawerCost = drawerCost + (hardwarePercentageCost * NoOfDrawers);
    
    console.log('drawerCost', drawerCost)
    //Shelve Calculation
    const ShelveArea = (Width * Depth)  / 1000000
    //console.log('noOfShelves', noOfShelves)
    let ShelveCost = (ShelveArea * doorMaterialType) + (ShelveArea * faceColour)


    let TotalShelveCost = (ShelveCost +  (ShelveCost * (faceSubstrate / 100))) * noOfShelves
    
    let TotalDrawerCost = drawerCost + (drawerCost * (faceSubstrate / 100));

    if (parseInt(NoOfDrawers) === 0){
      TotalDrawerCost = 0;
    }

    if (parseInt(product.hasShelve) === 0){
      TotalShelveCost = 0;
    }
    
    console.log('TotalDrawerCost', TotalDrawerCost)
    //calculate cabinet weight  
    const squireWeight = selectedBrand ? selectedBrand[0].squire_weight : 0
    const cabinetWeight = (carcaseArea * squireWeight) + (doorArea * squireWeight) + (totalDrawerArea * squireWeight) + (ShelveArea * squireWeight);
    
    const hardwareWeight = (doorPerItemCostKg * product.NoOfDoors) + (drawerPerItemCostKg * NoOfDrawers)
    
    const TotalCabinetWeight = (cabinetWeight + hardwareWeight) * ProductQty
      
    let PanelTotalCost = 0

    if (ProductType == 'panels') { // calculate panel cost

      const hardCost = getstockCodefromDB[0] && getstockCodefromDB[0].HardwareCost ? getstockCodefromDB[0].HardwareCost : 0

      PanelTotalCost = (((Width * Height)/1000000) * doorMaterialType) + (((Width * Height)/1000000) * faceColour)
      PanelTotalCost = PanelTotalCost + (PanelTotalCost * (faceSubstrate / 100)) + hardCost

      totalDoorCost = 0
      TotalShelveCost = 0
      DoorHingeCost = 0
      TotalDrawerCost = 0
      totalCarcaseCost = 0

    }

    shelveData.shelveData = TotalShelveCost;
    shelveData.shelvesCost = TotalShelveCost;
    var doorObj = {
        doorId:     DoorId,
        GapTop:     data.GapTop,
        GapBottom:  data.GapBottom,
        GapLeft:    data.GapLeft,
        GapRight:   data.GapRight,
        doorsCost:  totalDoorCost
    }


    if (getstockCodefromDB.length > 0) {

        var jobStockCode = getstockCodefromDB[0].StockCode
        var productStockFlag = 'false'

        const images = getstockCodefromDB[0].Image;

        if (images && images.length > 0) {
            const imgArr = images.split(',');
            image = config.BASE_URL + imgArr[0];
        }

        assemblyCost = getstockCodefromDB[0].AssemblyCost;
        hardwarecost = getstockCodefromDB[0].HardwareCost;
    } else {
        var jobStockCode = stockCode
        var productStockFlag = 'true'
    }

    const productpriceWithOutGst = (totalCarcaseCost + totalDoorCost + TotalDrawerCost + TotalShelveCost + hardwarecost + DoorHingeCost);

    const GST = productpriceWithOutGst * Gst;

    var productprice = productpriceWithOutGst + GST;

    if (ProductType == 'accessories') {
        productprice = ProductLineTotal
        jobStockCode = ProductStockCode
        hardwarecost = ProductPrice
        image = config.BASE_URL+ProductStockImage
        RoomId = 99
        roomData = 'Accessories'
    }



    if (ProductType == 'panels') {
      productprice = PanelTotalCost
    }


    var jobProductData = {
        DoorsCost   : totalDoorCost,
        ShelvesCost : TotalShelveCost,
        DrawerCost: TotalDrawerCost,
        DoorHingeCost: DoorHingeCost,
        HardwareCost: hardwarecost, 
        MaterialCost: totalCarcaseCost,
        Price: productprice,
        PriceWithoutGST: productpriceWithOutGst,
        assemblyCost: assemblyCost,
        productStockCode: jobStockCode,

        StockCode: stockCode,

        productStockFlag: productStockFlag,
        ProductId: product.ProductId,
        ProductName: product.ProductName,
        Width: Width,
        WidthMax: product.WidthMax,
        WidthMin: product.WidthMin,
        Height: Height,
        HeightMax: product.HeightMax,
        HeightMin: product.HeightMin,
        Depth: Depth,
        DepthMin: product.DepthMin,
        DepthMax: product.DepthMax,
        Elevation: Elevation,
        ElevationMin: product.ElevationMin,
        ElevationMax: product.ElevationMax,
        SoffitHeight: SoffitHeight,
        SoffitHeightMin: product.SoffitHeightMin,
        SoffitHeightMax: product.SoffitHeightMax,
        ToeHeight: ToeHeight,
        ToeHeightMin: product.ToeHeightMin,
        ToeHeightMax: product.ToeHeightMax,
        ToeRecess: ToeRecess,
        ToeRecessMin: product.ToeRecessMin,
        ToeRecessMax: product.ToeRecessMax,

        roomData: roomData,
        RoomId : RoomId,

        NoOfDoors: product.NoOfDoors,
        TotalHardwareCost : hardwarecost,
        CarcaseFinalCost: totalCarcaseCost,
        DoorFinalCost: totalDoorCost,
        image : image,
        code : productCode,
        HingeId: hingeData.HingeId,

        MicrowaveOven : data.MicrowaveOven,
        WineRack : data.WineRack,

        DoorId: doorObj.doorId,

        HardwareId: hardwareData.hardwareId,
        DoorHardwareId: hardwareData.DoorHardwareId,
        drawerHardwareId: hardwareData.drawerHardwareId,

        drawerGaps: drawerGaps,
        detailImage: detailImage,
        hasDoor: product.hasDoor,
        hasDrawer: product.hasDrawer,
        details : details,
        hasShelve : product.hasShelve,

        has_inner: product.has_inner,
        has_kicker: product.has_kicer,

        max_equal_drawer: product.max_equal_drawer,
        max_inner_drawer: product.max_inner_drawer,
        max_small_drawer: product.max_small_drawer,

        InteriorMaterial: product.InteriorMaterial,
        ExteriorMaterial: product.ExteriorMaterial,

        ProductSubCategoryId: product.ProductSubCategoryId,
        RightScribe: product.RightScribe,
        code2: product.code2,
        has_kicker: product.has_kicker,
        AdjustableShelve: AdjustableShelve,
        FixedShelve: FixedShelve,
        fixedQty: FixedShelve,
        equalQty: equalQty,
        adjustableQty: AdjustableShelve,
        HardwareLayout : HardwareLayout,

        StickerName: data.StickerName,

        ProductType: ProductType,
        ProductStockCode: ProductStockCode,
        ProductDescription: ProductDescription,
        ProductPrice: ProductPrice,
        ProductQty: ProductQty,
        ProductLineTotal: ProductLineTotal,

        DrawerSmall : data.DrawerSmall,
        DrawerInner : data.DrawerInner,
        DrawerEqual : data.DrawerEqual,
        Weight: ProductType == 'accessories' ? getstockCodefromDB[0].Weight : TotalCabinetWeight,

        isCustomized: data.isCustomized,
        TopEdge: lineTop ? 1 : 0,
        RightEdge: lineRight ? 1 : 0,
        BottomEdge: lineBottom ? 1 : 0,
        LeftEdge: lineLeft ? 1 : 0,
        NoOfMitre: NoOfMitre,
        
        displayname: displayname,

        slug: product.slug
    }

    return {jobProductData, doorObj, hingeData, shelveData, hardwareData, materialObj};

}
