import React, { Component } from 'react';
import Header from  '../components/Dashboard/Header';
import Sidebar from '../components/Dashboard/Sidebar';
import CustomerEdit from  '../components/Dashboard/Customers';
import Footer from '../components/Footer';
import config from '../config';
import swal from "sweetalert";
import { strings } from '../components/strings';

import { DoLogout } from '../components/Dashboard/Functions'

class Customers extends Component {
    constructor(props) {
        
        super(props);
        
        var authuser = this.getCustomer();
		
        this.state = {
			      CustomerId: authuser.CustomerId,
			      Name: authuser.Name,
			      Email: authuser.Email,
			      MobileNumber: authuser.MobileNumber,
			      customer: authuser
        };

        this.addproject = this.addproject.bind(this);
        this.getCustomer = this.getCustomer.bind(this);
    }

    addproject(data) {

      const customer = this.state.customer

      var customer_obj = customer;
      
      var Token = customer_obj.token;

      console.log('add project', customer);
    
      const params = {customerId: customer.CustomerId, name: data.name, clientName: customer.Name, contactNo: customer.MobileNumber, email: customer.Email};

      fetch(config.BASE_URL + '/api/addproject', {
          method: "POST",
          body: JSON.stringify(params),
          headers: {
              "Content-Type": "application/json",
              "Authorization": 'Bearer ' + ((Token) ? Token : '' )
          }
      })
      .then(data => data.json())
      .then(data => {

          window.location = `/dashboard/edit-order/${data.jobId}`;

          //this.setState({projects : data});
      })
      .catch(error => console.log(error));

  }

  componentDidMount() {
        
    const customer = this.getCustomer();
    
    if (customer) {
        this.setState( { customer: JSON.parse(customer) } );        
    } 
}  

	
  handleFormSubmit() {
    const {CustomerId, Name, MobileNumber, customer } = this.state
	var	data = {
		CustomerId: CustomerId,
		Name: Name,
		MobileNumber: MobileNumber
	}
			
    fetch(config.BASE_URL + '/api/updatecustomer', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			"Authorization": 'Bearer ' + ((customer.token) ? customer.token : '' )		  
        }		
      }).then((response) => response.json())
        .then((data) => {
			//localStorage.setItem("customer", JSON.stringify(data.response[0]));			
			swal("", `${strings.Profileupdatedsuccessfully}`, "success")
        })
        .catch((error) => {
            console.log("Error, with message::", error)
        });
  }

  getCustomer() {
    const customer = localStorage.getItem("customer");

    try {
  
      JSON.parse(customer);
  
    } catch (e) {
  
      localStorage.removeItem("customer");
      window.location = "/";
  
    }
  
    return customer;
  }
	
		
    render() {
        
        const customer = this.getCustomer();

        return (
            <div className="customer-dashboard">
                <Header 
                    page="customers"
                    customer={customer} 
                    doLogout={DoLogout} 
                    addproject={this.addproject} />
                <Sidebar />
				        <CustomerEdit />
                <Footer />
            </div>
        );
    }
}
export default Customers;