import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router'
import config from "../config";

class TopMenu extends Component {
    constructor(props) {

        super(props);

        this.state = {
          items: [],
		  currentimg: '',
		  hovered: false,
          hasClicked: false
        }

        this.getMenu = this.getMenu.bind(this);
        this.getProductDetails = this.getProductDetails.bind(this);
		    this.updateCurrentImage = this.updateCurrentImage.bind(this);
		    this.removeHover = this.removeHover.bind(this);
        this.clickSubmenu = this.clickSubmenu.bind(this)
    }

    clickSubmenu(ev, products){
      const ProductId = products && products.length > 0 ? products[0].ProductId : 0
      this.getProductDetails(ev, ProductId);
    }

	getMenu = async() => {
		await fetch(config.BASE_URL + '/api/getmenu', {
			headers: {
				"Content-Type": "application/json",
				'Accept': 'application/json'
			}
		})
		.then(data => data.json())
		.then(data => {

			this.setState({
				items: data.menuItem,
			}, () => {
        		localStorage.setItem("relatedProducts", JSON.stringify(data.menuItem));
			})
		})
		.catch(error => console.log(error));
	}

	updateCurrentImage = (img) => {
			this.setState({
				currentimg: img,
			}, () => {
				//console.log(this.state.currentimg);
			})
	}

	removeHover = (stateholder) => {
			this.setState({
				[stateholder]: '',
				hovered: false
			}, () => {
				//console.log(this.state);
			})
	}

	removeHoverSubs = (stateholder) => {
			this.setState({
				[stateholder]: ''
			}, () => {
				//console.log(this.state);
			})
	}

	addHover = (stateholder, ev) => {
		ev.preventDefault()
			this.setState({
				[stateholder]: 'hover',
				hovered: true,
        hasClicked: true
			}, () => {
				//console.log(this.state.currentimg);
			})
	}

  onAddHover = (stateholder, ev) => {
    const {hasClicked} = this.state

    if (!hasClicked) return;

    ev.preventDefault()
			this.setState({
				[stateholder]: 'hover',
				hovered: true
			}, () => {
				//console.log(this.state.currentimg);
			})
  }

	loopMenu = (data) => {
			//console.log(data[0].id);
			const topmenu = data.map(item => {
				return item.isActive ?
					<div key={item.id} className="m-01-menu-section level-0" id="m-01-model-menu">
					  <div onMouseLeave={() => this.removeHover('hover'+item.id)} onClick={(event) => this.addHover('hover'+item.id, event)} onMouseEnter={(event) => this.onAddHover('hover'+item.id, event)} className={'m-01-menu-item m-010-menu-item ' + this.state['hover'+item.id]}>
						<a onMouseEnter={() => this.updateCurrentImage(item.img)} className={'m-01-link m-01-menu-button '} data-img={item.img} id="m-01-models-menu-button" >
						  <span>{item.text}</span>
						</a>
						{item.children && item.children.length == 0 ? (
							<Fragment>
								{/*this.loopProducts(item.products || [])*/}
							</Fragment>
						) : (
							<Fragment>
								{ this.subMenu(item.children, item.id, item.img, item.products) }
							</Fragment>
						)}
					  </div>
					</div>
				: ''
				}
			);

			let home = <a className="m-01-link m-01-menu-button" href="/">Home</a>
			let customer = localStorage.getItem('customer');
			let jobId = localStorage.getItem('JobId');

			if (customer) {

				customer = JSON.parse(customer);
				jobId = JSON.parse(jobId);

				if (jobId > 0) {
					const href = `/dashboard/edit-order/${jobId}`;
					home = <a className="m-01-link m-01-menu-button" href={href}>Home</a>
				}
			}

		  return (
			<Fragment>
			{topmenu}
			<div className="m-01-menu-section level-0 pull-right"><a className="m-01-link m-01-menu-button" href="/contact-us">Contact Us</a></div>
			<div className="m-01-menu-section level-0 pull-right"><a className="m-01-link m-01-menu-button" href="/about-us">About Us</a></div>
		  	<div className="m-01-menu-section level-0 pull-right">{home}</div>
			</Fragment>
		  )
	}

    subMenu(subs, id, defaultImg) {
		//console.log('subs', subs);
        if (subs && subs.length > 0) {
            const Subs = subs.map(sub => {

				return sub.isActive ?
					  <div key={sub.id} className={'m-01-menu-item m-011-menu-item level-1 ' + this.state['hover'+sub.id]} onMouseLeave={() => this.removeHoverSubs('hover'+sub.id)} onMouseEnter={(event) => this.addHover('hover'+sub.id, event)}>
						<a onMouseEnter={() => this.updateCurrentImage(sub.img)} className="m-01-link m-01-icon-718"  data-img={sub.img}>
						  <span>{sub.text}</span>
						  <div className="m-01-link-image-wrapper">
							{sub.img ? (
								<img alt={sub.text} src={config.UPLOAD_URL+sub.img.slice(1)} />
							) : (
								''
							)}

						  </div>
						</a>

						{sub.products && sub.products.length > 0 ? (

							<div className="m-01-sub-menu m-01-level-3">
							  <div className="m-60-module-wrapper">
								<div className="module-container m-60-series">
									{this.loopProducts(sub.products || [])}
								</div>
								<div className="module-container m-60-flyout">
								  <div className="module-grid">
									<div className="m-60-flyout content-full-width">
									  <div className="m-60-background-wrapper">
										<div className="m-60-flyout-background">
										  <img src={this.state.currentimg} className="m-60-flyout-image" id="image-preview-right" />
										</div>
									  </div>
									</div>
								  </div>
								</div>
							  </div>
							</div>

						) : (
							<Fragment>
							</Fragment>
						)}
					  </div>
				: ''}
            );

            return (
                <div id="m-01-models-menu-button-content" className="m-01-sub-menu m-01-level-2" style={{left: "0px", display: "none"}}>
                    {Subs}
                </div>
            )
        }
    }

	loopProducts = (products) => {

        if (products.length > 0) {
		//console.log(products);
        if (products && products.length > 0) {

			const Products = products.map(product => {

				return (
						<Fragment>
							<a key={product.ProductId}
								   className="m-60-model-series"
								   onClick={(event) => this.getProductDetails(event, product.ProductId)}
								   onMouseEnter={() => product.Image ? this.updateCurrentImage(config.BASE_URL + product.Image, 'hover'+product.id) : '' }
								   data-productid={product.ProductId} id={product.ProductId} >
								   <span>{product.ProductName}</span>
							</a>
						</Fragment>
					)

				}
			);
			return (
				<div className="module-grid">
					<div className="m-60-series-container content-full-width">
						{Products}
					</div>
				</div>
			)
		}
	}}


	getProductDetails = (ev, ProductId) => {
		ev.preventDefault()
		ev.stopPropagation()
		Object.keys(this.state).map((st) => {
			if (st.indexOf('hover') > -1) {
				this.setState({
					hovered: false,
					[st]: ''
				}, () => {
					//console.log(this.state)
				});
			}
		});

		var redirect = false
		const urls = ['about-us', 'contact-us', 'partners']
		const pathname = this.props.location.pathname
		urls.forEach(function (url, index) {
			if (pathname.indexOf(url) > -1) {
				redirect = true;
			}
		});

    const { params } = this.props.match
    if (Object.keys(params).length > 0) {
        var productSku = params.prodsku
        if (typeof productSku !== 'undefined') {
          redirect = true;
        }
    }

		if (redirect){
			const { history } = this.props;
			history.push('/' + ProductId);
      //history.push('/');
		}else{
			this.props.gotoProduct(ProductId);
		}
	}

	componentDidUpdate() {
		//this.getMenu();
	}

    componentDidMount() {
		this.getMenu();
    }

    /* componentWillReceiveProps(newProps) {

    } */

    render() {

		//console.log('top menu', this.state);

		const { items, hovered } = this.state

		//console.log('items', items)

        const TopNav = (items && items.length > 0) ? this.loopMenu(items) : '';

        return(
			<Fragment>
			  {hovered ? (
				<div className="menu-overlay" style={{display: "block"}}></div>
			  ) : (
				<div className="menu-overlay" style={{display: "none"}}></div>
			  )}

				<div className="m-01-main-navigation" data-search="no">
					<div className="m-01-menu-item">
						<div className="m-01-sub-menu m-01-level-0">
							{TopNav}
						</div>
					</div>
				</div>
			</Fragment>
        )
    }
}

export default withRouter(TopMenu);
