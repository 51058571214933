// import React, { Component } from 'react';
import swal from "sweetalert";
import config from '../config';

export default {
	ActivateCustomer(params){
		this.doLoginById(params.customerid, params.activationcode);
  },
	doLoginById(id, activationcode) {
	  const param = {id: id, activationcode: activationcode};

	  fetch(config.BASE_URL + '/api/activatecustomer', {
	    method: "POST",
	    body: JSON.stringify(param),
	    headers: {
	        "Content-Type": "application/json",
	        'Accept': 'application/json'
	    }
	  })
	  .then(data => data.json())
	  .then(data => {
	    if (data.code === 200) {
	      localStorage.setItem("customer", JSON.stringify(data.response))
				swal({
						title: 'You account is now active',
						text: '',
						icon: "success"
					}).then(ok => {
						window.location = '/dashboard'
					})
	    }else{
				swal({
						title: 'Invalid Customer',
						text: '',
						icon: "error"
					}).then(ok => {
						window.location = '/'
					})
			}
	  })
	  .catch(error => console.log(error));
	}
}
