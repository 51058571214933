import React, { Component, useState } from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import ReactTooltip from 'react-tooltip'
import { withRouter } from 'react-router';
//import { Redirect } from "react-router-dom";
import EditProfile from '../admin/EditProfile';
import swal from 'sweetalert';
import { strings } from './strings';
import config from '../config';

class HeaderAdmin extends Component {
    constructor(props) {
        super(props);
		var authuser = localStorage.getItem('authuser');
        this.state = {
            Email: '',
            Password: '',
			authuser: JSON.parse(authuser)[0],
			isValidLogin: (authuser !== null && authuser !== '') ? true : false,
			editModal: false,
			cpOnly: false,
			modalTitle: 'Edit Profile',

        };
    }

	logoutUser = () => {

		swal({
			title: `${strings.Areyousure}`,
			icon: "warning",
			buttons: true,
			dangerMode: true
		  }).then(logout => {
			if (logout) {

				localStorage.removeItem('authuser')
				this.setState({
					Email: '',
					Password: '',
					authuser: [],
					isValidLogin: false,
					editModal: false,
					cpOnly: false,
					modalTitle: 'Edit Profile'
				});
				window.location.href = '/login';

			}
		});




	}

    changePassword = () => {
		var authuser = localStorage.getItem('authuser');
		var User = JSON.parse(authuser)[0];
        this.setState({
            UserId: User.Id,
            editModal: true,
			cpOnly: true,
			modalTitle: 'change Password'
        });
    }

    editProfile = () => {
		var authuser = localStorage.getItem('authuser');
		var User = JSON.parse(authuser)[0];
        this.setState({
			authuser: User,
            editModal: true,
			cpOnly: false,
			modalTitle: 'Edit Profile'
        });
    }



    render() {

		const path = this.props.location.pathname;

		var CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		  <a
			href="/admin"
			className="navbar-header-user"
			ref={ref}
			onClick={e => {
			  e.preventDefault();
			  onClick(e);
			}}
		  >
			{children}
			<span className="arrowdown-small">&#x25bc;</span>
		  </a>
		));

		// forwardRef again here!
		// Dropdown needs access to the DOM of the Menu to measure it
		var CustomMenu = React.forwardRef(
		  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
			const [value] = useState(0);

			return (
			  <div
				ref={ref}
				style={style}
				className={className}
				aria-labelledby={labeledBy}
			  >
				<ul className="list-unstyled">
				  {React.Children.toArray(children).filter(
					child =>
					  !value || child.props.children.toLowerCase().startsWith(value),
				  )}
				</ul>
			  </div>
			);
		  },
		);

        return (
				<div>
                <div className="dashboard-header" style={{ position: "fixed", top: 0, zIndex: "9" }}>
                    <nav className="navbar navbar-expand-lg top-nav fixed-top" style={{ marginBottom: 0 }}>
                        <a data-place="right" className="navbar-brand" href="/"><img src={config.TOP_LOGO} alt="Logo" style={ {maxWidth: '190px'} } /></a>
						  <ReactBootstrap.Dropdown className="admin-user-dropdown">
							<ReactBootstrap.Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
							  <i className="fa fa-user" aria-hidden="true"></i> <span>Hey! {this.state.authuser.Name}</span>
							</ReactBootstrap.Dropdown.Toggle>

							<ReactBootstrap.Dropdown.Menu as={CustomMenu}>
							  <ReactBootstrap.Dropdown.Item eventKey="1" onClick={() => this.changePassword()}>Change Password</ReactBootstrap.Dropdown.Item>
							  <ReactBootstrap.Dropdown.Item eventKey="2" onClick={() => this.editProfile()}>Edit Profile</ReactBootstrap.Dropdown.Item>
							  <ReactBootstrap.Dropdown.Item eventKey="3" onClick={() => this.logoutUser()}>Log Out</ReactBootstrap.Dropdown.Item>
							</ReactBootstrap.Dropdown.Menu>
						  </ReactBootstrap.Dropdown>
                    </nav>
                </div>

                <div className="nav-left-sidebar sidebar-dark">
                    <div className="slimScrollDiv" style={{ position: 'relative', overflow: 'hidden', width: 'auto', height: '100%' }}><div className="menu-list" style={{ overflow: 'hidden', width: 'auto', height: '100%' }}>
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <button className="d-xl-none d-lg-none" href="#" style={{ display: "none" }}>Menu</button>
                            <button className="navbar-toggler" style={{ display: "none" }} type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" />
                            </button>
                            <div className="collapse navbar-collapse sidebar" id="navbarNav">
                                <ul className="navbar-nav flex-column" style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingLeft: 0,
                                    marginBottom: 0,
                                    listStyle: "none"
                                }}>
                                    <li className="nav-item">
                                        <a data-tip="Projects" className={path === '/admin' || path === '/admin/jobs' ? 'nav-link current' : 'nav-link'} href="/admin/jobs"><i className="fas fa-drafting-compass" /></a>
                                    </li>

									<li className="nav-item">
                                        <a data-place="right" className={path === '/admin/products' ? 'nav-link current' : 'nav-link'} data-tip="Products" href="/admin/products"><i className="fas fa-columns" /></a>
                                    </li>


									<li className="nav-item">
                                        <a data-place="right" className={path === '/admin/material-list' ? 'nav-link current' : 'nav-link'} data-tip="Material Lists" href="/admin/material-list"><i className="fas fa-file-alt" /></a>
                                    </li>

									<li className="nav-item">
                                        <a data-place="right" className={path === '/admin/masters' ? 'nav-link current' : 'nav-link'} data-tip="Masters" href="/admin/masters"><i className="fas fa-hammer" /></a>
                                    </li>

									<li className="nav-item">
                                        <a data-place="right" className={path === '/admin/stock-code-list' ? 'nav-link current' : 'nav-link'} data-tip="Stockcodes" href="/admin/stock-code-list"><i className="fas fa-barcode" /></a>
                                    </li>

                                    <li className="nav-item">
                                        <a data-place="right" className={path === '/admin/customer-list' ? 'nav-link current' : 'nav-link'} data-tip="Customer List" href="/admin/customer-list"><i className="fas fa-phone" /></a>
                                    </li>
									<li className="nav-item">
										<a data-place="right" className={path === '/admin/outstanding' ? 'nav-link current' : 'nav-link'} data-tip="Outstanding" href="/admin/outstanding"><i className="fa fa-money-check-alt" /></a>
									</li>
                                    <li className="nav-item">
                                        <a data-place="right" className={path === '/admin/suppliers' ? 'nav-link current' : 'nav-link'} data-tip="Suppliers" href="/admin/suppliers"><i className="fas fa-industry"></i></a>
                                    </li>

									<i className="fas fa-cabinet-filing"></i>


                                    <li className="nav-item">
                                        <a data-place="right" className={path === '/admin/users' ? 'nav-link current' : 'nav-link'} data-tip="User Management" href="/admin/users"><i className="fa fa-users" /></a>
                                    </li>
									<li className="nav-item">
										<a data-place="right" className={path === '/admin/messages' ? 'nav-link current' : 'nav-link'} data-tip="Global Message" href="/admin/messages"><i className="fas fa-envelope"></i></a>
									</li>

									<li className="nav-item">
										<a data-place="right" className={path === '/admin/imagetips' ? 'nav-link current' : 'nav-link'} data-tip="Video Tips" href="/admin/imagetips"><i className="fas fa-info-circle"></i></a>
									</li>

                                    <li className="nav-item">
										<a data-place="right" className={path === '/admin/menus' ? 'nav-link current' : 'nav-link'} data-tip="Menu" href="/admin/menus"><i className="fas fa-bars"></i></a>
									</li>
                  <li className="nav-item">
										<a data-place="right" className={path === '/admin/metatags' ? 'nav-link current' : 'nav-link'} data-tip="Meta Tags" href="/admin/metatags"><i className="fas fa-tag"></i></a>
									</li>
                  <li className="nav-item">
										<a data-place="right" className={path === '/admin/zipcodes' ? 'nav-link current' : 'nav-link'} data-tip="Zipcodes" href="/admin/zipcodes"><i className="fas fa-map-marker"></i></a>
									</li>
									<li className="nav-item">
										<a data-place="right" className={path === '/admin/slangtranslation' ? 'nav-link current' : 'nav-link'} data-tip="Slang Translation" href="/admin/slangtranslation"><i className="fas fa-language"></i></a>
									</li>

									<li className="nav-item">
										<a data-place="right" className={path === '/admin/activity' ? 'nav-link current' : 'nav-link'} data-tip="Activity" href="/admin/activity"><i className="fas fa-tasks"></i></a>
									</li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                        <div className="slimScrollBar" style={{ background: 'rgb(0, 0, 0)', width: '7px', position: 'absolute', top: '0px', opacity: '0.4', display: 'none', borderRadius: '7px', zIndex: 99, right: '1px', height: '598px' }}></div>
                        <div className="slimScrollRail" style={{ width: '7px', height: '100%', position: 'absolute', top: '0px', display: 'none', borderRadius: '7px', background: 'rgb(51, 51, 51)', opacity: '0.2', zIndex: 90, right: '1px' }}></div>
                </div>
				</div>
        <ReactTooltip />
				<EditProfile editModal={this.state.editModal} cpOnly={this.state.cpOnly} User={this.state.authuser} modalTitle={this.state.modalTitle} />

				</div>

        )
    }
}
export default withRouter(HeaderAdmin);
