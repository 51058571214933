import React, { Component } from 'react';
import config from '../../config';
import { strings } from '../../components/strings';

import CreateProjectModal from './CreateProjectModal';

class Header extends Component {

   constructor(props) {
     super(props)
   }

    render() {
      
      const customer = JSON.parse(this.props.customer)
      const customername = customer && customer.DisplayName ? customer.DisplayName : customer && customer.Name  ? customer.Name : ''
   
        return (
            <div className="dashboard-header">
            <div className="navbar top-nav">
              <header>
                <div className="secondary-header">
                    <div className="container">
                      <div className="welcome"><i className="fa fa-user" aria-hidden="true"></i> <span>{strings.Hey.replace('[name]', customername)}</span></div>
                      <div>
                        <button type="button" onClick={this.props.doLogout}>Logout</button>
                        <CreateNewProject customer={this.props.customer} addproject={this.props.addproject} projects={this.props.projects} />
                      </div>
                    </div>
                </div>
                <div className="container headertop">
                  <div className="row">
                      <div className="col-xs-6 col-sm-3 col-md-3 logobox"><a href="#"><img style={ {maxWidth: '270px'} } className="top-logo" src={config.TOP_LOGO} alt="" /></a>{config.VERSION}</div>
                      <div className="col-xs-6 col-sm-9 col-md-9 header-right">
                        <div className="rightmid pull-right">


                        </div>
                      </div>
                    </div>
                  </div>
              </header>
            </div>
          </div>
        )
    }
}

class CreateNewProject extends Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
    }

    this.openModal =   this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.submit = this.submit.bind(this);

  }

  openModal() {
    this.setState(
      {
        open: true
      }
    )
  }

  closeModal() {
    this.setState(
      {
        open: false
      }
    )
  }

  submit(data) {

   this.closeModal();
   this.props.addproject(data);
  }

  render() {

    return(
      <>
        <button onClick={this.openModal}>Create New Project</button>
        <CreateProjectModal open={this.state.open} close={this.closeModal} submit={this.submit} />
        <FirstStep projects={this.props.projects} />
      </>
    )
  }
}

class FirstStep extends Component {

  constructor(props) {

    super(props);

    this.state = {
      class: 'first-message hide',
      projects: props.projects
    }

    this.close = this.close.bind(this);

  }

  close() {
    
    this.setState( { class: 'first-message hide', projects: this.props.projects }, () => {
      console.log('this', this.state);
    } )
  }

  componentDidUpdate() {

  }

  static getDerivedStateFromProps(nextProps, prevState){

    if (nextProps.projects !== prevState.projects) {
      return { class: 'first-message show' };
    } 
   
   else return null;
 }

  render() {    

    return (
      <>
        <div className={this.state.class}>Please create a new project to start adding products <button onClick={this.close} ><i className="fa fa-times" /></button></div>
      </>
    )    
  }

}

class Product extends Component {
  constructor(props) {
      super(props);

      this.state = {
          product: this.props.product
      }

      this.gotoProduct = this.gotoProduct.bind(this);
  }

  gotoProduct() {
      this.props.gotoProduct(this.state.product.ProductId);
  }

  render() {

      return(
          <button className="top-product-link" onClick={this.gotoProduct}>
              {this.props.product.ProductName}
          </button>
      )
  }
}

export default Header;
