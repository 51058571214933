import React, { Component } from 'react';
import config from "../config";
import swal from "sweetalert";
class GlobalMessage extends Component {
    constructor(props) {

        super(props);
		var authcustomer = JSON.parse(localStorage.getItem('customer'));
        this.state = {
			Customer: (authcustomer) ? authcustomer : [],
			hasSeen: false
        }
        
        this.getMessage = this.getMessage.bind(this)
		this.updateCustomerMessage = this.updateCustomerMessage.bind(this)
    }

	getMessage = async() => {
		if (this.state.Customer.length === 0) {
			return;
		}
		var $this = this;
		var customer = this.state.Customer;
		await fetch(config.BASE_URL + '/api/getmessage/'+customer.CustomerId, {
			headers: {
				"Content-Type": "application/json",
				'Accept': 'application/json',
				"Authorization": 'Bearer ' + ((customer) ? customer.token : '' )
			}
		})
		.then(data => data.json())
		.then(data => {
			if (data.hasOwnProperty('messageId') && !this.state.hasSeen) {
				var htmlObject = document.createElement('div');
				htmlObject.innerHTML = data.Content;
				swal(htmlObject, {
					className: "customer-global-message",
					buttons: {
						confirm: {
							text: "OK",
							closeModal: true
						}
					}
				}).then(function() {
					//console.log(data)	
					$this.updateCustomerMessage(data.messageId, customer)
				})					
			}			
		})
		.catch(error => console.log(error));		
	}

	updateCustomerMessage = async(messageId, customer) => {
		this.setState({
			hasSeen: true,
		}, () => {
					
		})
					
		var data = {
			CustomerId: customer.CustomerId,
			messageId: messageId
		}
		await fetch(config.BASE_URL + '/api/addcustomermessage', {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				'Accept': 'application/json',
				"Authorization": 'Bearer ' + ((customer) ? customer.token : '' )
			}
		})
		.then(data => data.json())
		.then(data => {		
		})
		.catch(error => console.log(error));		
	}
	
    componentDidMount() {
		this.getMessage();
    }

    render() {
        return(
			<>
			</>
        )
    }
}

export default GlobalMessage;