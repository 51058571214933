import React, { Component } from 'react';
import config from "../config";
import Modal from 'react-bootstrap-modal';
import swal from 'sweetalert';
import { withRouter } from "react-router-dom";
import { strings } from './strings';
import validate from './Validation';

class Login extends Component {

  constructor(props) {
    super(props);

    this.state = {
      openLogin: false,
      openSave: false,
      isLogin: false,
      openRegister: false,
      lblDashboard: strings.Dashboard,
      lblSave: strings.Save
    }

    this.openLogin = this.openLogin.bind(this);
    this.openSave = this.openSave.bind(this);
    this.closeLogin = this.closeLogin.bind(this);
    this.closeSave = this.closeSave.bind(this);
    this.doLogin = this.doLogin.bind(this);
    this.userLogin = this.userLogin.bind(this);
    this.loginBtn = this.loginBtn.bind(this);
    this.doLogout = this.doLogout.bind(this);
    this.openRegister = this.openRegister.bind(this);
    this.closeRegister = this.closeRegister.bind(this);
    this.doRegister = this.doRegister.bind(this);
    this.doLoginById = this.doLoginById.bind(this);

    this.saveLogin = this.saveLogin.bind(this);
    this.dashboard = this.dashboard.bind(this);
  }

  dashboard() {
    this.setState( {
      lblDashboard: 'Please wait...'
    }, () => {
      this.props.saveDashboard();
    });
    
  }

  saveLogin() {

    this.setState({ openSave: false });

    let customer = this.isJson(localStorage.getItem('customer'));

    if (customer) {
      if (customer.CustomerId > 0) {
        this.setState({isLogin:true});
        this.props.doLogin();
      }
    }
  }

  doLoginById(id) {
    const param = {id: id};

    fetch(config.BASE_URL + '/api/getcustomer', {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json'
      }
    })
    .then(data => data.json())
    .then(data => {
      if (data.code === 200) {

        this.setState({isLogin: true});

        localStorage.setItem("customer", JSON.stringify(data.response))

        this.closeRegister();

        const success = strings.RegistrationSuccess;

        swal("", success, "success");

        this.props.doLogin();

      }

    })
    .catch(error => console.log(error));

  }

  doRegister(data) {
    this.doLoginById(data.response)
  }

  closeRegister() {
    this.setState({openRegister: false});
  }

  openRegister() {

    const jobProduct = JSON.parse(localStorage.getItem("jobProduct"));

    if (jobProduct && jobProduct.length > 0) {
      this.setState( { openSave: true} )
    } else {
      this.setState({openRegister: true});
    }
  }

  doLogout() {

    swal({
      title: `${strings.Areyousure}`,
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(logout => {
      if (logout) {

        this.setState({isLogin: false});

        const customer = JSON.parse(localStorage.getItem("customer"));

        localStorage.removeItem("customer");
        localStorage.removeItem("project");
        localStorage.removeItem('jobProduct');
        localStorage.removeItem('postData');

        const data = JSON.stringify({customerId: customer.CustomerId, customerName: customer.Name, activity: 'logout'});

        fetch(config.BASE_URL + '/api/activity-log', {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
              "Content-Type": "application/json"
          }
        })
        .then()
        .then()
        .catch(error => console.log(error));

        this.props.doLogout();

      }
  });



  }

  userLogin() {

    const save = this.props.savingStatus === 'saving' ? 'Saving, please wait...' : strings.Save;

    return (
      <>
        <button onClick={this.doLogout} type="button">{strings.Logout}</button>

        { this.props.page === 'home' || this.props.page === 'edit-order' ?
          <button onClick={this.openSave} type="button">{save}</button>
        : '' }
        <button onClick={this.dashboard} type="button">{this.state.lblDashboard}</button>
      </>
    )
  }

  loginBtn() {

    const save = this.props.savingStatus === 'saving' ? 'Saving, please wait...' : strings.Save;

    return (
      <>
        <button onClick={this.openRegister} type="button">{strings.Register}</button>
        <button onClick={this.openSave} type="button">{save}</button>
        <button onClick={this.openLogin} type="button">{strings.Login}</button>
      </>
    )
  }

  closeLogin() {
    this.setState({ openLogin: false});
  }

  closeSave() {
    this.setState({ openSave: false });
  }

  openLogin() {

    const jobProduct = JSON.parse(localStorage.getItem("jobProduct"));

    if (jobProduct && jobProduct.length > 0) {
      this.setState( {openSave: true} );
    } else {
      this.setState({ openLogin: true});
    }
  }

  openSave() {

    const jobProduct = JSON.parse(localStorage.getItem("jobProduct"));

    if (this.state.isLogin === false) {

      if (jobProduct && jobProduct.length > 0) {

        this.setState({ openSave: true});

      } else {

        swal("", `${strings.Pleaseadditemtocart}`, "warning")

      }

    } else {
      this.props.saveProject()
    }
  }

  doLogin(data) {
    /*this.setState({isLogin:true});
    this.closeLogin();
    this.props.doLogin();*/

	  const { from } = this.props.location.state || {from:{pathname:''}};

    if (from.pathname.indexOf('/dashboard/') > -1){
      window.location = from.pathname
    }else{
      window.location = '/dashboard'
    }
  }

  isJson(str) {

    try {
        return JSON.parse(str);
    } catch (e) {
        return false;
    }
  }

  componentDidMount() {

    let customer = this.isJson(localStorage.getItem('customer'));

    if (customer) {
      if (customer.CustomerId > 0) {
        this.setState({isLogin:true});
        this.props.doLogin();
      }
    }

  }

  render() {

    let loginButton = this.props.isLogin ? this.userLogin() : this.loginBtn();

    return (
      <div>

        {loginButton}

        <LoginModal t={this.props.t} open={this.state.openLogin} close={this.closeLogin} submit={this.doLogin} />
        <SaveModal open={this.state.openSave} close={this.saveLogin} saveProject={this.props.saveProject}  />
        <RegisterModal t={this.props.t} open={this.state.openRegister} close={this.closeRegister} submit={this.doRegister} />

      </div>
    )
  }
}

class RegisterModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nameClass : 'form-control',
      emailClass: 'form-control',
      mobileClass: 'form-control',
      errorName: 'hidden',
      errorEmail: 'hidden',
      errorMobile: 'hidden',
      name: '',
      email: '',
      mobile: '',
      topError: 'hidden'
    }

    this.handleName = this.handleName.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleMobile = this.handleMobile.bind(this);
    this.submit = this.submit.bind(this);
    this.ValidateEmail = this.ValidateEmail.bind(this);

    this.keyPressed = this.keyPressed.bind(this);

  }

  keyPressed(e) {
    if (e.keyCode === 13) {
      this.submit();
    }
  }

  ValidateEmail(email)
  {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email))
    {
      return (true)
    }

    return (false)
  }

  handleName(e) {
    this.setState({name: e.target.value, nameClass: 'form-control', errorName: 'hidden', topError: 'hidden'});
  }

  handleEmail(e) {
    this.setState({email: e.target.value, emailClass: 'form-control', errorEmail: 'hidden', topError: 'hidden'});
  }

  handleMobile(e) {
    this.setState({mobile: e.target.value, mobileClass: 'form-control', errorMobile: 'hidden', topError: 'hidden'});
  }

  submit() {
    let valid = true;

    const name = this.state.name;
    const mobile = this.state.mobile;
    const email = this.state.email;

    const isEmailValid = this.ValidateEmail(email);
    const isPhoneValid = validate.ValidatePhone(mobile);

    if (name === '') {
      valid = false;
      this.setState(
        {
          nameClass: 'error border-danger form-control',
          errorName: 'd-block'
        }
      )
    }

    if (mobile === '') {
      valid = false;
      this.setState(
        {
          mobileClass: 'error border-danger form-control',
          errorMobile: 'd-block'
        }
      )
    }

    if (!isEmailValid) {
      valid = false;
      this.setState(
        {
          emailClass: 'form-control border-danger error',
          errorEmail: 'd-block',
        }
      )
    }

    if (!isPhoneValid) {
      valid = false;
      this.setState(
        {
          mobileClass: 'error border-danger form-control',
          errorMobile: 'd-block'
        }
      )
    }

    if (isEmailValid && valid) {

      fetch(config.BASE_URL + '/api/register', {
        method: "POST",
        body: JSON.stringify({name, email, mobile}),
        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json'
        }
      })
      .then(data => data.json())
      .then(data => {
        //console.log(data);
        if (data.error) {
          this.setState(
            {
              topError: 'border-danger bg-danger p-2 m-2'
            }
          )
        } else if (data.success) {
          this.setState({name: '', email: '', mobile: ''});
          if (data.activation_required === 'true'){
            this.props.close();
            swal({
                title: 'Activation Required',
                text: data.success,
                icon: "info"
              });
          }else{
            this.props.submit(data);
          }

        }
      })
      .catch(error => console.log(error));

      /*const data = {name:this.state.name, email:this.state.email, mobile:this.state.mobile}*/


    }
  }



  render() {



    return (
        <Modal
          show={this.props.open}
          onHide={this.props.close}
          className="RegisterModal"
        >
          <Modal.Header closeButton>
            {strings.Register}
          </Modal.Header>
          <Modal.Body>

          <div className={this.state.topError}>{strings.EmailAlreadyExists}</div>

          <div className="form-group">
          <label>{strings.Name}</label>
              <input type="text" value={this.state.name} className={this.state.nameClass} onChange={this.handleName} onKeyDown={this.keyPressed}  />
              <span className={this.state.errorName}>{strings.Entername}</span>
            </div>

            <div className="form-group">
            <label>{strings.Email}</label>
              <input type="text" value={this.state.email} className={this.state.emailClass} onChange={this.handleEmail} onKeyDown={this.keyPressed}  />
              <span className={this.state.errorEmail}>{strings.Entervalidemail}</span>
            </div>

            <div className="form-group">
            <label>{strings.Mobile}</label>
              <input type="text" value={this.state.mobile} className={this.state.mobileClass} onChange={this.handleMobile} onKeyDown={this.keyPressed} />
              <span className="mobile_registration" style={{margin: '8px 0', display: 'block'}}>{strings.RegPhoneMessage}</span>
              <span className={this.state.errorMobile}>{strings.Entermobilenumber}</span>
            </div>

            <button onClick={this.submit} type="button" className="btn cartbtn purchasebtn">{strings.Register}</button>

          </Modal.Body>
        </Modal>
    )
  }
}

class LoginModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      mobile: '',
      emailClass: 'form-control',
      mobileClass: 'form-control',
      errorMobile: 'hidden',
      errorEmail: 'hidden'
    }

    this.submit = this.submit.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleMobile = this.handleMobile.bind(this);
    this.keyPressed = this.keyPressed.bind(this);


  }

  keyPressed(e) {
    if (e.keyCode === 13) {
      this.submit();
    }
  }

  ValidateEmail(email)
  {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email))
    {
      return (true)
    }

    return (false)
  }

  submit() {
    let valid = true;

    if (this.ValidateEmail(this.state.email) === false)  {
      valid = false;
      this.setState({emailClass: 'form-control border border-danger', errorEmail: 'error'});
    }

    if (this.state.email === '') {
      valid = false;
      this.setState({emailClass: 'form-control border border-danger', errorEmail: 'error'});
    }

    if (this.state.mobile === '' || isNaN(this.state.mobile) === true) {
      valid = false;
      this.setState({mobileClass: 'form-control border border-danger', errorMobile: 'error'});
    }

    if (valid) {

      const user_login_data = {email: this.state.email, mobile: this.state.mobile}
      const check_blocked_data = {
        Email: this.state.email,
        MobileNumber: this.state.mobile
      }

        //Check if customer is blocked
        fetch(config.BASE_URL + '/api/isblocked', {
          method: 'POST',
          body: JSON.stringify(check_blocked_data),
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        })
        .then(data => data.json())
        .then( data => {
          console.log(data);
          if(data[0].block === 1){
              swal("", "This user is Blocked!", "error");
          } else{
            //userlogin
            fetch(config.BASE_URL + '/api/login', {
              method: "POST",
              body: JSON.stringify(user_login_data),
              headers: {
                  "Content-Type": "application/json",
                  'Accept': 'application/json'
              }
            })
            .then(data => data.json())
            .then(data => {

              if (data.code === 200) {

                this.setState({email: '', mobile: ''});

                const project = data.project;

                const customer = data.customer

                swal("", "Login Success!", "success");

                localStorage.setItem("customer", JSON.stringify(customer));

                if (project) {
                  localStorage.setItem("project", JSON.stringify(data.project));
                }

                this.props.submit(data);

              } else {

                swal("", "Invalid Login!", "error");

              }

            })
            .catch(error => console.log(error));
        }}
        ).catch(error => console.log(error));

    }
  }

  handleEmail(event) {
    this.setState({email: event.target.value, emailClass: 'form-control', errorEmail: 'hidden'});
  }

  handleMobile(event) {
    this.setState({mobile: event.target.value, mobileClass: 'form-control', errorMobile: 'hidden'});
  }


  render() {
    return (
      <Modal
          show={this.props.open}
          onHide={this.props.close}
          className="LoginModal"
        >

       <Modal.Header closeButton>
          {strings.Login}
       </Modal.Header>

        <Modal.Body>
          <div className="form-group">
          <label>{strings.Email}</label>
            <input type="text" name="email" className={this.state.emailClass} value={this.state.email} onChange={this.handleEmail} onKeyDown={this.keyPressed} />
            <span className={this.state.errorEmail}>{strings.Entervalidemail}</span>
          </div>
          <div className="form-group">
          <label>{strings.Mobile}</label>
            <input type="text" name="mobile" className={this.state.mobileClass} value={this.state.mobile} onChange={this.handleMobile} onKeyDown={this.keyPressed} />
            <span className={this.state.errorMobile}>{strings.Entermobilenumber}</span>
          </div>
          <button onClick={this.submit} type="button" className="btn cartbtn purchasebtn">{strings.Login}</button>
        </Modal.Body>

      </Modal>

    )

  }

}

class SaveModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      mobile: '',
      project: '',
      username: '',
      usermobile: '',
      userproject: '',
      nameClass : 'form-control',
      emailClass: 'form-control',
      mobileClass: 'form-control',
      projectClass: 'form-control',
      usernameClass: 'form-control',
      usermobileClass: 'form-control',

      errorName: 'hidden',
      errorEmail: 'hidden',
      errorMobile: 'hidden',
      errorProject: 'hidden',
      errorUsername: 'hidden',
      errorUsermobile: 'hidden',

      errorRegister: 'hidden',
      errorLogin: 'hidden'
    }

    this.handleChange = this.handleChange.bind(this);
    this.login = this.login.bind(this);
    this.register = this.register.bind(this);
    this.ValidateEmail = this.ValidateEmail.bind(this);

  }

  ValidateEmail(email)
  {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email))
    {
      return (true)
    }

    return (false)
  }

  handleChange(e) {
    this.setState({
      [e.target.name] : e.target.value,
      nameClass : 'form-control',
      emailClass: 'form-control',
      mobileClass: 'form-control',
      projectClass: 'form-control',
      usernameClass: 'form-control',
      usermobileClass: 'form-control',

      errorName: 'hidden',
      errorEmail: 'hidden',
      errorMobile: 'hidden',
      errorProject: 'hidden',
      errorUsername: 'hidden',
      errorUsermobile: 'hidden',

      errorRegister: 'hidden',
      errorLogin: 'hidden'
    });
  }


  login() {
    const email = this.state.username;
    const mobile = this.state.usermobile;

    let valid = true;

    if (email === '') {
      valid = false;
      this.setState({usernameClass: 'form-control border-danger', errorUsername: 'text-danger small'})
    }

    if (mobile === '') {
      valid = false;
      this.setState({usermobileClass: 'form-control border-danger', errorUsermobile: 'text-danger small'})
    }

    if (!this.ValidateEmail(email)) {
      valid = false;
      this.setState({usernameClass: 'form-control border-danger', errorUsername: 'text-danger small'})
    }

    if (valid) {
      const data = this.state;

      fetch(config.BASE_URL + '/api/savelogin', {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json'
        }
      })
      .then(data => data.json())
      .then(data => {
        if (data.error) {
          this.setState({errorLogin: 'p-2 text-bold border-danger bg-danger'})
        } else if (data.code === 200) {

            this.setState({isLogin: true});

            localStorage.setItem("customer", JSON.stringify(data.customer))
            localStorage.setItem("project", JSON.stringify(data.project))

            this.props.close(data);

            this.props.saveProject();

            //swal("", `${strings.LoginSuccessSaveOrder}`, "success");

        }
      })
      .catch( error => {

      });

    }
  }

  register() {
    const name = this.state.name;
    const mobile = this.state.mobile;
    const email = this.state.email;
    const project = this.state.project;

    let valid = true;

    if (name === '') {
      valid = false;
      this.setState({nameClass: 'form-control border-danger', errorName: 'text-danger small'});
    }

    if (mobile === '') {
      valid = false;
      this.setState({mobileClass: 'form-control border-danger', errorMobile: 'text-danger small'});
    }

    if (email === '') {
      valid = false;
      this.setState({emailClass: 'form-control border-danger', errorEmail: 'text-danger small'});
    } else if (!this.ValidateEmail(email)) {
      valid = false;
      this.setState({emailClass: 'form-control border-danger', errorEmail: 'text-danger small'});
    }

    if (!validate.ValidatePhone(mobile)){
      valid = false;
      this.setState({mobileClass: 'form-control border-danger', errorMobile: 'text-danger small'});
    }
    if (project === '') {
      valid = false;
      this.setState({projectClass: 'form-control border-danger', errorProject: 'text-danger small'});
    }

    if (valid) {


      const data = this.state;

      fetch(config.BASE_URL + '/api/saveregister', {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json'
        }
      })
      .then(data => data.json())
      .then(data => {
        if (data.error) {
          this.setState({errorRegister: 'p-2 text-bold border-danger bg-danger'})
        } else if (data.code == 200) {

            this.setState({isLogin: true});

            localStorage.setItem("customer", JSON.stringify(data.customer))
            localStorage.setItem("project", JSON.stringify(data.project))

            this.props.close(data);

            this.props.saveProject();

            //swal("", "Registration Success, please click save again to save the order", "success");

        }
      })
      .catch( error => {

      });

    }
  }



  render() {

    return (
      <Modal
        show={this.props.open}
        onHide={this.props.close}
        className="SaveModal"
      >
          <Modal.Header closeButton>
            {strings.LogintoSaveProject}
          </Modal.Header>
          <Modal.Body>
              <div className="row">

                <div className="col-md-6">

                  <h3>{strings.NewToFlatpacksWa}</h3>

                  <p>{strings.PleaseRegisterHere}</p>

                  <div className={this.state.errorRegister}>{strings.EmailAlreadyExists}</div>

                  <div className="form-group">

                  <label>{strings.Name}</label>
                    <input type="text" name="name" className={this.state.nameClass} onChange={this.handleChange} />
                    <span className={this.state.errorName}>{strings.Enteryourname}</span>

                  </div>

                  <div className="form-group">

                    <label>{strings.Email}</label>
                    <input type="text" name="email" className={this.state.emailClass} onChange={this.handleChange} />
                    <span className={this.state.errorEmail}>{strings.Entervalidemail}</span>

                  </div>

                  <div className="form-group">

                    <label>{strings.Mobile}</label>
                    <input type="text" name="mobile" className={this.state.mobileClass} onChange={this.handleChange} />
                    <span className={this.state.errorMobile}>{strings.Entermobilenumber}</span>

                  </div>

                  <div className="form-group">

                    <label>{strings.AddNewProject}</label>
                    <input type="text" name="project" placeholder={strings.Enterprojectname} onChange={this.handleChange} className={this.state.projectClass} />
                    <span className={this.state.errorProject}>{strings.Enterprojectname}</span>

                  </div>

                  <div className="form-group">

                  <button onClick={this.register} className="btn cartbtn purchasebtn">{strings.Register}</button>

                  </div>

                </div>

                <div className="col-md-6">

                  <h3>{strings.FlatpacksWACustomer}</h3>

                  <p>{strings.Alreadyregister} {strings.Loginhere}</p>

                  <div className={this.state.errorLogin}>{strings.Invalidemailandmobilenumber}</div>

                  <div className="form-group">

                    <label>{strings.Email}</label>
                    <input type="text" name="username" className={this.state.usernameClass} onChange={this.handleChange} />
                    <span className={this.state.errorUsername}>{strings.Entervalidemail}</span>

                  </div>

                  <div className="form-group">

                    <label>{strings.Mobile}</label>
                    <input type="text" name="usermobile" className={this.state.usermobileClass} onChange={this.handleChange} />
                    <span className={this.state.errorUsermobile}>{strings.Entermobilenumber}</span>

                  </div>

                  <div className="form-group">

                    <label>{strings.AddNewProject}</label>
                    <input type="text" name="userproject" placeholder={strings.Enterprojectname} onChange={this.handleChange} className={this.state.projectClass} />
                    <span className={this.state.errorProject}>{strings.Enterprojectname}</span>

                  </div>

                  <div className="form-group">

                    <button onClick={this.login} className="btn cartbtn purchasebtn">{strings.Login}</button>

                  </div>

                </div>

              </div>
          </Modal.Body>
      </Modal>
    )
  }

}

export default withRouter(Login);
