import React, { Component } from 'react';
import config from '../config';


class Footer extends Component {


  render() {
    return (
      <footer>
        <div className="container">
          <div className="row">
            <ColumnOne />
            <ColumnTwo />
            <ColumnThree />
          </div>
          
        </div>
        <Copyright />
      </footer>
    )
  }
}

class Copyright extends Component {


  render() {
    return (
      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              &copy; 2020 Flatpacks | All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    )
  }

}


class ColumnOne extends Component {

  render() {
    
    return (
      <div className="col-md-4">
        <a href="/" className="logo"><img src={config.FOOTER_LOGO} title="Flatpacks Australia" alt="Flatpacks Australia" /></a>

        <div className="widgets">

          <h4>About Flatpacks Australia:</h4>

          <p>We supply custom made cabinetry for Australia's Cabinet Makers and Builders.</p>

        </div>

        <div className="widgets">

          <ul>
            <li><i className="fa fa-envelope" />{ config.EMAIL }</li>
            <li><i className="fa fa-phone" />{ config.PHONE }</li>
          </ul>

        </div>
        
      
      </div>
    )
  } 

}

class ColumnTwo extends Component {

  constructor(props) {
    super(props);

    this.state = {
      news: [

      ]
    }
  }

  render() {
    
    return (
      <div className="col-md-4">
          <div className="widgets">  

              <h4>Office Hours</h4>
              <p><i className="fas fa-clock" />Monday-Friday: 9:00am - 5:00pm</p>

              <p><em>Showroom by appointment or booking.</em></p>

              <p><i className="fas fa-map-marker-alt" />Our Factory / Showroom Address:<br />
              8 Geelong Court<br />BIBRA LAKE WA 6163</p>

          </div>  
      </div>
    )
  }

}


class ColumnThree extends Component {

  render() {
    
    return (
      <div className="col-md-4">
        <h4>Our Partners</h4>
        <div className="widgets our-partners">
          <a href="/partners/laminex"><img alt="Laminex" src="/images/laminex.png" /><img alt="Laminex" className="colored" src="/images/laminex-green.png" /></a>
          <a href="/partners/polytec"><img alt="Polytec" src="/images/polytec.png" /><img alt="Polytec" className="colored" src="/images/polytec-green.png" /></a>
          <a href="/partners/blum"><img alt="Blum" src="/images/blum.png" /><img alt="Blue" className="colored" src="/images/blum-green.png" /></a>
          <a href="/partners/smartstone"><img alt="SmartStone" src="/images/smartstone.png" /><img alt="SmartStone" className="colored" src="/images/smartstone-green.png" /></a>
          <a href="/partners/formica"><img alt="Formica" src="/images/formica.png" /><img alt="Formica" className="colored" src="/images/formica-green.png" /></a>
          <a href="/partners/hafele"><img alt="Hafele" src="/images/hafele.png" /><img alt="Hafele" className="colored" src="/images/hafele-green.png" /></a>
          <a href="/partners/corian"><img alt="Corian" src="/images/corian.png" /><img alt="Corian" className="colored" src="/images/corian-green.png" /></a>
          <a href="/about-us"><img alt="Cmawa" src="/images/cmawa.png" /><img alt="Cmawa" className="colored" src="/images/cmawa-green.png" /></a>
        </div>
      </div>
    )
  }

}



export default Footer;