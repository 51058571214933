import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import config from "../config";
import HeaderAdmin from '../components/HeaderAdmin';
import FooterAdmin from '../components/FooterAdmin';
import Modal from 'react-bootstrap-modal';
import {Helmet} from "react-helmet";
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import swal from 'sweetalert';
import { strings } from '../components/strings';

class Metatags extends Component {

	constructor(props) {
		super(props);
		var authuser = JSON.parse(localStorage.getItem('authuser'));
		this.state = {
			AuthUser: (authuser) ? authuser[0] : [],
			data: [],
			columnWidth: [15, 15, 15, 45, 10],
			open: false
		}

		this.get = this.get.bind(this)
		this.open = this.open.bind(this);
		this.close = this.close.bind(this);
		this.submit = this.submit.bind(this);
		this.delete = this.delete.bind(this);
		this.onSortChange = this.onSortChange.bind(this);
		this.editFormatter = this.editFormatter.bind(this);
		this.headerFormatter = this.headerFormatter.bind(this);
		this.renderlist = this.renderlist.bind(this);
	}

	renderlist(data){
		this.setState({data: data.items}, () => {
			for (let index = 0; index < 15; index += 1) {
				if ($(`.react-bootstrap-table thead th:eq(${index})`).length === 0) break; // break if cant find any more columns

				$(`.react-bootstrap-table thead th:eq(${index})`).resizable({
				minWidth: 50,
				handles: 'e',
				resize: (__event, ui) => {
				const newColumnWidths = [...this.state.columnWidth];
				const container = $('.react-bootstrap-table').width();
				newColumnWidths[index] = ui.size.width / container * 100;
				this.setState({ columnWidth: newColumnWidths });
				},
				});

			}
			});
	}

	get() {
		fetch(config.BASE_URL + '/api/meta/get', {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				'Accept': 'application/json'
			  }
			})
			.then(data => data.json())
			.then(data => {
				this.renderlist(data)
			})
			.catch(error => console.log(error));
	}

    componentDidMount() {
      this.get();
    }

	open() {
		this.setState({open:true});
	}

	close() {
		this.setState({open:false});
	}

	submit(data) {
		this.add(data);
		this.close();
	}

	add(data) {
		const authuser = this.state.AuthUser;
		fetch(config.BASE_URL + '/api/meta/add', {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				'Accept': 'application/json',
				"Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
			}
		})
		.then(data => data.json())
		.then(data => {
			this.setState({data: data.items});
		})
		.catch(error => console.log(error));
	}

	delete(ID){

		swal({
      title: `${strings.Areyousure}`,
      text: "Once deleted, you will not be able to recover this record",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
				const authuser = this.state.AuthUser;
				const data = {
					metaid: ID
				}
				fetch(config.BASE_URL + '/api/meta/delete', {
				  method: "POST",
				  body: JSON.stringify(data),
				  headers: {
					  "Content-Type": "application/json",
					  'Accept': 'application/json',
					  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
					}
				  })
				  .then(data => data.json())
				  .then(data => {
						this.renderlist(data)
				  })
				  .catch(error => console.log(error));
      } else {
          return false;
      }
  });

	}

	save(data) {
		console.log('data', data)
		const authuser = this.state.AuthUser;
		fetch(config.BASE_URL + '/api/meta/update', {
		  method: "POST",
		  body: JSON.stringify(data),
		  headers: {
			  "Content-Type": "application/json",
			  'Accept': 'application/json',
			  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
			}
		  })
		  .then(data => data.json())
		  .then(data => {

		  })
		  .catch(error => console.log(error));
	}


	editFormatter(cell, row) {
		return  <button className="link-btn" onClick={() => this.delete(row.metaid)}>
					<i className="fa fa-trash" aria-hidden="true"></i>
				</button>;
  }

	headerFormatter(cell, row){
		return  <button className="btn purchasebtn" onClick={() => this.open()}>
					Add
				</button>;
	}

	onSortChange(field, order) {
		//console.log(field, order)
		localStorage.setItem("MetaSort", JSON.stringify({
		sortName: field,
		sortOrder: order
		}));
	}

	render() {
		var MetaSort = localStorage.getItem('MetaSort');

		if (MetaSort) {
			MetaSort = JSON.parse(MetaSort)
		}

		const defaultSort = {
			dataField: (MetaSort) ? MetaSort.sortName : null,
			order: (MetaSort) ? MetaSort.sortOrder : null,
		}


		const columns = [{
			dataField: 'site',
			text: 'Site',
			sort: true,
			editable: false,
			headerStyle: {width: this.state.columnWidth[0] + '%', verticalAlign: 'middle'},
			style: {verticalAlign: 'middle'},
			  onSort: this.onSortChange
		}, {
			dataField: 'page',
			sort: true,
			text: 'Slug',
			editable: false,
			style: {whiteSpace: 'normal', verticalAlign: 'middle'},
			headerStyle: {verticalAlign: 'middle', width: this.state.columnWidth[1] + '%'},
			onSort: this.onSortChange
		}, {
			dataField: 'metaname',
			sort: true,
			text: 'Meta Name',
			style: {whiteSpace: 'normal', verticalAlign: 'middle'},
			headerStyle: {verticalAlign: 'middle', width: this.state.columnWidth[2] + '%'},
			onSort: this.onSortChange
		}, {
			dataField: 'metavalue',
			sort: true,
			text: 'Meta Value',
			style: {whiteSpace: 'normal', verticalAlign: 'middle'},
			headerStyle: {verticalAlign: 'middle', width: this.state.columnWidth[3] + '%'},
			onSort: this.onSortChange
		}, {
			dataField: 'metaid',
			text: 'Meta ID',
			editable: false,
			sort: false,
			style: {whiteSpace: 'normal', padding: '8px', verticalAlign: 'middle'},
			formatter: this.editFormatter,
			headerFormatter: this.headerFormatter,
			headerStyle: {verticalAlign: 'middle', width: this.state.columnWidth[4] + '%'},
			onSort: this.onSortChange
		}];

		return (
			<div className="admin-wrapper">
				<HeaderAdmin />
				<div className="dashboard-wrapper partscontent">
				  <div className="dashboardhome">
					  <div className="container-fluid dashboard-content jobspagecnt">
						<div className="row">
						  <div className="col-md-12">
							<ol className="breadcrumb breadm">
								<li><a href="/admin">Admin</a></li>
								<li><i className="fa fa-angle-right midarrow" /><a href="/admin/metatags">Meta Tags</a></li>
							</ol>
						  </div>
						</div>

						<div className="row">
							<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
								<BootstrapTable
									keyField="metaid"
									data={ this.state.data }
									columns={ columns }
									sort={defaultSort}
									cellEdit={ cellEditFactory({
										mode: 'click',
										blurToSave: true,
										afterSaveCell: (oldValue, newValue, row, column) => {
											this.save(row)
										}
									})}
								/>

								<MetaModal open={this.state.open} close={this.close} submit={this.submit} />

							</div>
						</div>

						</div>
					</div>

				<FooterAdmin />
				</div>
			</div>
		  )
	}

}

class MetaModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      site  : 'cabinet-maker.flatpacksaustralia.com.au',
      page  : '/',
      metaname: '',
			metavalue: '',
			metaClass: 'form-control',
      errorClass: 'hidden'
    }

    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name] : e.target.value, metaClass: 'form-control', errorClass: 'hidden'} )
  }

  submit() {
    const zipcode = this.state.zipcode;

    let valid = true;

    if (zipcode == '') {
      valid = false;
      this.setState({zipcodeClass: 'form-control border-danger', errorClass: 'text-red'})
    }

    if (valid) {
      this.props.submit(this.state);
    }
  }

  render() {

    return (
      <Modal
          show={this.props.open}
          onHide={this.props.close}
          className="LoginModal"
        >

       <Modal.Header closeButton>
         Add Meta Tags
       </Modal.Header>

        <Modal.Body>
          <div className="form-group">
            <label>Site</label>
						<select name="site" onChange={this.handleChange} className="form-control">
                <option value="cabinet-maker.flatpacksaustralia.com.au" selected>cabinet-maker.flatpacksaustralia.com.au</option>
								<option value="flatpacksaustralia.com.au">flatpacksaustralia.com.au</option>
            </select>
          </div>
					<div className="form-group">
            <label>Page</label>
            <select name="page" onChange={this.handleChange} className="form-control">
                <option value="/" selected>/</option>
								<option value="/about-us">/about-us</option>
								<option value="/contact-us">/contact-us</option>
            </select>
          </div>
					<div className="form-group">
            <label>Meta Name</label>
            <input type="text" name="metaname" className={this.state.metaClass} onChange={this.handleChange} />
						<span className={this.state.errorClass}>Enter Meta Name</span>
          </div>
					<div className="form-group">
            <label>Meta Value</label>
            <textarea type="number" name="metavalue" className={this.state.metaClass} onChange={this.handleChange}></textarea>
						<span className={this.state.errorClass}>Enter Meta Value</span>
          </div>
          <button onClick={this.submit} type="button" className="btn cartbtn purchasebtn">Submit</button>
        </Modal.Body>

      </Modal>

    )

  }

}


export class RenderMetaTags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metatags: []
    }
    this.get = this.get.bind(this);
  }

	get(path) {
		const data = {
			path: path,
			site: 'cabinet-maker.flatpacksaustralia.com.au'
		}

		fetch(config.BASE_URL + '/api/meta/getpath', {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				'Accept': 'application/json'
			  }
			})
			.then(data => data.json())
			.then(data => {
				this.setState({
					metas: data.items
				})
			})
			.catch(error => console.log(error));
	}
	componentDidMount() {
		const path = window.location.pathname
		this.get(path);
	}
  render() {
		const {metas} = this.state
    return (
			<Helmet>
			{ metas && metas.length > 0 ? metas.map((meta, key) => (
					meta.metaname == 'title' ? <title>{meta.metavalue}</title> : <meta name={meta.metaname} content={meta.metavalue} />
				)) : ""
			}
			</Helmet>
		)
  }
}

export default Metatags
