import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import config from "../config";
import HeaderAdmin from '../components/HeaderAdmin';
import FooterAdmin from '../components/FooterAdmin';
import swal from 'sweetalert';
import Modal from 'react-bootstrap-modal';
import { strings } from '../components/strings';

import Dropzone from 'react-dropzone'
import axios from 'axios';
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';

const noCost = 'Cost Items does not include GST'

class MaterialList extends Component {

  constructor(props) {
    super(props);
	var authuser = JSON.parse(localStorage.getItem('authuser'));
    this.state = {
		AuthUser: (authuser) ? authuser[0] : [],
      material: [],
      brand: [],
      colour: [],
      finish: [],
      substrate: [],
	  doorstyle: []
    }

    this.getMaterials = this.getMaterials.bind(this);

    this.saveMaterial = this.saveMaterial.bind(this);
    this.saveBrand = this.saveBrand.bind(this);
    this.saveColour = this.saveColour.bind(this);
    this.saveFinish = this.saveFinish.bind(this);
    this.saveSubstrate = this.saveSubstrate.bind(this);
	this.saveDoorStyle = this.saveDoorStyle.bind(this);

    this.addColour = this.addColour.bind(this);
    this.addMaterial = this.addMaterial.bind(this);
    this.addFinish = this.addFinish.bind(this);
    this.addBrand = this.addBrand.bind(this);
    this.addSubstrate = this.addSubstrate.bind(this);
	this.addDoorStyle = this.addDoorStyle.bind(this);

    this.deleteMaterial = this.deleteMaterial.bind(this);
    this.deleteBrand = this.deleteBrand.bind(this);
    this.deleteFinish = this.deleteFinish.bind(this);
    this.deleteColour = this.deleteColour.bind(this);
    this.deleteSubstrate = this.deleteSubstrate.bind(this);
	this.deleteDoorStyle = this.deleteDoorStyle.bind(this);
  }

  deleteMaterial(data) {
	var authuser = this.state.AuthUser;
    fetch(config.BASE_URL + '/api/deletematerial', {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
		  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
        }
      })
      .then(data => data.json())
      .then(data => {
        this.getMaterials();
      })
      .catch(error => console.log(error));
  }

  deleteBrand(data) {
	var authuser = this.state.AuthUser;
    fetch(config.BASE_URL + '/api/deletebrand', {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
		  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
        }
      })
      .then(data => data.json())
      .then(data => {
        this.getMaterials();
      })
      .catch(error => console.log(error));
  }

  deleteFinish(data) {
	var authuser = this.state.AuthUser;
    fetch(config.BASE_URL + '/api/deletefinish', {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
		  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
        }
      })
      .then(data => data.json())
      .then(data => {
        this.getMaterials();
      })
      .catch(error => console.log(error));
  }

  deleteColour(data) {
	var authuser = this.state.AuthUser;
    fetch(config.BASE_URL + '/api/deletecolour', {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
		  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
        }
      })
      .then(data => data.json())
      .then(data => {
        this.getMaterials();
      })
      .catch(error => console.log(error));
  }


  addColour(data) {
	var authuser = this.state.AuthUser;
    fetch(config.BASE_URL + '/api/addcolour', {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
		  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
        }
      })
      .then(data => data.json())
      .then(data => {
        this.getMaterials();
      })
      .catch(error => console.log(error));
  }

  addMaterial(data) {
	var authuser = this.state.AuthUser;
    fetch(config.BASE_URL + '/api/addmaterial', {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
		  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
        }
      })
      .then(data => data.json())
      .then(data => {
        this.getMaterials();
      })
      .catch(error => console.log(error));
  }

  addBrand(data) {
	var authuser = this.state.AuthUser;
    fetch(config.BASE_URL + '/api/addbrand', {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
		  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
        }
      })
      .then(data => data.json())
      .then(data => {
        this.getMaterials();
      })
      .catch(error => console.log(error));
  }

  addFinish(data) {
	var authuser = this.state.AuthUser;
    fetch(config.BASE_URL + '/api/addfinish', {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
		  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
        }
      })
      .then(data => data.json())
      .then(data => {
        this.getMaterials();
      })
      .catch(error => console.log(error));
  }

  saveFinish(data) {
	var authuser = this.state.AuthUser;
    //console.log('saving'); console.log(data.row);

    fetch(config.BASE_URL + '/api/savefinish', {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
		  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
        }
      })
      .then(data => data.json())
      .then(data => {
        this.getMaterials();
      })
      .catch(error => console.log(error));
  }

  saveColour(data) {
	var authuser = this.state.AuthUser;
    console.log('saving colour'); console.log(data);

    fetch(config.BASE_URL + '/api/savecolour', {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
		  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
        }
      })
      .then(data => data.json())
      .then(data => {
        this.getMaterials();
      })
      .catch(error => console.log(error));
  }

  saveBrand(data) {
	var authuser = this.state.AuthUser;
    console.log('saving'); console.log(data);

    fetch(config.BASE_URL + '/api/savebrand', {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
		  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
        }
      })
      .then(data => data.json())
      .then(data => {
        this.getMaterials();
      })
      .catch(error => console.log(error));
  }

  saveMaterial(data) {
	var authuser = this.state.AuthUser;
    fetch(config.BASE_URL + '/api/savematerial', {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
		  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
        }
      })
      .then(data => data.json())
      .then(data => {
        this.getMaterials();
      })
      .catch(error => console.log(error));
  }

  saveSubstrate(data) {
	var authuser = this.state.AuthUser;
    fetch(config.BASE_URL + '/api/savesubstrate', {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
		  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
        }
      })
      .then(data => data.json())
      .then(data => {
        this.getMaterials();
      })
      .catch(error => console.log(error));
  }

  addSubstrate(data) {

    //console.log('data', data);

	var authuser = this.state.AuthUser;
    fetch(config.BASE_URL + '/api/addsubstrate', {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
		  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
        }
      })
      .then(data => data.json())
      .then(data => {
        this.getMaterials();
      })
      .catch(error => console.log(error));
  }

  deleteSubstrate(data) {
	var authuser = this.state.AuthUser;
    fetch(config.BASE_URL + '/api/deletesubstrate', {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
		  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
        }
      })
      .then(data => data.json())
      .then(data => {
        this.getMaterials();
      })
      .catch(error => console.log(error));
  }

  getMaterials() {
    fetch(config.BASE_URL + '/api/getmaterials', {
      method: "GET",
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json'
        }
      })
      .then(data => data.json())
      .then(data => {
        this.setState(data);
        //console.log(this.state)
      })
      .catch(error => console.log(error));
  }

  saveDoorStyle(data) {
	var authuser = this.state.AuthUser;
    fetch(config.BASE_URL + '/api/savedoorstyle', {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
		  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
        }
      })
      .then(data => data.json())
      .then(data => {
        this.getMaterials();
      })
      .catch(error => console.log(error));
  }

  addDoorStyle(data) {
	var authuser = this.state.AuthUser;
    fetch(config.BASE_URL + '/api/adddoorstyle', {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
		  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
        }
      })
      .then(data => data.json())
      .then(data => {
        this.getMaterials();
      })
      .catch(error => console.log(error));
  }

  deleteDoorStyle(data) {
	var authuser = this.state.AuthUser;
    fetch(config.BASE_URL + '/api/deletedoorstyle', {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
		  "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
        }
      })
      .then(data => data.json())
      .then(data => {
        this.getMaterials();
      })
      .catch(error => console.log(error));
  }

  componentDidMount() {
    this.getMaterials();
  }

  render() {



    return(

      <div className="admin-wrapper">
        <HeaderAdmin />
        <div className="dashboard-wrapper partscontent">
            <div className="dashboardhome">
                <div className="container-fluid dashboard-content jobspagecnt">
                    {/* ============================================================== */}
                    {/* pagecontent  */}
                    {/* ============================================================== */}
                    <div className="row">
                      <div className="col-md-12">
                        <ol className="breadcrumb breadm">
                            <li><a href="/admin">Admin</a></li>
                            <li><i className="fa fa-angle-right midarrow" /><a href="/admin/material-list">Material list</a></li>
                        </ol>
                      </div>
                    </div>

                    <MaterialType data={this.state.material} save={this.saveMaterial} add={this.addMaterial} delete={this.deleteMaterial} />
                    <Brand  data={this.state.brand}  material={this.state.material} save={this.saveBrand} add={this.addBrand} delete={this.deleteBrand} />
                    <Colour data={this.state.colour} finish={this.state.finish} substrate={this.state.substrate} brand={this.state.brand} save={this.saveColour} add={this.addColour} delete={this.deleteColour} />
                    <Finish data={this.state.finish} brand={this.state.brand} save={this.saveFinish} add={this.addFinish} delete={this.deleteFinish} />
                    <Substrate data={this.state.substrate} save={this.saveSubstrate} add={this.addSubstrate} delete={this.deleteSubstrate} brand={this.state.brand} />


                    {/* ============================================================== */}
                    {/* end pagecontent  */}
                    {/* ============================================================== */}
                </div>
            </div>
            <FooterAdmin />
        </div>
      </div>

    )

  }
}

class MaterialType extends Component {
  constructor(props) {
    super (props);

    this.state = {
      materialtype: props.data,
      columnWidth: [100, 400, 400, 100]
    }

    this.editFormatter = this.editFormatter.bind(this)
    this.save = this.save.bind(this)
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.submit = this.submit.bind(this);
    this.delete = this.delete.bind(this)
	this.onSortChange = this.onSortChange.bind(this);
  this.ratepersqmFormatter = this.ratepersqmFormatter.bind(this)
  }

  delete(row) {
    swal({
      title: `${strings.Areyousure}`,
      text: "Once deleted, you will not be able to recover this record",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
         this.props.delete(row)
      } else {
          return false;
      }
  });
  }

  editFormatter(cell,row) {
		return  <button className="link-btn" onClick={() => this.delete(row)}>
					<i className="fa fa-trash" aria-hidden="true"></i>
				</button>;
  }

  open() {
    this.setState({open:true});
  }

  close() {
    this.setState({open:false});
  }

  submit(data) {
    this.close();
    this.props.add(data)
  }

  save(row, cellName, cellValue) {
    this.props.save(row);
  }

  componentDidMount() {


      for (let index = 0; index < 10; index += 1) {

        if ($(`.react-bs-container-header thead th:eq(${index})`).length === 0) break; // break if cant find any more columns

        $(`.react-bs-container-header thead th:eq(${index})`).resizable({
          minWidth: 50,
          handles: 'e',
          resize: (__event, ui) => {
          const newColumnWidths = [...this.state.columnWidth];
          newColumnWidths[index] = ui.size.width;
          this.setState({ columnWidth: newColumnWidths });
          },
        });

      }

  }


  onSortChange(sortName, sortOrder) {
	localStorage.setItem("materialTypeSort", JSON.stringify({
		sortName: sortName,
		sortOrder: sortOrder
	}));
  }

  ratepersqmFormatter(cell, row){
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(row.RatePerSQm);
  }

  render() {
	var materialTypeSort = localStorage.getItem('materialTypeSort');

	if (materialTypeSort) {
		materialTypeSort = JSON.parse(materialTypeSort)
	}

	//console.log(materialTypeSort);

    const options = {
      prePage: "Previous", // Previous page button text
      nextPage: "Next", // Next page button text
      onRowClick: this.onRowClick,
      noDataText: "No records found",
      that: this, // <- needed this "this" in the search event handler
      sizePerPage: 5,
      defaultSortName: (materialTypeSort) ? materialTypeSort.sortName : null,
      defaultSortOrder: (materialTypeSort) ? materialTypeSort.sortOrder : null,
      sizePerPageList: [
          {
              text: "5",
              value: 5
          },
          {
              text: "10",
              value: 10
          },
          {
              text: "25",
              value: 25
          },
          {
              text: "50",
              value: 50
          },
          {
              text: "All",
              value:
                  this.props.data.length > 0
                      ? this.props.data.length
                      : 5
          }
      ],
	  onSortChange: this.onSortChange
  };

  const cellEditProp = {
    mode: 'click',
    blurToSave: true,
    afterSaveCell: this.save
  }



  return (
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

            <div className="inner-pagehead">
              <h1>Material Type<span className="text-dark">{noCost}</span></h1>
            </div>

            <BootstrapTable
                ref='table'
                data={this.props.data}
                pagination={true}
                options={options}
                search={false}
                hover={true}
                cellEdit={cellEditProp}
                trClassName={this.trClassFormat}>
                <TableHeaderColumn hidden={true}dataField='MaterialTypeId' isKey={true}>Material Type ID</TableHeaderColumn>
                <TableHeaderColumn dataField='MaterialType' width={this.state.columnWidth[1] + 'px'} dataSort={true} >Material Type</TableHeaderColumn>
                <TableHeaderColumn dataField='RatePerSQm' dataSort={true} width={this.state.columnWidth[2] + 'px'} columnClassName={this.tdClassName}
                dataFormat={this.ratepersqmFormatter}
                >Operational Costs Per Sqm</TableHeaderColumn>
                <TableHeaderColumn
                  tdStyle={{ cursor: "pointer", textAlign:"center"}}
                  thStyle={{ textAlign:"center" }}
                  width={this.state.columnWidth[3] + 'px'}
                  editable={false}
                  dataFormat={ this.editFormatter.bind(this) }>
                  <button onClick={this.open} className="btn purchasebtn">Add</button>
                </TableHeaderColumn>
            </BootstrapTable>
            <MaterialModal open={this.state.open} close={this.close} submit={this.submit} />
        </div>
    </div>
    )
  }
}

class Brand extends Component {
  constructor(props) {
    super (props);

    this.state = {
      brand: props.data,
      material: props.material,
      brandWidth: [100, 200, 200, 200, 200, 200, 100]
    }

    this.save = this.save.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.submit = this.submit.bind(this);
    this.delete = this.delete.bind(this);

	this.onSortChange = this.onSortChange.bind(this);
  }

  delete(row) {
    swal({
      title: `${strings.Areyousure}`,
      text: "Once deleted, you will not be able to recover this record",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
         this.props.delete(row)
      } else {
          return false;
      }
    });
  }

  open() {
    this.setState({open:true});
  }

  close() {
    this.setState({open:false});
  }

  submit(data) {
    this.close();
    this.props.add(data)
  }

  editFormatter(cell,row) {
		return  <button className="link-btn" onClick={() => this.delete(row)}>
					<i className="fa fa-trash" aria-hidden="true"></i>
				</button>;
  }

  save(row, cellName, cellValue) {

    if (cellName === 'MaterialType') {

      this.props.material.map(material => {
        if (material.MaterialType === cellValue) {
          row.MaterialTypeId = material.MaterialTypeId
        }
        return material;
      });

    }

    this.props.save(row);
  }

  componentDidMount() {


    for (let index = 0; index < 10; index += 1) {

      if ($(`.brand-section .react-bs-container-header thead th:eq(${index})`).length === 0) break; // break if cant find any more columns

      $(`.brand-section .react-bs-container-header thead th:eq(${index})`).resizable({
        minWidth: 50,
        handles: 'e',
        resize: (__event, ui) => {
        const newColumnWidths = [...this.state.brandWidth];
        newColumnWidths[index] = ui.size.width;
        this.setState({ brandWidth: newColumnWidths });
        },
      });

    }

}


onSortChange(sortName, sortOrder) {
	localStorage.setItem("brandSort", JSON.stringify({
		sortName: sortName,
		sortOrder: sortOrder
	}));
}


  render() {

	var brandSort = localStorage.getItem('brandSort');

	if (brandSort) {
		brandSort = JSON.parse(brandSort)
	}

	//console.log('brandSort', brandSort)

    const options = {
      prePage: "Previous", // Previous page button text
      nextPage: "Next", // Next page button text
      onRowClick: this.onRowClick,
      noDataText: "No records found",
      that: this, // <- needed this "this" in the search event handler
      sizePerPage: 5,
      defaultSortName: (brandSort) ? brandSort.sortName : null,
      defaultSortOrder: (brandSort) ? brandSort.sortOrder : null,
		onSortChange: this.onSortChange,
      sizePerPageList: [
          {
              text: "5",
              value: 5
          },
          {
              text: "10",
              value: 10
          },
          {
              text: "25",
              value: 25
          },
          {
              text: "50",
              value: 50
          },
          {
              text: "All",
              value:
                  this.props.data.length > 0
                      ? this.props.data.length
                      : 5
          }
      ]
  };

  const cellEditProp = {
    mode: 'click',
    blurToSave: true,
    afterSaveCell: this.save
  }

  const materials = this.props.material.map(material => {
    return material.MaterialType
  })

    return (
      <div className="brand-section row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            {/* top heading section*/}
            <div className="inner-pagehead">
                <h1>Brand<span className="text-dark">{noCost}</span></h1>
            </div>
            {/* top heading section*/}
            {/* table content section strat*/}

            <BootstrapTable
                ref='table'
                data={this.props.data}
                pagination={true}
                options={options}
                search={false}
                hover={true}
                cellEdit={cellEditProp}
                trClassName={this.trClassFormat}>
                <TableHeaderColumn hidden={true}dataField='BrandId' isKey={true}>Brand ID</TableHeaderColumn>
                <TableHeaderColumn dataSort={true} width={this.state.brandWidth[1] + 'px'} dataField='BrandName'>Brand Name</TableHeaderColumn>
                <TableHeaderColumn dataSort={true} width={this.state.brandWidth[2] + 'px'} dataField='Description' columnClassName={this.tdClassName}>Description</TableHeaderColumn>
                <TableHeaderColumn
                    dataField='MaterialType'
                    columnClassName={this.tdClassName}
                    dataSort={true}
                    width={this.state.brandWidth[3] + 'px'}
                    editable={ { type: 'select', options: { values: materials } } }
                >Material Type</TableHeaderColumn>
                <TableHeaderColumn dataSort={true} width={this.state.brandWidth[4] + 'px'} hidden={true} dataField='CostPercent' columnClassName={this.tdClassName}>Cost Percent</TableHeaderColumn>
                <TableHeaderColumn dataSort={true} hidden={true} width={this.state.brandWidth[5] + 'px'} dataField='BaseSqmPrice' columnClassName={this.tdClassName}>Base Sqm Price</TableHeaderColumn>
                <TableHeaderColumn dataSort={true} width={this.state.brandWidth[5] + 'px'} dataField='squire_weight' columnClassName={this.tdClassName}>Weight of Squire mm/Kg</TableHeaderColumn>
                <TableHeaderColumn
                  tdStyle={{ cursor: "pointer", width: "8%", textAlign:"center"}}
                  thStyle={{ width: "8%", textAlign:"center" }}
                  editable={false}
                  dataFormat={ this.editFormatter.bind(this) }>
                  <button onClick={this.open} className="btn purchasebtn">Add</button>
                </TableHeaderColumn>
            </BootstrapTable>
            <BrandModal material={this.props.material} open={this.state.open} close={this.close} submit={this.submit} />
        </div>
    </div>
    )
  }
}

class Colour extends Component {
  constructor(props) {
    super (props);

    this.state = {
      colour: props.data,
      open: false,
      columnWidth: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100]
    }

    this.save = this.save.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.submit = this.submit.bind(this);
    this.delete = this.delete.bind(this);

	this.onSortChange = this.onSortChange.bind(this);
  this.ratepersqmFormatter = this.ratepersqmFormatter.bind(this)
  }

  delete(row) {
    swal({
      title: `${strings.Areyousure}`,
      text: "Once deleted, you will not be able to recover this record",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
         this.props.delete(row)
      } else {
          return false;
      }
    });
  }

  open() {
    this.setState({open:true});
  }

  close() {
    this.setState({open:false});
  }

  submit(data) {
    this.close();
    this.props.add(data)
  }

  save(row, cellName, cellValue) {
	console.log('row', row)
    if (cellName === 'BrandName') {
      this.props.brand.map(brand => {
        if (brand.BrandName === cellValue) {
          row.BrandId = brand.BrandId
        }
      })
    }
    this.props.save(row);
  }

	editFormatter(cell,row) {
		return  <button className="link-btn" onClick={() => this.delete(row)}>
					<i className="fa fa-trash" aria-hidden="true"></i>
				</button>;
	}

	HideColourFormatter = (cell, row) =>{
		return row.HideColour === 1 ? 'Yes' : 'No'
	}

	ColourImageFormatter = (cell, row) =>{
		return <img src={config.UPLOAD_URL+row.ColourImage} style={{cursor: 'pointer', height: '25px', width: '25px'}} />
	}

	onDropImage = (image) => {
		//console.log('image', image)
	}

	colourFinishFormatter = (cell, row) => {
		return row.FinishName
	}

	colourSubstrateFormatter = (cell, row) => {
		return row.SubstrateName
	}

	colourBrandFormatter = (cell, row) => {
		return row.BrandName
	}

	filterFinish = (row) =>{
		const finishs = this.props.finish.filter(finish => finish.BrandId == row.BrandId).map(finish => {
			return {
				value: finish.FinishId,
				text: finish.FinishName
			}
		})
		return finishs
  }

  componentDidMount() {
    for (let index = 0; index < 10; index += 1) {
      if ($(`.colour-section .react-bs-container-header thead th:eq(${index})`).length === 0) break; // break if cant find any more columns
      $(`.colour-section .react-bs-container-header thead th:eq(${index})`).resizable({
        minWidth: 50,
        handles: 'e',
        resize: (__event, ui) => {
        const newColumnWidths = [...this.state.columnWidth];
        newColumnWidths[index] = ui.size.width;
        this.setState({ columnWidth: newColumnWidths });
        },
      });
    }
  }

onSortChange(sortName, sortOrder) {
	localStorage.setItem("colourSort", JSON.stringify({
		sortName: sortName,
		sortOrder: sortOrder
	}));
}

ratepersqmFormatter(cell, row){
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(row.RatePerSQm);
}

  render() {

	var colourSort = localStorage.getItem('colourSort');

	if (colourSort) {
		colourSort = JSON.parse(colourSort)
	}

    const options = {
      prePage: "Previous", // Previous page button text
      nextPage: "Next", // Next page button text
      onRowClick: this.onRowClick,
      noDataText: "No records found",
      that: this, // <- needed this "this" in the search event handler
      sizePerPage: 10,
      defaultSortName: (colourSort) ? colourSort.sortName : null,
      defaultSortOrder: (colourSort) ? colourSort.sortOrder : null,
	onSortChange: this.onSortChange,
      sizePerPageList: [
          {
              text: "5",
              value: 5
          },
          {
              text: "10",
              value: 10
          },
          {
              text: "25",
              value: 25
          },
          {
              text: "50",
              value: 50
          },
          {
              text: "All",
              value:
                  this.props.data.length > 0
                      ? this.props.data.length
                      : 5
          }
      ]
  };

  const cellEditProp = {
    mode: 'click',
    blurToSave: true,
    afterSaveCell: this.save
  }

  const brands = this.props.brand.map(brand => {
    return {
		value: brand.BrandId,
		text: brand.BrandName
	}
  })


  const substrates = this.props.substrate.map(substrate => {
    return {
		value: substrate.SubstrateId,
		text: substrate.SubstrateName
	}
  })

	const createImageEditor = (onDropImage, props) => (
		<UploadImage filename={props.ColourImage} onDropImage={ onDropImage } {...props}/>
	);

    return (
      <div className="row colour-section">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            {/* top heading section*/}
            <div className="inner-pagehead">
                <h1>Colour<span className="text-dark">{noCost}</span></h1>
            </div>
            {/* top heading section*/}
            {/* table content section strat*/}

            <BootstrapTable
                ref='table'
                data={this.props.data}
                pagination={true}
                options={options}
                search={false}
                hover={true}
                editable={false}
                cellEdit={cellEditProp}
                trClassName={this.trClassFormat}>
				        <TableHeaderColumn hidden={true}dataField='ColourId' isKey={true}>Colour ID</TableHeaderColumn>
                <TableHeaderColumn
                    dataField='BrandId'
                    dataSort={true}
                    width={this.state.columnWidth[1] + 'px'}
                    columnClassName={this.tdClassName}
                    editable={ { type: 'select', options: { values: brands } } }
					          dataFormat={ this.colourBrandFormatter.bind(this) }
                >Brand</TableHeaderColumn>
				        <TableHeaderColumn width={this.state.columnWidth[2] + 'px'} dataSort={true} dataField='ItemCode' >Item Code</TableHeaderColumn>
				        <TableHeaderColumn width={this.state.columnWidth[3] + 'px'} dataSort={true} dataField='ColourName' >Colour Name</TableHeaderColumn>
                <TableHeaderColumn
                    dataField='FinishId'
                    dataSort={true}
                    width={this.state.columnWidth[4] + 'px'}
                    columnClassName={this.tdClassName}
                    editable={ { type: 'select', options: { values: this.filterFinish } } }
					          dataFormat={ this.colourFinishFormatter.bind(this) }
                >Finish</TableHeaderColumn>
                <TableHeaderColumn
                    dataField='SubstrateId'
                    dataSort={true}
                    width={this.state.columnWidth[5] + 'px'}
                    columnClassName={this.tdClassName}
                    editable={ { type: 'select', options: { values: substrates } } }
					          dataFormat={ this.colourSubstrateFormatter.bind(this) }
                >Substrate</TableHeaderColumn>

                <TableHeaderColumn width={this.state.columnWidth[6] + 'px'}  dataSort={true} dataField='RatePerSQm' columnClassName={this.tdClassName}
                dataFormat={this.ratepersqmFormatter}
                >Rate Per Sqm</TableHeaderColumn>
                <TableHeaderColumn hidden={true} width={this.state.columnWidth[7] + 'px'}   dataSort={true} hidden={true} dataField='CostPercent' columnClassName={this.tdClassName}>Cost Percent</TableHeaderColumn>
                <TableHeaderColumn
                  dataField='ColourImage'
                  width={this.state.columnWidth[8] + 'px'}
                  columnClassName={this.tdClassName}
                  dataSort={true}
                  dataFormat={ this.ColourImageFormatter.bind(this) }
                  customEditor={ { getElement: createImageEditor, customEditorParameters: {  } } }
                >Colour Image</TableHeaderColumn>
                <TableHeaderColumn
                  dataField='HideColour'
                  width={this.state.columnWidth[9] + 'px'}
                  dataSort={true}
                  columnClassName={this.tdClassName}
                  editable={ { type: 'select', options: { values: [{value: 1, text: 'Yes'}, {value: 0, text: 'No'}] } } }
                  dataFormat={ this.HideColourFormatter.bind(this) }
                >Hide Colour</TableHeaderColumn>
                <TableHeaderColumn
                  tdStyle={{ cursor: "pointer", width: "8%", textAlign:"center"}}
                  thStyle={{ width: "8%", textAlign:"center" }}
                  editable={false}
                  dataFormat={ this.editFormatter.bind(this) }>

                  <button onClick={this.open} className="btn purchasebtn">Add</button>
                </TableHeaderColumn>
            </BootstrapTable>
            <ColourModal brand={this.props.brand} finish={this.props.finish} substrate={this.props.substrate} open={this.state.open} close={this.close} submit={this.submit} />
        </div>
    </div>
    )
  }
}

class Finish extends Component {
  constructor(props) {
    super (props);

    this.state = {
      finish: props.data,
      columnWidth: [100, 200, 200, 200, 200, 100]
    }

    this.save = this.save.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.submit = this.submit.bind(this);
    this.delete = this.delete.bind(this);

	this.onSortChange = this.onSortChange.bind(this);
  }

  delete(row) {
    swal({
      title: `${strings.Areyousure}`,
      text: "Once deleted, you will not be able to recover this record",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
         this.props.delete(row)
      } else {
          return false;
      }
    });
  }

  open() {
    this.setState({open:true});
  }

  close() {
    this.setState({open:false});
  }

  submit(data) {
    this.close();
    this.props.add(data)
  }

  save(row, cellName, cellValue) {

    if (cellName == 'BrandName') {
      this.props.brand.map(brand => {
        if (brand.BrandName == cellValue) {
          row.BrandId = brand.BrandId
        }
      })
    }

    this.props.save(row);
  }

  editFormatter(cell,row) {
		return  <button className="link-btn" onClick={() => this.delete(row)}>
					<i className="fa fa-trash" aria-hidden="true"></i>
				</button>;
  }

  componentDidMount() {
    for (let index = 0; index < 10; index += 1) {
      if ($(`.finish-section .react-bs-container-header thead th:eq(${index})`).length === 0) break; // break if cant find any more columns
      $(`.finish-section .react-bs-container-header thead th:eq(${index})`).resizable({
        minWidth: 50,
        handles: 'e',
        resize: (__event, ui) => {
        const newColumnWidths = [...this.state.columnWidth];
        newColumnWidths[index] = ui.size.width;
        this.setState({ columnWidth: newColumnWidths });
        },
      });
    }
  }

onSortChange(sortName, sortOrder) {
	localStorage.setItem("finishSort", JSON.stringify({
		sortName: sortName,
		sortOrder: sortOrder
	}));
}

  render() {

	var finishSort = localStorage.getItem('finishSort');

	if (finishSort) {
		finishSort = JSON.parse(finishSort)
	}

    const brands = this.props.brand.map(brand => {
      return brand.BrandName
    })

    const options = {
      prePage: "Previous", // Previous page button text
      nextPage: "Next", // Next page button text
      onRowClick: this.onRowClick,
      noDataText: "No records found",
      that: this, // <- needed this "this" in the search event handler
      sizePerPage: 10,
      defaultSortName: (finishSort) ? finishSort.sortName : null,
      defaultSortOrder: (finishSort) ? finishSort.sortOrder : null,
		onSortChange: this.onSortChange,
      sizePerPageList: [
          {
              text: "5",
              value: 5
          },
          {
              text: "10",
              value: 10
          },
          {
              text: "25",
              value: 25
          },
          {
              text: "50",
              value: 50
          },
          {
              text: "All",
              value:
                  this.props.data.length > 0
                      ? this.props.data.length
                      : 5
          }
      ]
  };

  const cellEditProp = {
    mode: 'click',
    blurToSave: true,
    afterSaveCell: this.save
  }

    return (
      <div className="row mt-4">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            {/* top heading section*/}
            <div className="inner-pagehead">
                <h1>Finish<span className="text-dark">{noCost}</span></h1>
            </div>
            {/* top heading section*/}
            {/* table content section strat*/}

            <BootstrapTable
                ref='table'
                data={this.props.data}
                pagination={true}
                options={options}
                search={false}
                hover={true}
                editable={false}
                cellEdit={ cellEditProp }
                trClassName={this.trClassFormat}>
                <TableHeaderColumn hidden={true} dataField='FinishtId' isKey={true}>Finish ID</TableHeaderColumn>
                <TableHeaderColumn
                    dataField='BrandName'
                    dataSort={true}
                    width={this.state.columnWidth[1] + 'px'}
                    columnClassName={this.tdClassName}
                    editable={ { type: 'select', options: { values: brands } } }
                >
                      Brand
                </TableHeaderColumn>
                <TableHeaderColumn width={this.state.columnWidth[2] + 'px'} dataSort={true} dataField='FinishName' >Finish Name</TableHeaderColumn>
                <TableHeaderColumn width={this.state.columnWidth[3] + 'px'} dataSort={true} dataField='Description' columnClassName={this.tdClassName}>Description</TableHeaderColumn>
				        <TableHeaderColumn hidden={true} width={this.state.columnWidth[4] + 'px'} dataSort={true} dataField='CostPercent' columnClassName={this.tdClassName}>Cost Percent</TableHeaderColumn>
                <TableHeaderColumn
                  tdStyle={{ cursor: "pointer", width: "8%", textAlign:"center"}}
                  thStyle={{ width: "8%", textAlign:"center" }}
                  editable={false}
                  dataFormat={ this.editFormatter.bind(this) }>
                  <button onClick={this.open} className="btn purchasebtn">Add</button>
                </TableHeaderColumn>
            </BootstrapTable>
            <FinishModal brand={this.props.brand} open={this.state.open} close={this.close} submit={this.submit} />
        </div>
    </div>
    )
  }
}

class ColourModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
		    finish: props.finish,
        substrate: props.substrate,
        ColourName  : '',
        BrandId     : 2,
        Description : '',
        RatePerSQm  : '',
        CostPercent : 0,
        colourNameClass: 'form-control',
        errorClass: 'hidden',
	      ColourImage: '',
        ItemCode: '',
        FinishId: 0,
        SubstrateId: 0,
    }

    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);
	  this.handleChangeBrand = this.handleChangeBrand.bind(this);
  }

	componentWillReceiveProps(nextProps){

    const defaultBrand = (nextProps.brand[0]) ? nextProps.brand[0].BrandId : 0
		//const defaultSubstrate = (this.props.substrate[0]) ? this.props.substrate[0].SubstrateId : 0
    const defaultFinish = (this.props.finish[0]) ? this.props.finish[0].FinishId : 0

    const substrate = nextProps.substrate.filter( s => {
        return s.BrandId == defaultBrand
    });

    const defaultSubstrate = (substrate[0]) ? substrate[0].SubstrateId : 0

		this.setState({
			finish: this.props.finish,
			BrandId: defaultBrand,
			FinishId: defaultFinish,
      SubstrateId: defaultSubstrate,
      substrate: substrate,
      ColourName: ''
		})
	}


  handleChange(e) {
    this.setState({ [e.target.name] : e.target.value, colourNameClass: 'form-control', errorClass: 'hidden'} )
  }

  handleChangeBrand(e) {

    const optionFinish = this.props.finish.filter(finish => finish.BrandId == e.target.value)
    const optionSubstrate = this.props.substrate.filter(substrate => substrate.BrandId == e.target.value)
	  //console.log('e.target.value', e.target.value)

    console.log('finish', optionFinish);
    console.log('substrate', optionSubstrate);

    let FinishId = this.state.FinishId;
    let SubstrateId = this.state.SubstrateId;

    if (e.target.name == 'BrandId') {
      FinishId = optionFinish[0].FinishId;
      SubstrateId = optionSubstrate[0].SubstrateId;
    }

    if (e.target.name == 'FinishId') {
      SubstrateId = optionSubstrate[0].SubstrateId;
    }

    this.setState({
      [e.target.name] : e.target.value,
      colourNameClass: 'form-control',
      errorClass: 'hidden',
      finish: optionFinish,
      substrate: optionSubstrate,
      FinishId: FinishId,
      SubstrateId: SubstrateId
    }, () => {
      //console.log('this.state', this.state)
    })

  }

  submit() {

    const colourName = this.state.ColourName;

    let valid = true;

    if (colourName == '') {
      valid = false;
      this.setState({colourNameClass: 'form-control border-danger', errorClass: 'text-red'})
    }

    if (valid) {

      this.props.submit(this.state);
    }
  }

	onDropImage = (image) => {
		console.log('image', image)
		this.setState({
			ColourImage: image
		}, () => {
			console.log('this.state', this.state)
		})
	}

  render() {

	const defaultBrand = this.state.BrandId
	const defaultSubstrate = this.state.SubstrateId
	const defaultFinish = this.state.FinishId

    const options = this.props.brand.map(brand => {
      return <option value={brand.BrandId}>{brand.BrandName}</option>
    })

    const optionFinish = this.state.finish.map(finish => {
      return <option value={finish.FinishId}>{finish.FinishName}</option>
    })

    const optionSubstrates = this.state.substrate.map(substrate => {
      return <option value={substrate.SubstrateId}>{substrate.SubstrateName}</option>
    })

	//console.log('this.props.brand', this.props.brand[0])
    return (
      <Modal
          show={this.props.open}
          onHide={this.props.close}
          className="LoginModal AddColourModal"
        >

       <Modal.Header closeButton>
         Add Colour
       </Modal.Header>

        <Modal.Body>
          <div className="form-group col-md-6" style={{paddingLeft: '0px', marginTop: '20px'}}>
            <label>Brand</label>
            <select name="BrandId" defaultValue={defaultBrand} onChange={this.handleChangeBrand} className="form-control">
                {options}
            </select>
          </div>
          <div className="form-group col-md-6" style={{paddingRight: '0px', marginTop: '20px'}}>
            <label>Finish</label>
			      <select name="FinishId" defaultValue={defaultFinish} onChange={this.handleChange} className="form-control">
                {optionFinish}
            </select>
          </div>
          <div className="form-group col-md-6" style={{paddingLeft: '0px', marginTop: '0px'}}>
            <label>Substrate</label>
            <select name="SubstrateId" defaultValue={defaultSubstrate} onChange={this.handleChange} className="form-control">
                {optionSubstrates}
            </select>
          </div>
          <div className="form-group col-md-6" style={{paddingRight: '0px',  marginTop: '0px'}}>
            <label>Colour Image</label>
			<UploadImage onDropImage={ this.onDropImage } hideIcon={true} />
          </div>
			<div style={{float: 'none', clear: 'both'}}></div>
          <div className="form-group col-md-6" style={{paddingLeft: '0px', marginTop: '0px'}}>
            <label>Item Code</label>
            <input type="text" name="ItemCode" className={this.state.colourNameClass} onChange={this.handleChange} />
            <span className={this.state.errorClass}>Enter Item Code</span>
          </div>
          <div className="form-group col-md-6"  style={{paddingRight: '0px',  marginTop: '0px'}}>
            <label>Colour Name</label>
            <input type="text" name="ColourName" value={this.state.ColourName} className={this.state.colourNameClass} onChange={this.handleChange} />
            <span className={this.state.errorClass}>Enter colour name</span>
          </div>
          <div className="form-group col-md-6" style={{paddingLeft: '0px', marginTop: '0px'}}>
            <label>Rate Per Sqm</label>
            <input type="number" name="RatePerSQm" className="form-control" onChange={this.handleChange} />
          </div>
          {/*
          <div className="form-group col-md-6" style={{paddingRight: '0px',  marginTop: '0px'}}>
            <label>Cost Percent</label>
            <input type="text" name="CostPercent" className="form-control" onChange={this.handleChange} />
          </div>
          */}
		  <div style={{float: 'none', clear: 'both'}}></div>
          <button onClick={this.submit} type="button" className="btn cartbtn purchasebtn">Submit</button>
        </Modal.Body>

      </Modal>

    )

  }

}

class MaterialModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      MaterialType  : '',
      RatePerSqm  : '',
      materialClass: 'form-control',
      errorClass: 'hidden'
    }

    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);



  }

  handleChange(e) {
    this.setState({ [e.target.name] : e.target.value, materialClass: 'form-control', errorClass: 'hidden'} )
  }

  submit() {
    const materialName = this.state.MaterialType;

    let valid = true;

    if (materialName == '') {
      valid = false;
      this.setState({materialClass: 'form-control border-danger', errorClass: 'text-red'})
    }

    if (valid) {
      this.props.submit(this.state);
    }
  }

  render() {



    return (
      <Modal
          show={this.props.open}
          onHide={this.props.close}
          className="LoginModal"
        >

       <Modal.Header closeButton>
         Add Material Type
       </Modal.Header>

        <Modal.Body>
          <div className="form-group">
            <label>Material Type</label>
            <input type="text" name="MaterialType" className={this.state.materialClass} onChange={this.handleChange} />
            <span className={this.state.errorClass}>Enter material type</span>
          </div>
          <div className="form-group">
            <label>Operational Cost Per Sqm</label>
            <input type="number" name="RatePerSqm" className="form-control" onChange={this.handleChange} />
          </div>
          <button onClick={this.submit} type="button" className="btn cartbtn purchasebtn">Submit</button>
        </Modal.Body>

      </Modal>

    )

  }

}

class Substrate extends Component {
  constructor(props) {
    super (props);

    this.state = {
      materialtype: props.data,
      columnWidth: [100, 300, 300, 300, 100, 100]
    }

    this.editFormatter = this.editFormatter.bind(this)
    this.save = this.save.bind(this)
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.submit = this.submit.bind(this);
    this.delete = this.delete.bind(this)

	   this.onSortChange = this.onSortChange.bind(this);
     this.brandFormatter = this.brandFormatter.bind(this);
  }

  delete(row) {
    swal({
      title: `${strings.Areyousure}`,
      text: "Once deleted, you will not be able to recover this record",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
         this.props.delete(row)
      } else {
          return false;
      }
  });
  }

  editFormatter(cell,row) {
		return  <button className="link-btn" onClick={() => this.delete(row)}>
					<i className="fa fa-trash" aria-hidden="true"></i>
				</button>;
  }

  open() {
    this.setState({open:true});
  }

  close() {
    this.setState({open:false});
  }

  submit(data) {
    this.close();
    this.props.add(data)
  }

  save(row, cellName, cellValue) {
    this.props.save(row);
  }

  componentDidMount() {
    for (let index = 0; index < 10; index += 1) {
      if ($(`.substrate-section .react-bs-container-header thead th:eq(${index})`).length === 0) break; // break if cant find any more columns
      $(`.substrate-section .react-bs-container-header thead th:eq(${index})`).resizable({
        minWidth: 50,
        handles: 'e',
        resize: (__event, ui) => {
        const newColumnWidths = [...this.state.columnWidth];
        newColumnWidths[index] = ui.size.width;
        this.setState({ columnWidth: newColumnWidths });
        },
      });
    }
  }

onSortChange(sortName, sortOrder) {
	localStorage.setItem("substrateSort", JSON.stringify({
		sortName: sortName,
		sortOrder: sortOrder
	}));
}

brandFormatter(cell, row){
  const mat = this.props.brand.filter(brand => {
    return brand.BrandId == cell
  });
  return (mat.length > 0) ? mat[0].BrandName : ''
}

  render() {

	var substrateSort = localStorage.getItem('substrateSort');

	if (substrateSort) {
		substrateSort = JSON.parse(substrateSort)
	}

    const options = {
      prePage: "Previous", // Previous page button text
      nextPage: "Next", // Next page button text
      onRowClick: this.onRowClick,
      noDataText: "No records found",
      that: this, // <- needed this "this" in the search event handler
      sizePerPage: 5,
      defaultSortName: (substrateSort) ? substrateSort.sortName : null,
      defaultSortOrder: (substrateSort) ? substrateSort.sortOrder : null,
		onSortChange: this.onSortChange,
      sizePerPageList: [
          {
              text: "5",
              value: 5
          },
          {
              text: "10",
              value: 10
          },
          {
              text: "25",
              value: 25
          },
          {
              text: "50",
              value: 50
          },
          {
              text: "All",
              value:
                  this.props.data.length > 0
                      ? this.props.data.length
                      : 5
          }
      ]
  };

  const cellEditProp = {
    mode: 'click',
    blurToSave: true,
    afterSaveCell: this.save
  }

  const brands = this.props.brand.map(brand => {
    return {
      text: brand.BrandName,
      value: brand.BrandId
    }
  })

  return (
      <div className="row substrate-section">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

            <div className="inner-pagehead">
                <h1>Substrate<span className="text-dark">{noCost}</span></h1>
            </div>

            <BootstrapTable
                ref='table'
                data={this.props.data}
                pagination={true}
                options={options}
                search={false}
                hover={true}
                cellEdit={cellEditProp}
                trClassName={this.trClassFormat}>
                <TableHeaderColumn dataSort={true} width={this.state.columnWidth[3] + 'px'} dataField='BrandId'
                editable={ { type: 'select', options: { values: brands } } }
                dataFormat={this.brandFormatter}
                >Brand</TableHeaderColumn>
                <TableHeaderColumn hidden={true}dataField='SubstrateId' isKey={true}>Substrate ID</TableHeaderColumn>
                <TableHeaderColumn dataSort={true} width={this.state.columnWidth[1] + 'px'} dataField='SubstrateName' >Substrate Naame</TableHeaderColumn>
                <TableHeaderColumn dataSort={true} width={this.state.columnWidth[2] + 'px'} dataField='Description'>Description</TableHeaderColumn>
                <TableHeaderColumn dataSort={true} width={this.state.columnWidth[3] + 'px'} dataField='CostPercent'>Cost Percent</TableHeaderColumn>
                <TableHeaderColumn
                  tdStyle={{ cursor: "pointer", textAlign:"center"}}
                  thStyle={{ textAlign:"center" }}
                  editable={false}
                  dataFormat={ this.editFormatter.bind(this) }>
                  <button onClick={this.open} className="btn purchasebtn">Add</button>
                </TableHeaderColumn>
            </BootstrapTable>
            <SubstrateModal brand={this.props.brand} open={this.state.open} close={this.close} submit={this.submit} />
        </div>
    </div>
    )
  }
}



class BrandModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      BrandName  : '',
      MaterialTypeId : 31,
      Description : '',
      BaseSqmPrice  : 0,
      CostPercent : 0,
      material: [],
      brandClass: 'form-control',
      errorClass: 'hidden',
      squire_weight: ''
    }

    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }

  handleChange(e) {
    if (e.target.name === 'squire_weight' && e.target.value != '') {
      if ( isNaN(e.target.value)) {
        console.log('this is not a number')
        return;
      }
    }
    this.setState({ [e.target.name] : e.target.value, brandClass : 'form-control', errorClass: 'hidden'} )
  }

  submit() {
    const brandName = this.state.BrandName;

    let valid = true;

    if (brandName == '') {
      valid = false;
      this.setState({brandClass: 'form-control border-danger', errorClass: 'text-red'})
    }

    if (valid) {
      this.props.submit(this.state);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.material !== prevProps.material) {      
      this.setState( {
        MaterialTypeId: this.props.material[0].MaterialTypeId
      })
    }
  }

  componentDidMount() {
    console.log('did mount', this.props.material)
    
  }

  render() {

    const options = this.props.material.map(material => {
      return <option value={material.MaterialTypeId}>{material.MaterialType}</option>
    })

    return (
      <Modal
          show={this.props.open}
          onHide={this.props.close}
          className="LoginModal"
        >

       <Modal.Header closeButton>
         Add Brand
       </Modal.Header>

        <Modal.Body>
          <div className="form-group">
            <label>Brand Name</label>
            <input type="text" name="BrandName" className={this.state.brandClass} onChange={this.handleChange} />
            <span className={this.state.errorClass}>Enter brand name</span>
          </div>
          <div className="form-group">
            <label>Description</label>
            <input type="text" name="Description" className="form-control" onChange={this.handleChange} />
          </div>
          <div className="form-group">
            <label>Material Type</label>
            <select name="MaterialTypeId" onChange={this.handleChange} className="form-control">
                {options}
            </select>
          </div>
          <div className="form-group">
            <label>Weight of Squire mm/Kg</label>
            <input value={this.state.squire_weight} type="text" name="squire_weight" className="form-control" onChange={this.handleChange} />
          </div>
          
          {/*
          <div className="form-group">
            <label>Base Sqm Price</label>
            <input type="text" name="BaseSqmPrice" className="form-control" onChange={this.handleChange} />
          </div>
          */}
          <button onClick={this.submit} type="button" className="btn cartbtn purchasebtn">Submit</button>
        </Modal.Body>

      </Modal>

    )

  }

}

class FinishModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      FinishName  : '',
      BrandId     : 31,
      Description : '',
      RatePerSQm  : 0,
      CostPercent : 0,
      finishClass : 'form-control',
      errorClass: 'hidden'
    }

    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }

  handleChange(e) {
    this.setState({ [e.target.name] : e.target.value, finishClass: 'form-control', errorClass: 'hidden'} )
  }

  submit() {
    const finishName = this.state.FinishName;

    let valid = true;

    if (finishName == '') {
      valid = false;
      this.setState({finishClass: 'form-control border-danger', errorClass: 'text-red'})
    }

    if (valid) {
      this.props.submit(this.state);
    }
  }

  render() {

    const options = this.props.brand.map(brand => {
      return <option value={brand.BrandId}>{brand.BrandName}</option>
    })

    return (
      <Modal
          show={this.props.open}
          onHide={this.props.close}
          className="LoginModal"
        >

       <Modal.Header closeButton>
         Add Finish
       </Modal.Header>

        <Modal.Body>
          <div className="form-group">
            <label>Finish Name</label>
            <input type="text" name="FinishName" className={this.state.finishClass} onChange={this.handleChange} />
            <span className={this.state.errorClass}>Enter finish name</span>
          </div>
          <div className="form-group">
            <label>Description</label>
            <input type="text" name="Description" className="form-control" onChange={this.handleChange} />
          </div>
          <div className="form-group">
            <label>Brand</label>
            <select name="BrandId" onChange={this.handleChange} className="form-control">
                {options}
            </select>
          </div>
          {/*
          <div className="form-group">
            <label>Cost Percent</label>
            <input type="text" name="CostPercent" className="form-control" onChange={this.handleChange} />
          </div>
          */}
          <button onClick={this.submit} type="button" className="btn cartbtn purchasebtn">Submit</button>
        </Modal.Body>

      </Modal>

    )

  }

}

class SubstrateModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      SubstrateName  : '',
      Description : '',
      CostPercent : '',
      BrandId: 2,
      nameClass : 'form-control',
      errorClass: 'hidden'
    }

    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }

  handleChange(e) {

    this.setState({ [e.target.name] : e.target.value, nameClass: 'form-control', errorClass: 'hidden'} )
  }

  submit() {
    const substrateName = this.state.SubstrateName;

    let valid = true;

    if (substrateName == '') {
      valid = false;
      this.setState({nameClass: 'form-control border-danger', errorClass: 'text-red'})
    }

    if (valid) {
      this.props.submit(this.state);
    }
  }

  render() {

    const options = this.props.brand.map(brand => {
      return <option value={brand.BrandId}>{brand.BrandName}</option>
    })

    return (
      <Modal
          show={this.props.open}
          onHide={this.props.close}
          className="LoginModal"
        >

       <Modal.Header closeButton>
         Add Substrate
       </Modal.Header>

        <Modal.Body>
        <div className="form-group">
          <label>Brand</label>
          <select name="BrandId" onChange={this.handleChange} className="form-control">
              {options}
          </select>
        </div>
          <div className="form-group">
            <label>Substrate Name</label>
            <input type="text" name="SubstrateName" className={this.state.nameClass} onChange={this.handleChange} />
            <span className={this.state.errorClass}>Enter substrate name</span>
          </div>
          <div className="form-group">
            <label>Description</label>
            <input type="text" name="Description" className="form-control" onChange={this.handleChange} />
          </div>
          <div className="form-group">
            <label>Cost Percent</label>
            <input type="number" name="CostPercent" className="form-control" onChange={this.handleChange} />
          </div>
          <button onClick={this.submit} type="button" className="btn cartbtn purchasebtn">Submit</button>
        </Modal.Body>

      </Modal>

    )

  }

}



const styles = {
	position: "relative",
	padding: "4px 15px",
	fontSize: "16px",
	border: "1px solid rgb(249, 250, 250)",
	background: "#EEE",
	cursor: "pointer",
	borderRadius: "5px"
};

class UploadImage extends Component {
    constructor(props) {
        super(props);
		this.state = {
			filename: props.filename,
			fileData: ''
		}
    }

	onDrop = (acceptedFiles) =>{
		var authuser = JSON.parse(localStorage.getItem('authuser'));
		const data = new FormData();
		acceptedFiles.forEach((file) => {
			const reader = new FileReader()

			reader.onabort = () => console.log('file reading was aborted')
			reader.onerror = () => console.log('file reading has failed')
			reader.onload = (e) => {

				const binaryStr = e.target.result
				this.setState({
					fileData: binaryStr
				});
			}
			reader.readAsDataURL(file);

			//upload image
			//console.log('authuser', authuser)
			data.append('folder', config.UPLOAD_PATH);
			data.append(`myimages[]`, file, file.name);
			axios.post(config.BASE_URL + "/api/colourimage", data, {
				headers: {
					"Authorization": 'Bearer ' + ((authuser) ? authuser[0].token : '' )
				}
			})
			.then(res => {
				this.setState({
					colourImage: res.data.filename
				}, () => {
					//console.log('this.state', this.state)
				})

				this.props.onDropImage(res.data.filename || '')
			})
		})
	}

  render() {
    const previewStyle = {
		display: 'inline',
		width: 50,
		height: 50,
    }

    return (
		<>
          <Dropzone
            onDrop={this.onDrop}
			multiple={false}
          >
            {({getRootProps, getInputProps}) => (
            <div className="menus-dropdown-dropzone" {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="drag-n-drop text-center">
			  {this.state.fileData != '' ? (
			  <img src={this.state.fileData} style={{height: '25px'}} />
			  ): <i className="fa fa-image" style={{fontSize: '25px'}} />}
			  </div>
            </div>
            )}
          </Dropzone>

		</>
	)
  }

}

export default MaterialList
