import config from '../config';

export const authService = {
    userLogin,
    userLogout,
    isLoggedIn
};

function userLogin(Email, Password) {
	var data = {
		Email: Email,
		Password: Password,
	}
	
	/* fetch(config.BASE_URL + '/api/userlogin', {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
	})
	.then(data => data.json())
	.then(data => {
		if (data.length != 0){
			return data;			
		}else{
			return [];
		}
	})
	.catch(error => console.log(error)); */
}

function userLogout() {
    localStorage.removeItem('user');
}

function isLoggedIn(){
	var user = localStorage.getItem('user') || '';
	return (user) ? true : false;
}

export const saveCartToDB = ( action, data, Token ) => {

	console.log('save cart', action, data, Token)

	fetch(config.BASE_URL + '/api/add-to-cart', {
		method: "POST",
		body: JSON.stringify( { action, data }),
		headers: {
			"Content-Type": "application/json",
			"Authorization": 'Bearer ' + ((Token) ? Token : '' )
		}
	})
	.then(data => data.json())
	.then(data => {
		console.log( data );
	})

}

export const clearCartFromDB = ( Token ) => {

	console.log('clearing');

	fetch(config.BASE_URL + '/api/clear-cart', {
		method: "get",
		headers: {
			"Content-Type": "application/json",
			"Authorization": 'Bearer ' + ((Token) ? Token : '' )
		}
	})
	.then(data => data.json())
	.then(data => {
		console.log( data );
	})

}
