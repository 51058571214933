import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Form } from 'react-bootstrap';
import config from "../config";
import HeaderAdmin from '../components/HeaderAdmin';
import FooterAdmin from '../components/FooterAdmin';
import Modal from 'react-bootstrap-modal';
import ReactTooltip from 'react-tooltip'
import { strings } from '../components/strings';

import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import Invoice from './Invoice';
import PayByOrders from './PayByOrders';

export default class CustomerListNew extends Component {

    constructor(props) {

        super(props);

        const authuser = JSON.parse(localStorage.getItem('authuser'));

        this.state = {
            customers: [],
            data: [],
            totalSize: 0,
            comments: [],
            expanded: [],
            page: 1,
            sizePerPage: 10,
            searchtext: '',
            currentIndex: 0,
            sortField: 'CustomerId',
            sortOrder: 'asc',
            addFailed: false,
            updateFailed: false,
            updateOpen : false,
            AuthUser: (authuser) ? authuser[0] : [],
            columnWidth: [0, 0, 15, 9, 9, 9, 9, 9, 9, 9],
            defaults: {}
        }

        this.getCustomersData = this.getCustomersData.bind(this);
        //this.save = this.save.bind(this);

        this.blockFormatter = this.blockFormatter.bind(this);
        this.payByAccountFormatter = this.payByAccountFormatter.bind(this);
        this.save = this.save.bind(this);
        this.expandComponent = this.expandComponent.bind(this);

        this.onSortChange = this.onSortChange.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.saveCustomer = this.saveCustomer.bind(this);
        this.invoiceButton = this.invoiceButton.bind(this);
        this.updateCustomer = this.updateCustomer.bind(this);
        this.closeUpdate = this.closeUpdate.bind(this);
        this.openUpdate = this.openUpdate.bind(this);
        this.creditLimitFormat = this.creditLimitFormat.bind(this);
        this.getDefaults = this.getDefaults.bind(this);
        this.onChecked = this.onChecked.bind(this);
    }

    creditLimitFormat(cell) {
        const amount = cell;

        return (
            <>
                {amount ? amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0}
            </>
        )
    }

    openUpdate() {
        this.setState( { updateOpen: true } );
    }


    closeUpdate() {
        this.setState( { updateOpen: false } );
    }


    updateCustomer(data) {
        const user = this.state.AuthUser;

        fetch(config.BASE_URL + '/api/updateexistingcustomer', {
            method: 'POST',
            body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				'Accept': 'application/json',
        		"Authorization": 'Bearer ' + user.token
				}
        })
        .then(response => response.json())
        .then((response) => {

            const customers = response.data;
            const totalSize = response.count;

            this.setState({
                customers : customers,
                data: customers.slice(0, this.state.sizePerPage),
                totalSize: totalSize,
                updateOpen: false
            }, () => {
				for (let index = 0; index < 15; index += 1) {
				  if ($(`.customer-section .react-bs-container-header thead th:eq(${index})`).length === 0) break; // break if cant find any more columns

				  $(`.customer-section .react-bs-container-header thead th:eq(${index})`).resizable({
					minWidth: 1,
					handles: 'e',
					resize: (__event, ui) => {
                    const newColumnWidths = [...this.state.columnWidth];

                    const container = $('.react-bs-container-header').width();

					newColumnWidths[index] = ui.size.width / container * 100;
					this.setState({ columnWidth: newColumnWidths });
					},
				  });

				}
			  });

        }).catch(error => console.log(error));
    }



    invoiceButton(cell, row) {

        return (
            <>
                <Invoice customer={row} authuser={this.state.AuthUser} />
                {row.add_by_admin  ?
                    <Editbutton
                        save={this.updateCustomer}
                        close={this.closeUpdate}
                        open={this.state.updateOpen}
                        openModal={this.openUpdate}
                        updateFailed={this.state.updateFailed}
                        customer={row}
                        authuser={this.state.AuthUser}
                    />
                    : '' }

                    <PayByOrders
                        authuser={this.state.AuthUser}
                        customer={row}
                    />

            </>
        )
    }

    saveCustomer(data) {

        const user = this.state.AuthUser;

        fetch(config.BASE_URL + '/api/addcustomer', {
            method: 'POST',
            body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				'Accept': 'application/json',
        		"Authorization": 'Bearer ' + user.token
				}
        })
        .then(response => response.json())
        .then( (response) => {

            if (response.success === 1) {
                this.setState( { open: false } );
                this.getCustomersData();
            } else {
                console.log('failed')
                this.setState( { addFailed: true  } )
            }
        }).catch(error => console.log(error));
    }

    open() {
        this.setState( { open: true } );
    }

    close() {
        this.setState( { open: false } );
    }

    save(rowData, cellName, cellValue){

        let valid = true;       

        if (cellName === 'ABN') {

            const weight = [10, 1, 3, 5, 7 , 9 , 11, 13, 15, 17, 19];

            const ABN = cellValue;

            let sum = 0;

            for(let i=0; i < ABN.length; i++) {
            
                let abn = ABN[i];

                if (i === 0) {
                    abn -= 1;
                }            
            
                sum += (parseInt(abn) * weight[i]);

            }            

            if (sum % 89 > 0) {
                
                valid = false;
             
            }

        }
        

        if (valid) {

            const data = {
                CustomerId : rowData.CustomerId,
                pay_by_account : rowData.pay_by_account,
                block : rowData.block,
                add_percent_order : rowData.add_percent_order,
                CreditLimit : rowData.CreditLimit,
                ABN : rowData.ABN
            }
    
            const user = this.state.AuthUser;

            fetch(config.BASE_URL + '/api/updatecustomers', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    'Accept': 'application/json',
                    "Authorization": 'Bearer ' + user.token
                    }
            }).then(function(response){
                console.log(response);
            }).catch(error => console.log(error));

        }    

    }

    blockFormatter(cell, row) {

        return row.block === 1 ? 'Yes' : 'No';

    }

    payByAccountFormatter(cell, row) {

        return row.pay_by_account === 1 ? 'Yes' : 'No';

    }

    getCustomersData(){

        var authuser = this.state.AuthUser;

        fetch(config.BASE_URL + '/api/getcustomerlistlimit', {
            body: JSON.stringify({ limit: this.state.sizePerPage, page: this.state.page}),
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
            }
        })
        .then(data => data.json())
        .then(data => {

            const customers = data.data;
            const totalSize = data.count;

            this.setState({
                customers : customers,
                data: customers.slice(0, this.state.sizePerPage),
                totalSize: totalSize
            }, () => {
				for (let index = 0; index < 15; index += 1) {
				  if ($(`.customer-section .react-bs-container-header thead th:eq(${index})`).length === 0) break; // break if cant find any more columns

				  $(`.customer-section .react-bs-container-header thead th:eq(${index})`).resizable({
					minWidth: 1,
					handles: 'e',
					resize: (__event, ui) => {
                    const newColumnWidths = [...this.state.columnWidth];

                    const container = $('.react-bs-container-header').width();

					newColumnWidths[index] = ui.size.width / container * 100;
					this.setState({ columnWidth: newColumnWidths });
					},
				  });

				}
			  });
        })
        .catch(error => console.log(error));
    }

    isExpandableRow(row) {
        return true
    }

    expandComponent(row) {

        return (
            <>
                <ChatBox data={row} user={this.state.AuthUser} />
            </>
        );
    }

    onChecked(e){
      //console.log('e', e);
      const {defaults} = this.state
      defaults[0][e.target.name] = defaults[0][e.target.name] === 0 ? 1 : 0;

      this.setState({
        defaults: defaults
      }, ()=> {
        this.saveDefaults();
      })
    }

    saveDefaults(){
      const {defaults} = this.state
      var authuser = this.state.AuthUser;
      fetch(config.BASE_URL + '/api/savedefault', {
        method: "POST",
        body: JSON.stringify(defaults),
        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json',
            "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
          }
        })
        .then(data => data.json())
        .then(data => {
          this.getDefaults();
        })
        .catch(error => console.log(error));
    }

    getDefaults(){
      var authuser = this.state.AuthUser;
      fetch(config.BASE_URL + '/api/getdefaults', {
          method: 'GET',
          headers: {
              "Content-Type": "application/json",
              'Accept': 'application/json',
              "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
          }
      })
      .then(data => data.json())
      .then(data => {
          this.setState({
              defaults : data.defaults
          }, () => {
          });
      });
    }

    componentDidMount() {
        this.getCustomersData();
        this.getDefaults();
    }

	onSortChange(sortName, sortOrder) {
		localStorage.setItem("CustomerSort", JSON.stringify({
		sortName: sortName,
		sortOrder: sortOrder
		}));
	}

    render() {

		var CustomerSort = localStorage.getItem('CustomerSort');

		if (CustomerSort) {
			CustomerSort = JSON.parse(CustomerSort)
		}

        const options = {
            prePage: "Previous", // Previous page button text
            nextPage: "Next", // Next page button text
            onRowClick: this.onRowClick,
            noDataText: "No records found",
            that: this, // <- needed this "this" in the search event handler
            sizePerPage: 25,
			defaultSortName: (CustomerSort) ? CustomerSort.sortName : null,
			defaultSortOrder: (CustomerSort) ? CustomerSort.sortOrder : null,
			onSortChange: this.onSortChange,
            sizePerPageList: [
                {
                    text: "5",
                    value: 5
                },
                {
                    text: "10",
                    value: 10
                },
                {
                    text: "25",
                    value: 25
                },
                {
                    text: "50",
                    value: 50
                },
                {
                    text: "All",
                    value:
                        this.state.customers.length > 0
                            ? this.state.customers.length
                            : 5
                }
            ]
        };

        const createNumberEditor = (onUpdate, props) => (<NumberEditor onUpdate={ onUpdate } {...props}/>);

        const createAbnEditor = (onUpdate, props) => (<AbnEditor onUpdate={ onUpdate } {...props} />);

        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            afterSaveCell: this.save
        }


        const {defaults} = this.state
      
        const isChecked = defaults[0] && defaults[0].activation_required === 1  ? true : false

        //console.log('user', this.state.AuthUser);

        return (

            <div className="admin-wrapper">
            <HeaderAdmin />
            <div className="dashboard-wrapper partscontent">
                <div className="dashboardhome">
                    <div className="container-fluid dashboard-content jobspagecnt">
                        {/* ============================================================== */}
                        {/* pagecontent  */}
                        {/* ============================================================== */}
                        <div className="row">
                        <div className="col-md-12">
                            <ol className="breadcrumb breadm">
                                <li><a href="/admin">Admin</a></li>
                                <li ><i className="fa fa-angle-right midarrow" /><a href="/admin/customer-list" style={{ cursor: "pointer" }}>Customers List</a></li>
                            </ol>
                        </div>
                        </div>
                        <div className="customer-section row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                {/* top heading section*/}
                                <div className="inner-pagehead">
                                    <h1>Customers List</h1>
                                    <div className="form-group">
                                        <label style={{fontSize: '20px', marginRight: '25px'}}>Email Activation Required on registration</label>
                                        <p className="greenCheckBox custom-masters" style={{paddingLeft: '0px', marginBottom: '0px', marginTop: '0px', display: 'inline-block'}}><label><input onClick={this.onChecked}  checked={isChecked}  type="checkbox" name="activation_required" /><span class="checkmark1"></span></label></p>
                                    </div>
                                </div>
                                  <div className="products-table">

                                    <BootstrapTable
                                        ref='table'
                                        data={this.state.customers}
                                        pagination={true}
                                        options={options}
                                        search={true}
                                        hover={true}
                                        cellEdit={cellEditProp}
                                        expandableRow={ this.isExpandableRow }
                                        expandComponent={ this.expandComponent }
                                        expandColumnOptions={ { expandColumnVisible: true } }
                                        trClassName={this.trClassFormat}>
                                        <TableHeaderColumn
                                            hidden={true}
                                            dataField='CustomerId'
                                            dataSort={true}
                                            isKey={true}>
                                                CustomerId
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField='Name'
                                            dataSort={true}
                                            editable={false}
                                            width={this.state.columnWidth[2] + '%'}
                                            >Name
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField='Email'
                                            dataSort={true}
                                            editable={false}
                                            width={this.state.columnWidth[3] + '%'}
                                            >Email</TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField='MobileNumber'
                                            dataSort={true}
                                            editable={false}
                                            width={this.state.columnWidth[4] + '%'}
                                            >Mobile</TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField='add_percent_order'
                                            dataSort={true}
                                            width={this.state.columnWidth[5] + '%'}
                                            customEditor={ { getElement : createNumberEditor, customEditorParameters: { step: 1 }  } }
                                            >Add % to Order</TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField='block'
                                            dataSort={true}
                                            dataFormat={ this.blockFormatter }
                                            width={this.state.columnWidth[6] + '%'}
                                            editable={ { type: 'checkbox', options: { values: '1:0' } } }
                                            >Block</TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField='pay_by_account'
                                            width={this.state.columnWidth[7] + '%'}
                                            dataFormat={ this.payByAccountFormatter }
                                            editable={ { type: 'checkbox', options: { values: '1:0' } } }
                                            dataSort={true}
                                            >Pay By Account</TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField='ABN'
                                            width={this.state.columnWidth[7] + '%'}
                                            customEditor={ { getElement : createAbnEditor , customEditorParameters: { authuser : this.state.AuthUser } } }
                                            dataSort={true}
                                            >ABN</TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField='CreditLimit'
                                            width={this.state.columnWidth[7] + '%'}
                                            dataFormat={ this.creditLimitFormat }
                                            editable={ { type: 'number' } }
                                            dataSort={true}
                                            >Credit Limit</TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField='CreatedDate'
                                            dataSort={true}
                                            editable={false}
                                            width={this.state.columnWidth[8] + '%'}
                                            >Registration Date</TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField='lastlogin'
                                            dataSort={true}
                                            editable={false}
                                            width={this.state.columnWidth[9] + '%'}
                                            >Last Login</TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField='invoice'
                                            dataSort={false}
                                            editable={false}
                                            headerAlign='center'
                                            dataAlign='center'
                                            dataFormat={this.invoiceButton}
                                            width={this.state.columnWidth[9] + '%'}
                                            >
                                            <button onClick={this.open} className="btn add-btn purchasebtn"><i className="fa fa-plus" /></button>
                                            </TableHeaderColumn>
                                    </BootstrapTable>
                                    <AddModal open={this.state.open} addFailed={this.state.addFailed} close={this.close} save={this.saveCustomer} />
                                    <ReactTooltip place="right" />
                                </div>




                            </div>
                        </div>
                        {/* ============================================================== */}
                        {/* end pagecontent  */}
                        {/* ============================================================== */}
                    </div>
                </div>
                <FooterAdmin />
            </div>
        </div>

        )

    }

}

class AbnEditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value : props.defaultValue,
            regexp : /^[0-9\b]+$/,
            open: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
    }

    open() {
        this.setState( { open: true } );
    }

    close() {
        this.setState( { open: false } );
    }

    getValue() {
      return this.state.value;
    }

    handleChange(e) {

        const value = e.target.value;

        if (this.state.regexp.test(value)) {
            this.setState( {
                value: e.target.value
            })
        }
    }

    render() {
        const { value, onUpdate, ...rest } = this.props;

        //console.log('props', this.props);

      return (

        <>
            <input
            { ...rest }
            type="text"
            pattern="[0-9]*"
            value={this.state.value}
            className="form-control"          
            onChange={this.handleChange}

            />
            <button onClick={this.open} className="button-link">ABN Lookup</button>
            <ABNModalLookup 
                open={this.state.open} 
                close={this.close} 
                abn={this.state.value} 
                authuser={this.props.authuser} />
        </>
      );
    }

}

class ABNModalLookup extends Component {

    constructor(props) {

        super(props);

        this.state = {

            abn: props.abn,
            response: []

        }

    }

    componentDidMount() {

        //console.log('did update', this.props);

        const data = { abn: this.props.abn }

        const authuser = this.props.authuser;
        
        fetch(config.BASE_URL + '/api/abnlookup', {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + ((authuser) ? authuser.token : '' )
              }
            })
        .then(data => data.json())
        .then(data => {

             const response = data.ABRPayloadSearchResults.response;

             this.setState( { response: response } );

        })
        .catch(error => console.log(error));

    }

    render() {

        const abn = this.state.response;

        let entityName = '';
        let abnStatus = '';
        let entityType = '';
        let GST = 'Not currently registered for GST';
        let state = '';
        let postcode = '';

        let familyName = '';
        let givenName = '';
        let otherGivenName = '';

        let legalName = true;

        let organizationName = '';

        //console.log('abn', abn);

        if (abn.businessEntity) {
            entityName = abn.businessEntity;

            abnStatus = abn.businessEntity.entityStatus.entityStatusCode;

            if (abn.businessEntity.legalName) {

                familyName  =    abn.businessEntity.legalName.familyName;
                givenName =      abn.businessEntity.legalName.givenName;
                otherGivenName = abn.businessEntity.legalName.otherGivenName;
            
            } else {

                legalName = false;

                organizationName = abn.businessEntity.mainName.organisationName;

            }

            if (abn.businessEntity.goodsAndServicesTax) {

                const effectiveFrom = abn.businessEntity.goodsAndServicesTax.effectiveFrom;

                const from = effectiveFrom.split("-");

                const months = { '01' : 'Jan', '02': 'Feb', '03': "Mar", '04': 'Apr', '05': 'May', '06': 'Jun', '07': 'Jul', '08': 'Aug', '09': 'Sep', '10': 'Oct', '11': 'Nov', '12': 'Dec'};

                GST = `Registered From ${from[2]} ${months[from[1]]} ${from[0]}`;

            }

            entityType = abn.businessEntity.entityType.entityDescription;

            state = abn.businessEntity.mainBusinessPhysicalAddress.stateCode;

            postcode = abn.businessEntity.mainBusinessPhysicalAddress.postcode;
        }

        return(
            
            <>
                <Modal
                    show={this.props.open}
                    onHide={this.props.close}
                    className="abn-lookup-modal">
                    <Modal.Header closeButton>
                        ABN Lookup
                    </Modal.Header>
                    <Modal.Body>

                        <h3>ABN Details for {this.props.abn}</h3>

                        <table className="table border">

                            <tbody>

                                <tr>

                                    <td>Entity Name:</td>

                                    <td>
                                        {
                                        legalName ?
                                            `${familyName}, ${givenName} ${otherGivenName}`
                                            
                                        : '' }

                                        { organizationName }
                                        
                                    </td>

                                </tr>

                                <tr>

                                    <td>ABN Status:</td>

                                    <td>{abnStatus}</td>

                                </tr>

                                <tr>

                                    <td>Entity type:</td>

                                    <td>{entityType}</td>

                                </tr>

                                <tr>

                                    <td>Goods & Services Tax (GST):</td>

                                    <td>{GST}</td>

                                </tr>

                                <tr>

                                    <td>Main business location:</td>

                                    <td>{state} {postcode}</td>

                                </tr>

                            </tbody>

                        </table>                        

                      

                    </Modal.Body>
                </Modal>
            </>

        )

    }

}



class Editbutton extends Component {

    /* constructor(props) {

        super(props);


    }  */

    render () {

        return (
            <>
                <button onClick={this.props.openModal} className="link-btn"><i className="fa fa-edit" /></button>
                <EditModal
                    save={this.props.save}
                    addFailed={this.props.updateFailed}
                    close={this.props.close}
                    open={this.props.open}
                    customer={this.props.customer} />
            </>
        )
    }
}

class EditModal extends Component {

    constructor(props) {

        super(props);

        this.state = {
            errors: false,
            CustomerId: props.customer.CustomerId,
            Name: props.customer.Name,
            Email: props.customer.Email,
            MobileNumber: props.customer.MobileNumber,
            add_percent_order: props.customer.add_percent_order,
            pay_by_account: props.customer.pay_by_account,
            InvoiceAddress: props.customer.InvoiceAddress
        }

        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.setState( { [e.target.name] : e.target.value } );
    }

    save() {
        this.props.save(this.state);
    }

    render() {
        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={this.props.close}
                    className="addCustomerModal">
                    <Modal.Header closeButton>
                        Edit Customer
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.errors ?

                                this.state.errors.map(error => {
                                    return (
                                    <div className="bg-danger error p-2">{error}</div>
                                    )
                                })

                            : ''
                        }
                        {
                            this.props.addFailed ?

                                <div className="bg-danger error p-2">Email Already exists</div>

                            : ''

                        }
                        <Form onSubmit={this.handleSubmit}>

                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="Name"
                                    value={this.state.Name}
                                    onChange={this.handleChange}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="Email"
                                    value={this.state.Email}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Mobile</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="MobileNumber"
                                    value={this.state.MobileNumber}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Add % To Order</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="add_percent_order"
                                    value={this.state.add_percent_order}
                                    onChange={this.handleChange}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="InvoiceAddress"
                                    value={this.state.InvoiceAddress}
                                    onChange={this.handleChange}
                                    rows="2"
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Check
                                    type="checkbox"
                                    name="pay_by_account"
                                    checked={this.state.pay_by_account}
                                    onChange={this.handleChange}
                                    label="Pay By Account" />
                            </Form.Group>



                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn cartbtn purchasebtn" onClick={this.save}> Update </button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

}

class AddModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            Name: '',
            Email: '',
            MobileNumber: '',
            InvoiceAddress: '',
            add_percent_order: 10,
            pay_by_account: 1,
            errors: '',
        }

        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
    }

    validateEmail(email)  {

        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email)) {
            return (true)
        }

        return (false)
    }

    save() {

        let valid = true;

        const email = this.state.Email;
        const mobile = this.state.MobileNumber;
        const errors = [];
        const name = this.state.Name;

        if (name.length === 0) {
            valid = false;
            errors.push('Name is required');
        }

        if (mobile.length === 0) {
            valid = false;
            errors.push('Mobile No is required');
        }

        if (email.length === 0) {
            valid = false;
            errors.push('Email Address is required')
        } else if (!this.validateEmail(email)) {
            valid = false;
            errors.push('Email is not valid email address');
        }

        this.setState( { errors: errors } );

        if (valid) {
            this.props.save(this.state);
        }
    }

    handleChange(e) {

        if (e.target.name === 'pay_by_account') {
            this.setState( { pay_by_account : this.state.pay_by_account === 1 ? 0 : 1 } )
        } else {
            this.setState( { [e.target.name] : e.target.value } )
        }

    }

    render () {
        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={this.props.close}
                    className="addCustomerModal">
                    <Modal.Header closeButton>
                        Add Customer
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.errors ?

                                this.state.errors.map(error => {
                                    return (
                                    <div className="bg-danger error p-2">{error}</div>
                                    )
                                })

                            : ''
                        }
                        {
                            this.props.addFailed ?

                                <div className="bg-danger error p-2">Email Already exists</div>

                            : ''

                        }
                        <Form onSubmit={this.handleSubmit}>

                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="Name"
                                    value={this.state.Name}
                                    onChange={this.handleChange}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="Email"
                                    value={this.state.Email}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Mobile</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="MobileNumber"
                                    value={this.state.MobileNumber}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Add % To Order</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="add_percent_order"
                                    value={this.state.add_percent_order}
                                    onChange={this.handleChange}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="InvoiceAddress"
                                    value={this.state.InvoiceAddress}
                                    onChange={this.handleChange}
                                    rows="2"
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Check
                                    type="checkbox"
                                    name="pay_by_account"
                                    checked={this.state.pay_by_account}
                                    onChange={this.handleChange}
                                    label="Pay By Account" />
                            </Form.Group>



                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn cartbtn purchasebtn" onClick={this.save}> Save </button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

class NumberEditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value : props.defaultValue,
            step : props.step
        }

        this.handleChange = this.handleChange.bind(this);
    }

    getValue() {
      return this.state.value;
    }

    handleChange(e) {
        this.setState( {
            value: e.target.value
        })
    }

    render() {
      const { value, onUpdate, ...rest } = this.props;
      return [
        <input
          { ...rest }
          type="number"
          className="form-control numberEditor"
          step={this.state.step}
          onChange={this.handleChange}

        />
      ];
    }
  }

  class ChatBox extends Component {

	constructor(props) {
		super(props);

		this.state = {
			id: props.id,
			data: props.data,
			AuthUser: props.user,
			NewComment: '',
			status: props.data.Status,
			pStatus: props.data.ProjectStatus,
			comments: []
        }

        this.handleChange = this.handleChange.bind(this);
        this.getComments = this.getComments.bind(this);
        this.renderComments = this.renderComments.bind(this);
        this.submitComment = this.submitComment.bind(this);
    }

    submitComment() {

        const user = this.state.AuthUser;
        const row = this.state.data;

        const commentdata = {
			CustomerId: row.CustomerId,
			commentText: this.state.NewComment,
			senderId: user.Id,
			senderName: user.Name,
			receiverId: (row.CustomerId) ? row.CustomerId : 0,
			receiverName: row.Name
		}


        var auth_user = this.state.AuthUser;

		fetch(config.BASE_URL + '/api/addcustomercomment', {
			method: 'POST',
			body: JSON.stringify(commentdata),
			headers: {
			  'Content-Type': 'application/json',
			  'Accept': 'application/json',
              "Authorization": 'Bearer ' + auth_user.token
			}
		})
		.then(data => data.json())
		.then(data => {

            var comments = this.state.comments;

			comments.push(data[0]);

            this.setState({
                comments: comments,
                NewComment: ''
			})

		})
		.catch(error => console.log(error));

	}

    renderComments(comments) {

		let commentHTML = '';
		if (comments.length > 0){
			commentHTML = comments.map( item => {
				return (
					<dl key={item.commentId} className="job-comments-wrapper">
					  <dt><b className="comment_name">{item.senderName} &nbsp;</b> <span className="comment_date">{item.createdDate}</span> &nbsp; : &nbsp; &nbsp; </dt>
					  <dd><div className="comment_text">{item.commentText}</div></dd>
					</dl>
				)
			});
		}

		return commentHTML;

	}

    handleChange(e) {
        this.setState({ NewComment: e.target.value});
    }

    getComments(CustomerId) {

        var user = this.state.AuthUser;

        fetch(config.BASE_URL + '/api/getcustomercomments/'+CustomerId, {
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": 'Bearer ' + user.token
            }
        })
        .then(data => data.json())
        .then(data => {

            var comments = this.state.comments;

            data.map(function(item) {
                var data = comments.filter(comment => comment.commentId === item.commentId);

                if (data.length === 0) {
                    comments.push(item);
                }

                return item;

            });

            this.setState({ comments: comments });
        })
        .catch(error => console.log(error));
    }

	componentDidMount() {
        const CustomerId = this.props.data.CustomerId;
        this.getComments(CustomerId);
	}


	render() {

        const id = this.state.id;
		//const data = this.state.data;
		const chatbox = `chatbox-${id}`;

		const comments = this.renderComments(this.state.comments);

		return (
			<>
				<div key={id} id={chatbox} >

					<div className="chat-box" ref={this.MsRef} style={ { marginLeft: '50px', height: '100px', overflow: "scroll" } }>

						{comments}

					</div>

                      <div className="admin chat-box-input" style={ { marginLeft: '40px' } }>
                          <div className="d-flex">
                              <div className="form-group mr-2">
							  	<input spellCheck="true" type="text" className="form-control" value={this.state.NewComment} placeholder={strings.customerChatPlaceholder} onChange={this.handleChange} />
                              </div>
                              <div>
                                  <button onClick={this.submitComment} className="btn purchasebtn">Submt</button>
                              </div>
                          </div>
                      </div>
				</div>
			</>
		)
	}


}
