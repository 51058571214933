import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip'

import { Carousel } from 'react-responsive-carousel';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Select, { components } from 'react-select';

import config from "../config";
import UploadImage from '../components/UploadImage';
import ColorSwatches from './ColorSwatches';

import PanelOptions from './PanelOptions';

import { strings } from './strings';

class ProductHomePanel extends Component {
  constructor(props) {
      super(props);
      //console.log(props.material)

      const defaultStockCode = props.stockcodes.filter( s => {
          return s.StockCode === props.product.stockcode
      });

      const image = defaultStockCode[0] ? defaultStockCode[0].Image : [];
      const Image = props.stockcodes[0] ? props.stockcodes[0].Image : [];

      //console.log('image', image, Image);
      //console.log('props.product', props.product)

      var defaultImages = image && image.length > 0 ? image.split(',') :  Image && Image.length > 0 ? Image.split(',') :  []

      defaultImages = defaultImages.map(image => {
          image = `${config.BASE_URL + image}`;
          return image;
      });

      if (props.product.image) {
        defaultImages = Array(props.product.image)
      }
      //console.log('defaultImages', defaultImages)

      this.state = {
        stockCodes: (typeof props.stockcodes !== 'undefined') ? props.stockcodes : [],
        materialType: (typeof props.materialType !== 'undefined') ? props.materialType : [],
        doorStyle: (typeof props.doorStyle !== 'undefined') ? props.doorStyle : [],
        brand: (typeof props.brand !== 'undefined') ? props.brand : [],
        finish: (typeof props.finish !== 'undefined') ? props.finish : [],
        colour: (typeof props.colour !== 'undefined') ? props.colour : [],
        substrate: (typeof props.substrate !== 'undefined') ? props.substrate : [],
        material: (typeof props.material !== 'undefined') ? props.material : [],
        layouts: (typeof props.layouts !== 'undefined') ? props.layouts : [],
        updateKey: ' ',
        products: props.products,
        product: props.product,
        doorHinge: (typeof props.doorHinge !== 'undefined') ? props.doorHinge : [],
        shelves: (typeof props.shelves !== 'undefined') ? props.shelves : [],
        materialArray: [],
        productsArray: localStorage.getItem("postData") ? JSON.parse(localStorage.getItem("postData")) : [],
        colorBoxUrl: "https://www.polytec.com.au/docs/technical/polytec-technical-availability-guide.pdf",
        hardware: [],
        rooms: [],

        DoorHardwareId: 0,

        cHeight: props.product && props.product.Height ? props.product.Height : "",
        cWidth: props.product && props.product.Width ? props.product.Width : "",
        cDepth: 0,
        cToeRecess: props.product && props.product.ToeRecess ? props.product.ToeRecess : "",
        cElevation: props.product && props.product.Elevation ? props.product.Elevation : "",
        cSoffitHeight: props.product && props.product.SoffitHeight ? props.product.SoffitHeight : "",
        cToeHeight: props.product && props.product.ToeHeight ? props.product.ToeHeight : "",

        HardwareLayout: props.product && props.product.HardwareLayout ? props.product.HardwareLayout : "",

        cWidthError: "",
        cHeightError: "",
        cDepthError: "",
        cToeHeightError: "",
        cToeRecessError: "",
        getRooms: false,
        productSave: props.productSave,
        editHinge: "",
        isLoaded: true,
        postData: {},
        jobProduct: localStorage.getItem("jobProduct") ? JSON.parse(localStorage.getItem("jobProduct")) : [],
        dynProName: "",
        colourfilter: [],
        mcolour: "1",
        mshelves: "1",
        productprice: "",
        carcasecost: "",
        project: {},



        details: "",

        MaterialType1 : props.materialDefault[0] ? props.materialDefault[0].MaterialTypeId : 1,
        MaterialType2 : props.materialDefault[1] ? props.materialDefault[1].MaterialTypeId : 1,
        MaterialType3 : 0,
        MaterialType4 : 0,
        MaterialType5 : 0,
        MaterialType6 : 0,

        MaterialBrand1 : props.materialDefault[0] ? props.materialDefault[0].BrandId : 1,
        MaterialBrand2 : props.materialDefault[1] ? props.materialDefault[1].BrandId : 2,
        MaterialBrand3 : 0,
        MaterialBrand4 : 0,
        MaterialBrand5 : 0,
        MaterialBrand6 : 0,

        MaterialFinish1 : props.materialDefault[0] ? props.materialDefault[0].FinishId : 1,
        MaterialFinish2 : props.materialDefault[1] ? props.materialDefault[1].FinishId : 2,
        MaterialFinish3 : 0,
        MaterialFinish4 : 0,
        MaterialFinish5 : 0,
        MaterialFinish6 : 0,

        MaterialSubstrate1 : props.materialDefault[0] ? props.materialDefault[0].SubstrateId : 2,
        MaterialSubstrate2 : props.materialDefault[1] ? props.materialDefault[1].SubstrateId : 2,
        MaterialSubstrate3 : 0,
        MaterialSubstrate4 : 0,
        MaterialSubstrate5 : 0,
        MaterialSubstrate6 : 0,

        MaterialColour1 : props.materialDefault[0] ? props.materialDefault[0].ColourId : 1,
        MaterialColour2 : props.materialDefault[1] ? props.materialDefault[1].ColourId : 173,
        MaterialColour3 : 0,
        MaterialColour4 : 0,
        MaterialColour5 : 0,


        isLogin: false,

        Width: 600,
        Height: 860,
        Depth: 0,
        Elevation: null,
        RightScribe: null,
        ToeHeight: 110,
        ToeRecess: 30,
        SoffitHeight: null,
        PicturePath: null,
        DoorId: 1,
        WidthMin: 200,
        WidthMax: 600,
        HeightMin: 900,
        HeightMax: 900,
        DepthMin: 200,
        DepthMax: 800,
        ToeHeightMin: 90,
        ToeHeightMax: 170,
        ToeRecessMin: 30,
        ToeRecessMax: 30,
        SoffitHeightMin: null,
        SoffitHeightMax: null,
        ElevationMin: null,
        ElevationMax: null,
        TotalHardwareCost: 0,
        NoOfDoors: 1,

        fixedQty: props.product.fixedQty ? props.product.fixedQty : 3,
        equalQty: props.product.equalQty ? props.product.equalQty : 3,
        adjustableQty: props.product.adjustableQty ? props.product.adjustableQty : 3,

        gapTop: props.product && props.product.DoorGapTop ? props.product.DoorGapTop: 3,
        gapBottom: props.product && props.product.DoorGapBottom ? props.product.DoorGapBottom: 0,
        gapLeft: props.product && props.product.DoorGapleft ? props.product.DoorGapLeft: 1.5,
        gapRight: props.product && props.product.DoorGapRight ? props.product.DoorGapRight: 1.5,

        HardwareId: 1,
        drawerHardwareId: 1,

        noOfShelve: 1,
        HingeId: 1,

        Gst: 0.1,
        allow : ["Pending"],
        //productImage : ["/images/slide_img1.jpg", "/images/slide_img2.jpg", "/images/slide_img1.jpg", "/images/slide_img2.jpg"],
        productImage : defaultImages && defaultImages.length > 0 ? defaultImages : [config.UPLOAD_URL+'/images/no-image.jpg'],
		      detailImage: [],
		        drawerGaps: props.product.drawerGaps,
		          show: true,
		            imageTips: {},
        lineTop: false,
        lineLeft: false,
        lineBottom: false,
        lineRight: false,
        ProductType: 'panels',
        ProductStockCode: this.props.product && this.props.product.stockcode ? this.props.product.stockcode : '',
        ProductDescription: '',
        ProductPrice: 0,
        ProductQty: 1,
        ProductLineTotal: 0,
        NoOfMitre: 0,
        isCustomized: 0,

        RoomId: 1,
        roomData : 'Room 1',
        slug: '',
        panelindex: 0,
        tooltipindex: 0,
        showtooltip: true
      }

      this.button = this.button.bind(this);
      this.dimensionContent = this.dimensionContent.bind(this);
      this.materialsContent = this.materialsContent.bind(this);
      this.doorOptionsContent = this.doorOptionsContent.bind(this);
      this.shelvesContent = this.shelvesContent.bind(this);
      this.doorGapsContent = this.doorGapsContent.bind(this);
      this.layoutContent = this.layoutContent.bind(this);

      this.productChangeValues = this.productChangeValues.bind(this);
      this.handleColourChange = this.handleColourChange.bind(this);
		  this.handleHardwareChange = this.handleHardwareChange.bind(this);

      this.getMaterial = this.getMaterial.bind(this);
      this.getMaterialType = this.getMaterialType.bind(this);
      this.getBrands = this.getBrands.bind(this);
      this.getSubstrate = this.getSubstrate.bind(this);
      this.getDoor = this.getDoor.bind(this);
      this.getHardware = this.getHardware.bind(this);
      this.getDoorHinge = this.getDoorHinge.bind(this);
      this.getShelves = this.getShelves.bind(this);
      this.getProductStockCodes = this.getProductStockCodes.bind(this);

      //this.getJobMaterials = this.getJobMaterials.bind(this);
      //this.getJobDoors = this.getJobDoors.bind(this);
      //this.getJobHardwares = this.getJobHardwares.bind(this);
      //this.getJobShelves = this.getJobShelves.bind(this);
      //this.getJobHinges = this.getJobHinges.bind(this);

      this.getGst = this.getGst.bind(this);

      this.getFinish = this.getFinish.bind(this);
      this.getColour = this.getColour.bind(this);
      this.getBrands = this.getBrands.bind(this);

      this.recalculate = this.recalculate.bind(this);

      this.updateCart = this.updateCart.bind(this);

      this.addToCart = this.addToCart.bind(this);

      this.updateImage = this.updateImage.bind(this);

      this.input = React.createRef();

      this.handleMaterialType = this.handleMaterialType.bind(this);
      this.handleMaterialBrand = this.handleMaterialBrand.bind(this);
      this.handleMaterialFinish = this.handleMaterialFinish.bind(this);
      this.handleMaterialSubstrate = this.handleMaterialSubstrate.bind(this);
      this.handleMaterialColour = this.handleMaterialColour.bind(this);

      this.handleColourSelect = this.handleColourSelect.bind(this);
      this.handleDetails = this.handleDetails.bind(this);

      this.onClickEdges = this.onClickEdges.bind(this)
      this.NoOfMitreChange = this.NoOfMitreChange.bind(this)

      this.removeImage = this.removeImage.bind(this);

      this.setData = this.setData.bind(this);

      this.showTooltip = this.showTooltip.bind(this);
      this.handleTabSelect = this.handleTabSelect.bind(this);
      this.storeTabhandle = this.storeTabhandle.bind(this);

  }

  showTooltip () {
    const {showtooltip, tooltipindex, panelindex} = this.state
    if (!showtooltip) return;
    let panel = document.querySelectorAll('.react-tabs__tab-panel')[panelindex]
    let tooltip = panel.querySelectorAll('.tooltip-button')[tooltipindex]
    console.log('tooltipindex', tooltipindex)
    if (typeof tooltip === 'undefined') {
      clearInterval(this.interval);
      return;
    }

    ReactTooltip.show(tooltip)
  }

  storeTabhandle(tab){
    let visitedtab = sessionStorage.getItem('visitedtab') || []

    if (!Array.isArray(visitedtab)) {
      visitedtab = JSON.parse(visitedtab)
    }

    if (visitedtab.indexOf(tab) >= 0){
      return false
    }
    console.log('visitedtab', visitedtab)
    visitedtab.push(tab)
    sessionStorage.setItem('visitedtab', JSON.stringify(visitedtab));
    return true
  }

  handleTabSelect(index, lastindex, event){

    const tab = event.target.getAttribute('href')
    const showtooltip = this.storeTabhandle(tab)

    if (!showtooltip) return;

    const that = this
    clearInterval(this.interval);
    this.setState({
      showtooltip: true,
      panelindex: index,
      tooltipindex: 0
    }, () => {
      setTimeout(function(){ that.showTooltip(); }, 600);
    })

    this.interval = setInterval(() =>
      this.setState(prevState => {
          return {
            tooltipindex : prevState.tooltipindex + 1
          };
    }, () => {
          that.showTooltip();
    }), 4000);
  }

  NoOfMitreChange(e){
    this.setState({
       [e.target.name]: e.target.value
     }, () =>{
       this.updateImage()
     })

  }
  onClickEdges (edge) {
    this.setState(prevState => ({
       [edge]: !prevState[edge]
     }), () =>{
       this.updateImage()
     })

  }

   handleDetails(e) {
       this.setState( {details: e.target.value} );
   }



    componentWillMount() {
        //this.getImageTips();
    }

    getImageTips() {
        fetch(config.BASE_URL + '/api/getimagetips', {
            method: "GET",
			headers: {
			  'Content-Type': 'application/json',
			  'Accept': 'application/json'
			}
        })
		.then(data => data.json())
		.then(data => {
      this.setState({
				imageTips: data.imageTips
      }, () => {
        const showtooltip = this.storeTabhandle('#dimension')
        if (showtooltip) {
          this.showTooltip()
          this.interval = setInterval(() =>
            this.setState(prevState => {
                return {
                  tooltipindex : prevState.tooltipindex + 1
                };
          }, () => {
                this.showTooltip();
          }), 4000);
        }
    	})
		})
		.catch(error => console.log(error));
	}


  updateImage() {

    const {lineTop, lineLeft, lineBottom, lineRight, NoOfMitre, product} = this.state
    var  noShortEdges = 0;
    var  noLongEdges = 0;

    if (lineTop){
        noShortEdges += 1
    }
    if (lineBottom){
        noShortEdges += 1
    }
    if (lineLeft){
        noLongEdges += 1
    }
    if (lineRight){
        noLongEdges += 1
    }

    let stockCode = '';

    let isCustomized = 1;

    let images = [];


    let pStockCodes = this.state.stockCodes.filter( s => {
        return product.ProductId === s.ProductId
    });

    //console.log('updateimage product.ProductId == s.ProductId', pStockCodes);

    if (pStockCodes.length > 0) {

        pStockCodes = pStockCodes.filter(code => {
            return code.noShortEdges === noShortEdges && code.noLongEdges === noLongEdges;
        });

        //console.log('NoOfMitre', NoOfMitre)
        //console.log('pStockCodes', pStockCodes)

        if (product.ProductName.indexOf('Kicker') !== -1) {
          pStockCodes = pStockCodes.filter(code => {
              return parseInt(code.NoOfMitre) === parseInt(NoOfMitre);
          });

          //console.log('updateimage code.NoOfMitre === NoOfMitre', pStockCodes);
        }

        //console.log('updateimage pstockocdes', pStockCodes);
    }

        if (pStockCodes.length > 0) {

            stockCode = pStockCodes[0].StockCode;

            isCustomized = 0;

            pStockCodes = pStockCodes.filter( s => {
                return s.Image !== null
            });

            if (pStockCodes.length > 0) {

                const Image = pStockCodes[0].Image;

                if (Image) {

                    const imageArr = Image.split(',');

                    images = imageArr.map(image => {

                        image = `${config.BASE_URL + image}`;

                        return image;
                    });

                }
            }
        }


        console.log('images', images);

    this.setState(
        {
            productImage: images,
            stockCode: stockCode,
            isCustomized: isCustomized
        }
    );

}

  updateCart() {

        this.props.updateCart(this.state);

        this.setState({
			    detailImage: [],
          lineTop: this.props.product.ProductName.indexOf('Panels') != -1 ? true : false,
          lineLeft:  this.props.product.ProductName.indexOf('Panels') != -1 ? true : false,
          lineBottom:  this.props.product.ProductName.indexOf('Panels') != -1 ? true : false,
          lineRight:  this.props.product.ProductName.indexOf('Panels') != -1 ? true : false,
                updateKey: ''
		}, () => {
      //console.log('product', product)
		})
  }

  addToCart() {
	  //console.log('add to cart', this.state);
    this.props.addToCart(this.state);

		this.setState({
			detailImage: [],
      lineTop: this.props.product.ProductName.indexOf('Panels') != -1 ? true : false,
      lineLeft:  this.props.product.ProductName.indexOf('Panels') != -1 ? true : false,
      lineBottom:  this.props.product.ProductName.indexOf('Panels') != -1 ? true : false,
      lineRight:  this.props.product.ProductName.indexOf('Panels') != -1 ? true : false,

		}, () => {
      //console.log('product', product)
		})

  }

  handleShelvesChange = (e) => {

    console.log('shelves', e.target.name, e.target.value);

    this.setState({
        [e.target.name]: e.target.value
    }, () => {
      this.recalculate();
      this.updateImage();
    });

  }

  handleMaterialType(e) {


      const materials = this.state.material;

      let MaterialType1 = 0;
      let MaterialType2 = 0;
      let material = [];
      let brandId = 0;
      let finishes = [];
      let finishId = 0;
      let brands = [];
      let substrates = [];
      let substrateId = 0;
      let colours = [];
      let colourId = 0;

      switch (e.target.name) {

          case 'MaterialType1':

              MaterialType1 = parseInt(e.target.value);

              material = materials[0];

              brands = material.brand.filter( b => {
                  return b.MaterialTypeId === MaterialType1
              });

              brandId = brands.length > 0 ? brands[0].BrandId : 0;

              finishes = material.finish.filter( f => {
                  return f.BrandId === brandId;
              });

              finishId = finishes.length > 0 ? finishes[0].FinishId : 0;

              substrates = material.substrate.filter( s => {
                  return s.BrandId === brandId;
              });

              substrateId = substrates.length > 0 ? substrates[0].SubstrateId : 0;

              colours = material.colour.filter( c => {
                  return c.BrandId === brandId && c.FinishId === finishId && c.SubstrateId === substrateId;
              });

              colourId = colours.length > 0 ? colours[0].ColourId : 0;

              this.setState( {
                  MaterialType1: parseInt(e.target.value),
                  MaterialBrand1: brandId,
                  MaterialFinish1: finishId,
                  MaterialSubstrate1: substrateId,
                  MaterialColour1: colourId
              });

          break;

          case 'MaterialBrand1':

              MaterialType1 = this.state.MaterialType1;

              material = materials[0];

              brandId = parseInt(e.target.value);

              finishes = material.finish.filter( f => {
                  return f.BrandId === brandId;
              });

              finishId = finishes.length > 0 ? finishes[0].FinishId : 0;

              substrates = material.substrate.filter( s => {
                  return s.BrandId === brandId;
              });

              substrateId = substrates.length > 0 ? substrates[0].SubstrateId : 0;

              colours = material.colour.filter( c => {
                  return c.BrandId === brandId && c.FinishId === finishId && c.SubstrateId === substrateId;
              });

              colourId = colours.length > 0 ? colours[0].ColourId : 0;

              this.setState( {
                  MaterialBrand1: parseInt(e.target.value),
                  MaterialFinish1: finishId,
                  MaterialSubstrate1: substrateId,
                  MaterialColour1: colourId
              });

          break;

          case 'MaterialFinish1':

              material = materials[0];

              brandId = this.state.MaterialBrand1;

              finishId = parseInt(e.target.value)

              substrates = material.substrate.filter( s => {
                  return s.BrandId === brandId;
              });

              substrateId = substrates.length > 0 ? substrates[0].SubstrateId : 0;

              colours = material.colour.filter( c => {
                  return c.BrandId === brandId && c.FinishId === finishId && c.SubstrateId === substrateId;
              });

              colourId = colours.length > 0 ? colours[0].ColourId : 0;

              this.setState( {
                  MaterialFinish1: parseInt(e.target.value),
                  MaterialSubstrate1: substrateId,
                  MaterialColour1: colourId
              });


          break;

          case 'MaterialSubstrate1':

              material = materials[0];

              brandId = this.state.MaterialBrand1;
              finishId = this.state.MaterialFinish1

              substrateId = parseInt(e.target.value);

              colours = material.colour.filter( c => {
                  return c.BrandId === brandId && c.FinishId === finishId && c.SubstrateId === substrateId;
              });

              colourId = colours.length > 0 ? colours[0].ColourId : 0;

              this.setState( {
                  MaterialSubstrate1: parseInt(e.target.value),
                  MaterialColour1: colourId
              });

          break;

          case 'MaterialType2':

              MaterialType2 = parseInt(e.target.value);

              material = materials[1];

              brands = material.brand.filter( b => {
                  return b.MaterialTypeId === MaterialType2
              });

              brandId = brands.length > 0 ? brands[0].BrandId : 0;

              finishes = material.finish.filter( f => {
                  return f.BrandId === brandId;
              });

              finishId = finishes.length > 0 ? finishes[0].FinishId : 0;

              substrates = material.substrate.filter( s => {
                  return s.BrandId === brandId;
              });

              substrateId = substrates.length > 0 ? substrates[0].SubstrateId : 0;

              colours = material.colour.filter( c => {
                  return c.BrandId === brandId && c.FinishId === finishId && c.SubstrateId === substrateId;
              });

              colourId = colours.length > 0 ? colours[0].ColourId : 0;

              this.setState( {
                  MaterialType2: parseInt(e.target.value),
                  MaterialBrand2: brandId,
                  MaterialFinish2: finishId,
                  MaterialSubstrate2: substrateId,
                  MaterialColour2: colourId,

                  MaterialType3: parseInt(e.target.value),
                  MaterialType4: parseInt(e.target.value),
                  MaterialType5: parseInt(e.target.value)
              });

          break;

          case 'MaterialBrand2':

              material = materials[1];

              brandId = parseInt(e.target.value)

              finishes = material.finish.filter( f => {
                  return f.BrandId === brandId;
              });

              finishId = finishes.length > 0 ? finishes[0].FinishId : 0;

              substrates = material.substrate.filter( s => {
                  return s.BrandId === brandId;
              });

              substrateId = substrates.length > 0 ? substrates[0].SubstrateId : 0;

              colours = material.colour.filter( c => {
                  return c.BrandId === brandId && c.FinishId === finishId && c.SubstrateId === substrateId;
              });

              colourId = colours.length > 0 ? colours[0].ColourId : 0;

              this.setState( {
                  MaterialBrand2: parseInt(e.target.value),
                  MaterialFinish2: finishId,
                  MaterialSubstrate2: substrateId,
                  MaterialColour2: colourId,

                  MaterialBrand3: parseInt(e.target.value),
                  MaterialBrand4: parseInt(e.target.value),
                  MaterialBrand5: parseInt(e.target.value)
              });

          break;

          case 'MaterialFinish2':

              material = materials[1];

              brandId = this.state.MaterialBrand2;

              finishId = parseInt(e.target.value);

              substrates = material.substrate.filter( s => {
                  return s.BrandId === brandId;
              });

              substrateId = substrates.length > 0 ? substrates[0].SubstrateId : 0;

              colours = material.colour.filter( c => {
                  return c.BrandId === brandId && c.FinishId === finishId && c.SubstrateId === substrateId;
              });

              colourId = colours.length > 0 ? colours[0].ColourId : 0;

              this.setState( {
                  MaterialFinish2: parseInt(e.target.value),
                  MaterialSubstrate2: substrateId,
                  MaterialColour2: colourId,

                  MaterialFinish3: parseInt(e.target.value),
                  MaterialFinish4: parseInt(e.target.value),
                  MaterialFinish5: parseInt(e.target.value)
              });

          break;

          case 'MaterialSubstrate2':

              material = materials[1];

              brandId = this.state.MaterialBrand2;
              finishId = this.state.MaterialFinish2
              substrateId = parseInt(e.target.value);

              colours = material.colour.filter( c => {
                  return c.BrandId === brandId && c.FinishId === finishId && c.SubstrateId === substrateId;
              });

              colourId = colours.length > 0 ? colours[0].ColourId : 0;

              this.setState( {
                  MaterialSubstrate2: parseInt(e.target.value),
                  MaterialColour2: colourId,

                  MaterialSubstrate3: parseInt(e.target.value),
                  MaterialSubstrate4: parseInt(e.target.value),
                  MaterialSubstrate5: parseInt(e.target.value)
              });

          break;

          default:

      }

}

handleMaterialBrand(e) {
    const value = e.target.value;

    //console.log('handle', e.target.name)

    if (e.target.name === 'MaterialBrand1') {

        this.setState( {
            MaterialBrand1: value
        }, () => {
            this.recalculate();
            this.updateImage();
        });

    } else {
        this.setState( {
            MaterialBrand2: value,
            MaterialBrand3: value,
            MaterialBrand4: value,
            MaterialBrand5: value
        }, () => {
            this.recalculate();
            this.updateImage();
        })
    }
}

handleMaterialFinish(e) {
    const value = e.target.value;

    if (e.target.name === 'MaterialFinish1') {

        this.setState( {
            MaterialFinish1: value
        }, () => {
            this.recalculate();
            this.updateImage();
        });

    } else {
        this.setState( {
            MaterialFinish2: value,
            MaterialFinish3: value,
            MaterialFinish4: value,
            MaterialFinish5: value
        }, () => {
            this.recalculate();
            this.updateImage();
        })
    }
}

handleMaterialSubstrate(e) {
    const value = e.target.value;

    if (e.target.name === 'MaterialSubstrate1') {

        this.setState( {
            MaterialSubstrate1: value
        }, () => {
            this.recalculate();
            this.updateImage();
        });

    } else {
        this.setState( {
            MaterialSubstrate2: value,
            MaterialSubstrate3: value,
            MaterialSubstrate4: value,
            MaterialSubstrate5: value
        }, () => {
            this.recalculate();
            this.updateImage();
        })
    }
}

handleMaterialColour(e) {
    const value = e.target.value;

    if (e.target.name === 'MaterialColour1') {

        this.setState( {
            MaterialColour1: value
        }, () => {
            this.recalculate();
            this.updateImage();
        });

    } else {
        this.setState( {
            MaterialColour2: value,
            MaterialColour3: value,
            MaterialColour4: value,
            MaterialColour5: value
        }, () => {
            this.recalculate();
            this.updateImage();
        })
    }
}


  handleHardwareChange = (e) => {
	//ar sectionId = e.target.name.slice(-1)
	var targetName = e.target.name
	//var trimName = targetName.replace(/[0-9]/g, '')
	var targetValue = e.target.value

	var drawerHardwareId = 'drawerHardwareId'

	this.setState(() => {
		return {
			[targetName]: targetValue,
			[drawerHardwareId]: targetValue
		};
	}, () => {
        this.recalculate();
        this.updateImage();
    });
  }


  handleColourChange = (e) => {
	var sectionId = e.target.name.slice(-1)
	var targetName = e.target.name
	var trimName = targetName.replace(/[0-9]/g, '')
	var targetValue = e.target.value

	//console.log('e', e, targetName, targetValue);

	//console.log('trimName');
    //console.log(trimName);

	this.setState((prevState, props) => {
		return {
			[targetName]: targetValue,

			[trimName+'3']: (sectionId === '1' ) ? targetValue : prevState[`${trimName}3`],
			[trimName+'4']: (sectionId === '1' ) ? targetValue : prevState[`${trimName}4`],
			[trimName+'5']: (sectionId === '1' ) ? targetValue : prevState[`${trimName}5`],
		};
	}, () => {
		//console.log(this.state);
        this.recalculate();
        this.updateImage();
    });
  }

  handleBrand(e, productsectionId, key, msection) {

    var brandId = e.target.value

    var colorUrlBox = "color" + msection.replace(/ /g, '')
    var colorBoxUrl = brandId === 1 ? "https://www.polytec.com.au/docs/technical/polytec-technical-availability-guide.pdf" : brandId === 2 ? "https://gocabinets.com.au/wp-content/uploads/2014/02/Formica-Colours.pdf" : "https://selector.com/media/files/laminex-colour-palette-brochure-1.pdf"


	var sectionId = e.target.name.slice(-1)
	var targetName = e.target.name
	var trimName = targetName.replace(/[0-9]/g, '')
	var targetValue = e.target.value

	this.setState((prevState, props) => {
		return {
			[colorUrlBox]: colorBoxUrl,
			[`MaterialBrand${productsectionId}`] : targetValue,

			[trimName+'3']: (sectionId === '1' ) ? targetValue : prevState[`${trimName}3`],
			[trimName+'3']: (sectionId === '1' ) ? targetValue : prevState[`${trimName}3`],
			[trimName+'4']: (sectionId === '1' ) ? targetValue : prevState[`${trimName}4`],
			[trimName+'5']: (sectionId === '1' ) ? targetValue : prevState[`${trimName}5`],
		};
	}, () => {
		//console.log(this.state);
        //this.recalculate();
        //this.updateImage();
    });

	//console.log('material')
    //console.log(this.state.material)

    this.state.material.map((Material, key) => {

            fetch(config.BASE_URL + '/api/getfinish/' + brandId, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(data => data.json())
            .then(data => {

                let material = this.state.material;

                material[key].finish = data

                this.setState({ material: material })

            })
            .catch(error => console.log(error));

            return Material;

    })

    fetch(config.BASE_URL + '/api/getcolour/' + brandId, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(data => data.json())
        .then(data => {

            let material = this.state.material;

            material[key].colour = data

            this.setState({ material: material, colourfilter: data })

        })
        .catch(error => console.log(error));
}

  getGst() {
    /*fetch(config.BASE_URL + '/api/getgst', {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then(data => data.json())
    .then(data => {
        //console.log("gst....", data[0].GST)
        this.setState({
            Gst: data[0].GST,
        })
    })
    .catch(error => console.log(error));
    */

  }

  getJobMaterials(orderId) {

    fetch(config.BASE_URL + '/api/getJobMaterials/' + orderId, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then(data => data.json())
    .then(data => {

        //console.log('get job materials', data);

        data.map(value => {
            if (value.ProductSectionId === 1) {
                this.setState(
                    {
                        MaterialType1 : value.MaterialId,
                        MaterialBrand1 : value.BrandId,
                        MaterialFinish1 : value.FinishId,
                        MaterialSubstrate1 : value.SubstrateId,
                        MaterialColour1 : value.ColourId
                    }
                )
            }

            if (value.ProductSectionId === 2) {
                this.setState(
                    {
                        MaterialType2 : value.MaterialId,
                        MaterialBrand2 : value.BrandId,
                        MaterialFinish2 : value.FinishId,
                        MaterialSubstrate2 : value.SubstrateId,
                        MaterialColour2 : value.ColourId
                    }
                )
            }

            if (value.ProductSectionId === 3) {
                this.setState(
                    {
                        MaterialType3 : value.MaterialId,
                        MaterialBrand3 : value.BrandId,
                        MaterialFinish3 : value.FinishId,
                        MaterialSubstrate3 : value.SubstrateId,
                        MaterialColour3 : value.ColourId
                    }
                )
            }

            if (value.ProductSectionId === 4) {
                this.setState(
                    {
                        MaterialType4 : value.MaterialId,
                        MaterialBrand4 : value.BrandId,
                        MaterialFinish4 : value.FinishId,
                        MaterialSubstrate4 : value.SubstrateId,
                        MaterialColour4 : value.ColourId
                    }
                )
            }

            if (value.ProductSectionId === 5) {
                this.setState(
                    {
                        MaterialType5 : value.MaterialId,
                        MaterialBrand5 : value.BrandId,
                        MaterialFinish5 : value.FinishId,
                        MaterialSubstrate5 : value.SubstrateId,
                        MaterialColour5 : value.ColourId
                    }
                )
            }

            return value;
        });


    })
    .catch(error => console.log(error));
}

getJobDoors(orderId) {

    fetch(config.BASE_URL + '/api/getJobDoors/' + orderId, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then(data => data.json())
    .then(data => {

        if (data) {

            this.setState(
                {
                    gapTop: data.GapTop,
                    //DoorId : data.DoorId,
                    gapBottom: data.GapBottom,
                    gapRight : data.GapRight,
                    gapLeft : data.GapLeft
                }
            )

        }
    })
    .catch(
        error => {
            this.setState(
                {
                    gapTop: 3,
                    DoorId : 1,
                    gapBottom: 0,
                    gapRight : 1.5,
                    gapLeft : 1.5
                }
            )
        }
    );
}


getJobHinges(orderId) {

    fetch(config.BASE_URL + '/api/getJobHinges/' + orderId, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then(data => data.json())
    .then(data => {
        this.setState(
            {
               HingeId : data.HingeId
            }
        )
    })
    .catch(
        error => {
            this.setState(
                {
                    HingeId : 1
                }
            )
        }
    );
}

getJobHardwares(orderId) {

    fetch(config.BASE_URL + '/api/getJobHardwares/' + orderId, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then(data => data.json())
    .then(data => {
        this.setState(
            {
               HardwareId : data.HardwareId
            }
        )
    })
    .catch(
        error => {
            this.setState(
            {
                HardwareId : 1
            }
        )
    } );
}

getJobShelves(orderId) {

  fetch(config.BASE_URL + '/api/getJobShelves/' + orderId, {
      method: "GET",
      headers: {
          "Content-Type": "application/json"
      }
  })
  .then(data => data.json())
  .then(data => {
      this.setState(
          {
             mshelves : data.ShelveId,
             noOfShelve : data.NoOfShelves
          }
      )
  })
  .catch(
      error => {
          this.setState(
              {
                  mshelves : 1,
                  noOfShelve : 1
              }
          )
      }
  );
}

  getShelves() {
    fetch(config.BASE_URL + '/api/getshelves', {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then(data => data.json())
    .then(data => {

        this.setState({
            shelves: data,
        })
    })
    .catch(error => console.log(error));
  }

  getProductStockCodes() {
    fetch(config.BASE_URL + '/api/getstockcodes', {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then(data => data.json())
    .then(data => {

        //console.log('stockcodes', data);

        this.setState({
            stockCodes: data,
        })
    })
    .catch(error => console.log(error));
  }

  getHardware() {
    fetch(config.BASE_URL + '/api/gethardware', {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then(data => data.json())
    .then(data => {
        this.setState({
            hardware: data
        })
    })
    .catch(error => console.log(error));
  }

  getDoorHinge() {
    fetch(config.BASE_URL + '/api/getdoorhinge', {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then(data => data.json())
    .then(data => {
		//console.log("product....", this.state.product)
		//console.log("doorHinge....", data)
        this.setState({
            doorHinge: data,
        })
    })
    .catch(error => console.log(error));
  }

  getDoor() {
    fetch(config.BASE_URL + '/api/getdoorstyle', {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then(data => data.json())
    .then(data => {
        //console.log("door type....", data)
        this.setState({
            doorStyle: data
        })
    })
    .catch(error => console.log(error));
  }

  getSubstrate() {
    fetch(config.BASE_URL + '/api/getsubstrate', {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then(data => data.json())
    .then(data => {

        this.setState({
          substrate: data
        })
    })
    .catch(error => console.log(error));
  }

  getBrands() {
    fetch(config.BASE_URL + '/api/getbrand', {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then(data => data.json())
    .then(data => {
        this.setState({
          brand: data
        })
    })
    .catch(error => console.log(error));
  }

 getColour(materialInfo, finishInfo) {

    fetch(config.BASE_URL + '/api/getcolour/1', {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then(data => data.json())
    .then(data => {

      var materialArr = [];
          materialInfo.map((materialData) => {
          let obj = {
              Section: materialData.Section,
              SectionId: materialData.SectionId,
              ProductSectionId: materialData.ProductSectionId,
              finish: finishInfo,
              colour: data
          }

          materialArr.push(obj)

          return materialData;

       });

        this.setState({
            material: materialArr,
            colourfilter: data
        });
      })
      .catch(error => console.log(error));
  }

  getFinish(materialInfo) {

    fetch(config.BASE_URL + '/api/getfinish/1', {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then(data => data.json())
    .then(data => {
        this.getColour(materialInfo, data)
    })
    .catch(error => console.log(error));
  }

  getMaterial(product) {

    var productId = product.ProductId

    if (productId > 0) {

        fetch(config.BASE_URL + '/api/getmaterial/' + productId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(data => data.json())
        .then(data => {
            this.getFinish(data);
        })
        .catch(error => console.log(error));

    }
  }

  getMaterialType() {

    fetch(config.BASE_URL + '/api/getmaterialtype', {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then(data => data.json())
    .then(data => {
        this.setState({
          materialType: data,
          isLoaded: false
        })
    })
    .catch(error => console.log(error));

  }

  productChangeValues(e) {

    this.setState(
      {
        [e.target.name] : e.target.value,
      });

        if (e.target.name === "Width") {

          if ((e.target.value >= this.state.product.WidthMin) && (e.target.value <= this.state.product.WidthMax)) {
                this.setState({
                    WidthError: ""
                })
            } else {
                var cWidthError = `Please enter min ${this.state.product.WidthMin} and max  ${this.state.product.WidthMax}`
                this.setState({
                    WidthError: cWidthError
                })
            }
        }

        if (e.target.name === "Height") {

            if ((e.target.value >= this.state.product.HeightMin) && (e.target.value <= this.state.product.HeightMax)) {

                this.setState({
                    HeightError: ""
                })
            } else {
                var cHeightError = `Please enter min ${this.state.product.HeightMin} and max  ${this.state.product.HeightMax}`
                this.setState({
                    HeightError: cHeightError
                })
            }
        }

        if (e.target.name === "Depth") {
            if ((e.target.value >= this.state.product.DepthMin) && (e.target.value <= this.state.product.DepthMax)) {
                this.setState({
                    DepthError: ""
                })
            } else {
                var cDepthError = `Please enter min ${this.state.product.DepthMin} and max  ${this.state.product.DepthMax}`
                this.setState({
                    DepthError: cDepthError
                })
            }
        }

        if (e.target.name === "ToeRecess") {

          if (this.state.product.ProductName === 'Overhead 1 Door' || this.state.product.ProductName === 'Overhead Open') {
                this.setState({
                    elevation: e.target.value
                }, () => {
                    if ((e.target.value >= this.state.product.ElevationMin) && (e.target.value <= this.state.product.ElevationMax)) {
                        this.setState({
                            ToeRecessError: ""
                        })
                    } else {
                        const cToeRecessError1 = `Please enter min ${this.state.product.ElevationMin} and max  ${this.state.product.ElevationMax}`
                        this.setState({
                            ToeRecessError: cToeRecessError1
                        })
                    }
                })

            } else {
                if ((e.target.value >= this.state.product.ToeRecessMin) && (e.target.value <= this.state.product.ToeRecessMax)) {
                    this.setState({
                        ToeRecessError: ""
                    })
                } else {
                    const cToeRecessError2 = `Please enter min ${this.state.product.ToeRecessMin} and max  ${this.state.product.ToeRecessMax}`
                    this.setState({
                        ToeRecessError: cToeRecessError2
                    })
                }
            }

        }

        if (e.target.name === "ToeHeight") {
            if (this.state.product.ProductName === 'Overhead 1 Door' || this.state.product.ProductName === 'Overhead Open') {
                this.setState({
                    SoffitHeight: e.target.value
                }, () => {
                    if ((e.target.value >= this.state.product.SoffitHeightMin) && (e.target.value <= this.state.product.SoffitHeightMax)) {
                        this.setState({
                            ToeHeightError: ""
                        })
                    } else {
                        var cToeHeightError = `Please enter min ${this.state.product.SoffitHeightMin} and max  ${this.state.product.SoffitHeightMax}`
                        this.setState({
                            ToeHeightError: cToeHeightError
                        })
                    }
                })
                if ((e.target.value >= this.state.product.SoffitHeightMin) && (e.target.value <= this.state.product.SoffitHeightMax)) {
                    this.setState({
                        ToeHeightError: ""
                    })
                } else {
                    const cToeHeightError1 = `Please enter min ${this.state.product.SoffitHeightMin} and max  ${this.state.product.SoffitHeightMax}`
                    this.setState({
                        ToeHeightError: cToeHeightError1
                    })
                }
            } else {
                if ((e.target.value >= this.state.product.ToeHeightMin) && (e.target.value <= this.state.product.ToeHeightMax)) {
                    this.setState({
                        ToeHeightError: ""
                    })
                } else {
                    const cToeHeightError2 = `Please enter min ${this.state.product.ToeHeightMin} and max  ${this.state.product.ToeHeightMax}`
                    this.setState({
                        ToeHeightError: cToeHeightError2
                    })
                }
            }
        }

      this.recalculate();
  }


  dimensionContent() {
	   const {PanelHeight, PanelWidth} = this.state.imageTips
       //const {product} = this.state;

     const relatedProducts = this.props.relatedProducts || [];
     const labelHeight = relatedProducts.subcategory && strings[relatedProducts.subcategory.id] && strings[relatedProducts.subcategory.id].PanelHeight ? strings[relatedProducts.subcategory.id].PanelHeight : strings.PanelHeight

     const labelWidth = relatedProducts.subcategory && strings[relatedProducts.subcategory.id] && strings[relatedProducts.subcategory.id].PanelWidth ? strings[relatedProducts.subcategory.id].PanelWidth : strings.PanelWidth

    return (
      <>
        <h3>Select Stock Size</h3>
        <div className="selectsizes">
          <ul>
              <li className="selected"><span>{this.state.Height} X {this.state.Width} </span></li>
          </ul>
        </div>
        <div className="col-md-12 createcustomsize">
          <h3>Create Custom Size</h3>
          <div className="cabinetsizes">
              <div className="col-md-6 leftlable">
              <button className="tooltip-button"  data-tip={PanelHeight && PanelHeight.video ? ('<iframe className="sproutvideo-player" src="'+PanelHeight.video+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : PanelHeight && PanelHeight.text ? PanelHeight.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {labelHeight}
              </div>

              <div className="col-md-6 rightqty">
                <div className="qty mt-5">
                    <input
                        type="number"
                        id="cHeight"
                        className="count"
                        name="Height"
                        value={this.state.Height}
                        onChange={this.productChangeValues} />
                </div>
              </div>

          </div>

          <div style={{ fontSize: 14, color: "red", marginBottom: 20 }}> {this.state.HeightError} </div>

          <div className="cabinetsizes">

              <div className="col-md-6 leftlable">

                <button className="tooltip-button" data-tip={PanelWidth && PanelWidth.video ? ('<iframe className="sproutvideo-player" src="'+PanelWidth.video+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : PanelWidth && PanelWidth.text ? PanelWidth.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {labelWidth}

              </div>

              <div className="col-md-6 rightqty">
                <div className="qty mt-5">
                    <input type="number"
                        id="cHeight"
                        className="count"
                        name="Width"
                        value={this.state.Width}
                        onChange={this.productChangeValues} />
                </div>
              </div>

          </div>

          <div style={{ fontSize: 14, color: "red", marginBottom: 20 }}> {this.state.WidthError} </div>
          <input type="hidden"
              id="cHeight"
              className="count"
              name="Depth"
              value={0}
               />
               <input type="hidden"
                   id="cHeight"
                   className="count"
                   name="ToeHeight"
                   value={0}
                    />
        <ReactTooltip place="top" html={true} globalEventOff='click' />
        </div>

        </>
    )

  }

  doorOptionsContent() {

    const doorHinge = this.state.doorHinge;

	//var removeHinge = []

	/*if (product.ProductId === 1) {
		removeHinge = ['Both']
	}

	if (product.ProductId === 2) {
		removeHinge = ['Left', 'Right']
	}*/

    const hardware = this.state.hardware.filter(h => {
        return h.HardwareName.indexOf('Hinge') >= 0;
    });

    const {DoorHinging, Hardware} = this.state.imageTips

    const hingeOptions = doorHinge.filter(hinge => {
        return this.state.NoOfDoors === hinge.NoOfDoor
    });

    //console.log('state', this.state);
    //console.log('hinge options', hingeOptions);
    //console.log('NoOfDoors', this.state.NoOfDoors);

    return (
      <>
        <div id="dooroption">
            <div className="dooroption doorgaps">

                <div className="door-select">

                    <button className="tooltip-button"  data-tip={Hardware && Hardware !== '' ? ('<iframe className="sproutvideo-player" src="'+Hardware+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : (strings.DoorOptionsTabHardware)} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.DoorOptionsTabHardware}
                    <select id="doorHarwareId"
                            name="DoorHardwareId"
                            onChange={this.handleShelvesChange}>
                        {
                            hardware && hardware.length > 0 ? hardware.map((Hardware, key) => (

                                <option key={key}
                                        selected={this.state.DoorHardwareId === Hardware.HardwareId}
                                        value={Hardware.HardwareId}>{Hardware.HardwareName}</option>

                            )) : ""
                        }
                    </select>

                </div>


                <div className="door-select">
                    <button className="tooltip-button"  data-tip={DoorHinging && DoorHinging !== '' ? ('<iframe className="sproutvideo-player" src="'+DoorHinging+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : (strings.DoorOptionsTabDoorHinging)} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.DoorOptionsTabDoorHinging}


                    <select
                            id="hinge"
                            name="HingeId"
                            onChange={this.handleShelvesChange}>
                        {
                            hingeOptions && hingeOptions.length > 0 ? hingeOptions.map((hinge, key) => (


                                    <option key={key}
                                        value={hinge.HingeId}
                                        selected={this.state.HingeId === hinge.HingeId}
                                        >{hinge.HingeType}</option>



                            )) : ""
                        }

                    </select>
                </div>

					<ReactTooltip place="top" html={true} globalEventOff='click' />
				</div>


			</div>
		  </>
		)
    }

  layoutContent() {

    const layouts = this.state.layouts;

    return (
        <>
            <div className="door-select">
                <button className="tooltip-button"  data-tip={strings.HardwareLayout} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.HardwareLayout}
                <select name="HardwareLayout"
                          id="HardwareLayout"
                        onChange={this.handleShelvesChange}>
                    <option value="">-- Please Select --</option>
                    {
                        layouts.map( l => {
                            return <option value={l.layout} selected={l.layout === this.state.HardwareLayout} >{l.layout}</option>
                        })
                    }
                </select>

            </div>

            <ReactTooltip place="top" html={true} globalEventOff='click' />

        </>
    )

  }

  shelvesContent() {

    //console.log('shelvesContent', this.state)

    const {lineTop, lineLeft, lineBottom, lineRight} = this.state

    var  noShortEdges = 0;
    var  noLongEdges = 0;

    if (lineTop){
        noShortEdges += 1
    }
    if (lineBottom){
        noShortEdges += 1
    }
    if (lineLeft){
        noLongEdges += 1
    }
    if (lineRight){
        noLongEdges += 1
    }

    var ProductName = this.props.product.ProductName
    ProductName = ProductName.slice(0, -1);
    return (
      <>
      <div className="midtabsection">
      <h3 style={{textAlign: 'center'}}>Click on the edges to select</h3><br />
      <PanelOptions product={this.props.product} lineTop={lineTop} lineLeft={lineLeft} lineBottom={lineBottom} lineRight={lineRight} onClickEdges={this.onClickEdges} />
      <br />
      <h4 style={{textAlign: 'center'}}>{ProductName} with {noShortEdges} Short Edge{(noShortEdges > 1) ? 's' : ''} and {noLongEdges} Long Edge{(noLongEdges > 1) ? 's' : ''}</h4>
      { this.props.product.ProductName && this.props.product.ProductName.indexOf("Kicker") > -1 ?
      <div id="dooroption">
          <div className="dooroption doorgaps">
              <div className="door-select">
              {strings.NoOfMitre}
      <select id="NoOfMitre"
              name="NoOfMitre"
              defaultValue={this.state.NoOfMitre}
              onChange={this.NoOfMitreChange.bind(this)}>
            <option value="0"  selected={0 === this.state.NoOfMitre}> 0 </option>
            <option value="1" selected={1 === this.state.NoOfMitre}> 1 </option>
            <option value="2" selected={2 === this.state.NoOfMitre}> 2 </option>
      </select>
      </div>
      </div>
      </div> : ''
    }
      </div>
      </>
    )

  }

  removeImage(key){

    var product = this.state.product
    var detailImage = Array.isArray(product.detailImage) ? product.detailImage : []

    if (detailImage.length > 0) {
      delete detailImage[key];
    }

		product.detailImage = detailImage
		this.setState({
			product: product,
			detailImage: detailImage
		}, () => {
      //console.log('product.detailImage', product.detailImage)
		})
  }

	onDropImage	= (file) =>{
		var {detailImage} = this.state
		detailImage.push(file)
		this.setState({
			detailImage: detailImage
		}, () => {

		})
	}

  doorGapsContent() {

    const {product, detailImage} = this.state

    const {DoorGapTop, DoorGapBottom, DoorGapLeft, DoorGapRight, DrawerGaps} = this.state.imageTips

    return (
      <>
        <div id="doorgaps" className="doorgaps">


            <div className="door-select">
			    <ul className="detail-images" style={{margin: "0px"}}>
          {
              detailImage.map((img, key) => {
              let ext = img.split('.').pop()
              if (ext != 'bmp' && ext != 'png' && ext != 'jpg' && ext != 'jpeg' && ext != 'BMP' && ext != 'PNG' && ext != 'JPG' && ext != 'JPEG') {
                img = '/uploads/' + ext + '-prev.png'
              }
              return (
              <li><i style={{float: 'right'}} className="fa fa-times"  onClick={this.removeImage.bind(null, key)} />
              <img alt="" src={config.UPLOAD_URL+img} /></li>
              )
            }
          )}
			    </ul>
            </div>

            <div className="door-select">
			    <UploadImage
				    onDropImage={this.onDropImage}
			    />
            </div>
            <div className="form-group" style={{paddingTop: "0px"}}>

                <label>{strings.VariationsTabDetails}</label>

                <textarea className="form-control" style={{width: '100%', height: '70px', minHeight: '70px'}} onChange={this.handleDetails} value={this.state.details} name="details" />

            </div>

            { product.hasDoor === 1 ?

                <>

                    <div style={{float: 'none', clear: 'both'}}></div>
                    <div className="door-select form-group col-md-6" style={{width:"48%", marginRight: "10px", paddingLeft: '0px', marginTop: '0px', paddingTop: "0px"}}>
                            <button className="tooltip-button"
                                data-tip={DoorGapTop && DoorGapTop !== '' ? ('<img src='+config.UPLOAD_URL+DoorGapTop+' />') : (strings.VariationsTabDoorGapTop)}
                                data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.VariationsTabDoorGapTop}
                            <input type="text" id="gapTop" name="gapTop" value={this.state.gapTop} onChange={this.productChangeValues} placeholder="0mm" />
                        </div>

                    <div className="door-select form-group col-md-6"  style={{width:"48%", paddingRight: '0px',  marginTop: '0px', paddingTop: "0px"}}>
                            <button className="tooltip-button"  data-tip={DoorGapBottom && DoorGapBottom !== '' ? ('<img src='+config.UPLOAD_URL+DoorGapBottom+' />') : (strings.VariationsTabDoorGapBottom)} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.VariationsTabDoorGapBottom}
                            <input type="text" id="gapBottom" name="gapBottom" value={this.state.gapBottom} onChange={this.productChangeValues} placeholder="0mm" />
                        </div>
                    <div style={{float: 'none', clear: 'both'}}></div>
                    <div className="door-select form-group col-md-6" style={{width:"48%", marginRight: "10px", paddingLeft: '0px', marginTop: '0px', paddingTop: "0px"}}>
                            <button className="tooltip-button"  data-tip={DoorGapLeft && DoorGapLeft !== '' ? ('<img src='+config.UPLOAD_URL+DoorGapLeft+' />') : (strings.VariationsTabDoorGapLeft)} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.VariationsTabDoorGapLeft}
                            <input type="text" id="gapLeft" name="gapLeft" value={this.state.gapLeft} onChange={this.productChangeValues} placeholder="1.5mm" />
                        </div>
                    <div className="door-select form-group col-md-6"  style={{width:"48%", paddingRight: '0px',  marginTop: '0px', paddingTop: "0px"}}>
                            <button className="tooltip-button"  data-tip={DoorGapRight && DoorGapRight !== '' ? ('<img src='+config.UPLOAD_URL+DoorGapRight+' />') : (strings.VariationsTabDoorGapRight)} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.VariationsTabDoorGapRight}
                            <input type="text" id="gapRight" name="gapRight" value={this.state.gapRight} onChange={this.productChangeValues} placeholder="1.5mm" />
                        </div>

                </>

                : '' }

                { product.hasDrawer === 1  ?

                    <>

                        <div style={{float: 'none', clear: 'both'}}></div>
                        <div className="door-select" style={{paddingTop: "0px"}}>
                            <button className="tooltip-button"  data-tip={DrawerGaps && DrawerGaps !== '' ? ('<iframe className="sproutvideo-player" src="'+DrawerGaps+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : (strings.CabinetOptionsTabDrawerGaps)} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button> {strings.CabinetOptionsTabDrawerGaps}
                            <textarea onChange={(event) =>this.setState({drawerGaps: event.target.value})} value={this.state.drawerGaps} style={{width: '100%', height: '70px', minHeight: '70px'}}></textarea>

                        </div>

                    </>

                    : '' }


        </div>
        <ReactTooltip place="top" html={true} globalEventOff='click' />
      </>
    )

  }

	onSelectImage = (colour, SectionId) => {
		this.setState({
			[`MaterialColour${SectionId}`] : colour.value,
			[`MaterialColourSelect${SectionId}`] : SectionId
		})

	}

	handleColourSelect = (SectionId, colour, selControl) => {
		this.setState({
			[`${selControl.name}`] : colour.value,
			[`MaterialColourSelect${SectionId}`] : SectionId
		})
	}

  materialsContent() {
	//console.log('render material content')
    const material  = this.state.material;
    const materialType = this.state.materialType;
    const substrate = this.state.substrate;
    //const colorBoxUrl = this.state.colorBoxUrl;
    const brand = this.state.brand;

	//console.log(material)
	const { InteriorMaterial, ExteriorMaterial, ToeMaterial, CaseEdgeMaterial, FaceEdgeMaterial} = this.state.imageTips

  //const {DefaultMaterials} = this.props.product
  //console.log('DefaultMaterials', DefaultMaterials)

	const Option = props => {
		return (
		<components.Option {...props}>
			<img alt="select colour" className="SelectColourImage" src={config.UPLOAD_URL+props.data.image} /> &nbsp;{props.data.label}
		</components.Option>
		);
	};

  const relatedProducts = this.props.relatedProducts || [];
  const ExteriorLabel = relatedProducts.subcategory && strings[relatedProducts.subcategory.id] && strings[relatedProducts.subcategory.id].ExteriorMaterial ? strings[relatedProducts.subcategory.id].ExteriorMaterial : strings.ExteriorMaterial

    return (
      <>
        <div id="materailoption">
            <div className="col-md-12 createcustomsize">
				<ReactTooltip place="top" html={true} globalEventOff='click' />
                {
                    material && material.length > 0 ? material.map((Material, key) => {
                        //console.log('Material', Material)
                        if (Material.SectionId === 2) {

                        /*     var colorBoxUrDynamic = "color" + Material.Section.replace(/ /g, '');


                        let values = {
                            1 : this.state.MaterialType1,
                            2 : this.state.MaterialType2,
                            3 : this.state.MaterialType3,
                            4 : this.state.MaterialType4,
                            5 : this.state.MaterialType5
                        };

                        let brandValues = {
                            1 : this.state.MaterialBrand1,
                            2 : this.state.MaterialBrand2,
                            3 : this.state.MaterialBrand3,
                            4 : this.state.MaterialBrand4,
                            5 : this.state.MaterialBrand5
                        };

                        let finishValues = {
                            1 : this.state.MaterialFinish1,
                            2 : this.state.MaterialFinish2,
                            3 : this.state.MaterialFinish3,
                            4 : this.state.MaterialFinish4,
                            5 : this.state.MaterialFinish5
                        };

                        let substrateValues = {
                            1 : this.state.MaterialSubstrate1,
                            2 : this.state.MaterialSubstrate2,
                            3 : this.state.MaterialSubstrate3,
                            4 : this.state.MaterialSubstrate4,
                            5 : this.state.MaterialSubstrate5
                        };

                        let colourValues = {
                            1 : this.state.MaterialColour1,
                            2 : this.state.MaterialColour2,
                            3 : this.state.MaterialColour3,
                            4 : this.state.MaterialColour4,
                            5 : this.state.MaterialColour5
                        }; */

                        let SectionId = 2; //Material.SectionId;

                        //console.log('SectionId', SectionId)
                        //console.log(`MaterialBrand${SectionId}`, this.state[`MaterialBrand${SectionId}`]);


                        let value = this.state[`MaterialType${SectionId}`];
                        let brandValue = this.state[`MaterialBrand${SectionId}`];
                        let finishValue = this.state[`MaterialFinish${SectionId}`];
                        let substrateValue = this.state[`MaterialSubstrate${SectionId}`];
                        let colourValue = this.state[`MaterialColour${SectionId}`];

                        //console.log('colourValue' + SectionId, colourValue);
                        /*
                        let DefaultMaterial = DefaultMaterials && DefaultMaterials[SectionId-1] ? DefaultMaterials[SectionId-1] : []
                        //console.log('DefaultMaterial', DefaultMaterial);
                        value = DefaultMaterial.MaterialTypeId ? DefaultMaterial.MaterialTypeId : this.state[`MaterialType${SectionId}`];
                        brandValue = DefaultMaterial.BrandId ? DefaultMaterial.BrandId : this.state[`MaterialBrand${SectionId}`];

                        substrateValue = DefaultMaterial.SubstrateId ? DefaultMaterial.SubstrateId : this.state[`MaterialSubstrate${SectionId}`];
                        colourValue = DefaultMaterial.ColourId ? DefaultMaterial.ColourId : this.state[`MaterialColour${SectionId}`];
                        */

						//let colourLabel = ''
						//let doorStyle = this.state.doorStyle;



						var tooltipLink = <></>
						switch(Material.SectionId) {
							case 1:
								tooltipLink = <button className="tooltip-button"  data-tip={InteriorMaterial && InteriorMaterial.video ? ('<iframe className="sproutvideo-player" src="'+InteriorMaterial.video+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : InteriorMaterial && InteriorMaterial.text ? InteriorMaterial.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button>
								break;
							case 2:
								tooltipLink = <button className="tooltip-button"  data-tip={ExteriorMaterial && ExteriorMaterial.video ? ('<iframe className="sproutvideo-player" src="'+ExteriorMaterial.video+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : ExteriorMaterial && ExteriorMaterial.text ? ExteriorMaterial.text : ''} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button>
								break;
							case 3:
								tooltipLink = <button className="tooltip-button"  data-tip={ToeMaterial ? ('<iframe className="sproutvideo-player" src="'+ToeMaterial+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : (Material.Section)} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button>
								break;
							case 4:
								tooltipLink = <button className="tooltip-button"  data-tip={CaseEdgeMaterial ? ('<iframe className="sproutvideo-player" src="'+CaseEdgeMaterial+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : (Material.Section)} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button>
								break;
							case 5:
								tooltipLink = <button className="tooltip-button"  data-tip={FaceEdgeMaterial ? ('<iframe className="sproutvideo-player" src="'+FaceEdgeMaterial+'&autoPlay=true&loop=true&bigPlayButton=false&showControls=false&volumeControl=false&seekBar&transparent=false&volume=0&background=true" style="width:450px; height: 285px" frameBorder="0" allowFullScreen></iframe>') : (Material.Section)} data-html={true}><i style={{cursor: 'pointer'}} className="fa fa-info-circle" aria-hidden="true" /></button>
								break;
							default:
								tooltipLink = ''
                        }


                        //console.log('Material.colour', Material.colour)

                        const brandOptions = brand.filter((brand) => {
                            return brand.MaterialTypeId === value
                        });

                        const finishOptions = Material.finish.filter((finish) => {
                            return finish.BrandId === brandValue
                        });

                        finishValue = (finishOptions.length > 0) ? finishOptions[0].FinishId : finishValue;

                        //finishValue = DefaultMaterial.FinishId ? DefaultMaterial.FinishId : finishValue;

						const colours = Material.colour.filter((Colour, key) => {
							return brandValue === Colour.BrandId && Colour.FinishId === finishValue && Colour.SubstrateId === substrateValue
                        });

                        const colourOptions = colours.map((Colour, key) => {
							return {
								value   : Colour.ColourId,
								label   : Colour.ColourName,
								image   : Colour.ColourImage,
								brandid : Colour.BrandId
							}
                        });

						//console.log('colourOptions', colourOptions)



                        //console.log('finish', finishOptions);

                        let defaultOption = [];

                        defaultOption = colourOptions.filter((colour) =>{
							return colour.value === colourValue
                        })
                        //console.log('colourOptions', colourOptions);
                        //console.log('colourValue', colourValue);
                        //console.log('defaultOption', defaultOption);


                        return (
                            <React.Fragment key={key}>
                                <h3>
									{tooltipLink}  {ExteriorLabel}
								</h3>
                                {/*
								{Material.SectionId == 2 ? (
                                <div className="cabinetsizes">
                                    <div className="col-md-4 leftlable">{strings.materialTabSelectyourDoorStyle}</div>
                                    <div className="col-md-8 rightqty">
                                        <div className="mt-5 materials_type">
										<select id="doorid"
												name="DoorId"
												onChange={this.handleColourChange}
												defaultValue={this.state.DoorId}>
											{
												doorStyle && doorStyle.length > 0 ? doorStyle.map((door, key) => (

													<option key={key}
															value={door.DoorId}>{door.DoorName}</option>

												)) : ""
											}
										</select>
                                        </div>
                                    </div>
                                </div>
								) : ('')} */}
                                <div className="cabinetsizes">
                                    <div className="col-md-4 leftlable">{strings.materialTabMaterialType}</div>
                                    <div className="col-md-8 rightqty">
                                        <div className="mt-5 materials_type">
                                            <select name={"MaterialType" + Material.SectionId} id={"materaltype" + Material.SectionId} onChange={this.handleMaterialType}>
                                                {
                                                    materialType && materialType.length > 0 ? materialType.map((material, key) => (

                                                        <option key={key} value={material.MaterialTypeId} selected={material.MaterialTypeId === value} >{material.MaterialType}</option>

                                                    )) : ""
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="cabinetsizes">
                                    <div className="col-md-4 leftlable">{strings.materialTabBrand}</div>
                                    <div className="col-md-8 rightqty">
                                        <div className="mt-5 materials_type">

                                            <select name={"MaterialBrand" + Material.SectionId} id={"materalbrand " + Material.SectionId} onChange={this.handleMaterialType}>
                                                {
                                                    brandOptions && brandOptions.length > 0 ? brandOptions.map((brands, key) => (
                                                        <option key={key} value={brands.BrandId} selected={brands.BrandId === brandValue}>{brands.BrandName}</option>
                                                    )) : ""
                                                }
                                            </select>


                                        </div>
                                    </div>
                                </div>
                                <div className="cabinetsizes">
                                    <div className="col-md-4 leftlable">{strings.materialTabFinish}</div>
                                    <div className="col-md-8 rightqty">
                                        <div className="mt-5 materials_type">
                                            <select name={"MaterialFinish" + Material.SectionId} id={"materalfinish " + Material.SectionId} onChange={this.handleMaterialType}>
                                                {
                                                    finishOptions && finishOptions.length > 0 ? finishOptions.map((Finish, key) => (

                                                        <option key={key} value={Finish.FinishId} selected={Finish.FinishId === finishValue}>{Finish.FinishName}</option>

                                                    )) : ""
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="cabinetsizes">
                                    <div className="col-md-4 leftlable">{strings.materialTabSubstrate}</div>
                                    <div className="col-md-8 rightqty">
                                        <div className="mt-5 materials_type">
                                            <select name={"MaterialSubstrate" + Material.SectionId} id={"materalsubstrate " + Material.SectionId} onChange={this.handleMaterialType}>
                                                {
                                                    substrate && substrate.length > 0 ? substrate.map((Substrate, key) => (
                                                        <option
                                                          key={key}
                                                          value={Substrate.SubstrateId} selected={Substrate.SubstrateId === substrateValue}>{Substrate.SubstrateName}</option>
                                                    )) : ""
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="cabinetsizes">
                                    <div className="col-md-4 leftlable">{strings.materialTabColour}
                                    </div>
                                    <div className="col-md-8 rightqty">
                                        <div className="mt-5 materials_type">

											<Select
												options={colourOptions}
												backspaceRemovesValue={true}
												value = {defaultOption.length > 0 ? defaultOption[0] : colourOptions[0]}
												defaultValue={defaultOption}
												name={"MaterialColour" + Material.SectionId}
												onChange={this.handleColourSelect.bind(this, Material.SectionId)}
												styles={{
													control: (base, state) => ({
														...base,
														'&:hover': { borderColor: 'gray' }, // border style on hover
														border: 'none', // default border color
														boxShadow: 'none', // no box-shadow
													})
												}}
												components={{ Option }}

                                            />


											<ColorSwatches onSelectImage={this.onSelectImage} colours={colourOptions} SectionId={Material.SectionId} />
											{/*
                                            <select
                                                name={"MaterialColour" + Material.SectionId}
                                                onChange={this.handleMaterialColour}
                                                defaultValue={colourValue}
                                                id={"materalcolour " + Material.SectionId}>
                                                {

                                                    Material.colour && Material.colour.length > 0 ? Material.colour.map((Colour, key) => (
                                                        <option
                                                          key={key}
                                                          onChange={this.handleColourChange}
                                                          selected={Colour.ColourId == colourValue}
                                                          value={Colour.ColourId}>{Colour.ColourName}</option>
                                                    )) : ""
                                                }
                                            </select>

                                            <ReactTooltip place="top" html={true} />

                                            */}
                                        </div>
                                    </div>
                                </div>
                                <hr style={{marginTop: '0px'}} />

                            </React.Fragment>

                        )}

                        //return Material; //eslint compliance

                    }) : ""
                }
                <div className="cabinetsizes" id="hiddenattribute" style={{ display: 'none' }}>
                    <div className="col-md-6 leftlable">Horizontal Grain
                    </div>
                    <div className="col-md-6 rightqty">
                        <div className="mt-5 checkboxdiv">
                            <label className="radiobox">
                                <input type="checkbox" defaultValue />
                                <div className="checkmark" /> </label>
                        </div>
                    </div>
                </div>


            </div>


        </div>

        </>
    )

  }

  shouldComponentUpdate(newProps) {
      const product = newProps.product;

      if (product && product.ProductName) {
          //console.log('should', product)
          return true;
      }

      return false;
  }

  componentDidUpdate(newProps, prevState) {

    //console.log('did update panel');
    //console.log(prevState);
    //console.log(this.props);

    if (this.props.updateKey !== prevState.updateKey) {
        this.setData();
    }

  }

  componentDidMount() {
      this.setData();
      this.getImageTips()
  }

  componentWillUnmount(){
    sessionStorage.removeItem('visitedtab');
    clearInterval(this.interval)
  }


  setData() {

    if (true) {

        const product = this.props.product;

        console.log('setdata this.props.product', product);

        var lastProduct = this.props.products.slice(-1).pop()

        const MaterialData = product.MaterialData ? product.MaterialData : [];
        const hardwareData = product.hardwareData ? product.hardwareData : { HardwareId : 1, DoorHardwareId : 1 };

        const doorData = product.doorData ? product.doorData : { GapTop: 3, GapBottom : 0, GapRight : 1.5, GapLeft : 1.5 }

        const materialTypes = this.props.materialType;

        const materialType = materialTypes.length > 0 ? materialTypes[0] : [];

        const defaultMaterialType2 = materialType ? materialType.MaterialTypeId : 74;

        let defaultDoorId = 2
        let defaultHardwareId = 1;

        const doors = this.props.door;

        const door = this.props.door.filter(d => {
            return d.DoorId == defaultDoorId
        });

        if (door.length == 0 && doors.length > 0) {
            defaultDoorId = doors[0].DoorId;
        }

        if (product.hasDrawer == 1) {
            defaultHardwareId = 3;
        }

        const noDoor =  7;
        const twoDoor = 3;

        let hingeId = product.HingeId ? product.HingeId : 1;

        if (product.NoOfDoors === 0) {
            hingeId = noDoor;
        }

        if (product.NoOfDoors === 2) {
            hingeId = twoDoor;
        }

        const hingeData = product.hingeData ? product.hingeData: { HingeId : hingeId };


        const allProducts = JSON.parse(localStorage.getItem("relatedProducts")) || [];

        //console.log('image', Image);

        const MaterialType1 =  MaterialData && MaterialData.length > 0 ? MaterialData[0].MaterialId : this.props.materialDefault && this.props.materialDefault[0] ? this.props.materialDefault[0].MaterialTypeId : 1;
        const MaterialType2 =  MaterialData && MaterialData.length > 0 ? MaterialData[1].MaterialId : this.props.materialDefault && this.props.materialDefault[1] ? this.props.materialDefault[1].MaterialTypeId : 1;

        const MaterialBrand1 = MaterialData && MaterialData.length > 0 ? MaterialData[0].BrandId : this.props.materialDefault && this.props.materialDefault[0] ? this.props.materialDefault[0].BrandId : 1;
        const MaterialBrand2 = MaterialData && MaterialData.length > 1 ? MaterialData[1].BrandId : this.props.materialDefault && this.props.materialDefault[1] ? this.props.materialDefault[1].BrandId : 1;

        const MaterialSubstrate1 = MaterialData && MaterialData.length > 0 ? MaterialData[0].SubstrateId : this.props.materialDefault && this.props.materialDefault[0] ? this.props.materialDefault[0].SubstrateId : 1;
        const MaterialSubstrate2 = MaterialData && MaterialData.length > 1 ? MaterialData[1].SubstrateId : this.props.materialDefault && this.props.materialDefault[1] ? this.props.materialDefault[1].SubstrateId : 1;

        const MaterialFinish1 = MaterialData && MaterialData.length > 0 ? MaterialData[0].FinishId : this.props.materialDefault && this.props.materialDefault[0] ? this.props.materialDefault[0].FinishId : 1;
        const MaterialFinish2 = MaterialData && MaterialData.length > 1 ? MaterialData[1].FinishId : this.props.materialDefault && this.props.materialDefault[1] ? this.props.materialDefault[1].FinishId : 1;

        const MaterialColour1 = MaterialData && MaterialData.length > 0 ? MaterialData[0].ColourId : this.props.materialDefault && this.props.materialDefault[0] ? this.props.materialDefault[0].ColourId : 1;
        const MaterialColour2 = MaterialData && MaterialData.length > 1 ? MaterialData[1].ColourId : this.props.materialDefault && this.props.materialDefault[1] ? this.props.materialDefault[1].ColourId : 1;

        //console.log('material', MaterialData);
        //console.log('Type', MaterialType1);
        //console.log('Brand', MaterialBrand1);
        //console.log('Substrate', MaterialSubstrate1);
        //console.log('Finish', MaterialFinish1);

        //console.log('hardware data', hardwareData);
        console.log('product.ProductName.indexOf', product.ProductName.indexOf('Panels'))
        this.setState({
            //relatedproducts   : this.props.relatedProducts,
            product           : product,
            Width             : product.Width,
            Height            : product.Height,
            Depth             : product.Depth,
            Elevation         : product.Elevation,
            RightScribe       : product.RightScribe,
            ToeHeight         : product.ToeHeight,
            ToeRecess         : product.ToeRecess,
            SoffitHeight      : product.SoffitHeight,
            PicturePath       : product.PicturePath,
            WidthMin          : product.WidthMin,
            WidthMax          : product.WidthMax,
            HeightMin         : product.HeightMax,
            HeightMax         : product.HeightMax,
            DepthMin          : product.DepthMin,
            DepthMax          : product.DepthMax,
            ToeHeightMin      : product.ToeHeightMin,
            ToeHeightMax      : product.ToeHeightMax,
            ToeRecessMin      : product.ToeRecessMin,
            ToeRecessMax      : product.ToeRecessMax,
            SoffitHeightMin   : product.SoffitHeightMin,
            SoffitHeightMax   : product.SoffitHeightMax,
            ElevationMin      : product.ElevationMin,
            ElevationMax      : product.ElevationMax,
            TotalHardwareCost : product.TotalHardwareCost,
            NoOfDoors         : product.NoOfDoors,
            noOfShelve        : product.noOfShelve ? product.noOfShelve : 1,
            mshelves          : product.mshelves ? product.mshelves : 2,
            HingeId           : hingeData ? hingeData.HingeId : hingeId,

            HardwareId        : hardwareData ? hardwareData.HardwareId : 0,

            HardwareLayout    : product.HardwareLayout ? product.HardwareLayout : '',

            DrawerHardwareId  : product.HardwareId ? product.HarwareId: 1,

            DoorHardwareId    : hardwareData.DoorHardwareId ? hardwareData.DoorHardwareId: 1,

            fixedQty : product.fixedQty ? product.fixedQty : 0,
            equalQty : product.equalQty ? product.equalQty : 0,
            adjustableQty : product.adjustableQty ? product.adjustableQty : 0,

            AdjustableShelve: product.adjustableQty ? product.adjustableQty : 0,
            FixedShelve: product.fixedQty ? product.fixedQty : 0,

            DoorId: defaultDoorId,

            MicrowaveOven : product.MicrowaveOven,
            WineRack : product.WineRack,

            // MaterialType1 : MaterialData && MaterialData.length > 0 ? MaterialData[0].MaterialId : newProps.materialDefault.MaterialTypeId,
            MaterialType1 : MaterialType1, //newProps.materialDefault[0] ? newProps.materialDefault[0].MaterialTypeId : 1,
            //MaterialType2 : MaterialData && MaterialData.length > 0 ? MaterialData[0].MaterialId : defaultMaterialType2,
            MaterialType2 : MaterialType2, //newProps.materialDefault[1] ? newProps.materialDefault[1].MaterialTypeId : defaultMaterialType2,
            MaterialType3 : 0,
            MaterialType4 : 0,
            MaterialType5 : 0,
            MaterialType6 : 0,

            //MaterialBrand1 : MaterialData && MaterialData.length > 0 ? MaterialData[0].BrandId : newProps.materialDefault.BrandId,
            MaterialBrand1 : MaterialBrand1, //newProps.materialDefault[0] ? newProps.materialDefault[0].BrandId : 1,
            MaterialBrand2 : MaterialBrand2, //newProps.materialDefault[1] ? newProps.materialDefault[1].BrandId : 2,
            MaterialBrand3 : 0,
            MaterialBrand4 : 0,
            MaterialBrand5 : 0,
            MaterialBrand6 : 0,

            //MaterialFinish1 : MaterialData && MaterialData.length > 0 ? MaterialData[0].FinishId : newProps.materialDefault.FinishId,
            MaterialFinish1 : MaterialFinish1, //newProps.materialDefault[0] ? newProps.materialDefault[0].FinishId : 1,
            MaterialFinish2 : MaterialFinish2, //newProps.materialDefault[1] ? newProps.materialDefault[1].FinishId : 2,
            MaterialFinish3 : 0,
            MaterialFinish4 : 0,
            MaterialFinish5 : 0,
            MaterialFinish6 : 0,

            //MaterialSubstrate1 : MaterialData && MaterialData.length > 0 ? MaterialData[0].SubstrateId : 2,
            MaterialSubstrate1 : MaterialSubstrate1, //newProps.materialDefault[0] ? newProps.materialDefault[0].SubstrateId : 2,
            MaterialSubstrate2 : MaterialSubstrate2, //newProps.materialDefault[1] ? newProps.materialDefault[1].SubstrateId : 2,
            MaterialSubstrate3 : 1,
            MaterialSubstrate4 : 1,
            MaterialSubstrate5 : 1,
            MaterialSubstrate6 : 1,

            //MaterialColour1 : MaterialData && MaterialData.length > 0 ? MaterialData[0].ColourId : newProps.materialDefault.ColourId,
            MaterialColour1 : MaterialColour1, //newProps.materialDefault[0] ? newProps.materialDefault[0].ColourId : 1,
            MaterialColour2 : MaterialColour2, //newProps.materialDefault[1] ? newProps.materialDefault[1].ColourId : 173,
            MaterialColour3 : 0,
            MaterialColour4 : 0,
            MaterialColour5 : 0,

			hasDoor     : product.hasDoor,
			hasDrawer   : product.hasDrawer,
			drawerGaps  : product.drawerGaps,
            detailImage : this.props.productSave == 'edit' && product.detailImage && !Array.isArray(product.detailImage) ? JSON.parse(product.detailImage) : Array.isArray(product.detailImage) ? product.detailImage : [],

            Gst         : this.props.gst,
            min_order   : this.props.min_order,
            material    : this.props.material,
            materialType: materialTypes,
            brand       : this.props.brand,
            substrate   : this.props.substrate,
            doorStyle   : this.props.door,
            hardware    : this.props.hardware,
            doorHinge   : this.props.hinge,
            shelves     : this.props.shelve,
            layouts     : this.props.layouts,
            stockCodes  : this.props.stockcodes,

            details     : product.details ? product.details : '',

            maxShelve   : this.props.maxShelve,
            minShelve   : this.props.minShelve,
            minDrawer   : this.props.minDrawer,
            maxDrawer   : this.props.maxDrawer,

            minFixedShelve : this.props.minFixedShelve,
            maxFixedShelve : this.props.maxFixedShelve,
            maxAdjustableShelve : this.props.maxAdjustableShelve,
            minAdjustableShelve : this.props.minAdjustableShelve,

            minDrawerEqual : this.props.minDrawerEqual,
            maxDrawerEqual : this.props.maxDrawerEqual,
            minxDrawerSmall : this.props.minDrawerSmall,
            maxDrawerSmall : this.props.maxDrawerSmall,
            minDrawerInner : this.props.minDrawerInner,
            maxDrawerInner : this.props.maxDrawerInner,

            DrawerSmall : product.DrawerSmall ? product.DrawerSmall : 0,
            DrawerEqual : product.DrawerEqual ? product.DrawerEqual : 1,
            DrawerInner : product.DrawerInner ? product.DrawerInner : 0,

            GapTop      : doorData.GapTop,
            GapBottom   : doorData.GapBottom,
            GapRight    : doorData.GapRight,
            GapLeft     : doorData.GapLeft,

            productSave : this.props.productSave,
            updateKey   : this.props.updateKey,
            slug        : product.slug,
            lineTop: this.props.productSave == 'edit' ? product.TopEdge : (product.ProductName.indexOf('Panels') != -1) ? 1 : 0,
            lineLeft:  this.props.productSave == 'edit' ? product.LeftEdge : (product.ProductName.indexOf('Panels') != -1) ? 1 : 0,
            lineBottom:  this.props.productSave == 'edit' ? product.BottomEdge : (product.ProductName.indexOf('Panels') != -1) ? 1 : 0,
            lineRight:  this.props.productSave == 'edit' ? product.RightEdge : (product.ProductName.indexOf('Panels') != -1) ? 1 : 0,
            NoOfMitre: product.NoOfMitre ? parseInt(product.NoOfMitre) : 0
        }, () => {
            //console.log('product.NoOfMitre', product.NoOfMitre)
            //console.log('after setdata product', this.state.product)
            this.updateImage();
        });

    }


  }

  recalculate() {

    /* var colorRPS = this.state.material[1].colour.filter(colour => {
        return colour.ColourId == this.state.MaterialColour2
    });

    //console.log('state', this.state);

    var shelveFHRH = this.state.shelves.filter(shelve => {
            return shelve.ShelveId == this.state.mshelves
        }); */


    let productprice = 0;
    let doorscost = 0;
    let shelvescost = 0;
    let carcasecost = 0;

    const hardwarecost = this.state.product.TotalHardwareCost;

    //const back = this.state.Width * this.state.Height;
    //const top = this.state.Width * this.state.Depth;
    //const bottom = this.state.Width * this.state.Depth;
    //const side = this.state.Height *  this.state.Depth * 2;

    //const carcase = (back + top + bottom + side) / 1000000;

    /* const materialType = this.state.materialType.filter(type => {
        return parseInt(this.state.MaterialType1) === type.MaterialTypeId
    }); */

    this.setState({
        Price: productprice,
        DoorsCost: doorscost,
        ShelvesCost: shelvescost,
        HardwareCost: hardwarecost,
        CarcaseCost: carcasecost
    }, () => {

    });

  }

  button(label, img, id)  {

    const target = `#${id}`;

    return (
      <button className="nav-link" data-toggle="pill" href={target}>
        <span><img alt={label} src={img} /></span>
        {label}
      </button>
    )
  }

  render() {

    ///console.log('rendering');

    const product = this.props.product;

    const status = product.Status ? product.Status : 'Pending';

    const productSave = this.props.productSave;

    const dimensionTab = this.button(strings.dimensionTab, '/images/dimension.png', 'dimension');
    const materialsTab = this.button(strings.materialsTab, '/images/materialicon.png', 'materials');
    //const doorOptionsTab = this.button(strings.doorOptionsTab, '/images/dooroption.png', 'doors');
    const shelfOptionsTab = this.button(strings.cabinetOptionsTab, '/images/ahelfoption.png', 'shelves');
    const doorGapsTab = this.button(strings.variationsTab, '/images/doorgap.png', 'gaps');

    const dimensionContent = this.dimensionContent();
    const materialContent = this.materialsContent();
    //const doorContent = this.doorOptionsContent();
    const shelvesContent = this.shelvesContent();
    const doorGapsContent = this.doorGapsContent();
    //const layoutContent = this.layoutContent();

    //console.log('5. jobProduct', this.props.products);

    console.log('this.state.productImage', this.state.productImage)

    return (
      <>
            <div className="col-xs-12 col-sm-6 col-md-4 leftsliderbox">
                      <h1>{product ? product.ProductName : ""}</h1>

                      <ImageSlider isCustomized={this.state.isCustomized} images={this.state.productImage} />

                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-5 midtabsection">

                      <Tabs defaultIndex={0} onSelect={this.handleTabSelect}>
                        <TabList>
                            <Tab>{dimensionTab}</Tab>
                            <Tab>{materialsTab}</Tab>
                            <Tab>{shelfOptionsTab}</Tab>
                            <Tab>{doorGapsTab}</Tab>
                        </TabList>

                        <TabPanel>
                            {dimensionContent}
                        </TabPanel>

                        <TabPanel>
                            {materialContent}
                        </TabPanel>

                        <TabPanel>
          								{shelvesContent}

          							</TabPanel>

                        <TabPanel>
                            {doorGapsContent}
                        </TabPanel>

                      </Tabs>
                      {
                          status === 'Pending' ?
                          (productSave === "edit") ?
                              <button className="upsize" onClick={this.updateCart}>Update</button>
                              :
                              <button className="upsize" onClick={this.addToCart}>{strings.AddToCart}</button>
                           : ""
                      }
                    </div>

      </>
    )
  }
}

class ImageSlider extends Component {

    /* constructor(props) {
        super(props);
    } */

    render() {
        //console.log('this.props.images', this.props.images)
        const isCustomized = this.props.isCustomized;
        const images = this.props.images.map( (image, key) => {
            return (
                <div key={key}>
                    <img alt={"slider" + key} id="carousel-img" src={image} />
                    <div className="overlay-bg" />
                </div>
            )
        });

        return (

            <>

            {
             (images.length > 0) ?

                <Carousel autoPlay>
                { images }
                </Carousel>

            :

                <div className="not-available">

                    { isCustomized == 1 ?

                        <h2>Customized Product</h2>

                        : '' }

                </div>

            }

            </>
        )
    }

}

export default ProductHomePanel
