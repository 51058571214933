import React, { Component } from 'react';
import Header from  '../components/Header';
import Footer from '../components/Footer';
import swal from "sweetalert";
import config from '../config';
//import Newsletter from '../components/Newsletter';

class Partners extends Component {

  constructor(props) {
    super(props);

        this.state = {
          slug: '',
          products: {},
          product: {},
          productSave:"save",
          materialArray: [],
          productsArray: [],
          jobProduct: [],
          project: {ProjectName: '', ProjectId: 0},
          isLogin: false,
          noProject: false,
          customerId: 0,
          jcustomer: [],
          jobId: 0,
          RoomId: 1,
          roomData: 'Room 1',
          defaultProduct: [],
          stockCodes: [],
          customer: {Name: '', Email: '', MobileNumber: '', CustomerId: 0}
        }

        this.doLogin = this.doLogin.bind(this);
        this.doLogout = this.doLogout.bind(this);
        this.addproject = this.addproject.bind(this);
        this.getActiveProject = this.getActiveProject.bind(this);
        this.goToProducts = this.goToProducts.bind(this);        
        
  }

  doLogout() {
    this.setState({isLogin:false, project: [], customer: [], customerId: 0})
    swal("", "You have been logout!", "success");
}

doLogin() {

    const customer = localStorage.getItem("customer");

    const project = localStorage.getItem("project");

    const jProject = JSON.parse(project);

    const jcustomer = JSON.parse(customer);

    let noProject = true;

    if (jcustomer.projects > 0 || (jProject && jProject.ProjectId > 0)) {
        noProject = false;
    }        

    this.setState(
        {
            isLogin:true, 
            customer : customer,
            jcustomer : jcustomer,
            customerId: jcustomer.CustomerId,
            project: jProject,
            noProject: noProject
        }
    );

    if (noProject) {
        this.getActiveProject(jcustomer.CustomerId);
    }    
    
}

addproject(data) {

  const customer = this.state.jcustomer;
  
  const params = {name: data.name, customerId: this.state.customerId, clientName: customer.Name, contactNo: customer.MobileNumber, email:customer.Email}

  fetch(config.BASE_URL + '/api/addnewproject', {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json'
      }
    })
    .then(data => data.json())
    .then(data => {

      let customer = JSON.parse(this.state.customer);

      customer.projects = 1;

      localStorage.setItem("customer", JSON.stringify(customer));
      localStorage.setItem("project", JSON.stringify(data));
      
      this.setState({noProject: false, project: data})
      
    })
    .catch(error => console.log(error)); 
  
}

getActiveProject(customerId) {
  fetch(config.BASE_URL + '/api/get-active-project/' + customerId , {
      method: "GET",
      headers: {
          "Content-Type": "application/json"
      }
  })
  .then(data => data.json())
  .then(data => {
      if (data.length > 0) {
          this.setState({
              noProject: false,
              project: data[0]
          });
      }
  })
  .catch(error => console.log(error));
}

goToProducts(productId, productSave) {
        
  fetch(config.BASE_URL + '/api/getproductbyId/' + productId, {
      method: "GET",
      headers: {
          "Content-Type": "application/json"
      }
  })
  .then(data => data.json())
  .then(data => {         
     
      //console.log('got product'); console.log(data[0]);

     this.setState({ products: data, product: data[0], productSave: productSave })
  })
  .catch(error => console.log(error));
}

componentDidMount() {

    const slug = this.props.match.params.slug;
    this.setState( {slug: slug} );
}

  render() {

    console.log('render', this.state);

    return (
      <>
        <Header 
            page="partners"
            saveDashboard={this.saveDashboard}
            saveProject={this.saveProject}
            sendProducts={this.goToProducts} 
            doLogin={this.doLogin} 
            doLogout={this.doLogout}
            project={this.state.project}
            saveLogin={this.saveLogin}
            isLogin={this.state.isLogin} />

        <Content slug={this.state.slug} />    
        
        <Footer />   
      </>
    )
  }
}

class Content extends Component {

  constructor(props) {

    super(props);

    this.state = {
        title: '',
        content: '',
        slug: ''
    }

    this.laminex = this.laminex.bind(this);
    this.polytec = this.polytec.bind(this);
    this.formica = this.formica.bind(this);
    this.blum  = this.blum.bind(this);
    this.hafele = this.hafele.bind(this);
    this.smartstone = this.smartstone.bind(this);
    this.corian = this.corian.bind(this);    

  }  

  laminex() {
      return (
          <>
            <div className="row">
                <div className="col-md-6">
                    <p>Laminex® Australia believes that good design makes good living. As the industry leader of decorative board, laminates and surfaces in Australia for over 80 years, they pride themselves on their proven quality, Australian made and environmentally sustainable products.</p>
                    <p>Laminex is a brand that is well-known and loved in Australia for generations. At Flat Packs WA we are proud to offer a full range of Laminex products and solutions for just about any application.</p>

                    <p><strong>What is Laminex?</strong><br />The portfolio of Laminex owned products include brands such as Laminex, Essastone, Formica and Cullitys building products.</p>
                    <p>Laminex manufacture raw wood panels, a vital product for decorative surfaces, and distribute both premium and trade products. Laminex products are made from either MDF substrate or particleboard.</p>

                    <p>Laminex is also a market leader in innovation and sustainability. Their products are “Greenfirst” produced which are independently certified for low emissions (VOCs).</p> 
                </div>
                <div className="col-md-6">
                    <p><strong>What makes Laminex a great brand?</strong><br />Laminex products have been around for a long time which means the company has a wealth of experience and insight into building products, developed over the course of many years. Their experience and longevity in the marketplace makes their products trusted, reliable and built-to-last.</p>
                    <p>With a long history of design and manufacturing knowledge in Australia, you can be sure that Laminex products will excite and delight the most discerning buyers.</p>

                    <p><strong>How do I know that I’m purchasing a good product?</strong><br />At Flat Packs WA, we have a long standing relationship with Laminex and our history means that we are able to supply their products with confidence to our valued customers.</p>

                    <p>We believe that excellent service and high quality products are the reasons our customers come back time after time. That’s why we work with Laminex and other outstanding brands that align with our mission to deliver the highest quality custom cabinetry around!</p>
                </div>
            </div>
           
          </>
      )
  }

  polytec() {
    return (
        <>
          <div className="row">

             <div className="col-md-6">   

                <p>Flat Packs WA is proud to offer Polytech decorative, finished board products which come in a range of stunning, versatile colours and are 100% Australian made.</p>

                <p>Polytech started as two brothers from Sydney and grew from there. They are now Australia wide and giving their competitors a serious run for their money.</p>

                <p>Polytech has lots of new products including Wood Matt which is one of their latest offerings. It comes in 7 or 8 aesthetic colours to suit a range of design applications.</p>

                <p>They look great and exhibit natural, authentic finishes which are open grain and pre-finished, ready-to-go.</p>

                <p>The Ravine range is also now available in store. These attractive products are embossed so they feel textured to the touch. The beauty of this range is that it comes in 10-12 colours and a has a range of thicknesses including 18, 25 and 32mm.</p>

                <p>They can be excellent for having thicker floating shelves the same colours as your doors. </p>

                <p>Polytech products are highly versatile and come in a range of different sizes. 3600 x 1200 wide, or even 1800 wide.</p>
            </div>

            <div className="col-md-6">

                

                <p>There is also a lightweight version which is made of an MDF substrate, making the bigger sheets much lighter and easier to work with.</p>
                
                <p>This is rarely seen across the industry at this stage. We also supply an outdoor MDF product which is virtually water proof.</p>

                <p> Distinctive Polytech board products are forcing the rest of the industry to sit up and take notice, offering a range of stunning colours which are modern and striking as well as high quality, on-trend and cost effective in the marketplace. </p>

                <p><img alt="" src="/images/polytec-image.png" /></p>

             </div>

          </div>
        </>
    )
}

formica() {
    return (
        <>

            <div className="row">

                <div className="col-md-6">

                    <p>For Australian architects, designers and specifers, changing trends and technology dictate the materials used, and for 100 years the Formica brand has been providing design professionals with decorative surface solutions.</p>

                    <p>Design and create stylish kitchens, bathrooms and commercial spaces with Formica® - easy, practical and with wide range of products.</p>
                    
                    <p>FORMICA® COLOUR RANGE features a simple mix and match collection of solids, patterns and woodgrains.</p>

                    <p>The range includes over 130 options for benchtops, with over 90 of those available for doors, drawers and vertical panels.</p>

                    <p>Whatever the application, the Formica Colour Range helps you create perfect interiors every time.</p>
                </div>

                <div className="col-md-6">

                    <img alt="" src="/images/formica-image.png" />

                </div>

            </div>
        </>
    )
}

blum() {
    return (
        <>

            <div className="row">

                <div className="col-md-6">
                    <p>Flat Packs WA is proud to source our materials from trusted suppliers. When it comes to hardware, we choose Blum for our hinge, lift and pull-out cabinet systems, as well as assembly tools.</p>

                    <p><strong>Did You Know?</strong><br />Blum is a family-owned hardware manufacturer based in Austria. It was originally founded by Julius Blum, an Austrian blacksmith, who made horseshoe studs and furniture hinges, and is now an international company with award-winning product quality and design.</p>
                    
                    <p>Blum’s soft-close system, BLUMOTION, is considered the best in industry for doors and hinges that close slowly, without a sound.</p>

                    <p><img alt="" src="/images/blum-image.png" /></p>

                 </div>

                 <div className="col-md-6">   

                    <h4>Why Blum?</h4>

                    <ul>
                        <li><strong>Lifetime Warranty</strong><br />Blum stands by their products and goes above and beyond to repair or replace their products. </li>
                        <li><strong>Legendary Quality</strong><br />Blum’s full product line and manufacturing machinery is rigorously tested for quality and durability; Blum also runs extensive research and development programs for its hinges and lift mechanisms. Blum hinges, in particular, are the best in the business; we offer their industry-leading AVENTOS HK-XS lift system for our cabinetry products.</li>
                        <li><strong>Variety and Price Point</strong><br />Blum hardware is available in black, white and grey,and there are options for every price point.</li>
                        <li><strong>Availability</strong><br />Blum chooses to sell through dealers only, and every Blum dealer must be trained and knowledgeable in their products. We appreciate this attention to detail and are a proud Blum dealer.</li>
                    </ul>

                    <p><strong>Try it Out - Online</strong><br />If you’re planning a renovation and wondering if custom-made cabinetry would suit your needs, try our online design tool to create and design your perfect cabinetry, and get an instant quote.</p>
                </div>
            </div>

        </>
    )
}

hafele() {
    return (
        <>
            <div className="row">

                <div className="col-md-6">
                    
                    <p>Welcome to the world of beautiful solutions for modern living.</p>

                    <p>Häfele believes that the perfect home is about more than just great looks. It is also about creating a space that perfectly combines style with function, and is tailor made to suit you and your lifestyle.</p>

                    <p>This is the Häfele ‘ideas for living’ philosophy. Creating individual, inspiring, functional spaces that have a positive impact on the way you live.</p>
                    
                    <p>Established in Germany and now operating all over the world, the name Häfele is famous for  innovation, quality and engineering.</p>

                    <p>Our fittings, hardware and systems improve the way that kitchens work in every way possible.</p>
                    
                    <p>In choosing Häfele you are benefiting from  decades of specialist knowledge and experience brought to life through ideas that will enhance your experience of your home  very day – and for many years to come:</p>

                    <p><strong>Ideas from Häfele are built to last.</strong></p>

                </div>

                <div className="col-md-6">

                    <img alt="" src="/images/hafele-image.png" />

                </div>

            </div>
        </>
    )
}

corian() {
    return (
        <>
             <div className="row">

                <div className="col-md-6">

                    <p><strong>Freedom in creativity... Trust in performance.</strong></p>

                    <p>Quite simply, DuPont™ Corian® is the seamless solution to a multitude of demands in surface design. Enabling imagination to meet the needs of functionality, Corian® adapts beautifully to any role. From subtle elegance to the most exotic of forms, this is a material created with innovation in mind.</p>

                    <p>Pure in its solidity, powerful in its performance, versatile to its core, Corian® can shape itself to almost any concept with effortless style. And these valuable talents were built to last.</p>
                    
                    <p>Whether working hard on an easy-care countertop or enduring as a decorative wall design, CorianDuPont™ Corian® is the embodiment of grace under pressure. Welcoming shape, pattern and light, this high tech surface is comfortable in all kinds of environments, interior or exterior, humid or dry, hygiene-critical or high-traffic.</p>

                    <p>DuPontTM Corian® is a material that has embodied a spirit of continual evolution ever since its ground breaking invention in the 60s, both in its flexible nature and in the hands of the creative thinkers who have pushed forward its capabilities.</p>

                </div>

                <div className="col-md-6">

                    <p><strong>MATERIAL INSPIRATION</strong><br />From idea to reality, the resourcefulness of Corian® is entirely at your creative disposal.</p>
                    
                    <p>Workable to an unrestrained variety of forms and blending into seamless utility, Corian® Can answer the  challenges of multiple projects and applications. Strong, homogenous, Non-porous and resistant, Corian® is a material as ingenious as the human imagination.</p>

                    <p><img alt="" src="/images/corian-image.png" /></p>
                
                </div>

            </div>
        </>
    )
}

smartstone() {
    return (
        <>
          Content Here
        </>
    )
}
  

  render() {

    let title = '';
    let content = '';    

    let background = '/images/about-us-banner.jpg';
    let position = 'center center';

    switch (this.props.slug) {
        case 'laminex':

            title = 'Laminex';

            content = this.laminex();

            background = '/images/laminex-banner.png';

        break;

        case 'polytec':

            title = 'Polytec';

            content = this.polytec();

            background = '/images/polytec-banner.jpg';

        break;

        case 'blum':

            title = 'Blum Hardware';

            content = this.blum();

            background = '/images/blum-banner.png';

            position = '0 -83px';

        break;

        case 'formica':

            title = 'Formica';

            content = this.formica();

            background = '/images/formica-banner.png';

        break;

        case 'corian':

            title = 'Corian';

            content = this.corian();

            background = '/images/corian-banner.png';

        break;

        case 'hafele':

            title = 'Hafele';

            content = this.hafele();

            background = '/images/hafele-banner.png';

            position = 'top center';

        break;

        case 'smartstone':

            title = 'Smart Stone';

            content = this.smartstone();

            background = '/images/laminex-banner.png';

        break;

        default:
    }

    return (

    <>

      <div className="top-background main-banner" style={  { backgroundImage: `url(${background})`, backgroundPosition: `${position}`}  }>
        <div className="container">
            <div className="row">
              <div className="col-md-12">
                
                <h1>{title}</h1>

              </div>
            </div>
        </div>
      </div>

      <div className="main-content">

        <div className="container">

            <div className="row news-article">

                {content}
             

            </div>

        </div>

      </div>

    </>

    )

  }

}

export default Partners
